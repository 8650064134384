import { Box, Typography, Switch, Button, Checkbox } from '@mui/material'
import React, { useState } from 'react'
import Modal from '../../../components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import ColorPicker from '../../../components/ColorPicker/ColorPicker'
import { DateTime } from '../../../services/time'
import { useFormik } from 'formik'
import {
  CompanysApi,
  type IndicatorSetting,
  TimeSheetApi
} from '../../../services/Swagger'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

interface DialogProps {
  onClose: () => void
  indicatorSettings: IndicatorSetting[] | undefined
}
interface FormProps {
  indicatorSettings: IndicatorSetting[] | undefined
  auto_deduct: boolean
}

export default function DialogOption ({
  onClose,
  indicatorSettings
}: DialogProps): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const { data } = useQuery(
    ['api/company/getCompanySetting/approve_timesheet'],
    async () => {
      const res = await new CompanysApi().getCompanySetting(
        'approve_timesheet'
      )
      return res.data
    }
  )

  const handleSubmit = async (values: FormProps): Promise<void> => {
    try {
      setIsLoading(true)
      // Save approve_timesheet setting
      const params: any = { ...data }
      params.value.auto_deduct_breaktime.value = values.auto_deduct
      await new CompanysApi().updateCompanySetting('approve_timesheet', params)
      // Save  indicatorSettings
      if (values.indicatorSettings != null) {
        await new TimeSheetApi().changeIndicatorSetting(
          values.indicatorSettings
        )
      }
      onClose()
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik<FormProps>({
    initialValues: {
      auto_deduct: (data as any)?.value?.auto_deduct_breaktime.value ?? false,
      indicatorSettings
    },
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
  const isShowChecked = (indicatorSettings: IndicatorSetting[] | undefined): boolean => {
    return ((indicatorSettings?.find((setting) => setting.is_show)) != null)
  }
  return (
    <Modal open onClose={onClose} size="small" title={t('00_06_options')}>
      <Box sx={{ p: 4 }}>
        <form onSubmit={formik.handleSubmit}>
          <Box className="Tools-break">
            <Typography>{t('02_00_auto_deduct_break')}</Typography>
            <Switch
              checked={formik.values.auto_deduct}
              onChange={(event, checked) => { void formik.setFieldValue('auto_deduct', checked) }
              }
            />
          </Box>
          <div className="border"></div>
          <Box className="Tools-break">
            <Typography>{t('02_00_show_indicator')}</Typography>
            <Switch
              checked={isShowChecked(formik.values.indicatorSettings)}
              onChange={(event, checked) => {
                formik.values.indicatorSettings?.forEach((value, index) => {
                  void formik.setFieldValue(
                    `indicatorSettings[${index}].is_show`,
                    checked
                  )
                })
              }}
            />
          </Box>
          {isShowChecked(formik.values.indicatorSettings) &&
            formik.values.indicatorSettings?.map((st: any, index: number) => (
              <Box key={index} className="Tools-color">
                <Checkbox
                  checked={st.is_show}
                  onChange={(e, checked) => {
                    void formik.setFieldValue(
                      `indicatorSettings[${index}].is_show`,
                      checked
                    )
                  }}
                />
                <Typography
                  sx={{ minWidth: 200 }}
                >{`Over ${DateTime.ConverMinute(
                  st.value
                )} minutes`}</Typography>
                <Box sx={{ minWidth: 150, marginLeft: 'auto' }}>
                  <ColorPicker
                    onChange={(newColor: string) => {
                      void formik.setFieldValue(
                        `indicatorSettings[${index}].color`,
                        newColor
                      )
                    }}
                    value={st.color}
                    disabled={st.is_show}
                  />
                </Box>
              </Box>
            ))}
          <Button
            onClick={() => { formik.handleSubmit() }}
            disabled={isLoading}
            sx={{ display: 'block', marginLeft: 'auto', mt: 4 }}
            variant="contained"
            color="primary"
          >
            {t('00_03_apply')}
          </Button>
        </form>
      </Box>
    </Modal>
  )
}
