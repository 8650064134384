import React, { useState } from 'react'
import { Modal } from '../../../components'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import { TimeSheetApi, type TimeSheetDetailUpdate } from '../../../services/Swagger'
import moment from 'moment'
import { toast } from 'react-toastify'
import storage from '../../../services/storage'

interface ClockOutModalProps {
  onClose: () => void
  data?: ListTimeSheet
  dataUpdate: () => void
}

export default function ClockOutModal ({ onClose, data, dataUpdate }: ClockOutModalProps): JSX.Element {
  const companySetting = storage.getItem('COMPANY_INFOMATION')
  const offset = moment().tz(companySetting?.timezone).utcOffset() * 60
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const handleClockOut = async (): Promise<void> => {
    const newData: TimeSheetDetailUpdate = {
      registration_time_approve_id: data?.timesheet.timesheet_id ?? 0,
      check_out_time: moment().unix(),
      group_id: data?.timesheet.group_id,
      employee_id: data?.employee.employee_id,
      checkin_note: '',
      checkout_note: '',
      stamp_value_in: '',
      stamp_value_out: '',
      is_client_in: 2,
      is_client_out: 2,
      is_approved: false,
      offset,
      is_lock: false,
      is_break: false,
      parent_id: 0,
      is_paid: false
    }
    try {
      onClose()
      const checkInTime = data?.timesheet.check_in_time
      if ((checkInTime != null) && (newData.check_out_time ?? 0) < checkInTime) {
        toast.error(t('02_00_clocked_out_time_cannot_earlier_than_clocked_in_time'), {
          position: toast.POSITION.BOTTOM_RIGHT
        })
        return
      }
      await new TimeSheetApi().updateTimeRegistration([newData])
      setIsLoading(true)
      dataUpdate()
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
        <Modal
            open={true}
            onClose={() => { onClose() }}
            size="small"
            title={t('20_03_clock_out')}
        >
            <Box sx={{ px: 4, py: 2 }}>
                <Grid sx={{ px: 4, py: 10 }}>
                    <Typography variant='subtitle1' textAlign={'center'}>{t('02_00_clockout_confirm_message')}</Typography>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    sx={{ py: 2, justifyContent: 'flex-end' }}
                >
                    <Grid item>
                        <Button onClick={() => { onClose() }} variant="outlined">
                            {t('20_13_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            disabled={isLoading}
                            onClick={() => { void handleClockOut() }}
                        >
                            {t('22_00_ok')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}
