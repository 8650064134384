import moment from 'moment'
import {
  type CompanySetting,
  type EmployeeDetailNew,
  type ListShiftForTimeSheet,
  type ReponseListTimeSheet
} from '../../../services/Swagger'
import { groupBy } from 'lodash'
import { DateTime } from '../../../services/time'
import storage from '../../../services/storage'

export interface ListTimeSheet {
  id: string
  timestamp: number
  timesheet: ReponseListTimeSheet
  employee: EmployeeDetailNew
  shifts: ListShiftForTimeSheet[]
  scheduleTotal: number
  workStartTime?: number
  workEndTime?: number
}

function getShiftInfo (shifts: ListShiftForTimeSheet[]): { scheduleTotal: number, workStartTime?: number, workEndTime?: number } {
  const scheduleTotal = shifts?.reduce(
    (a, b) => a + Number(b?.duration) - Number(b.duration_break),
    0
  ) ?? 0
  // lấy ra starttime của Scheduled shift đầu và endtime của Scheduled shift cuối cùng
  const workStartTime = (shifts?.length > 0)
    ? shifts[0].start_time
    : 0
  const workEndTime = (shifts?.length > 0)
    ? shifts[shifts.length - 1].end_time
    : 0
  return { scheduleTotal, workStartTime, workEndTime }
}
// Group shift to departments structure
export function processData (
  employees: EmployeeDetailNew[],
  timesheets: ReponseListTimeSheet[],
  shifts: ListShiftForTimeSheet[]
): ListTimeSheet[] {
  const getStartOfDay = (startTime?: number): number =>
    moment
      .unix(startTime ?? 0)
      .startOf('D')
      .unix()

  const timesheetGroupedData = groupBy(timesheets, (item) => {
    return item.employee_id
  })

  const shiftGroupedData = groupBy(shifts, (item) => {
    return item.employee_id ?? item.open_employee_id
  })
  const newRowData: ListTimeSheet[] = []
  employees?.forEach((employee) => {
    const employeeId = employee?.employee_id ?? 0
    const eTimesheets: ReponseListTimeSheet[] = timesheetGroupedData[employeeId]
    const eShiftsCanMerge: ListShiftForTimeSheet[] = shiftGroupedData[employeeId]?.filter(shift => {
      if (eTimesheets === undefined) return false
      return eTimesheets?.findIndex(item => getStartOfDay(item.check_in_time) === getStartOfDay(shift.start_time)) > -1
    })
    const eShiftsCanNotMerge: ListShiftForTimeSheet[] = shiftGroupedData[employeeId]?.filter(shift => {
      if (eTimesheets?.length === 0 || eTimesheets === undefined) return true
      return eTimesheets?.findIndex(item => getStartOfDay(item.check_in_time) !== getStartOfDay(shift.start_time)) > -1
    })
    if (eTimesheets?.length > 0) {
      eTimesheets?.forEach(timesheet => {
        const shiftsByTimesheet: ListShiftForTimeSheet[] = eShiftsCanMerge?.filter(
          (shift) => {
            return (
              Number(shift.employee_id) === Number(timesheet.employee_id) &&
              getStartOfDay(timesheet.check_in_time) ===
                getStartOfDay(shift.start_time)
            )
          }
        )
        const { scheduleTotal, workStartTime, workEndTime } = getShiftInfo(shiftsByTimesheet)
        newRowData.push({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          id: `${timesheet.timesheet_id?.toString()}${employee?.employee_id?.toString()}${timesheet.check_in_time?.toString()}-${(Math.random() + 1).toString(36).substring(7)}`,
          timestamp: getStartOfDay(timesheet.check_in_time),
          timesheet,
          workStartTime,
          workEndTime,
          employee: employee ?? {},
          shifts: shiftsByTimesheet,
          scheduleTotal
        })
      })
    }
    const eShiftsCanNotMergeByDate = Object.values(groupBy(eShiftsCanNotMerge, (item) => getStartOfDay(item.start_time)))
    if (eShiftsCanNotMergeByDate?.length > 0) {
      eShiftsCanNotMergeByDate?.forEach(eShifts => {
        const { scheduleTotal, workStartTime, workEndTime } = getShiftInfo(eShifts)
        newRowData.push({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          id: `${eShifts[0].shift_id?.toString()}${employee?.employee_id?.toString()}${eShifts[0].start_time?.toString()}-${(Math.random() + 1).toString(36).substring(7)}`,
          timestamp: getStartOfDay(eShifts[0].start_time),
          timesheet: {},
          workStartTime,
          workEndTime,
          employee: employee ?? {},
          shifts: eShifts,
          scheduleTotal
        })
      })
    }
  })
  return newRowData
}
export interface TimesheetCheckResponse {
  isValid: boolean
  message: string
}

export function checkTimesheetGreaterThan (
  item: ListTimeSheet,
  companySetting: CompanySetting | any,
  t: (...args: any[]) => string
): TimesheetCheckResponse {
  const defaultPlanDuration = Number(
    storage.getItem('COMPANY_INFOMATION').default_plan_duration
  )
  const warningDuration = Number(companySetting.value.warning_duration.value)
  const warningDurationType = companySetting.value.warning_duration_type.value
  // conver timestamp ra phút
  const worked = moment
    .duration(item.timesheet !== undefined ? item.timesheet.actual_total : 0, 'seconds')
    .asMinutes()
  // total time custom
  const convertWarningDuration = DateTime.FormatDuration(warningDuration * 60)
  // total time default
  const tooltipDefault = DateTime.FormatDuration(
    (defaultPlanDuration + warningDuration) * 60
  )

  if (warningDurationType === 'no') return { isValid: false, message: '' }
  if ((worked !== 0) && warningDurationType === 'custom' && worked > warningDuration) {
    return {
      isValid: true,
      message: `${t('02_00_over_time_custom', {
        total_time: convertWarningDuration
      })}`
    }
  } else if (
    warningDurationType === 'scheduled_total' &&
    item.shifts.length > 0
  ) {
    const shiftDuration = moment
      .duration(item.shifts[0].duration, 'seconds')
      .asMinutes()
    // total timescheduled_total
    const convertShiftDuration = DateTime.FormatDuration(shiftDuration * 60)
    if (worked > warningDuration + shiftDuration) {
      return {
        isValid: true,
        message: `${t('02_00_over_time_tooltip', {
          total_time: convertShiftDuration
        })}`
      }
    }
  } else if (
    (worked !== 0) &&
    warningDurationType === 'standard_shift_duration' &&
    worked > warningDuration + defaultPlanDuration
  ) {
    return {
      isValid: true,
      message: `${t('02_00_over_time_default_duration', {
        total_time: tooltipDefault
      })}`
    }
  }
  return { isValid: false, message: '' }
}
