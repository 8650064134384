import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Select,
  type SelectProps,
  Typography,
  Box,
  TextField
} from '@mui/material'
import { cloneDeep } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../Icon/Icon'
import { useEffect, useState } from 'react'
import storage from '../../services/storage'
import { MODULEID } from '../../services/modules'
import { CheckAclTimesheet } from '../../pages/Timesheet/Helpers/TimesheetAcl'
interface SelectedGroup {
  group_id: number
}

interface SelectedDepartments {
  department_id: number
  groups: SelectedGroup[]
}

interface Props {
  initDefaultAll?: boolean
  onChange?: (deparments: SelectedDepartments[]) => void
  onClose?: (deparments: SelectedDepartments[]) => void
  SelectProps?: SelectProps
  value?: any[]
  valueType?: 'groupArr' | 'groupIds' | 'deparment'
}
const MenuProps = {
  PaperProps: {
    style: {
      width: 350,
      maxHeight: '50vh'
    }
  }
}

export default function GroupPicker ({
  onChange,
  onClose,
  SelectProps,
  value,
  valueType
}: Props): JSX.Element {
  const { t } = useTranslation()
  const departments = storage.getItem('ALL_DEPARTMENT')
  const groupStore = storage.getItem('GROUP_STORE')
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [selected, setSelected] = useState<SelectedDepartments[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const filteredData = new CheckAclTimesheet().GetGroupView(departments, MODULEID.timesheet)

  const selectAll = (): void => {
    const dataSelect: any[] = getAllSelectedDepartment(filteredData)
    setSelected([...dataSelect])
    setSelectAllChecked(true)
  }
  useEffect(() => {
    const totalDepartmentGroups = filteredData.reduce((total: number, department: { groups: any[] }) => {
      return total + department.groups.length
    }, 0)

    const totalSelectedGroups = selected.reduce((total, department) => {
      return total + department.groups.length
    }, 0)

    if (totalDepartmentGroups === totalSelectedGroups) {
      setSelectAllChecked(true)
    }
  }, [selected])

  const handleChangeDepartment = (checked: boolean, departmentId: number): void => {
    const status = getDepStatus(+departmentId)
    let indexDep = -1
    let selectedDep = cloneDeep(selected).find((dep, index) => {
      if (+dep.department_id === +departmentId) {
        indexDep = index
        return true
      } else {
        return false
      }
    })
    if (selectedDep != null) {
      selectedDep.groups = []
    }
    if (selectedDep?.groups.length === 0) {
      setSelectAllChecked(false) // Uncheck "Check All" checkbox
    }
    const orgDep = departments.find(
      (dep: any) => +dep.department_id === +departmentId
    )
    const newGroups: SelectedGroup[] = []
    switch (status) {
      case 1: // all
        if (selectedDep != null) {
          selectedDep.groups = []
        }
        break
      case 0: // indeterminate
      case -1:
        orgDep.groups.map((group: any): boolean => {
          newGroups.push({ group_id: +group.group_id })
          return true
        })
        if (selectedDep === undefined) {
          selectedDep = { department_id: +departmentId, groups: newGroups }
        } else {
          selectedDep.groups = newGroups
        }
        break
    }
    const newSelected = [...selected]
    if (newSelected.length > 0 && (selectedDep != null)) {
      newSelected[indexDep] = selectedDep
      setSelected([...newSelected])
    }
    if (indexDep < 0 && (selectedDep != null)) {
      newSelected.push({ ...selectedDep })
      setSelected([...newSelected])
    }
  }

  const handleChangeGroup = (
    checked: boolean,
    departmentId: number,
    groupId: number
  ): void => {
    let indexDep = -1
    const newGroupSelected: SelectedGroup = { group_id: +groupId }
    const selectedDep = selected.find((dep, index) => {
      if (+dep.department_id === +departmentId) {
        indexDep = index
        return true
      }
      return false
    })
    if (checked) {
      if (selectedDep != null) {
        selectedDep.groups.push(newGroupSelected)
      } else {
        setSelected([
          ...selected,
          { department_id: +departmentId, groups: [newGroupSelected] }
        ])
      }
    } else {
      if (selectedDep != null) {
        let indexGroup = -1
        const isSelectGroup = selectedDep.groups.find((group, index) => {
          if (+group.group_id === +groupId) {
            indexGroup = index
            return true
          }
          return false
        })
        if (isSelectGroup != null) {
          selectedDep.groups.splice(indexGroup, 1)
        }
      }
    }
    const newSelected = [...selected]
    if (newSelected.length > 0 && (selectedDep != null)) {
      newSelected[indexDep] = selectedDep
      setSelected([...newSelected])
    }
  }

  const getDepStatus = (departmentId: number): number => {
    const orgDep = departments.find(
      (dep: any) => +dep.department_id === +departmentId
    )
    const selectedDep = selected.find(
      (dep) => +dep.department_id === +departmentId
    )
    if ((selectedDep == null) || selectedDep.groups.length === 0) {
      return -1
    } else {
      return orgDep.groups.length === selectedDep.groups.length ? 1 : 0
    }
  }

  const getGrStatus = (departmentId: number, groupId: number): boolean => {
    const selectedDep = selected.find(
      (dep) => +dep.department_id === +departmentId
    )
    if (selectedDep == null) return false
    const checked = selectedDep.groups.find(
      (group) => +group.group_id === +groupId
    )
    return (checked != null)
  }

  const renderValue = (): string | JSX.Element => {
    const groups: any[] = getGroupsArr(selected)
    if (groups.length > 0) {
      return `${(groupStore[groups[0].group_id].group_name) as string} ${groups.length > 1 ? `+ ${groups.length - 1}` : ''
        }`
    } else {
      return <Typography variant="body2">{t('00_06_seletct')}</Typography>
    }
  }

  const onSelectChange = (): void => {
    const data = selected.filter((dep) => dep.groups.length > 0)
    if (onChange != null) { onChange(data) }
  }

  const onSelectionClose = (newSelected?: SelectedDepartments[]): void => {
    const data = (newSelected ?? selected).filter((dep) => dep.groups.length > 0)
    if (onClose != null) { onClose(data) }
  }

  React.useEffect(() => {
    if ((value?.length) != null) {
      let selected: any[] = []
      switch (valueType) {
        case 'groupArr':
        case 'groupIds':
          selected = getSelectedData(filteredData, value, valueType)
          if (selected.length > 0) {
            setSelected([...selected])
          }
          break
      }
    }
  }, [value])
  const handleSelectAll = (checked: boolean): void => {
    setSelectAllChecked(checked) // Update "selectAllChecked" state
    if (checked) {
      selectAll() // Call selectAll function to check all checkboxes
    } else {
      setSelected([]) // Clear selected departments and groups
    }
  }
  const filteredDepartments = filteredData.filter((department: any) =>
    department.groups.length > 0 && department.groups.some((group: any) =>
      group.group_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  )
  return (
    <FormControl
      className='Departments'
      fullWidth>
      <Select
        multiple
        displayEmpty
        MenuProps={MenuProps}
        value={selected}
        renderValue={renderValue}
        onChange={onSelectChange}
        onClose={() => { onSelectionClose() }}
        className={SelectProps?.className ?? ''}
        startAdornment={
          <Icon iconName="Group" style={{ marginRight: 8, fontSize: 16 }} />
        }
        {...SelectProps}
      >
        <Box sx={{ mt: 3, px: 4 }}>
          <TextField
            className=''
            fullWidth
            value={searchQuery}
            onChange={(event) => { setSearchQuery(event.target.value) }}
            placeholder={t('20_92_search') ?? ''}>
          </TextField>
        </Box>
        <FormControlLabel
          label={t('00_06_all_employee_roles')}
          className='Departments-bold'
          sx={{ pl: 4 }}
          control={
            <Checkbox
              checked={selectAllChecked}
              onChange={(event) => { handleSelectAll(event.target.checked) }}
            />
          }
        />
        {filteredDepartments
          .map((department: any) => (
            <div key={`department${department.department_id as string}`}>
              <FormControlLabel
                label={department.department_name}
                className='Departments-bold'
                sx={{ pl: 4 }}
                control={
                  <>
                    <Checkbox
                      indeterminate={getDepStatus(department.department_id) === 0}
                      checked={getDepStatus(department.department_id) === 1}
                      onChange={(event: any, checked) => {
                        handleChangeDepartment(checked, department.department_id)
                      }} />
                  </>
                }
              />
              {department.groups.map((group: any) => {
                return (
                  <div key={`group_${group.group_id as string}`}>
                    <FormControlLabel
                      sx={{ pl: 8 }}
                      label={group.group_name}
                      control={
                        <Checkbox
                          checked={getGrStatus(
                            department.department_id,
                            group.group_id
                          )}
                        />
                      }
                      onChange={(event: any, checked: boolean) => {
                        handleChangeGroup(
                          checked,
                          department.department_id,
                          group.group_id
                        )
                      }}
                    />
                  </div>
                )
              })}
            </div>
          ))}
      </Select>
    </FormControl>
  )
}

export function getGroupsArr (deparments: SelectedDepartments[]): SelectedGroup[] {
  let groups: SelectedGroup[] = []
  const dp = cloneDeep(deparments as any[])
  dp.map((dep) => {
    dep.groups.map((group: any, key: number) => {
      dep.groups[key].department_id = dep.department_id
      return true
    })
    groups = groups.concat(
      dep.groups.map((group: any) => {
        return { group_id: group.group_id }
      })
    )
    return true
  })
  return groups
}

export function getGroupIds (deparments: SelectedDepartments[]): number[] {
  const groups: SelectedGroup[] = getGroupsArr(deparments)
  const ids = groups.map((group) => group.group_id)
  return ids
}

export function getSelectedData (
  departments: any[],
  value: any[],
  valueType: string
): SelectedDepartments[] {
  const selected: SelectedDepartments[] = []
  departments.forEach((department: any) => {
    const groups: any[] = []
    department.groups?.forEach((group: any) => {
      if (valueType === 'groupArr') {
        if (value.length > 0 && (Boolean(value.find((g) => +g.group_id === +group.group_id)))) {
          groups.push({
            group_id: +group.group_id
          })
        }
      } else if (valueType === 'groupIds') {
        if (value?.includes(+group.group_id)) {
          groups.push({
            group_id: +group.group_id
          })
        }
      }
    })
    if (groups.length > 0) {
      selected.push({
        department_id: department.department_id,
        groups
      })
    }
  })
  return selected
}

export function getAllSelectedDepartment (departments?: any[]): any[] {
  const data =
    (departments != null) && departments.length > 0
      ? departments
      : storage.getItem('ALL_DEPARTMENT')
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const dataSelect: any[] = [...data].map(({ department_id, groups }: any) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const groupMapped = groups?.map(({ group_id, department_id }: any) => {
      return { group_id, department_id }
    })
    return {
      department_id,
      groups: groupMapped
    }
  })
  return dataSelect
}

export function getAllGroupIds (departments?: any[]): SelectedGroup[] {
  const data =
    (departments != null) && departments.length > 0
      ? departments
      : storage.getItem('ALL_DEPARTMENT')
  return getGroupsArr(data)
}
