/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Grid, Typography } from '@mui/material'
import Modal from '../../../components/Modal/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import { DateTime } from '../../../services/time'
import { TimesheetHelper } from '../Helpers/TimesheetHelper'
import { type ReponseListTimeSheet, type RegistrationApprovedDetailByTimesheetIdResponse } from '../../../services/Swagger'

interface ApproveDetailProps {
  onClose: () => void
  approveData: RegistrationApprovedDetailByTimesheetIdResponse[]
  timesheet?: ListTimeSheet
  employeeTimesheet?: ReponseListTimeSheet[]
  approveTotal: any
}

export default function ApproveDetail ({ onClose, approveData, timesheet, employeeTimesheet, approveTotal }: ApproveDetailProps): JSX.Element {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const noData = require('../../../assets/images/no_data_found.svg').default

  return (
        <Modal open onClose={onClose} size="small" title={t('00_06_approved_details')}>
            <Box sx={{ px: 4, py: 2 }}>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('20_24_scheduled_total')}</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'center'}>
                        <Typography>
                            {((timesheet) != null)
                              ? DateTime.FormatDuration(new TimesheetHelper().calculateTotalScheduledTotal(timesheet.shifts))
                              : DateTime.FormatDuration(approveTotal.totalShifts)} {t('00_06_unit_hours_long')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('20_24_scheduled_break')}</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'center'}>
                        <Typography>{((timesheet) != null) ? DateTime.FormatDuration(new TimesheetHelper().calculateTotalScheduledBreak(timesheet?.shifts)) : DateTime.FormatDuration(approveTotal.totalShiftBreak)} {t('00_06_unit_hours_long')}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('20_24_worked_total')}</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'center'}>
                        <Typography>
                            {((timesheet) != null)
                              ? DateTime.FormatDuration(timesheet.timesheet.actual_total ?? 0)
                              : DateTime.FormatDuration(approveTotal.totalTimesheet)} {t('00_06_unit_hours_long')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('20_24_worked_break')}</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={'center'}>
                        <Typography>{((timesheet) != null) ? DateTime.FormatDuration(new TimesheetHelper().calculateTotalBreaks(employeeTimesheet)) : DateTime.FormatDuration(approveTotal.totalSheetBreak)} {t('00_06_unit_hours_long')}</Typography>
                    </Grid>
                </Grid>
                {approveData.length > 0
                  ? (
                      approveData.map((item: RegistrationApprovedDetailByTimesheetIdResponse) => (
                        <Grid key={item.registration_time_approve_id} container alignItems="center" className='border' spacing={2} sx={{ py: 3 }}>
                            <Grid item xs={7}>
                                <Typography fontWeight={500}>{`${((item.value as any).code)} - ${((item.value as any).name)}`}</Typography>
                            </Grid>
                            <Grid>
                                <Typography>{new TimesheetHelper().getRangeTimeDeciamal(item)}</Typography>
                            </Grid>
                        </Grid>
                      ))
                    )
                  : (<Grid textAlign={'center'} sx={{ py: 3 }}>
                    <img src={noData} alt="no-data"></img>
                    <Typography>{t('02_00_no_timesheets_is_approved')}</Typography>
                    </Grid>)}
            </Box>
        </Modal>
  )
}
