import React from 'react'
import { type ReponseListTimeSheet } from '../../../services/Swagger'
import { Box } from '@mui/material'

interface ShiftAbbreviationProp {
  timeSheet: ReponseListTimeSheet
}

const TimeSheetAbbreviation = ({ timeSheet }: ShiftAbbreviationProp): JSX.Element => {
  const backgroundColor = timeSheet.shift_type_color ?? ''
  return (
    <Box className="TimeSheetAbbreviation" style={{ backgroundColor }}>
      {timeSheet.abbreviation}
    </Box>
  )
}

export default TimeSheetAbbreviation
