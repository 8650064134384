import * as React from 'react'
import { Alert, Box, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Storage from '../../../services/storage'
import { useNavigate } from 'react-router-dom'

export default function Logout (): JSX.Element {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const getData = (): void => {
    try {
      Storage.clear()
      navigate('/auth/login')
    } catch (ex: any) {
      setLoading(false)
    }
  }
  React.useEffect(() => {
    getData()
  }, [])
  return (
    <Box width={'100%'}>
      <Box sx={{ my: 4 }}>
        {loading && (
          <>
            <Box sx={{ mb: 10, justifyContent: 'center', display: 'flex' }}>
              <CircularProgress size={40} />
            </Box>
            <Typography component="h1" variant="h6" align="center">
              {t('15_01_logout_text')}
            </Typography>
          </>
        )}
        {!loading && (
          <Alert sx={{ mb: 2 }} severity="error">
            {t('15_01_logout_text')}
          </Alert>
        )}
      </Box>
    </Box>
  )
}
