import React, { useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { DateTime } from '../../../services/time'
import { useTranslation } from 'react-i18next'
import { type EmployeeDetailNew, TimeSheetApi } from '../../../services/Swagger'
import { ImageModal, Modal } from '../../../components'
import { useQuery } from '@tanstack/react-query'
import { type ListTimeSheet } from '../Services/TimesheetServices'

interface TimesheetActionProps {
  data?: ListTimeSheet
  onClose: () => void
  employee?: EmployeeDetailNew
}

export default function TimeSheetDetail ({
  data,
  onClose,
  employee
}: TimesheetActionProps): JSX.Element {
  const { t } = useTranslation()

  const timesheetDetail = useQuery(
    ['api/timeregistration/getDetailTimeSheet'],
    async () => {
      const res = await new TimeSheetApi().getDetailTimeSheet(data?.timesheet.timesheet_id ?? 0)
      return res.data
    }
  )
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const handleImageClick = (imageUrl: any): void => {
    setSelectedImage(imageUrl)
    setImageModalOpen(true)
  }

  return (
        <Modal
            open={true}
            onClose={() => { onClose() }}
            size='small'
            title={t('02_00_timesheet_detail', {
              name: data?.employee.fullname,
              date: DateTime.FormatDate(data?.timesheet.check_in_time, 'days')
            })}
        >
            <TableContainer sx={{ padding: 3, overflow: 'scroll', maxHeight: '600px' }} component={Paper}>
            <ImageModal onClose={() => { setImageModalOpen(false) }} url={selectedImage} open={imageModalOpen} />
                <Table aria-label='timesheet'>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell
                                sx={{ textTransform: 'uppercase', borderBottom: '1px solid #e5e5e5' }}
                                align='left'
                            >
                                {t('20_04_clock_in')}
                            </TableCell>
                            <TableCell
                                sx={{ textTransform: 'uppercase', borderBottom: '1px solid #e5e5e5' }}
                                align='left'
                            >
                                {t('20_22_clock_out')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                <Typography variant='body2'>{t('02_00_real_time')}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                {((timesheetDetail.data?.registration_time?.check_in_time) != null)
                                  ? (
                                        <>
                                            {DateTime.GetHours(
                                              parseInt(timesheetDetail.data?.registration_time?.check_in_time)
                                            )}{' '}
                                            -{' '}
                                            {DateTime.FormatDate(
                                              parseInt(timesheetDetail.data?.registration_time?.check_in_time)
                                            )}
                                        </>
                                    )
                                  : (
                                      '-'
                                    )}
                            </TableCell>
                            <TableCell align='left'>
                                {((timesheetDetail.data?.registration_time?.check_out_time) != null)
                                  ? (
                                        <>
                                            {DateTime.GetHours(
                                              parseInt(timesheetDetail.data?.registration_time?.check_out_time)
                                            )}{' '}
                                            -{' '}
                                            {DateTime.FormatDate(
                                              parseInt(timesheetDetail.data?.registration_time?.check_out_time)
                                            )}
                                        </>
                                    )
                                  : (
                                      '-'
                                    )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                <Typography variant='body2'>{t('20_23_rounded_time')}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                {((timesheetDetail.data?.check_in_time) != null)
                                  ? (
                                        <>
                                            {DateTime.GetHours(timesheetDetail.data?.check_in_time)} -{' '}
                                            {DateTime.FormatDate(timesheetDetail.data?.check_in_time)}
                                        </>
                                    )
                                  : (
                                      '-'
                                    )}
                            </TableCell>
                            <TableCell align='left'>
                                {((timesheetDetail.data?.check_out_time) != null)
                                  ? (
                                        <>
                                            {DateTime.GetHours(timesheetDetail.data?.check_out_time)} -{' '}
                                            {DateTime.FormatDate(timesheetDetail.data?.check_out_time)}
                                        </>
                                    )
                                  : (
                                      '-'
                                    )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                <Typography variant='body2'>{t('25_00_group')}</Typography>
                            </TableCell>
                            <TableCell align='left'>{`${timesheetDetail.data?.group?.group_name ?? ''} - ${timesheetDetail.data?.group?.department?.department_name ?? ''}`}</TableCell>
                            <TableCell align='left'>{`${timesheetDetail.data?.group?.group_name ?? ''} - ${timesheetDetail.data?.group?.department?.department_name ?? ''}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                <Typography variant='body2'>{t('20_98_note')}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                {data?.timesheet.clock_in_note}
                            </TableCell>
                            <TableCell align='left'>
                                {data?.timesheet.clock_out_note}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                <Typography variant='body2'>{t('20_23_device')}</Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                  wordBreak: 'break-all',
                                  borderBottom: '1px solid #e5e5e5'
                                }}
                                align='left'
                            >
                                {data?.timesheet.clock_in_device}
                            </TableCell>
                            <TableCell
                                sx={{
                                  wordBreak: 'break-all',
                                  borderBottom: '1px solid #e5e5e5'
                                }}
                                align='left'
                            >
                                {data?.timesheet.clock_out_device}

                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                <Typography variant='body2'>{t('00_06_location')}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                {data?.timesheet.clock_in_location}
                            </TableCell>
                            <TableCell align='left'>
                                {data?.timesheet.clock_out_location}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ paddingTop: 3 }} component='th' scope='row'>
                                <Typography variant='body2'>{t('20_23_photo')}</Typography>
                            </TableCell>
                            <TableCell sx={{ paddingTop: 3 }} align='left'>
                                {((data?.timesheet.clock_in_image) !== '') && (
                                    <img
                                        src={data?.timesheet.clock_in_image}
                                        alt={t('20_23_photo') ?? undefined}
                                        width='200'
                                        height='200'
                                        className='img'
                                        onClick={() => { handleImageClick(data?.timesheet.clock_in_image ?? '') }}
                                    />
                                )}
                            </TableCell>
                            <TableCell sx={{ paddingTop: 3 }} align='left'>
                                {((data?.timesheet.clock_out_image) !== '') && (
                                    <img
                                        src={data?.timesheet.clock_out_image}
                                        alt={t('20_23_photo') ?? undefined}
                                        width='200'
                                        height='200'
                                        className='img'
                                        onClick={() => { handleImageClick(data?.timesheet.clock_out_image ?? '') }}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Modal>
  )
}
