import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Storage from '../services/storage'
import moment from 'moment'
import 'moment-timezone'
import { UsersApi, CompanysApi, DepartmentsApi } from '../services/Swagger'
import { STATUS_ACTIVE } from '../services/constants'
import i18n from '../services/i18n'
import { Box, CircularProgress } from '@mui/material'

function Root (): JSX.Element {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)

  const getData = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await new UsersApi().getUserProfile()
      void i18n.changeLanguage(response.data.language)
      const response1 = await new CompanysApi().getCompanyInformation()
      const response2 = await new DepartmentsApi().getAllDepartment(
        false,
        '-1',
        '-1'
      )
      const response3 = await new CompanysApi().getListModule()
      Storage.setItem('USER_PROFILE', response.data)
      Storage.setItem('COMPANY_INFOMATION', response1.data)
      Storage.setItem('ALL_DEPARTMENT', response2.data)
      response2.data.forEach((department: any, key: number) => {
        response2.data[key].groups = department.groups.filter(
          (group: any) => +group.status === STATUS_ACTIVE
        )
      })
      Storage.setItem('LIST_MODULE', response3.data)
      const deparmentStore: any = {}
      const groups: any[] = []
      response2.data.forEach((department) => {
        department?.groups?.forEach((group: any) => {
          groups[group.group_id.toString()] = group
          if ((Boolean(group.child)) && group.child.length > 0) {
            group.child.map((assignment: any) => {
              groups[assignment.group_id.toString()] = assignment
              return true
            })
          }
        })
      })
      Storage.setItem('DEPARTMENT_STORE', deparmentStore)
      Storage.setItem('GROUP_STORE', groups)

      const companyInfo = Storage.getItem('COMPANY_INFOMATION')
      moment.tz.setDefault(companyInfo.timezone)
      moment.locale('en', {
        week: {
          dow: companyInfo.first_day_of_week
        }
      })
      setIsLoading(false)
    } catch (ex: any) {
      console.log(ex)
    }
  }

  useEffect(() => {
    const localToken: any = Storage.getItem('APP_TOKEN')
    if (localToken == null) {
      navigate('/auth/login')
    } else {
      void getData()
    }
  }, [])
  return (
    <div className="AppMain">
      {isLoading && <Box sx={{ my: 50, justifyContent: 'center', display: 'flex' }}>
        <CircularProgress size={40} />
      </Box>}
      {!isLoading && <Outlet />}
    </div>
  )
}

export default Root
