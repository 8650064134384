/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
 import * as DotNet from './DotNetServices/index'
 export * from './Services/index'
 export { DotNet }
 export const timeClockAPI = new DotNet.TimeClockApi()
 