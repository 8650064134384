/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangePass } from '../models';
// @ts-ignore
import { DropProfile } from '../models';
// @ts-ignore
import { EmployeeDetail } from '../models';
// @ts-ignore
import { EmployeeDetailNew } from '../models';
// @ts-ignore
import { EmployeeDetailV2 } from '../models';
// @ts-ignore
import { EmployeeSetting } from '../models';
// @ts-ignore
import { EmployeeShort } from '../models';
// @ts-ignore
import { EmployeeToken } from '../models';
// @ts-ignore
import { FilterTimeSheet } from '../models';
// @ts-ignore
import { FormFilterAllEmployee } from '../models';
// @ts-ignore
import { FormFilterColleague } from '../models';
// @ts-ignore
import { FormListEmployee } from '../models';
// @ts-ignore
import { FormListEmployeeByGroups } from '../models';
// @ts-ignore
import { FormParamV2 } from '../models';
// @ts-ignore
import { GroupDetail } from '../models';
// @ts-ignore
import { Profile } from '../models';
// @ts-ignore
import { ResultDataEmployees } from '../models';
// @ts-ignore
import { Users } from '../models';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changeDefaultCompany
         * @param {number} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDefaultCompany: async (key: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('changeDefaultCompany', 'key', key)
            const localVarPath = `/api/user/changeDefaultCompany/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changePassword
         * @param {ChangePass} body changePassword Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (body: ChangePass, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('changePassword', 'body', body)
            const localVarPath = `/api/user/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createToken
         * @param {EmployeeToken} body createToken Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: async (body: EmployeeToken, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createToken', 'body', body)
            const localVarPath = `/api/user/createToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteToken
         * @param {number} employeeId 
         * @param {string} uuid 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken: async (employeeId: number, uuid: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteToken', 'employeeId', employeeId)
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteToken', 'uuid', uuid)
            const localVarPath = `/api/user/deleteToken/{uuid}/{employeeId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary dropProfile
         * @param {DropProfile} body DropProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropProfile: async (body: DropProfile, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('dropProfile', 'body', body)
            const localVarPath = `/api/user/dropProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllEmployees
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmployees: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getAllEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDashboardInformation
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardInformation: async (minTime: string, maxTime: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getDashboardInformation', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getDashboardInformation', 'maxTime', maxTime)
            const localVarPath = `/api/user/getDashboardInformation/{min_time}/{max_time}`
                .replace(`{${"min_time"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"max_time"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEmployeeByGroups
         * @param {FilterTimeSheet} body getEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeByGroups: async (body: FilterTimeSheet, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getEmployeeByGroups', 'body', body)
            const localVarPath = `/api/user/getEmployeeByGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEmployeeByID
         * @param {number} empId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeByID: async (empId: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'empId' is not null or undefined
            assertParamExists('getEmployeeByID', 'empId', empId)
            const localVarPath = `/api/user/getEmployeeByID/{empId}`
                .replace(`{${"empId"}}`, encodeURIComponent(String(empId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEmployeeSettings
         * @param {number} moduleId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeSettings: async (moduleId: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('getEmployeeSettings', 'moduleId', moduleId)
            const localVarPath = `/api/user/getEmployeeSettings/{moduleId}`
                .replace(`{${"moduleId"}}`, encodeURIComponent(String(moduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListBirthdayInSameGroup
         * @param {number} employeeID 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListBirthdayInSameGroup: async (employeeID: number, minTime: string, maxTime: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeID' is not null or undefined
            assertParamExists('getListBirthdayInSameGroup', 'employeeID', employeeID)
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListBirthdayInSameGroup', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListBirthdayInSameGroup', 'maxTime', maxTime)
            const localVarPath = `/api/user/getListBirthdayInSameGroup/{employeeID}/{min_time}/{max_time}`
                .replace(`{${"employeeID"}}`, encodeURIComponent(String(employeeID)))
                .replace(`{${"min_time"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"max_time"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListEmployee
         * @param {FormListEmployee} body getListEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployee: async (body: FormListEmployee, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListEmployee', 'body', body)
            const localVarPath = `/api/user/getListEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListEmployeeByGroups
         * @param {FormListEmployeeByGroups} body ListEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeByGroups: async (body: FormListEmployeeByGroups, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListEmployeeByGroups', 'body', body)
            const localVarPath = `/api/user/getListEmployeeByGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListEmployeeColleague
         * @param {FormFilterColleague} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeColleague: async (body: FormFilterColleague, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListEmployeeColleague', 'body', body)
            const localVarPath = `/api/user/getListEmployeeColleague`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListEmployees
         * @param {FormParamV2} body getListEmployees Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployees: async (body: FormParamV2, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListEmployees', 'body', body)
            const localVarPath = `/api/user/getListEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUserProfile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveImage
         * @param {string} [source] 
         * @param {any} [file] image
         * @param {number} [clockType] clock_type
         * @param {number} [type] type
         * @param {number} [id] id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveImage: async (source?: string, file?: any, clockType?: number, type?: number, id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/saveImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (clockType !== undefined) { 
                localVarFormParams.append('clock_type', clockType as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchAllEmployee
         * @param {FormFilterAllEmployee} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllEmployee: async (body: FormFilterAllEmployee, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('searchAllEmployee', 'body', body)
            const localVarPath = `/api/user/searchAllEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateEmployeeFireBase
         * @param {object} body updateEmployeeFireBase Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeFireBase: async (body: object, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateEmployeeFireBase', 'body', body)
            const localVarPath = `/api/user/updateEmployeeFireBase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateEmployeeSetting
         * @param {number} employeeSettingId 
         * @param {EmployeeSetting} body updateEmployeeSetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeSetting: async (employeeSettingId: number, body: EmployeeSetting, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeSettingId' is not null or undefined
            assertParamExists('updateEmployeeSetting', 'employeeSettingId', employeeSettingId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateEmployeeSetting', 'body', body)
            const localVarPath = `/api/user/updateEmployeeSetting/{employee_setting_id}`
                .replace(`{${"employee_setting_id"}}`, encodeURIComponent(String(employeeSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateUserProfile
         * @param {Profile} body updateUserProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (body: Profile, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserProfile', 'body', body)
            const localVarPath = `/api/user/updateUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadFile
         * @param {string} [source] 
         * @param {any} [file] image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (source?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/uploadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary changeDefaultCompany
         * @param {number} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeDefaultCompany(key: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeDefaultCompany(key, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary changePassword
         * @param {ChangePass} body changePassword Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(body: ChangePass, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createToken
         * @param {EmployeeToken} body createToken Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createToken(body: EmployeeToken, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createToken(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteToken
         * @param {number} employeeId 
         * @param {string} uuid 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteToken(employeeId: number, uuid: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteToken(employeeId, uuid, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary dropProfile
         * @param {DropProfile} body DropProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dropProfile(body: DropProfile, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dropProfile(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllEmployees
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEmployees(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeShort>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEmployees(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDashboardInformation
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardInformation(minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardInformation(minTime, maxTime, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getEmployeeByGroups
         * @param {FilterTimeSheet} body getEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeByGroups(body: FilterTimeSheet, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeDetailNew>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeByGroups(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getEmployeeByID
         * @param {number} empId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeByID(empId: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeByID(empId, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getEmployeeSettings
         * @param {number} moduleId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeSettings(moduleId: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeSetting>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeSettings(moduleId, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListBirthdayInSameGroup
         * @param {number} employeeID 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListBirthdayInSameGroup(employeeID: number, minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListBirthdayInSameGroup(employeeID, minTime, maxTime, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListEmployee
         * @param {FormListEmployee} body getListEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListEmployee(body: FormListEmployee, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDataEmployees>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployee(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListEmployeeByGroups
         * @param {FormListEmployeeByGroups} body ListEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListEmployeeByGroups(body: FormListEmployeeByGroups, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployeeByGroups(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListEmployeeColleague
         * @param {FormFilterColleague} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListEmployeeColleague(body: FormFilterColleague, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDataEmployees>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployeeColleague(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListEmployees
         * @param {FormParamV2} body getListEmployees Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListEmployees(body: FormParamV2, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeDetailV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployees(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getUserProfile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfile(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saveImage
         * @param {string} [source] 
         * @param {any} [file] image
         * @param {number} [clockType] clock_type
         * @param {number} [type] type
         * @param {number} [id] id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveImage(source?: string, file?: any, clockType?: number, type?: number, id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveImage(source, file, clockType, type, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary searchAllEmployee
         * @param {FormFilterAllEmployee} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAllEmployee(body: FormFilterAllEmployee, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDataEmployees>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAllEmployee(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateEmployeeFireBase
         * @param {object} body updateEmployeeFireBase Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployeeFireBase(body: object, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployeeFireBase(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateEmployeeSetting
         * @param {number} employeeSettingId 
         * @param {EmployeeSetting} body updateEmployeeSetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployeeSetting(employeeSettingId: number, body: EmployeeSetting, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployeeSetting(employeeSettingId, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateUserProfile
         * @param {Profile} body updateUserProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(body: Profile, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploadFile
         * @param {string} [source] 
         * @param {any} [file] image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(source?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(source, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary changeDefaultCompany
         * @param {number} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDefaultCompany(key: number, source?: string, options?: any): AxiosPromise<Users> {
            return localVarFp.changeDefaultCompany(key, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary changePassword
         * @param {ChangePass} body changePassword Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(body: ChangePass, source?: string, options?: any): AxiosPromise<Users> {
            return localVarFp.changePassword(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createToken
         * @param {EmployeeToken} body createToken Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken(body: EmployeeToken, source?: string, options?: any): AxiosPromise<EmployeeToken> {
            return localVarFp.createToken(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteToken
         * @param {number} employeeId 
         * @param {string} uuid 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(employeeId: number, uuid: string, source?: string, options?: any): AxiosPromise<EmployeeToken> {
            return localVarFp.deleteToken(employeeId, uuid, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary dropProfile
         * @param {DropProfile} body DropProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropProfile(body: DropProfile, source?: string, options?: any): AxiosPromise<string> {
            return localVarFp.dropProfile(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllEmployees
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmployees(source?: string, options?: any): AxiosPromise<Array<EmployeeShort>> {
            return localVarFp.getAllEmployees(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDashboardInformation
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardInformation(minTime: string, maxTime: string, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getDashboardInformation(minTime, maxTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getEmployeeByGroups
         * @param {FilterTimeSheet} body getEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeByGroups(body: FilterTimeSheet, source?: string, options?: any): AxiosPromise<Array<EmployeeDetailNew>> {
            return localVarFp.getEmployeeByGroups(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getEmployeeByID
         * @param {number} empId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeByID(empId: number, source?: string, options?: any): AxiosPromise<EmployeeDetail> {
            return localVarFp.getEmployeeByID(empId, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getEmployeeSettings
         * @param {number} moduleId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeSettings(moduleId: number, source?: string, options?: any): AxiosPromise<Array<EmployeeSetting>> {
            return localVarFp.getEmployeeSettings(moduleId, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListBirthdayInSameGroup
         * @param {number} employeeID 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListBirthdayInSameGroup(employeeID: number, minTime: string, maxTime: string, source?: string, options?: any): AxiosPromise<Array<EmployeeDetail>> {
            return localVarFp.getListBirthdayInSameGroup(employeeID, minTime, maxTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListEmployee
         * @param {FormListEmployee} body getListEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployee(body: FormListEmployee, source?: string, options?: any): AxiosPromise<ResultDataEmployees> {
            return localVarFp.getListEmployee(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListEmployeeByGroups
         * @param {FormListEmployeeByGroups} body ListEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeByGroups(body: FormListEmployeeByGroups, source?: string, options?: any): AxiosPromise<Array<GroupDetail>> {
            return localVarFp.getListEmployeeByGroups(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListEmployeeColleague
         * @param {FormFilterColleague} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeColleague(body: FormFilterColleague, source?: string, options?: any): AxiosPromise<ResultDataEmployees> {
            return localVarFp.getListEmployeeColleague(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListEmployees
         * @param {FormParamV2} body getListEmployees Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployees(body: FormParamV2, source?: string, options?: any): AxiosPromise<Array<EmployeeDetailV2>> {
            return localVarFp.getListEmployees(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getUserProfile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(source?: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.getUserProfile(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveImage
         * @param {string} [source] 
         * @param {any} [file] image
         * @param {number} [clockType] clock_type
         * @param {number} [type] type
         * @param {number} [id] id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveImage(source?: string, file?: any, clockType?: number, type?: number, id?: number, options?: any): AxiosPromise<string> {
            return localVarFp.saveImage(source, file, clockType, type, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchAllEmployee
         * @param {FormFilterAllEmployee} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllEmployee(body: FormFilterAllEmployee, source?: string, options?: any): AxiosPromise<ResultDataEmployees> {
            return localVarFp.searchAllEmployee(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateEmployeeFireBase
         * @param {object} body updateEmployeeFireBase Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeFireBase(body: object, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateEmployeeFireBase(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateEmployeeSetting
         * @param {number} employeeSettingId 
         * @param {EmployeeSetting} body updateEmployeeSetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeSetting(employeeSettingId: number, body: EmployeeSetting, source?: string, options?: any): AxiosPromise<EmployeeSetting> {
            return localVarFp.updateEmployeeSetting(employeeSettingId, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateUserProfile
         * @param {Profile} body updateUserProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(body: Profile, source?: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.updateUserProfile(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploadFile
         * @param {string} [source] 
         * @param {any} [file] image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(source?: string, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.uploadFile(source, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary changeDefaultCompany
     * @param {number} key 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeDefaultCompany(key: number, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeDefaultCompany(key, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary changePassword
     * @param {ChangePass} body changePassword Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePassword(body: ChangePass, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).changePassword(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createToken
     * @param {EmployeeToken} body createToken Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createToken(body: EmployeeToken, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createToken(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteToken
     * @param {number} employeeId 
     * @param {string} uuid 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteToken(employeeId: number, uuid: string, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteToken(employeeId, uuid, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary dropProfile
     * @param {DropProfile} body DropProfile Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public dropProfile(body: DropProfile, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).dropProfile(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllEmployees
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllEmployees(source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAllEmployees(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDashboardInformation
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getDashboardInformation(minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getDashboardInformation(minTime, maxTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getEmployeeByGroups
     * @param {FilterTimeSheet} body getEmployeeByGroups Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getEmployeeByGroups(body: FilterTimeSheet, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getEmployeeByGroups(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getEmployeeByID
     * @param {number} empId 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getEmployeeByID(empId: number, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getEmployeeByID(empId, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getEmployeeSettings
     * @param {number} moduleId 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getEmployeeSettings(moduleId: number, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getEmployeeSettings(moduleId, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListBirthdayInSameGroup
     * @param {number} employeeID 
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getListBirthdayInSameGroup(employeeID: number, minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getListBirthdayInSameGroup(employeeID, minTime, maxTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListEmployee
     * @param {FormListEmployee} body getListEmployee Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getListEmployee(body: FormListEmployee, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getListEmployee(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListEmployeeByGroups
     * @param {FormListEmployeeByGroups} body ListEmployeeByGroups Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getListEmployeeByGroups(body: FormListEmployeeByGroups, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getListEmployeeByGroups(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListEmployeeColleague
     * @param {FormFilterColleague} body getListEmployeeColleague Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getListEmployeeColleague(body: FormFilterColleague, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getListEmployeeColleague(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListEmployees
     * @param {FormParamV2} body getListEmployees Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getListEmployees(body: FormParamV2, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getListEmployees(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getUserProfile
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserProfile(source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserProfile(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveImage
     * @param {string} [source] 
     * @param {any} [file] image
     * @param {number} [clockType] clock_type
     * @param {number} [type] type
     * @param {number} [id] id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public saveImage(source?: string, file?: any, clockType?: number, type?: number, id?: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).saveImage(source, file, clockType, type, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchAllEmployee
     * @param {FormFilterAllEmployee} body getListEmployeeColleague Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public searchAllEmployee(body: FormFilterAllEmployee, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).searchAllEmployee(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateEmployeeFireBase
     * @param {object} body updateEmployeeFireBase Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateEmployeeFireBase(body: object, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateEmployeeFireBase(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateEmployeeSetting
     * @param {number} employeeSettingId 
     * @param {EmployeeSetting} body updateEmployeeSetting Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateEmployeeSetting(employeeSettingId: number, body: EmployeeSetting, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateEmployeeSetting(employeeSettingId, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateUserProfile
     * @param {Profile} body updateUserProfile Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserProfile(body: Profile, source?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserProfile(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploadFile
     * @param {string} [source] 
     * @param {any} [file] image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public uploadFile(source?: string, file?: any, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).uploadFile(source, file, options).then((request) => request(this.axios, this.basePath));
    }
}
