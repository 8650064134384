import {
  type FMessage,
  type FRoom,
  type FUser,
  useFirebaseChat
} from '@dansoft/titanplan-services'
import React, { useEffect, useRef, useState } from 'react'
import Storage from '../../services/storage'
import {
  ChatItem,
  ChatSearch,
  ChatUser,
  InputMessenger,
  MessengerItem,
  UserMessenger
} from './Components'
import { Icon } from '@fluentui/react'
import DialogGroup from './Components/DialogGroup'
import InfiniteScroll from 'react-infinite-scroll-component'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { showNameAvatar, showDate } from './Components/MessengerItem'
import { Grid, Box, Typography } from '@mui/material'
import { type Profile } from '../../services/Swagger'

export default function Messenger (): JSX.Element {
  const [dialogGroupModalState, setDialogGroupModalState] = useState<{
    visible: boolean
    contacts: FUser[]
  }>({
    visible: false,
    contacts: []
  })
  const [isFocused, setIsFocused] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [chatItemLoaded, setChatItemLoaded] = useState(12)
  const [contextMenuRoom, setContextMenuRoom] = useState<FRoom>()
  const contextMenuRef = useRef<HTMLDivElement>(null)
  const [replyMessage, setReplyMessage] = useState<FMessage | undefined>(
    undefined
  )
  const [replyWithImage, setReplyingWithImage] = useState<FMessage | undefined>(
    undefined
  )
  const [replyType, setReplyType] = useState<string | null>(null)

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      (contextMenuRef.current != null) &&
      !contextMenuRef.current.contains(event.target as Node)
    ) {
      setContextMenuRoom(undefined)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [contextMenuRoom])

  const localToken: any = Storage.getItem('APP_TOKEN')
  const userProfile: Profile = Storage.getItem('USER_PROFILE')
  const settings = {
    companyId: userProfile.user?.db_name ?? '',
    apiUrl: process.env.REACT_APP_API_URL ?? '',
    token: localToken !== null ? localToken.token : '',
    user: {
      _id: Number(userProfile.employee_id),
      name: userProfile.fullname ?? '',
      avatar: userProfile.avatar ?? ''
    }
  }
  const {
    rooms,
    contacts,
    currentRoom,
    switchRoom,
    selectContacts,
    messages,
    onReaction,
    sendMessage,
    uploadFileToFireStore
  } = useFirebaseChat(settings, settings.user)

  const onClick = (): void => {
    setDialogGroupModalState({
      visible: true,
      contacts
    })
  }

  const onFocus = (): void => {
    setIsFocused(true)
  }

  const onBlur = (): void => {
    setTimeout(() => {
      setIsFocused(false)
    }, 200)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value)
  }

  const filteredContacts = contacts?.filter((contact) =>
    contact.name.toLowerCase().includes(searchText.toLowerCase())
  )
  const filteredRooms = rooms?.filter((room) =>
    room.name.toLowerCase().includes(searchText.toLowerCase())
  )

  const Row = ({ index, style }: any): JSX.Element => {
    return (
      <Grid key={`row${index as string}`} style={style}>
        <ChatUser
          user={filteredContacts[index]}
          onClick={() => {
            selectContacts([filteredContacts[index]])
            setIsFocused(false)
          }}
        />
      </Grid>
    )
  }

  const handleReplyMessage = (message: FMessage): void => {
    setReplyMessage(message)
  }
  const handleImageUploaded = (message: FMessage): void => {
    setReplyingWithImage(message)
  }
  const handleCancelReply = (): void => {
    setReplyMessage(undefined)
  }
  const handleCancelReplyWithImage = (): void => {
    setReplyingWithImage(undefined)
  }

  useEffect(() => {
    setReplyMessage(undefined)
    setReplyingWithImage(undefined)
  }, [currentRoom])

  useEffect(() => {
    if ((replyMessage != null) && replyType !== 'message') {
      setReplyType('message')
    } else if ((replyWithImage != null) && replyType !== 'image') {
      setReplyType('image')
    }
  }, [replyMessage, replyWithImage])

  useEffect(() => {
    if (replyType === 'message') {
      setReplyingWithImage(undefined)
    } else if (replyType === 'image') {
      setReplyMessage(undefined)
    }
  }, [replyType])

  return (
    <Box className="messenger">
      <Box className="messenger-container">
        <Box className="messenger-chatList">
          <Box className="messenger-search">
            <ChatSearch
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={handleSearchChange}
            />
            <Icon
              onClick={() => {
                onClick()
              }}
              iconName="AddGroup"
              style={{ cursor: 'pointer', fontSize: '20px' }}
            />
          </Box>
          <Box className="messenger-chatItem">
            {isFocused
              ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      height={Number(height)}
                      width={Number(width)}
                      itemCount={filteredContacts?.length}
                      itemSize={76}
                    >
                      {Row}
                    </List>
                  )}
                </AutoSizer>
                )
              : (
                <InfiniteScroll
                  dataLength={chatItemLoaded}
                  next={() => {
                    setChatItemLoaded(chatItemLoaded + 5)
                  }}
                  className="Scroll-chatItem"
                  height={800}
                  hasMore={chatItemLoaded < filteredRooms?.length}
                  loader={<Typography>Loading...</Typography>}
                >
                  {filteredRooms?.slice(0, chatItemLoaded).map((room) => {
                    return (
                      <Box
                        key={room._id}
                        onClick={() => {
                          switchRoom(room._id)
                        }}
                        onContextMenu={(e: any) => {
                          e.preventDefault()
                          setContextMenuRoom(room)
                        }}
                      >
                        <ChatItem
                          key={room._id}
                          currentRoom={currentRoom}
                          room={room}
                        />
                      </Box>
                    )
                  })}
                </InfiniteScroll>
                )}
          </Box>
        </Box>
        <Box className="messenger-content">
          {(currentRoom != null)
            ? (
              <>
                <UserMessenger room={currentRoom} />
                <InfiniteScroll
                  loader={
                    <Typography textAlign={'center'}>Loading...</Typography>
                  }
                  dataLength={messages.length}
                  next={() => {
                    console.log('next')
                  }}
                  inverse={true}
                  height={700}
                  className="Scroll-messengerBox"
                  hasMore={false}
                >
                  <Box className="MessengerBox">
                    {messages
                      .sort((a, b) => a.createdAt - b.createdAt)
                      .map((message, index) => {
                        return (
                          <MessengerItem
                            key={message._id}
                            message={message}
                            user={settings.user}
                            room={currentRoom}
                            isMyMessage={message.user._id === settings.user?._id}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onReaction={onReaction}
                            showDate={showDate(index, messages)}
                            showNameAvatar={showNameAvatar(index, messages)}
                            onReply={handleReplyMessage}
                            onImageUploaded={handleImageUploaded}
                          />
                        )
                      })}
                  </Box>
                </InfiniteScroll>
                <InputMessenger
                  onAttack={(file) => {
                    void uploadFileToFireStore(file)
                  }}
                  onSend={(text) => {
                    void sendMessage(text)
                  }}
                  onReplyMessage={replyMessage}
                  handleCancelReply={handleCancelReply}
                  replyWithImage={replyWithImage}
                  handleCancelReplyWithImage={handleCancelReplyWithImage}
                  replyType={replyType}
                />
              </>
              )
            : (
              <Box className="welcomeBox"></Box>
              )}
        </Box>
        {dialogGroupModalState.visible && (
          <DialogGroup
            isOpen={dialogGroupModalState.visible}
            contacts={dialogGroupModalState.contacts}
            onCreate={(group: FUser[]) => {
              selectContacts(group)
            }}
            onClose={() => {
              setDialogGroupModalState({
                visible: false,
                contacts: []
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}
