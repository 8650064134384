/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  MenuList,
  MenuItem,
  Button,
  Popover,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../../theme'
import DialogOption from './DialogOption'
import { type FilterTimeSheet, type IndicatorSetting } from '../../../services/Swagger'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined'
import WarningBox from './WarningBox'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ApproveTimesheets from './ApproveTimesheets'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import storage from '../../../services/storage'
import { MODULEID, getGroupRoleByModule } from '../../../services/modules'

interface ToolProps {
  createRole: any
  indicatorSettings: IndicatorSetting[] | undefined
  onIndicatorSettingsUpdate: () => void
  employeeWarning: any
  handleNewTimesheet: (time: number) => void
  filter: FilterTimeSheet
  rowGroupingModelStr: string
  handleRowGroupingView: (groupType: [string, string]) => void
  dataUpdate: () => void
  createAndApprove: () => Promise<void>
  lockAll: () => void
  unLockAll: () => void
  listDontApproveTimesheet: ListTimeSheet[]
  isLock: boolean
  handleExport: () => Promise<string | undefined>
  rowGroupingModel: string[]
  handleEditTimesheetByWarning: (timesheet: ListTimeSheet) => void
}

export default function Tools ({
  createRole,
  indicatorSettings,
  onIndicatorSettingsUpdate,
  employeeWarning, handleNewTimesheet,
  filter,
  rowGroupingModelStr,
  handleRowGroupingView,
  dataUpdate,
  createAndApprove,
  lockAll,
  unLockAll,
  listDontApproveTimesheet,
  isLock,
  rowGroupingModel,
  handleExport, handleEditTimesheetByWarning
}: ToolProps): JSX.Element {
  const { t } = useTranslation()
  const [employeeSubMenuAnchorEl, setEmployeeSubMenuAnchorEl] = useState(null)
  const [workSubMenuAnchorEl, setWorkSubMenuAnchorEl] = useState(null)
  const [showWarning, setShowWarning] = useState(null)
  const [showOption, setShowOption] = useState(false)
  const [showApproveTimesheet, setShowApproveTimesheet] = useState(false)

  const handleShowWarningOpen = (event: any): void => {
    setShowWarning(event.currentTarget)
  }
  const handleShowWarningClose = (): void => {
    setShowWarning(null)
  }
  const handleEmployeeSubMenuOpen = (event: any): void => {
    setEmployeeSubMenuAnchorEl(event.currentTarget)
  }

  const handleEmployeeSubMenuClose = (): void => {
    setEmployeeSubMenuAnchorEl(null)
  }

  const handleWorkSubMenuOpen = (event: any): void => {
    setWorkSubMenuAnchorEl(event.currentTarget)
  }

  const handleWorkSubMenuClose = (): void => {
    setWorkSubMenuAnchorEl(null)
  }

  const handleOpenNewTimesheet = (): void => {
    setWorkSubMenuAnchorEl(null)
    handleNewTimesheet(filter.start_time ?? 0)
  }
  const handleGroupView = (): void => {
    handleEmployeeSubMenuClose()
    handleRowGroupingView(['timeSheet_date', 'worked_group'])
  }
  const handleUnscheduled = (): void => {
    handleEmployeeSubMenuClose()
    handleRowGroupingView(['timeSheet_date', 'scheduled_group'])
  }
  const handleEmployeeView = (): void => {
    handleEmployeeSubMenuClose()
    handleRowGroupingView(['timeSheet_date', 'employee_name'])
  }
  const handleDefaultView = (): void => {
    handleEmployeeSubMenuClose()
    handleRowGroupingView(['', ''])
  }
  const handleApproveTimesheets = (): void => {
    setWorkSubMenuAnchorEl(null)
    setShowApproveTimesheet(true)
  }
  const handleClickCreateAndApprove = (): void => {
    setWorkSubMenuAnchorEl(null)
    void createAndApprove()
  }
  const handleLockAll = (): void => {
    setWorkSubMenuAnchorEl(null)
    lockAll()
  }
  const handleUnLockAll = (): void => {
    setWorkSubMenuAnchorEl(null)
    unLockAll()
  }
  const handleClickExport = (): void => {
    setWorkSubMenuAnchorEl(null)
    void handleExport()
  }
  const openWarningMenu = Boolean(showWarning)
  const openEmployeeSubMenu = Boolean(employeeSubMenuAnchorEl)
  const openWorkSubMenu = Boolean(workSubMenuAnchorEl)
  const userProfile = storage.getItem('USER_PROFILE')
  const [groupRole] = useState(
    getGroupRoleByModule(MODULEID.timesheet, userProfile.primary_group.group_id)
  )

  return (
    <Box className="Tools">
      <MenuList className="Tools-icon">
        {employeeWarning.length > 0 && (
          <MenuItem onClick={handleShowWarningOpen}>
            <ReportGmailerrorredOutlinedIcon
              color="error"
              className="icons-24"
            />
          </MenuItem>
        )}
        <Popover
          open={openWarningMenu}
          anchorEl={showWarning}
          onClose={handleShowWarningClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <WarningBox handleEditTimesheetByWarning={(ts) => {
            handleShowWarningClose()
            handleEditTimesheetByWarning(ts)
          }} employeeWarning={employeeWarning} />
        </Popover>
        <MenuItem onClick={handleEmployeeSubMenuOpen}>
          <RemoveRedEyeOutlinedIcon className="icons-24" />
        </MenuItem>
        <Popover
          open={openEmployeeSubMenu}
          anchorEl={employeeSubMenuAnchorEl}
          onClose={handleEmployeeSubMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuList className='Tools-width'>
            <MenuItem onClick={handleDefaultView}><Typography className='Tools-text'>{t('13_02_default')}</Typography> {rowGroupingModelStr === '' && (<CheckOutlinedIcon color={rowGroupingModelStr === '' ? 'primary' : undefined}></CheckOutlinedIcon>)}</MenuItem>
            <MenuItem onClick={handleUnscheduled}><Typography className='Tools-text'>{t('02_00_employee_group_view')}</Typography> {(rowGroupingModelStr === 'timeSheet_datescheduled_group' || rowGroupingModelStr === 'scheduled_group') && (<CheckOutlinedIcon color={(rowGroupingModelStr === 'timeSheet_datescheduled_group' || rowGroupingModelStr === 'scheduled_group') ? 'primary' : undefined}></CheckOutlinedIcon>)}</MenuItem>
            <MenuItem onClick={handleGroupView}><Typography className='Tools-text'>{t('02_00_work_view')}</Typography> {(rowGroupingModelStr === 'timeSheet_dateworked_group' || rowGroupingModelStr === 'worked_group') && (<CheckOutlinedIcon color={(rowGroupingModelStr === 'timeSheet_dateworked_group' || rowGroupingModelStr === 'worked_group') ? 'primary' : undefined}></CheckOutlinedIcon>)}</MenuItem>
            <MenuItem onClick={handleEmployeeView}><Typography className='Tools-text'>{t('00_06_employee_view')} </Typography>{(rowGroupingModelStr === 'timeSheet_dateemployee_name' || rowGroupingModelStr === 'employee_name') && (<CheckOutlinedIcon color={(rowGroupingModelStr === 'timeSheet_dateemployee_name' || rowGroupingModelStr === 'employee_name') ? 'primary' : undefined}></CheckOutlinedIcon>)}</MenuItem>
          </MenuList>
        </Popover>
        {((groupRole.Create ?? false) || (groupRole.Approve ?? false) || (groupRole.Lock ?? false) || (groupRole.Report ?? false)) && (
          <MenuItem onClick={handleWorkSubMenuOpen}>
            <CardTravelOutlinedIcon className="icons-24" />
          </MenuItem>
        )}
        <Popover
          open={openWorkSubMenu}
          anchorEl={workSubMenuAnchorEl}
          onClose={handleWorkSubMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuList>
            {(groupRole.Approve ?? false) && (
              <MenuItem onClick={handleApproveTimesheets}>{t('02_01_approve_timesheets')}</MenuItem>
            )}
            {listDontApproveTimesheet.length > 0 && (groupRole.Approve ?? false) && (groupRole.Create ?? false) && (
              <MenuItem onClick={handleClickCreateAndApprove}>
                {t('02_00_create_timesheets_and_approve_all')}
              </MenuItem>
            )}
            {(groupRole.Create ?? false) && (
              <MenuItem onClick={handleOpenNewTimesheet}>{t('02_00_create_a_new_timesheet')}</MenuItem>
            )}
            {!isLock && (groupRole.Lock ?? false) && (
              <MenuItem onClick={handleLockAll}>{t('20_89_lock_all')}</MenuItem>
            )}
            {isLock && (groupRole.Lock ?? false) && (
              <MenuItem onClick={handleUnLockAll}>{t('00_06_unlock_all')}</MenuItem>
            )}
            {rowGroupingModel.includes('timeSheet_date') && rowGroupingModel.includes('scheduled_group') && (
              <MenuItem onClick={handleClickExport}>{t('13_03_export')}</MenuItem>
            )}
          </MenuList>
        </Popover>
        {showApproveTimesheet && (
          <ApproveTimesheets dataUpdate={dataUpdate} startTime={filter.start_time ?? 0} endTime={filter.end_time ?? 0} onClose={() => {
            setShowApproveTimesheet(false)
          }} />
        )}
        <MenuItem onClick={() => { setShowOption(true) }}>
          <TuneOutlinedIcon className="icons-24" />
        </MenuItem>
        {showOption && (
          <DialogOption
            indicatorSettings={indicatorSettings}
            onClose={() => {
              setShowOption(false)
              onIndicatorSettingsUpdate()
            }}
          />
        )}
      </MenuList>
      {createRole.length > 0 && (
        <Button onClick={handleOpenNewTimesheet} variant="contained" color="primary" sx={{ color: Colors.black }}>
          {t('20_23_new_timesheet')}
        </Button>
      )}
    </Box>
  )
}
