import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/Modal/Modal'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { DatePicker } from '@mui/x-date-pickers'
import GroupPicker, { getGroupsArr } from '../../../components/GroupPicker/GroupPicker'
import { Colors } from '../../../theme'
import { useFormik } from 'formik'
import moment from 'moment'
import { validationGroups } from '../Helpers/Schema'
import FormAlert from '../../../components/Form/FormAlert'
import { type ApproveRangeTimeRegistration, TimeSheetApi } from '../../../services/Swagger'
import { toast } from 'react-toastify'
import storage from '../../../services/storage'

interface ApproveTimesheetsProps {
  onClose: () => void
  startTime: number
  endTime: number
  dataUpdate: () => void
}
interface CustomApproveRangeTimeRegistration extends ApproveRangeTimeRegistration {
  status: string
}

export default function ApproveTimesheets ({ onClose, startTime, dataUpdate, endTime }: ApproveTimesheetsProps): JSX.Element {
  const companySetting = storage.getItem('COMPANY_INFOMATION')
  const dateFormat = companySetting?.date_format
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async (values: CustomApproveRangeTimeRegistration): Promise<void> => {
    const { status, ...data } = values
    try {
      setIsLoading(true)
      if (values.status === 'approve') {
        await new TimeSheetApi().approveRangeTimeRegistration(data)
      } else if (values.status === 'unapprove') {
        await new TimeSheetApi().unApproveRangeTimeRegistration(data)
      }
      onClose()
      dataUpdate()
    } catch (ex: any) {
      toast.error(t(ex.message), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }

  const [approve] = useState<CustomApproveRangeTimeRegistration>({
    min_time: startTime,
    max_time: endTime,
    groups: [],
    status: 'approve'
  })
  const formik = useFormik({
    initialValues: approve,
    enableReinitialize: true,
    validationSchema: validationGroups,
    onSubmit: handleSubmit
  })
  const selectedDate = formik.values.min_time

  return (
    <Modal open onClose={onClose} size="small" title={t('02_01_approve_timesheets')}>
      <Box sx={{ px: 4, py: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <FormAlert formik={formik} />
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography>{t('10_01_action')}</Typography>
            </Grid>
            <Grid item xs>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formik.values.status}
              >
                <FormControlLabel value="approve" onChange={(event) => { void formik.setFieldValue('status', (event.target as HTMLInputElement).value) }} control={<Radio />} label={t('20_00_approve')} />
                <FormControlLabel value="unapprove" onChange={(event) => { void formik.setFieldValue('status', (event.target as HTMLInputElement).value) }} control={<Radio />} label={t('02_01_unapprove')} />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography>{t('00_06_from')}</Typography>
            </Grid>
            <Grid item xs>
              <DatePicker
                format={dateFormat}
                value={moment.unix(formik.values.min_time)}
                slotProps={{ textField: { variant: 'standard' } }}
                onChange={(date: any) => {
                  void formik.setFieldValue('min_time', moment(date).unix())
                }}
                slots={{
                  openPickerIcon: ArrowDropDownOutlinedIcon
                }}
                maxDate={moment.unix(endTime)}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography>{t('20_80_to')}</Typography>
            </Grid>
            <Grid item xs>
              <DatePicker
                format={dateFormat}
                value={moment.unix(formik.values.max_time)}
                slotProps={{ textField: { variant: 'standard' } }}
                onChange={(date: any) => {
                  void formik.setFieldValue('max_time', moment(date).unix())
                }}
                slots={{
                  openPickerIcon: ArrowDropDownOutlinedIcon
                }}
                minDate={moment.unix(selectedDate)}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography>{t('00_06_group')}</Typography>
            </Grid>
            <Grid item xs>
              <GroupPicker
                onClose={(departments: any) => {
                  void formik.setFieldValue('groups', getGroupsArr(departments))
                }}
                SelectProps={{
                  style: { backgroundColor: Colors.white, width: '100%' },
                  variant: 'standard'
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ py: 2, justifyContent: 'flex-end' }}
          >
            <Grid item>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => {
                  formik.handleSubmit()
                }}
              >
                {t('20_13_save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  )
}
