import { type FUser } from '@dansoft/titanplan-services'
import { Icon } from '@fluentui/react'
import {
  TextField,
  Box,
  Checkbox,
  Button,
  Stack
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChatUser from './ChatUser'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'

interface ContactProps {
  contacts: FUser[]
  onClose: (yes?: boolean) => void
  onCreate: (group: FUser[]) => void
}

export default function ContactsSelect ({
  contacts,
  onClose,
  onCreate
}: ContactProps): JSX.Element {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedContacts, setSelectedContacts] = useState<FUser[]>([])
  const handleContactSelect = (contact: FUser): void => {
    if (selectedContacts.includes(contact)) {
      setSelectedContacts(selectedContacts.filter((c) => c !== contact))
    } else {
      setSelectedContacts([...selectedContacts, contact])
    }
  }
  const handleCreateGroupChat = (): void => {
    onCreate(selectedContacts)
    onClose()
  }

  const filteredContacts = contacts?.filter((contact) =>
    contact.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const Row = ({ index, style }: any): JSX.Element => {
    return (
      <Box className="ContactsSelect-item" style={style} key={`row${index as string}`}>
        <Checkbox
          checked={selectedContacts.includes(filteredContacts[index])}
          onChange={() => { handleContactSelect(filteredContacts[index]) }}
        />
        <ChatUser
          user={filteredContacts[index]}
          onClick={() => { handleContactSelect(filteredContacts[index]) }}
        />
      </Box>
    )
  }

  return (
    <Box sx={{ p: 4 }} className="ContactsSelect">
      <TextField
        className="boder-none ContactsSelect-search"
        variant="outlined"
        fullWidth
        placeholder={t('20_90_search_condition') ?? ''}
        sx={{ mb: 2 }}
        InputProps={{
          endAdornment: <Icon iconName="Search" />
        }}
        value={searchQuery}
        onChange={(e) => { setSearchQuery(e.target.value) }}
      />
      <Box className="ContactsSelect-box">
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height?.toString() ?? 0}
              width={width?.toString() ?? 0}
              itemCount={filteredContacts?.length}
              itemSize={76}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </Box>
      <Stack direction="row" justifyContent="flex-end" sx={{ pt: 0 }}>
        <Button
          disabled={selectedContacts.length === 0}
          variant="contained"
          color="primary"
          onClick={handleCreateGroupChat}
          sx={{ mt: 2 }}
        >
          {t('00_03_create')}
        </Button>
      </Stack>
    </Box>
  )
}
