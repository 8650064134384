import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../../theme'
import { DateTime } from '../../../services/time'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import { TimesheetHelper } from '../Helpers/TimesheetHelper'

interface WarningBoxProps {
  employeeWarning: ListTimeSheet[]
  handleEditTimesheetByWarning: (timesheet: ListTimeSheet) => void
}

export default function WarningBox ({ employeeWarning, handleEditTimesheetByWarning }: WarningBoxProps): JSX.Element {
  const { t } = useTranslation()
  const handleOpenEditTimesheet = (timesheet: ListTimeSheet): void => {
    handleEditTimesheetByWarning(timesheet)
  }

  return (
    <Box className="WarningBox">
      <Typography
        sx={{ p: 3, textTransform: 'uppercase', fontWeight: 500 }}
        variant="h5"
      >
        {t('02_00_too_long_timesheet_warning')} ({employeeWarning.length})
      </Typography>
      <div className="border"></div>
      {employeeWarning.map((item: ListTimeSheet, index) => {
        const actualTotal = DateTime.FormatDuration(
          item.timesheet.actual_total ?? 0
        )
        return (
          <Box
            className="WarningBox-item"
            key={index}
            sx={{ p: 3 }}
            display="flex"
            gap={3}
            alignItems="center"
            onClick={() => { handleOpenEditTimesheet(item) }}
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: item.employee.color ?? Colors.primary,
                fontSize: 10
              }}
              alt={item.employee.fullname}
              title={item.employee.fullname}
              src={item.employee.avatar}
            >
              {new TimesheetHelper().getInitials(item.employee.fullname ?? '')}
            </Avatar>
            <Box>
              <Typography fontWeight="500">
                {' '}
                {item.employee.fullname}
              </Typography>
              <Typography color={Colors.danger}>{`${t(
                '02_00_actual_total'
              )} ${actualTotal}`}</Typography>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
