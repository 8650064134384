/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import moment from 'moment'
import {
  type RegistrationTimeToApprove,
  type ListShiftForTimeSheet,
  type FormFilterLockTimeSheet,
  type ReponseListTimeSheet,
  type RegistrationApprovedDetailByTimesheetIdResponse,
  type EmployeeDetailNew,
  type FormFilterUserGroups
} from '../../../services/Swagger'
import storage from '../../../services/storage'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import { type CustomTimeSheetDetailUpdate } from '../Components/NewTimesheet'
import { DateTime } from '../../../services/time'

const checkFormat = (setting: string): number => {
  if (setting.includes('HH')) return 24
  else return 12
}
export const TIMESHEET_DEFAULT: CustomTimeSheetDetailUpdate = {
  break_times: [] as RegistrationTimeToApprove[],
  registration_time_approve_id: 0,
  shift_id: undefined,
  check_in_time: undefined,
  check_out_time: undefined,
  check_in_time_hour: -1,
  check_out_time_hour: -1,
  checkin_note: undefined,
  checkout_note: undefined,
  employee_id: 0,
  group_id: 0,
  is_lock: false,
  approvedTime: 0,
  is_break: false,
  registration_time_id: 0,
  offset: 0,
  is_client_out: 2,
  is_client_in: 2,
  stamp_value_out: '',
  stamp_value_in: '',
  parent_id: 0,
  note: undefined,
  is_paid: false,
  is_approved: false,
  type: 0,
  device_info: '',
  updated_by: 0,
  updated_type: 0,
  clock_in_image: undefined,
  clock_out_image: undefined,
  cost_center_id: 0,
  shift_type_id: 0,
  use_break: false
}
export class TimesheetHelper {
  checkRanges (
    a: number | undefined,
    z: number | undefined,
    ranges: any
  ): boolean {
    let currentMax = a
    for (let i = 0; i < ranges.length; i++) {
      const [c, d] = ranges[i]

      if (c < (currentMax ?? 0) || d > (z ?? 0)) {
        // Condition violated: c is smaller than currentMax or d is greater than z
        return false
      }
      if (c >= d) {
        return false
      }

      currentMax = d

      if (i < ranges.length - 1 && d >= ranges[i + 1][0]) {
        // Condition violated: The current range overlaps with the next range
        return false
      }
    }

    return true
  }

  ranges (breakTimes: RegistrationTimeToApprove[] | undefined): any {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return breakTimes?.map(({ check_in_time, check_out_time }) => [
      check_in_time,
      check_out_time
    ])
  }

  checkValueInput = (value: string): number => {
    const convertedValue = value.replace(':', '')
    const companySetting = storage.getItem('COMPANY_INFOMATION')
    const hourSetting = checkFormat(companySetting.time_format)
    let hours, minutes
    if (
      convertedValue.length === 1 ||
      (convertedValue.length === 2 && Number(convertedValue) <= hourSetting)
    ) {
      hours = convertedValue
    }
    if (convertedValue.length === 2) {
      if (Number(convertedValue) > hourSetting) {
        hours = convertedValue.slice(0, 1)
        minutes = convertedValue.slice(1).padStart(1, '0')
      }
      if (Number(convertedValue) < hourSetting) {
        hours = convertedValue
      }
    }
    if (convertedValue.length === 3) {
      if (Number(convertedValue.slice(0, 2)) > hourSetting) {
        hours = convertedValue.slice(0, 1)
        minutes = convertedValue.slice(1).padStart(1, '0')
      }
      if (Number(convertedValue.slice(0, 2)) < hourSetting) {
        hours = convertedValue.slice(0, 2)
        minutes = convertedValue.slice(2).padStart(2, '0')
      }
    }
    if (convertedValue.length === 4) {
      hours = convertedValue.slice(0, 2)
      minutes = convertedValue.slice(2)
    }
    if (convertedValue.length > 4) {
      hours = convertedValue.slice(0, 2)
      minutes = convertedValue.slice(2).padStart(2, '0')
    }
    return Number(hours) * 3600 + Number(minutes ?? 0) * 60
  }

  calculateTotalTimeWorked = (
    checkInTime: number,
    checkOutTime: number,
    breakTimes: any
  ): string => {
    let totalTime = 0
    if (checkInTime !== 0 && checkOutTime !== 0) {
      totalTime = checkOutTime - checkInTime
    }
    breakTimes.forEach((breakTime: RegistrationTimeToApprove) => {
      if (breakTime.check_in_time != null && breakTime.check_out_time != null) {
        totalTime -= breakTime.check_out_time - breakTime.check_in_time
      }
    })
    const time = DateTime.FormatDuration(totalTime)
    return time
  }

  calculateTotalActualTotal = (
    data: ListTimeSheet[],
    type?: 'worked' | 'approved' | 'break'
  ): number => {
    const totalActualTotal = data.reduce((total, item) => {
      if (type === 'worked' && item.timesheet?.actual_total) {
        return total + item.timesheet.actual_total
      }
      if (type === 'approved' && item.timesheet?.status === 1) {
        return (
          total +
          ((item.timesheet.check_out_time ?? 0) -
            (item.timesheet.check_in_time ?? 0) -
            (item.timesheet.duration_break ?? 0))
        )
      }
      if (type === 'break' && item.timesheet?.duration_break) {
        return total + item.timesheet.duration_break
      }
      return total
    }, 0)
    return totalActualTotal
  }

  calculateTotalShiftDuration = (
    shifts: ListShiftForTimeSheet[] | undefined,
    type?: 'shift' | 'break'
  ): number | undefined => {
    if (type === 'shift') {
      const shiftDuration = shifts?.reduce((shiftTotal, shift) => {
        const duration = (shift?.duration ?? 0) - (shift?.duration_break ?? 0)
        return shiftTotal + duration
      }, 0)
      return shiftDuration
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    } else if (type === 'break') {
      const breakDuration = shifts?.reduce((breakTotal, shift) => {
        const durationBreak = shift?.duration_break ?? 0
        return breakTotal + durationBreak
      }, 0)
      return breakDuration
    }
    return undefined
  }

  dontApproveTimesheet = (data: ListTimeSheet[]): ListTimeSheet[] => {
    return data.filter((item) => {
      if (item.timesheet && item.timesheet.is_lock === true) {
        return false
      } else if (item.timesheet && item.timesheet.status === 0) {
        if (
          item.timesheet.check_in_time === null ||
          item.timesheet.check_out_time === null
        ) {
          return false
        } else {
          return true
        }
      } else if (
        Object.keys(item.timesheet).length === 0 &&
        item.shifts.length > 0
      ) {
        return true
      } else {
        return false
      }
    })
  }

  getInitials = (name: string): string => {
    const wordArray = name.split(' ')
    const first = wordArray[0].charAt(0)
    const last = wordArray[wordArray.length - 1].charAt(0)
    return first + last
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  downloadFile = (response: any) => {
    const headers = response.headers
    const filename = headers.filename
    const contentType = headers['content-type']
    const linkElement = document.createElement('a')
    try {
      const blob = new Blob([response.data], {
        type: contentType
      })
      const url = window.URL.createObjectURL(blob)

      linkElement.setAttribute('href', url)
      linkElement.setAttribute('download', filename)

      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false
      })
      linkElement.dispatchEvent(clickEvent)
    } catch (ex) {
      console.log(ex)
    }
  }

  checkIsLock = (data: ListTimeSheet[]): boolean => {
    for (const item of data) {
      if (!item.timesheet.is_lock) {
        return false
      }
    }
    return true
  }

  listLockAll = (
    data: ListTimeSheet[] | ListTimeSheet
  ): FormFilterLockTimeSheet[] => {
    if (Array.isArray(data)) {
      return data.map((item: ListTimeSheet) => ({
        is_lock: true,
        registration_time_approve_id: item.timesheet.timesheet_id
      }))
    } else {
      return [
        {
          is_lock: true,
          registration_time_approve_id: data.timesheet.timesheet_id
        }
      ]
    }
  }

  filterEmpoyeeGroup = (
    groups: FormFilterUserGroups[] | undefined,
    data: ListTimeSheet[]
  ): ListTimeSheet[] => {
    const filteredData = []
    const groupIds = groups?.map((group) => group.group_id)

    for (const item of data) {
      const employeeGroups = item.employee.groups
      if (employeeGroups?.some((group) => groupIds?.includes(Number(group.group_id)))) {
        filteredData.push(item)
      }
    }
    return filteredData
  }

  filterWorkedGroups = (groups: FormFilterUserGroups[] | undefined, data: ListTimeSheet[]): ListTimeSheet[] => {
    const groupIds = new Set(groups?.map(group => group.group_id))
    const filteredData = data.filter(item => {
      const shifts = item.shifts
      const hasMatchingShift = shifts?.some(shift => groupIds?.has(shift.group_id))
      const hasMatchingTimesheet = item.timesheet?.group_id && groupIds.has(item.timesheet.group_id)

      return hasMatchingShift || hasMatchingTimesheet
    })

    return filteredData
  }

  sortEmployeesByName = (
    employees: EmployeeDetailNew[]
  ): EmployeeDetailNew[] => {
    return employees.sort((a, b) => {
      const fullnameA = a.fullname?.toLowerCase()
      const fullnameB = b.fullname?.toLowerCase()
      if (
        fullnameA !== undefined &&
        fullnameB !== undefined &&
        fullnameA < fullnameB
      ) {
        return -1
      }
      if (
        fullnameA !== undefined &&
        fullnameB !== undefined &&
        fullnameA < fullnameB
      ) {
        return 1
      }
      return 0
    })
  }

  dateTimesheet = (shiftList: ReponseListTimeSheet[] | undefined): any => {
    const groupedData: any = {}
    shiftList?.forEach((item) => {
      const checkInDate = moment
        .unix(item.check_in_time ?? 0)
        .startOf('day')
        .unix()
      if (!groupedData[checkInDate]) {
        groupedData[checkInDate] = []
      }
      groupedData[checkInDate].push(item)
    })
    return groupedData
  }

  calculateTotalScheduledTotal = (
    shiftList: ListShiftForTimeSheet[] | undefined
  ): number => {
    let totalScheduledTotal = 0
    if (shiftList) {
      for (let i = 0; i < shiftList.length; i++) {
        totalScheduledTotal +=
          (shiftList[i].duration ?? 0) - (shiftList[i].duration_break ?? 0)
      }
    }
    return totalScheduledTotal
  }

  getRangeTimeDeciamal = (
    detail: RegistrationApprovedDetailByTimesheetIdResponse
  ): string | undefined => {
    if (detail.salary_rule?.is_only_one_time) {
      return detail.approve_time?.toString()
    } else {
      return DateTime.FormatDuration(Number(detail.approve_time))
    }
  }

  calculateTotalScheduledBreak = (
    shiftList: ListShiftForTimeSheet[] | undefined
  ): number => {
    let totalScheduledBreak = 0
    if (shiftList) {
      for (let i = 0; i < shiftList.length; i++) {
        totalScheduledBreak += shiftList[i].duration_break ?? 0
      }
    }
    return totalScheduledBreak
  }

  calculateTotalTimesheet = (
    timesheet: ReponseListTimeSheet[] | undefined
  ): number => {
    let totalWorked = 0
    if (timesheet) {
      for (let i = 0; i < timesheet.length; i++) {
        totalWorked +=
          (timesheet[i].check_out_time ?? 0) -
          (timesheet[i].check_in_time ?? 0)
      }
    }
    return totalWorked
  }

  calculateTotalBreaks = (
    timesheet: ReponseListTimeSheet[] | undefined
  ): number => {
    let totalBreaks = 0
    if (timesheet) {
      for (let i = 0; i < timesheet.length; i++) {
        const breaks = timesheet[i].breaks
        if (breaks) {
          for (let j = 0; j < breaks.length; j++) {
            totalBreaks +=
              (breaks[j].check_out_time ?? 0) - (breaks[j].check_in_time ?? 0)
          }
        }
      }
    }
    return totalBreaks
  }
}
