import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { DateTime } from '../../../services/time'
import { TimesheetHelper } from '../Helpers/TimesheetHelper'
interface TimeAutocompleteProps {
  value: number
  onChange: (value: number) => void
  status?: number
}
export interface Hour {
  inputValue?: string
  text: string
  value?: number
}

export default function TimeAutocomplete ({
  value,
  onChange,
  status
}: TimeAutocompleteProps): JSX.Element {
  const filter = createFilterOptions<Hour>()
  const listInterval: readonly Hour[] = DateTime.getListHourOfDay(
    30
  )
  return (
    <Autocomplete
      disablePortal
      value={DateTime.ConvertHour(value)}
      disabled={status === 1}
      onChange={(event, value) => {
        if (typeof value === 'string') {
          const formated = new TimesheetHelper().checkValueInput(value)
          onChange(formated)
        } else if (value?.value !== undefined && value.value >= 0) {
          const newVal = moment.duration(value.value, 'minutes').asSeconds()
          onChange(newVal)
        } else if ((value?.inputValue) != null) {
          const formated = new TimesheetHelper().checkValueInput(
            value?.inputValue ?? ''
          )
          onChange(formated)
        } else if (value == null) {
          onChange(-1)
        }
      }}
      options={listInterval}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.text)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            text: `Add "${inputValue}"`
          })
        }
        return filtered
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        if (option.inputValue != null) {
          return option.inputValue
        }
        return option.text
      }}
      renderOption={(props, option) => <li {...props}>{option.text}</li>}
      renderInput={(params) => {
        return <TextField
          variant="standard"
          placeholder='ex: 01:00'
          {...params}
        />
      }}
      selectOnFocus
      handleHomeEndKeys
      freeSolo
    />
  )
}
