import React, { useEffect, useState } from 'react'
import { FormControl, Select, MenuItem, Box, InputLabel } from '@mui/material'
import moment from 'moment'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { type DateRange } from '@mui/x-date-pickers-pro'
import { useTranslation } from 'react-i18next'
import storage from '../../services/storage'

interface PeriodDatePickerProps {
  onChange: (values: DateRange<any>) => void
  startTime?: number
  endTime?: number
}
const companySetting = storage.getItem('COMPANY_INFOMATION')
const dateFormat = companySetting?.date_format
export default function PeriodDatePicker ({ onChange, startTime, endTime }: PeriodDatePickerProps): JSX.Element {
  const shortcutsItems: Array<{ label: string, value: DateRange<any> }> = [
    {
      label: 'Today',
      value: [moment().startOf('day'), moment().endOf('day')]
    },
    {
      label: 'Yesterday',
      value: [
        moment().subtract(1, 'day').startOf('day'),
        moment().subtract(1, 'day').endOf('day')
      ]
    },
    {
      label: 'This Week',
      value: [moment().startOf('week'), moment().endOf('week')]
    },
    {
      label: 'Last Week',
      value: [
        moment().subtract(1, 'week').startOf('week'),
        moment().subtract(1, 'week').endOf('week')
      ]
    },
    {
      label: 'This Month',
      value: [moment().startOf('month'), moment().endOf('month')]
    },
    {
      label: 'Last Month',
      value: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
      ]
    },
    {
      label: 'January',
      value: [
        moment().startOf('year').month(0).startOf('month'),
        moment().startOf('year').month(0).endOf('month')
      ]
    },
    {
      label: 'February',
      value: [
        moment().startOf('year').month(1).startOf('month'),
        moment().startOf('year').month(1).endOf('month')
      ]
    },
    {
      label: 'March',
      value: [
        moment().startOf('year').month(2).startOf('month'),
        moment().startOf('year').month(2).endOf('month')
      ]
    },
    {
      label: 'April',
      value: [
        moment().startOf('year').month(3).startOf('month'),
        moment().startOf('year').month(3).endOf('month')
      ]
    },
    {
      label: 'May',
      value: [
        moment().startOf('year').month(4).startOf('month'),
        moment().startOf('year').month(4).endOf('month')
      ]
    },
    {
      label: 'June',
      value: [
        moment().startOf('year').month(5).startOf('month'),
        moment().startOf('year').month(5).endOf('month')
      ]
    },
    {
      label: 'July',
      value: [
        moment().startOf('year').month(6).startOf('month'),
        moment().startOf('year').month(6).endOf('month')
      ]
    },
    {
      label: 'August',
      value: [
        moment().startOf('year').month(7).startOf('month'),
        moment().startOf('year').month(7).endOf('month')
      ]
    },
    {
      label: 'September',
      value: [
        moment().startOf('year').month(8).startOf('month'),
        moment().startOf('year').month(8).endOf('month')
      ]
    },
    {
      label: 'Octorber',
      value: [
        moment().startOf('year').month(9).startOf('month'),
        moment().startOf('year').month(9).endOf('month')
      ]
    },
    {
      label: 'Novemb',
      value: [
        moment().startOf('year').month(10).startOf('month'),
        moment().startOf('year').month(10).endOf('month')
      ]
    },
    {
      label: 'December',
      value: [
        moment().startOf('year').month(11).startOf('month'),
        moment().startOf('year').month(11).endOf('month')
      ]
    },
    {
      label: 'Other',
      value: ['', '']
    }
  ]
  const [value, setValue] = useState<DateRange<Date>>(shortcutsItems[0].value)
  const [selectedShortcut, setSelectedShortcut] = useState<string>(
    JSON.stringify(shortcutsItems[0].value)
  )
  useEffect(() => {
    if ((startTime != null) && (endTime != null)) {
      const groupValue: DateRange<any> = [
        moment.unix(startTime).startOf('day'),
        moment.unix(endTime).endOf('day')
      ]
      setValue(groupValue)
      const shortcut = shortcutsItems.find((sc) => {
        return (
          moment(sc.value[0]).unix() === startTime &&
          moment(sc.value[1]).unix() === endTime
        )
      })
      if (shortcut != null) {
        setSelectedShortcut(JSON.stringify(shortcut.value))
      } else {
        setSelectedShortcut(JSON.stringify(oTher.value))
      }
    }
  }, [startTime, endTime])
  const oTher = shortcutsItems[shortcutsItems.length - 1]
  const { t } = useTranslation()

  return (
    <Box className="PeriodDatePicker">
      <FormControl className="PeriodDatePicker-control">
        <InputLabel>{t('20_38_period')}</InputLabel>
      <Select
          value={selectedShortcut}
          label={t('20_38_period')}
          onChange={(e: any) => {
            setSelectedShortcut(e.target.value)
            const newValueObj: DateRange<Date> = JSON.parse(e.target.value)
            const newValue: DateRange<Date> = [
              moment(newValueObj[0]).startOf('day') as any,
              moment(newValueObj[1]).endOf('day') as any
            ]
            setValue(newValue)
            onChange(newValue)
          }}
        >
          {shortcutsItems.map((item, index) => (
            <MenuItem key={index} value={JSON.stringify(item.value)}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DateRangePicker
        format={dateFormat}
        value={value}
        onChange={(newValue: any) => {
          const startTime = moment(newValue[0]).startOf('day') as any
          const endTime = moment(newValue[1]).endOf('day') as any
          setValue([startTime, endTime])
          onChange([startTime, endTime])
          const shortcut = shortcutsItems.find((sc) => {
            return (
              sc.value[0].toString() === startTime.toString() &&
              sc.value[1].toString() === endTime.toString()
            )
          })
          if (shortcut != null) {
            setSelectedShortcut(JSON.stringify(shortcut.value))
          } else {
            setSelectedShortcut(JSON.stringify(oTher.value))
          }
        }}
      />
    </Box>
  )
}
