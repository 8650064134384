/* eslint-disable multiline-ternary */
import React from 'react'
import Modal from '../../../components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Grid, Typography, Tooltip, List, ListItemText, Button, Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { UsersApi } from '../../../services/Swagger'
import { Colors } from '../../../theme'
import moment from 'moment'
import storage from '../../../services/storage'
import { TimesheetHelper } from '../Helpers/TimesheetHelper'

interface EmployeeInfomationProps {
  onClose: () => void
  id: number
}

export default function EmployeeInfomation ({ onClose, id }: EmployeeInfomationProps): JSX.Element {
  const { t } = useTranslation()
  const companySetting = storage.getItem('COMPANY_INFOMATION')
  const dateFormat = companySetting?.date_format
  const employeeInfomation = useQuery(
    ['api/user/getEmployeeByID', { empId: id }],
    async () => {
      const res = await new UsersApi().getEmployeeByID(id)
      return res.data
    },
    {
      enabled: true
    }
  )
  const phone1 = employeeInfomation.data?.phone1 ?? ''
  const phone2 = employeeInfomation.data?.phone2 ?? ''

  let listPhone = ''

  if (phone2 !== '') {
    listPhone = `${phone1}, ${phone2}`
  } else {
    listPhone = phone1
  }
  const secondaryGroupNames = employeeInfomation.data?.secondary_groups?.map((group) => group.group_name)
  return (
    <Modal open onClose={onClose} title={t('21_00_employee_information')}>
      {employeeInfomation.isLoading ? (
        <Box sx={{ p: 4 }}>
          <Grid display={'flex'} justifyContent={'center'}>
            <Skeleton variant="circular" width={120} height={120} />
          </Grid>
          <Grid display={'flex'} justifyContent={'center'}>
            <Skeleton height={26} width="50%" variant="text" />
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography className="link" variant="body2">
                {t('20_01_email')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" />
            </Grid>
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">{t('20_13_group')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" />
            </Grid>
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">{t('09_01_skills')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" />
            </Grid>
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">{t('00_06_telephone')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" />
            </Grid>
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">{t('18_01_birthday')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ py: 2, justifyContent: 'flex-end' }}
          >
            <Grid item>
              <Button onClick={() => { onClose() }} variant="outlined">
                {t('20_13_cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained">
                {t('00_03_edit')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ p: 4 }} >
          <Grid display={'flex'} justifyContent={'center'}>
            <Avatar
              sx={{
                width: 120,
                height: 120,
                bgcolor: employeeInfomation.data?.color ?? Colors.primary,
                fontSize: 40
              }}
              alt={employeeInfomation.data?.fullname}
              title={employeeInfomation.data?.fullname}
              src={employeeInfomation.data?.avatar}
            >
              {new TimesheetHelper().getInitials(employeeInfomation.data?.fullname ?? '')}
            </Avatar>
          </Grid>
          <Grid>
            <Typography sx={{ pt: 2 }} variant="h5" textTransform={'uppercase'} textAlign={'center'}>
              {employeeInfomation.data?.fullname}
            </Typography>
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography className="link" variant="body2">
                {t('20_01_email')}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {((employeeInfomation.data?.email) != null) && (
                <a href={`mailto:${employeeInfomation.data.email}`}>
                  <Typography>{employeeInfomation.data.email}</Typography>
                </a>
              )}
            </Grid>
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">{t('20_13_group')}</Typography>
            </Grid>
            <Grid item xs={5}>
              {((employeeInfomation.data?.primary_group?.group_name) != null) && (
                <div>
                  <Typography>
                    {`${employeeInfomation.data.primary_group.group_name} (${employeeInfomation.data.primary_group.department?.department_name ?? ''})`}
                    {secondaryGroupNames?.length === 1 && (
                      <span>
                        {`,${(secondaryGroupNames[0] ?? '')}`}
                      </span>
                    )}
                    {(secondaryGroupNames != null) && secondaryGroupNames.length > 1 && (
                      <Tooltip
                        title={
                          <List>
                            {secondaryGroupNames.map((name: any) => (
                              <ListItemText primary={name} key={name} />
                            ))}
                          </List>
                        }
                      >
                        <span className="link">{t('03_01_and_more', {
                          number: secondaryGroupNames.length - 1
                        })}</span>
                      </Tooltip>
                    )}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">{t('09_01_skills')}</Typography>
            </Grid>
            <Grid item xs={5}>
            </Grid>
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">{t('00_06_telephone')}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{listPhone}</Typography>
            </Grid>
          </Grid>
          <Grid sx={{ py: 2 }} container justifyContent={'center'} alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">{t('18_01_birthday')}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>
                {employeeInfomation.data?.birthday !== null
                  ? moment.unix(Number(employeeInfomation.data?.birthday)).format(dateFormat)
                  : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ py: 2, justifyContent: 'flex-end' }}
          >
            <Grid item>
              <Button onClick={() => { onClose() }} variant="outlined">
                {t('20_13_cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained">
                {t('00_03_edit')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Modal>
  )
}
