import React, { useEffect, useState } from 'react'
import { UsersApi, type EmployeeSetting, type EmployeeDetailNew } from '../../services/Swagger'
import { Autocomplete, Avatar, Box, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TimesheetHelper } from '../../pages/Timesheet/Helpers/TimesheetHelper'
import { Colors } from '../../theme'
import storage from '../../services/storage'

interface EmployeePickerProps {
  options?: any[]
  onEmployeeChange: (employee: any) => void
  employeeData?: EmployeeDetailNew
  getEmployeeSettings: EmployeeSetting[] | undefined
}

export default function EmployeePicker ({ options, onEmployeeChange, getEmployeeSettings, employeeData }: EmployeePickerProps): JSX.Element {
  const { t } = useTranslation()
  const filteredData: any = getEmployeeSettings?.find((item) => item.key === 'timesheet_last_selected_employees')
  const userProfile = storage.getItem('USER_PROFILE')
  const employeeID = employeeData?.employee_id ?? 0
  const [selectedEmployeeID, setSelectedEmployeeID] = useState<number | null>(null)

  useEffect(() => {
    setSelectedEmployeeID(employeeID)
  }, [employeeID])

  const onSelectChange = async (event: any, value: any): Promise<void> => {
    setSelectedEmployeeID(value?.employee_id)
    if (value === null) {
      return
    }
    onEmployeeChange(value)
    const employeeValue = {
      employee_setting_id: filteredData.employee_setting_id,
      employee_id: userProfile.employee_id,
      key: 'timesheet_last_selected_employees',
      module_id: 2,
      value
    }
    await new UsersApi().updateEmployeeSetting(filteredData.employee_setting_id, employeeValue)
  }
  return (
    <Box sx={{ minWidth: '300px', background: 'white' }}>
      <Autocomplete
        fullWidth
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        value={options?.find((option: any) => Number(option.employee_id) === Number(selectedEmployeeID)) || null}
        options={options ?? []}
        groupBy={(option: any) => option.group_name}
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        getOptionLabel={(option: any) => option.fullname || ''}
        isOptionEqualToValue={(option, value) => option?.employee_id === value?.employee_id}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onChange={onSelectChange}
        renderOption={(props, option) => (
          <Box component="li" display="flex" gap={3} alignItems="center" {...props}>
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: option.color ?? Colors.primary,
                fontSize: 10
              }}
              alt={option.fullname}
              title={option.fullname}
              src={option.avatar}
            >
              {new TimesheetHelper().getInitials(option.fullname ?? '')}
            </Avatar>
            <Typography fontWeight="500"> {option.fullname}</Typography>
          </Box>
        )}
        renderInput={(params) => <TextField {...params} variant="standard" placeholder={t('20_91_select_employee') ?? ''} />}
      />
    </Box>
  )
}
