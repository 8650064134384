import { type TimeSheetDetailUpdate } from '../../../services/Swagger'
import { getGroupRoleByModule } from '../../../services/modules'

export class CheckAclTimesheet {
  // check show group theo quyền viewall
  GetGroupView = (departments: any[], moduleId: number): any[] => {
    const filteredData = departments?.map((department: any): any => {
      const filteredGroups = department.groups
        .map((group: any) => ({
          group,
          groupRole: getGroupRoleByModule(moduleId, group.group_id)
        }))
        .filter(
          (groupWithRole: any) => groupWithRole.groupRole.ViewAll === true
        )
        .map((groupWithRole: any) => groupWithRole.group)
      return {
        ...department,
        groups: filteredGroups
      }
    })
    return filteredData
  }

  // check show group theo quyền approve
  GetGroupApprove = (departments: any[], moduleId: number): any[] => {
    const filteredData = departments?.map((department: any): any => {
      const filteredGroups = department.groups
        .map((group: any) => ({
          group,
          groupRole: getGroupRoleByModule(moduleId, group.group_id)
        }))
        .filter(
          (groupWithRole: any) => groupWithRole.groupRole.Approve === true
        )
        .map((groupWithRole: any) => groupWithRole.group)
      return {
        ...department,
        groups: filteredGroups
      }
    })
    return filteredData
  }

  // check show group theo quyền Create
  GetGroupCreate = (departments: any[], moduleId: number): any[] => {
    const filteredData = departments?.map((department: any): any => {
      const filteredGroups = department.groups
        .map((group: any) => ({
          group,
          groupRole: getGroupRoleByModule(moduleId, group.group_id)
        }))
        .filter((groupWithRole: any) => groupWithRole.groupRole.Create === true)
        .map((groupWithRole: any) => groupWithRole.group)
      return {
        ...department,
        groups: filteredGroups
      }
    })
    return filteredData
  }

  GetGroupShowCreate = (departments: any[], moduleId: number): any[] => {
    const filteredData = departments?.flatMap((department: any): any[] => {
      const filteredGroups = department.groups
        .map((group: any) => ({
          group,
          groupRole: getGroupRoleByModule(moduleId, group.group_id)
        }))
        .filter((groupWithRole: any) => groupWithRole.groupRole.Create === true)
        .map((groupWithRole: any) => groupWithRole.group)
      return filteredGroups
    })
    return filteredData
  }

  GetShiftRole = (
    shifts: TimeSheetDetailUpdate[],
    moduleId: number
  ): TimeSheetDetailUpdate[] => {
    const filteredGroups = shifts.filter((shift) => {
      const groupRole = getGroupRoleByModule(moduleId, shift.group_id ?? 0)
      return groupRole.Create === true && groupRole.Approve === true
    })
    return filteredGroups
  }
}
