import { Container, CssBaseline, Box, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

export default function LogIn (): JSX.Element {
  const { t } = useTranslation()
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 360,
          mx: 'auto'
        }}
      >
        <Box sx={{ mb: 8, width: '100%', textAlign: 'center' }}>
          <img
            src={
              // eslint-disable-next-line @typescript-eslint/no-var-requires
              require('../../assets/images/TITANPLAN_logomark_primary.svg')
                .default
            }
            alt="logo"
            height={72}
          />
        </Box>
        <Outlet />
        <Typography sx={{ mt: 10 }} align="center">
          {t('15_01_titanplan_copyright', {
            year: new Date().getFullYear(),
            agency: process.env.REACT_APP_NAME
          })}
        </Typography>
      </Box>
    </Container>
  )
}
