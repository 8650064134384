import {
  ClickAwayListener,
  Input,
  Popper,
  Stack
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Icon from '../Icon/Icon'
import { TwitterPicker } from 'react-color'
interface ColorPickerProps {
  value: string
  name?: string
  onChange?: (color: string) => void
  disabled?: boolean
}
export default function ColorPicker (props: ColorPickerProps): JSX.Element {
  const [color, setColor] = useState(props.value)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState(false)
  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? 'spring-popper' : undefined
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (props.disabled ?? false) {
      setAnchorEl(event.currentTarget)
      setOpen((previousOpen) => !previousOpen)
    }
  }
  const handleClickAway = (): void => {
    setOpen(false)
  }
  useEffect(() => {
    setColor(props.value)
  }, [props.value])
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Input
          value={color}
          name={props.name}
          disabled={!(props.disabled ?? false)}
          onClick={(ev) => {
            handleClick(ev)
          }}
          startAdornment={
            <Icon
              iconName="SquareShapeSolid"
              style={{ color: `${color}`, marginRight: 8 }}
            />
          }
        />
        <Popper
          id={id}
          open={open}
          className="popper-color"
          placement="bottom-start"
          anchorEl={anchorEl}
        >
          <TwitterPicker
            color={color}
            onChange={(color: any) => {
              setColor(color.hex)
              props.onChange?.(color.hex)
            }}
          />
        </Popper>
      </Stack>
    </ClickAwayListener>
  )
}
