import React from 'react'
import { Icon, type IIconProps } from '@fluentui/react/lib/Icon'
import { Colors } from '../../theme'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
type Props = {
  color?: string
} & IIconProps
export default function TIcon ({ color, ...props }: Props): JSX.Element {
  const colors = {
    primary: Colors.primary,
    white: 'white',
    black: 'black'
  } as any
  return (
    <Icon
      style={{ fontSize: 16, color: (color != null) ? colors[color] : '#7b7c7e' }}
      {...props}
    />
  )
}

export function Loading (): JSX.Element {
  return (
    <Box sx={{ mb: 10, justifyContent: 'center', display: 'flex' }}>
      <CircularProgress size={40} />
    </Box>
  )
}
