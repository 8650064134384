import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import './iconsax.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { HelmetProvider } from 'react-helmet-async'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import i18next from 'i18next'
import theme from './theme'
import './services/i18n'
import './services/api'
import { Provider } from 'react-redux'
import { Store } from './redux/index'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(
  'ac4670d20df712b31ce552e0b8292713Tz02MzQ2NyxFPTE3MTIxMjgzMzM3NzcsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)

initializeIcons()

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={Store}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <HelmetProvider>
                <CssBaseline />
                <App />
              </HelmetProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </Provider>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
