/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useQuery } from '@tanstack/react-query'
import {
  CompanysApi,
  type FilterTimeSheet,
  PlanningsApi,
  TimeSheetApi,
  UsersApi
} from '../../../services/Swagger'
import { useState } from 'react'
import { MODULEID } from '../../../services/modules'

export const useTimesheetService = () => {
  const [filter, setFilter] = useState<FilterTimeSheet>({
    groups: [],
    start_time: 0,
    end_time: 0,
    is_approved: -1,
    orders: { order_key: 'check_in_time', order_value: 'DESC' }
  })
  const employeeQuery = useQuery(
    ['/api/user/getEmployeeByGroups', filter],
    async () => {
      const res = await new UsersApi().getEmployeeByGroups(filter)
      return res.data
    },
    { enabled: false }
  )
  const timesheetQuery = useQuery(
    ['/api/timeregistration/getListTimeSheet', filter],
    async () => {
      const res = await new TimeSheetApi().getListTimeSheet(filter)
      return res.data
    },
    { enabled: false }
  )
  const shiftQuery = useQuery(
    ['api/shiftplanning/getListShiftForTimeSheet', filter],
    async () => {
      const res = await new PlanningsApi().getListShiftForTimeSheet(filter)
      return res.data
    },
    { enabled: false }
  )
  const indicatorSettingQuery = useQuery(
    ['api/timeregistration/getListIndicatorSetting'],
    async () => {
      const res = await new TimeSheetApi().getListIndicatorSetting()
      return res.data
    }
  )
  const timesheetSetting = useQuery(
    ['api/company/getCompanySetting/timesheet_setting'],
    async () => {
      const res = await new CompanysApi().getCompanySetting(
        'timesheet_setting'
      )
      return res.data
    },
    { enabled: false }
  )
  const getEmployeeSettings = useQuery(['api/user/getEmployeeSettings'],
    async () => {
      const res = await new UsersApi().getEmployeeSettings(MODULEID.timesheet)
      return res.data
    },
    { enabled: false }
  )
  return {
    filter,
    setFilter,
    timesheetQuery,
    employeeQuery,
    shiftQuery,
    indicatorSettingQuery,
    timesheetSetting,
    getEmployeeSettings
  }
}
