import { Alert } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface FormAlertProps {
  formik: any
  error?: string
}
export default function FormAlert ({ formik, error }: FormAlertProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      {Object.keys(formik.errors).length > 0 &&
        Object.keys(formik.touched).length > 0 && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {Object.keys(formik.errors).map((key, index): JSX.Element | undefined => {
              if ((Boolean((formik.errors)[key])) && (Boolean((formik.touched)[key]))) { return <div key={index}>{t((formik.errors)[key])}</div> } else { return undefined }
            })}
          </Alert>
      )}
        {(error !== undefined) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {t(error)}
        </Alert>
        )}
    </>
  )
}
