import * as React from 'react'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import TextField, { type TextFieldProps } from '@mui/material/TextField'
import { Icon } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

export default function ChatSearch (props: TextFieldProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <Box className="search">
      <TextField
        {...props}
        className="search-input"
        placeholder={t('20_92_search') ?? ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon iconName="Search" />
            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}
