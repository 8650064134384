import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../../components'
import { Box, Button, Grid, Typography } from '@mui/material'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import { DateTime } from '../../../services/time'
import moment from 'moment'
import { type RegistrationTimeToApprove, TimeSheetApi } from '../../../services/Swagger'
import { toast } from 'react-toastify'

interface DeleteModalProps {
  onClose: () => void
  data?: ListTimeSheet
  dataUpdate: () => void
}

export default function DeleteModal ({ onClose, data, dataUpdate }: DeleteModalProps): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const handleDelete = async (): Promise<void> => {
    const newData: RegistrationTimeToApprove = {
      registration_time_approve_id: data?.timesheet.timesheet_id ?? 0,
      is_lock: false,
      is_break: false,
      is_client_in: 0,
      is_client_out: 2,
      parent_id: 0,
      is_paid: false
    }
    try {
      await new TimeSheetApi().deleteTimeRegistrationApprove([newData])
      setIsLoading(true)
      onClose()
      dataUpdate()
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
        <Modal
            open={true}
            onClose={() => { onClose() }}
            size="small"
            title={t('00_06_DIALOG_CONFIRMATION_TITLE')}
        >
            <Box sx={{ px: 4, py: 2 }}>
                <Grid sx={{ px: 4, py: 10 }}>
                    <Typography variant='subtitle1' textAlign={'center'}>{t('00_06_dialog_delete_content')}</Typography>
                    <Typography textAlign={'center'}>{`${DateTime.ConvertHour((data?.timesheet?.check_in_time ?? 0) -
                        moment((data?.timesheet?.check_in_time ?? 0) * 1000)
                            .startOf('day')
                            .unix())} - ${((data?.timesheet.check_out_time) != null)
                                ? DateTime.ConvertHour((data.timesheet.check_out_time ?? 0) - moment((data?.timesheet?.check_out_time ?? 0) * 1000)
                                    .startOf('day')
                                    .unix())
                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                : '?'} ${DateTime.FormatDate(data?.timesheet.check_in_time, 'date')} - ${data?.employee.fullname ?? ''} - ${data?.timesheet.group_name ?? 0} (${data?.timesheet.department_name})`}</Typography>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    sx={{ py: 2, justifyContent: 'flex-end' }}
                >
                    <Grid item>
                        <Button onClick={() => { onClose() }} variant="outlined">
                            {t('20_13_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color='error'
                            disabled={isLoading}
                            onClick={() => { void handleDelete() }}
                        >
                            {t('20_00_delete')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}
