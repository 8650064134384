import { Box } from '@mui/material'
import React from 'react'
import { type TimesheetCheckResponse } from '../Services/TimesheetServices'
interface LinearProgressProps {
  value: number
  showWarnings: TimesheetCheckResponse
}

export default function LinearProgress ({
  value,
  showWarnings
}: LinearProgressProps): JSX.Element {
  const color =
    showWarnings.isValid && showWarnings.isValid
      ? '255, 87, 107'
      : '8, 176, 160'
  return (
    <Box>
      <Box
        height={22}
        sx={{
          width: `${value > 100 ? 100 : value}%`,
          background: `linear-gradient(to right, rgba(${color}, .2), rgb(${color}))`
        }}
      ></Box>
    </Box>
  )
}
