/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EmployeeAvai } from '../models';
// @ts-ignore
import { FilterTimeSheet } from '../models';
// @ts-ignore
import { FormCopyMoveShift } from '../models';
// @ts-ignore
import { FormFilterConflictPlanning } from '../models';
// @ts-ignore
import { FormFilterEmployeeAvailable } from '../models';
// @ts-ignore
import { FormFilterInformationGroupView } from '../models';
// @ts-ignore
import { FormFilterListMyShift } from '../models';
// @ts-ignore
import { FormFilterListPlanColleague } from '../models';
// @ts-ignore
import { FormFilterListPlanningGroupView } from '../models';
// @ts-ignore
import { FormFilterListPlanningTeamScheduled } from '../models';
// @ts-ignore
import { FormParamV2 } from '../models';
// @ts-ignore
import { GetListPlanningGroupDetail } from '../models';
// @ts-ignore
import { InfoPlanning } from '../models';
// @ts-ignore
import { InformationGroupView } from '../models';
// @ts-ignore
import { ListEmployeeAvailable } from '../models';
// @ts-ignore
import { ListShiftForTimeSheet } from '../models';
// @ts-ignore
import { PlanningDays } from '../models';
// @ts-ignore
import { PlanningV2 } from '../models';
// @ts-ignore
import { ReponseListPlanningMobile } from '../models';
// @ts-ignore
import { ShiftDetail } from '../models';
// @ts-ignore
import { ShiftTemplatesDetail } from '../models';
/**
 * PlanningsApi - axios parameter creator
 * @export
 */
export const PlanningsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changeStatusPlanning
         * @param {number} id 
         * @param {number} status 
         * @param {ShiftDetail} body changeStatusPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusPlanning: async (id: number, status: number, body: ShiftDetail, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeStatusPlanning', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('changeStatusPlanning', 'status', status)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('changeStatusPlanning', 'body', body)
            const localVarPath = `/api/shiftplanning/changeStatusPlanning/{id}/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkConflictPlanning
         * @param {FormFilterConflictPlanning} body checkConflictPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConflictPlanning: async (body: FormFilterConflictPlanning, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('checkConflictPlanning', 'body', body)
            const localVarPath = `/api/shiftplanning/checkConflictPlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary copyMoveShift
         * @param {FormCopyMoveShift} body copyMoveShift Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMoveShift: async (body: FormCopyMoveShift, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('copyMoveShift', 'body', body)
            const localVarPath = `/api/shiftplanning/copyMoveShift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createEmployeePlanningTemplate
         * @param {ShiftTemplatesDetail} body createEmployeePlanningTemplate Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployeePlanningTemplate: async (body: ShiftTemplatesDetail, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createEmployeePlanningTemplate', 'body', body)
            const localVarPath = `/api/shiftplanning/createEmployeePlanningTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createPlanning
         * @param {ShiftDetail} body createPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanning: async (body: ShiftDetail, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createPlanning', 'body', body)
            const localVarPath = `/api/shiftplanning/createPlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePlanningTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanningTemplate: async (id: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePlanningTemplate', 'id', id)
            const localVarPath = `/api/shiftplanning/deletePlanningTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInfoPlanning
         * @param {FormParamV2} body getInfoPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoPlanning: async (body: FormParamV2, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getInfoPlanning', 'body', body)
            const localVarPath = `/api/shiftplanning/getInfoPlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInformationGroupView
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterInformationGroupView} body getInformationGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformationGroupView: async (minTime: string, maxTime: string, body: FormFilterInformationGroupView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getInformationGroupView', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getInformationGroupView', 'maxTime', maxTime)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getInformationGroupView', 'body', body)
            const localVarPath = `/api/shiftplanning/getInformationGroupView/{minTime}/{maxTime}`
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListEmployeeAvailable
         * @param {FormFilterEmployeeAvailable} body getListEmployeeAvailable Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeAvailable: async (body: FormFilterEmployeeAvailable, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListEmployeeAvailable', 'body', body)
            const localVarPath = `/api/shiftplanning/getListEmployeeAvailable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListMyShift
         * @param {FormFilterListMyShift} body getListMyShift Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListMyShift: async (body: FormFilterListMyShift, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListMyShift', 'body', body)
            const localVarPath = `/api/shiftplanning/getListMyShift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListOpenPlanningByEmployeeID
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOpenPlanningByEmployeeID: async (page: number, limit: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getListOpenPlanningByEmployeeID', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getListOpenPlanningByEmployeeID', 'limit', limit)
            const localVarPath = `/api/shiftplanning/getListOpenPlanningByEmployeeID/{page}/{limit}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)))
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListOpenPlanningByEmployeeIdAndTimeStamp
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOpenPlanningByEmployeeIdAndTimeStamp: async (minTime: string, maxTime: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListOpenPlanningByEmployeeIdAndTimeStamp', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListOpenPlanningByEmployeeIdAndTimeStamp', 'maxTime', maxTime)
            const localVarPath = `/api/shiftplanning/getListOpenPlanningByEmployeeIdAndTimeStamp/{minTime}/{maxTime}`
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListPlanColleague
         * @param {number} id 
         * @param {FormFilterListPlanColleague} body getListPlanColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanColleague: async (id: number, body: FormFilterListPlanColleague, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getListPlanColleague', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListPlanColleague', 'body', body)
            const localVarPath = `/api/shiftplanning/getListPlanColleague/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListPlanning
         * @param {FormParamV2} body getListPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanning: async (body: FormParamV2, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListPlanning', 'body', body)
            const localVarPath = `/api/shiftplanning/getListPlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListPlanningByEmployeeID
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningByEmployeeID: async (minTime: string, maxTime: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListPlanningByEmployeeID', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListPlanningByEmployeeID', 'maxTime', maxTime)
            const localVarPath = `/api/shiftplanning/getListPlanningByEmployeeID/{minTime}/{maxTime}`
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListPlanningByEmployeeIDAndMinTime
         * @param {number} employeeId 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningByEmployeeIDAndMinTime: async (employeeId: number, minTime: string, maxTime: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getListPlanningByEmployeeIDAndMinTime', 'employeeId', employeeId)
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListPlanningByEmployeeIDAndMinTime', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListPlanningByEmployeeIDAndMinTime', 'maxTime', maxTime)
            const localVarPath = `/api/shiftplanning/getListPlanningByEmployeeIDAndMinTime/{employeeId}/{minTime}/{maxTime}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListPlanningGroupView
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterListPlanningGroupView} body getListPlanningGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningGroupView: async (minTime: string, maxTime: string, body: FormFilterListPlanningGroupView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListPlanningGroupView', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListPlanningGroupView', 'maxTime', maxTime)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListPlanningGroupView', 'body', body)
            const localVarPath = `/api/shiftplanning/getListPlanningGroupView/{minTime}/{maxTime}`
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListPlanningMobile
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterListPlanningGroupView} body getListPlanningMobile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningMobile: async (minTime: string, maxTime: string, body: FormFilterListPlanningGroupView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListPlanningMobile', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListPlanningMobile', 'maxTime', maxTime)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListPlanningMobile', 'body', body)
            const localVarPath = `/api/shiftplanning/getListPlanningMobile/{minTime}/{maxTime}`
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListPlanningTeamScheduled
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterListPlanningTeamScheduled} body getListPlanningTeamScheduled Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningTeamScheduled: async (minTime: string, maxTime: string, body: FormFilterListPlanningTeamScheduled, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListPlanningTeamScheduled', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListPlanningTeamScheduled', 'maxTime', maxTime)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListPlanningTeamScheduled', 'body', body)
            const localVarPath = `/api/shiftplanning/getListPlanningTeamScheduled/{minTime}/{maxTime}`
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListPlanningTemplate
         * @param {number} employeeID 
         * @param {number} groupID 
         * @param {boolean} isColleague 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningTemplate: async (employeeID: number, groupID: number, isColleague: boolean, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeID' is not null or undefined
            assertParamExists('getListPlanningTemplate', 'employeeID', employeeID)
            // verify required parameter 'groupID' is not null or undefined
            assertParamExists('getListPlanningTemplate', 'groupID', groupID)
            // verify required parameter 'isColleague' is not null or undefined
            assertParamExists('getListPlanningTemplate', 'isColleague', isColleague)
            const localVarPath = `/api/shiftplanning/getListPlanningTemplate/{employeeID}/{groupID}/{isColleague}`
                .replace(`{${"employeeID"}}`, encodeURIComponent(String(employeeID)))
                .replace(`{${"groupID"}}`, encodeURIComponent(String(groupID)))
                .replace(`{${"isColleague"}}`, encodeURIComponent(String(isColleague)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListShiftForTimeSheet
         * @param {FilterTimeSheet} body getListShiftForTimeSheet Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListShiftForTimeSheet: async (body: FilterTimeSheet, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListShiftForTimeSheet', 'body', body)
            const localVarPath = `/api/shiftplanning/getListShiftForTimeSheet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListShiftMarket
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {number} quantity 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListShiftMarket: async (minTime: string, maxTime: string, quantity: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListShiftMarket', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListShiftMarket', 'maxTime', maxTime)
            // verify required parameter 'quantity' is not null or undefined
            assertParamExists('getListShiftMarket', 'quantity', quantity)
            const localVarPath = `/api/shiftplanning/getListShiftMarket/{minTime}/{maxTime}/{quantity}`
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)))
                .replace(`{${"quantity"}}`, encodeURIComponent(String(quantity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMergePlanningTeamScheduled
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {boolean} mergeGroup 
         * @param {FormFilterListPlanningTeamScheduled} body getMergePlanningTeamScheduled Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMergePlanningTeamScheduled: async (minTime: string, maxTime: string, mergeGroup: boolean, body: FormFilterListPlanningTeamScheduled, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getMergePlanningTeamScheduled', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getMergePlanningTeamScheduled', 'maxTime', maxTime)
            // verify required parameter 'mergeGroup' is not null or undefined
            assertParamExists('getMergePlanningTeamScheduled', 'mergeGroup', mergeGroup)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getMergePlanningTeamScheduled', 'body', body)
            const localVarPath = `/api/shiftplanning/getMergePlanningTeamScheduled/{minTime}/{maxTime}/{mergeGroup}`
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)))
                .replace(`{${"mergeGroup"}}`, encodeURIComponent(String(mergeGroup)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPlanningByID
         * @param {number} id 
         * @param {number} fakeId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanningByID: async (id: number, fakeId: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPlanningByID', 'id', id)
            // verify required parameter 'fakeId' is not null or undefined
            assertParamExists('getPlanningByID', 'fakeId', fakeId)
            const localVarPath = `/api/shiftplanning/getPlanningByID/{id}/{fakeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fakeId"}}`, encodeURIComponent(String(fakeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateEmployeePlanningTemplate
         * @param {number} id 
         * @param {ShiftTemplatesDetail} body updateEmployeePlanningTemplate Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeePlanningTemplate: async (id: number, body: ShiftTemplatesDetail, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmployeePlanningTemplate', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateEmployeePlanningTemplate', 'body', body)
            const localVarPath = `/api/shiftplanning/updateEmployeePlanningTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updatePlanning
         * @param {number} id 
         * @param {ShiftDetail} body updatePlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanning: async (id: number, body: ShiftDetail, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePlanning', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePlanning', 'body', body)
            const localVarPath = `/api/shiftplanning/updatePlanning/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanningsApi - functional programming interface
 * @export
 */
export const PlanningsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanningsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary changeStatusPlanning
         * @param {number} id 
         * @param {number} status 
         * @param {ShiftDetail} body changeStatusPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatusPlanning(id: number, status: number, body: ShiftDetail, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatusPlanning(id, status, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary checkConflictPlanning
         * @param {FormFilterConflictPlanning} body checkConflictPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkConflictPlanning(body: FormFilterConflictPlanning, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeAvai>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkConflictPlanning(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary copyMoveShift
         * @param {FormCopyMoveShift} body copyMoveShift Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyMoveShift(body: FormCopyMoveShift, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanningV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyMoveShift(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createEmployeePlanningTemplate
         * @param {ShiftTemplatesDetail} body createEmployeePlanningTemplate Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmployeePlanningTemplate(body: ShiftTemplatesDetail, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftTemplatesDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmployeePlanningTemplate(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createPlanning
         * @param {ShiftDetail} body createPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlanning(body: ShiftDetail, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlanning(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletePlanningTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlanningTemplate(id: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftTemplatesDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlanningTemplate(id, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getInfoPlanning
         * @param {FormParamV2} body getInfoPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoPlanning(body: FormParamV2, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoPlanning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoPlanning(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getInformationGroupView
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterInformationGroupView} body getInformationGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInformationGroupView(minTime: string, maxTime: string, body: FormFilterInformationGroupView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformationGroupView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInformationGroupView(minTime, maxTime, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListEmployeeAvailable
         * @param {FormFilterEmployeeAvailable} body getListEmployeeAvailable Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListEmployeeAvailable(body: FormFilterEmployeeAvailable, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEmployeeAvailable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployeeAvailable(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListMyShift
         * @param {FormFilterListMyShift} body getListMyShift Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListMyShift(body: FormFilterListMyShift, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanningDays>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListMyShift(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListOpenPlanningByEmployeeID
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListOpenPlanningByEmployeeID(page: number, limit: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListOpenPlanningByEmployeeID(page, limit, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListOpenPlanningByEmployeeIdAndTimeStamp
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListOpenPlanningByEmployeeIdAndTimeStamp(minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListOpenPlanningByEmployeeIdAndTimeStamp(minTime, maxTime, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListPlanColleague
         * @param {number} id 
         * @param {FormFilterListPlanColleague} body getListPlanColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPlanColleague(id: number, body: FormFilterListPlanColleague, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListPlanColleague(id, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListPlanning
         * @param {FormParamV2} body getListPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPlanning(body: FormParamV2, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanningV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListPlanning(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListPlanningByEmployeeID
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPlanningByEmployeeID(minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListPlanningByEmployeeID(minTime, maxTime, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListPlanningByEmployeeIDAndMinTime
         * @param {number} employeeId 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPlanningByEmployeeIDAndMinTime(employeeId: number, minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListPlanningByEmployeeIDAndMinTime(employeeId, minTime, maxTime, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListPlanningGroupView
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterListPlanningGroupView} body getListPlanningGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPlanningGroupView(minTime: string, maxTime: string, body: FormFilterListPlanningGroupView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetListPlanningGroupDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListPlanningGroupView(minTime, maxTime, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListPlanningMobile
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterListPlanningGroupView} body getListPlanningMobile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPlanningMobile(minTime: string, maxTime: string, body: FormFilterListPlanningGroupView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReponseListPlanningMobile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListPlanningMobile(minTime, maxTime, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListPlanningTeamScheduled
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterListPlanningTeamScheduled} body getListPlanningTeamScheduled Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPlanningTeamScheduled(minTime: string, maxTime: string, body: FormFilterListPlanningTeamScheduled, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetListPlanningGroupDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListPlanningTeamScheduled(minTime, maxTime, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListPlanningTemplate
         * @param {number} employeeID 
         * @param {number} groupID 
         * @param {boolean} isColleague 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPlanningTemplate(employeeID: number, groupID: number, isColleague: boolean, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftTemplatesDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListPlanningTemplate(employeeID, groupID, isColleague, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListShiftForTimeSheet
         * @param {FilterTimeSheet} body getListShiftForTimeSheet Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListShiftForTimeSheet(body: FilterTimeSheet, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListShiftForTimeSheet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListShiftForTimeSheet(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListShiftMarket
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {number} quantity 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListShiftMarket(minTime: string, maxTime: string, quantity: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListShiftMarket(minTime, maxTime, quantity, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getMergePlanningTeamScheduled
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {boolean} mergeGroup 
         * @param {FormFilterListPlanningTeamScheduled} body getMergePlanningTeamScheduled Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMergePlanningTeamScheduled(minTime: string, maxTime: string, mergeGroup: boolean, body: FormFilterListPlanningTeamScheduled, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMergePlanningTeamScheduled(minTime, maxTime, mergeGroup, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getPlanningByID
         * @param {number} id 
         * @param {number} fakeId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanningByID(id: number, fakeId: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanningByID(id, fakeId, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateEmployeePlanningTemplate
         * @param {number} id 
         * @param {ShiftTemplatesDetail} body updateEmployeePlanningTemplate Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployeePlanningTemplate(id: number, body: ShiftTemplatesDetail, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftTemplatesDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployeePlanningTemplate(id, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updatePlanning
         * @param {number} id 
         * @param {ShiftDetail} body updatePlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlanning(id: number, body: ShiftDetail, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlanning(id, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanningsApi - factory interface
 * @export
 */
export const PlanningsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanningsApiFp(configuration)
    return {
        /**
         * 
         * @summary changeStatusPlanning
         * @param {number} id 
         * @param {number} status 
         * @param {ShiftDetail} body changeStatusPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusPlanning(id: number, status: number, body: ShiftDetail, source?: string, options?: any): AxiosPromise<ShiftDetail> {
            return localVarFp.changeStatusPlanning(id, status, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary checkConflictPlanning
         * @param {FormFilterConflictPlanning} body checkConflictPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConflictPlanning(body: FormFilterConflictPlanning, source?: string, options?: any): AxiosPromise<Array<EmployeeAvai>> {
            return localVarFp.checkConflictPlanning(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary copyMoveShift
         * @param {FormCopyMoveShift} body copyMoveShift Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMoveShift(body: FormCopyMoveShift, source?: string, options?: any): AxiosPromise<PlanningV2> {
            return localVarFp.copyMoveShift(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createEmployeePlanningTemplate
         * @param {ShiftTemplatesDetail} body createEmployeePlanningTemplate Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployeePlanningTemplate(body: ShiftTemplatesDetail, source?: string, options?: any): AxiosPromise<ShiftTemplatesDetail> {
            return localVarFp.createEmployeePlanningTemplate(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createPlanning
         * @param {ShiftDetail} body createPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanning(body: ShiftDetail, source?: string, options?: any): AxiosPromise<ShiftDetail> {
            return localVarFp.createPlanning(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletePlanningTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanningTemplate(id: number, source?: string, options?: any): AxiosPromise<ShiftTemplatesDetail> {
            return localVarFp.deletePlanningTemplate(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getInfoPlanning
         * @param {FormParamV2} body getInfoPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoPlanning(body: FormParamV2, source?: string, options?: any): AxiosPromise<InfoPlanning> {
            return localVarFp.getInfoPlanning(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getInformationGroupView
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterInformationGroupView} body getInformationGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformationGroupView(minTime: string, maxTime: string, body: FormFilterInformationGroupView, source?: string, options?: any): AxiosPromise<InformationGroupView> {
            return localVarFp.getInformationGroupView(minTime, maxTime, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListEmployeeAvailable
         * @param {FormFilterEmployeeAvailable} body getListEmployeeAvailable Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeAvailable(body: FormFilterEmployeeAvailable, source?: string, options?: any): AxiosPromise<ListEmployeeAvailable> {
            return localVarFp.getListEmployeeAvailable(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListMyShift
         * @param {FormFilterListMyShift} body getListMyShift Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListMyShift(body: FormFilterListMyShift, source?: string, options?: any): AxiosPromise<Array<PlanningDays>> {
            return localVarFp.getListMyShift(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListOpenPlanningByEmployeeID
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOpenPlanningByEmployeeID(page: number, limit: number, source?: string, options?: any): AxiosPromise<Array<ShiftDetail>> {
            return localVarFp.getListOpenPlanningByEmployeeID(page, limit, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListOpenPlanningByEmployeeIdAndTimeStamp
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOpenPlanningByEmployeeIdAndTimeStamp(minTime: string, maxTime: string, source?: string, options?: any): AxiosPromise<Array<ShiftDetail>> {
            return localVarFp.getListOpenPlanningByEmployeeIdAndTimeStamp(minTime, maxTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListPlanColleague
         * @param {number} id 
         * @param {FormFilterListPlanColleague} body getListPlanColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanColleague(id: number, body: FormFilterListPlanColleague, source?: string, options?: any): AxiosPromise<Array<ShiftDetail>> {
            return localVarFp.getListPlanColleague(id, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListPlanning
         * @param {FormParamV2} body getListPlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanning(body: FormParamV2, source?: string, options?: any): AxiosPromise<Array<PlanningV2>> {
            return localVarFp.getListPlanning(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListPlanningByEmployeeID
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningByEmployeeID(minTime: string, maxTime: string, source?: string, options?: any): AxiosPromise<Array<ShiftDetail>> {
            return localVarFp.getListPlanningByEmployeeID(minTime, maxTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListPlanningByEmployeeIDAndMinTime
         * @param {number} employeeId 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningByEmployeeIDAndMinTime(employeeId: number, minTime: string, maxTime: string, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getListPlanningByEmployeeIDAndMinTime(employeeId, minTime, maxTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListPlanningGroupView
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterListPlanningGroupView} body getListPlanningGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningGroupView(minTime: string, maxTime: string, body: FormFilterListPlanningGroupView, source?: string, options?: any): AxiosPromise<Array<GetListPlanningGroupDetail>> {
            return localVarFp.getListPlanningGroupView(minTime, maxTime, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListPlanningMobile
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterListPlanningGroupView} body getListPlanningMobile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningMobile(minTime: string, maxTime: string, body: FormFilterListPlanningGroupView, source?: string, options?: any): AxiosPromise<ReponseListPlanningMobile> {
            return localVarFp.getListPlanningMobile(minTime, maxTime, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListPlanningTeamScheduled
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {FormFilterListPlanningTeamScheduled} body getListPlanningTeamScheduled Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningTeamScheduled(minTime: string, maxTime: string, body: FormFilterListPlanningTeamScheduled, source?: string, options?: any): AxiosPromise<Array<GetListPlanningGroupDetail>> {
            return localVarFp.getListPlanningTeamScheduled(minTime, maxTime, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListPlanningTemplate
         * @param {number} employeeID 
         * @param {number} groupID 
         * @param {boolean} isColleague 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPlanningTemplate(employeeID: number, groupID: number, isColleague: boolean, source?: string, options?: any): AxiosPromise<Array<ShiftTemplatesDetail>> {
            return localVarFp.getListPlanningTemplate(employeeID, groupID, isColleague, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListShiftForTimeSheet
         * @param {FilterTimeSheet} body getListShiftForTimeSheet Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListShiftForTimeSheet(body: FilterTimeSheet, source?: string, options?: any): AxiosPromise<Array<ListShiftForTimeSheet>> {
            return localVarFp.getListShiftForTimeSheet(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListShiftMarket
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {number} quantity 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListShiftMarket(minTime: string, maxTime: string, quantity: number, source?: string, options?: any): AxiosPromise<Array<ShiftDetail>> {
            return localVarFp.getListShiftMarket(minTime, maxTime, quantity, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getMergePlanningTeamScheduled
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {boolean} mergeGroup 
         * @param {FormFilterListPlanningTeamScheduled} body getMergePlanningTeamScheduled Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMergePlanningTeamScheduled(minTime: string, maxTime: string, mergeGroup: boolean, body: FormFilterListPlanningTeamScheduled, source?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getMergePlanningTeamScheduled(minTime, maxTime, mergeGroup, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPlanningByID
         * @param {number} id 
         * @param {number} fakeId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanningByID(id: number, fakeId: number, source?: string, options?: any): AxiosPromise<ShiftDetail> {
            return localVarFp.getPlanningByID(id, fakeId, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateEmployeePlanningTemplate
         * @param {number} id 
         * @param {ShiftTemplatesDetail} body updateEmployeePlanningTemplate Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeePlanningTemplate(id: number, body: ShiftTemplatesDetail, source?: string, options?: any): AxiosPromise<ShiftTemplatesDetail> {
            return localVarFp.updateEmployeePlanningTemplate(id, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updatePlanning
         * @param {number} id 
         * @param {ShiftDetail} body updatePlanning Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanning(id: number, body: ShiftDetail, source?: string, options?: any): AxiosPromise<ShiftDetail> {
            return localVarFp.updatePlanning(id, body, source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanningsApi - object-oriented interface
 * @export
 * @class PlanningsApi
 * @extends {BaseAPI}
 */
export class PlanningsApi extends BaseAPI {
    /**
     * 
     * @summary changeStatusPlanning
     * @param {number} id 
     * @param {number} status 
     * @param {ShiftDetail} body changeStatusPlanning Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public changeStatusPlanning(id: number, status: number, body: ShiftDetail, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).changeStatusPlanning(id, status, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary checkConflictPlanning
     * @param {FormFilterConflictPlanning} body checkConflictPlanning Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public checkConflictPlanning(body: FormFilterConflictPlanning, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).checkConflictPlanning(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary copyMoveShift
     * @param {FormCopyMoveShift} body copyMoveShift Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public copyMoveShift(body: FormCopyMoveShift, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).copyMoveShift(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createEmployeePlanningTemplate
     * @param {ShiftTemplatesDetail} body createEmployeePlanningTemplate Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public createEmployeePlanningTemplate(body: ShiftTemplatesDetail, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).createEmployeePlanningTemplate(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createPlanning
     * @param {ShiftDetail} body createPlanning Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public createPlanning(body: ShiftDetail, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).createPlanning(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletePlanningTemplate
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public deletePlanningTemplate(id: number, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).deletePlanningTemplate(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getInfoPlanning
     * @param {FormParamV2} body getInfoPlanning Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getInfoPlanning(body: FormParamV2, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getInfoPlanning(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getInformationGroupView
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {FormFilterInformationGroupView} body getInformationGroupView Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getInformationGroupView(minTime: string, maxTime: string, body: FormFilterInformationGroupView, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getInformationGroupView(minTime, maxTime, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListEmployeeAvailable
     * @param {FormFilterEmployeeAvailable} body getListEmployeeAvailable Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListEmployeeAvailable(body: FormFilterEmployeeAvailable, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListEmployeeAvailable(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListMyShift
     * @param {FormFilterListMyShift} body getListMyShift Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListMyShift(body: FormFilterListMyShift, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListMyShift(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListOpenPlanningByEmployeeID
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListOpenPlanningByEmployeeID(page: number, limit: number, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListOpenPlanningByEmployeeID(page, limit, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListOpenPlanningByEmployeeIdAndTimeStamp
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListOpenPlanningByEmployeeIdAndTimeStamp(minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListOpenPlanningByEmployeeIdAndTimeStamp(minTime, maxTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListPlanColleague
     * @param {number} id 
     * @param {FormFilterListPlanColleague} body getListPlanColleague Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListPlanColleague(id: number, body: FormFilterListPlanColleague, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListPlanColleague(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListPlanning
     * @param {FormParamV2} body getListPlanning Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListPlanning(body: FormParamV2, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListPlanning(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListPlanningByEmployeeID
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListPlanningByEmployeeID(minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListPlanningByEmployeeID(minTime, maxTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListPlanningByEmployeeIDAndMinTime
     * @param {number} employeeId 
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListPlanningByEmployeeIDAndMinTime(employeeId: number, minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListPlanningByEmployeeIDAndMinTime(employeeId, minTime, maxTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListPlanningGroupView
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {FormFilterListPlanningGroupView} body getListPlanningGroupView Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListPlanningGroupView(minTime: string, maxTime: string, body: FormFilterListPlanningGroupView, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListPlanningGroupView(minTime, maxTime, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListPlanningMobile
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {FormFilterListPlanningGroupView} body getListPlanningMobile Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListPlanningMobile(minTime: string, maxTime: string, body: FormFilterListPlanningGroupView, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListPlanningMobile(minTime, maxTime, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListPlanningTeamScheduled
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {FormFilterListPlanningTeamScheduled} body getListPlanningTeamScheduled Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListPlanningTeamScheduled(minTime: string, maxTime: string, body: FormFilterListPlanningTeamScheduled, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListPlanningTeamScheduled(minTime, maxTime, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListPlanningTemplate
     * @param {number} employeeID 
     * @param {number} groupID 
     * @param {boolean} isColleague 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListPlanningTemplate(employeeID: number, groupID: number, isColleague: boolean, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListPlanningTemplate(employeeID, groupID, isColleague, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListShiftForTimeSheet
     * @param {FilterTimeSheet} body getListShiftForTimeSheet Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListShiftForTimeSheet(body: FilterTimeSheet, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListShiftForTimeSheet(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListShiftMarket
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {number} quantity 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getListShiftMarket(minTime: string, maxTime: string, quantity: number, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getListShiftMarket(minTime, maxTime, quantity, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getMergePlanningTeamScheduled
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {boolean} mergeGroup 
     * @param {FormFilterListPlanningTeamScheduled} body getMergePlanningTeamScheduled Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getMergePlanningTeamScheduled(minTime: string, maxTime: string, mergeGroup: boolean, body: FormFilterListPlanningTeamScheduled, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getMergePlanningTeamScheduled(minTime, maxTime, mergeGroup, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPlanningByID
     * @param {number} id 
     * @param {number} fakeId 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public getPlanningByID(id: number, fakeId: number, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).getPlanningByID(id, fakeId, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateEmployeePlanningTemplate
     * @param {number} id 
     * @param {ShiftTemplatesDetail} body updateEmployeePlanningTemplate Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public updateEmployeePlanningTemplate(id: number, body: ShiftTemplatesDetail, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).updateEmployeePlanningTemplate(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updatePlanning
     * @param {number} id 
     * @param {ShiftDetail} body updatePlanning Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanningsApi
     */
    public updatePlanning(id: number, body: ShiftDetail, source?: string, options?: AxiosRequestConfig) {
        return PlanningsApiFp(this.configuration).updatePlanning(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }
}
