import React from 'react'
import { type ListShiftForTimeSheet } from '../../../services/Swagger'
import { Box } from '@mui/material'

interface ShiftAbbreviationProp {
  shift: ListShiftForTimeSheet
}

const ShiftAbbreviation = ({ shift }: ShiftAbbreviationProp): JSX.Element | null => {
  if (shift.shift_type_id === 1) {
    return null
  }
  const backgroundColor = shift.shift_type_color ?? ''
  return (
    <Box className="ShiftAbbreviation" style={{ backgroundColor }}>
      {shift.abbreviation}
    </Box>
  )
}

export default ShiftAbbreviation
