import React from 'react'
import { type CostCenterDetail, type ListCostCenterGroupByCategory } from '../../../services/Swagger'
import { ListSubheader, MenuItem } from '@mui/material'

export default function GetCostCenter (
  CostCenters: ListCostCenterGroupByCategory[] | undefined
): JSX.Element[] {
  const costCenterOptions: JSX.Element[] = []
  CostCenters?.map((row: ListCostCenterGroupByCategory) => {
    costCenterOptions.push(
      <ListSubheader key={`key-${row.category_id?.toString() as string}`}>
        {row.category_name}
      </ListSubheader>
    )
    row.list?.map((cost: CostCenterDetail) => {
      costCenterOptions.push(
        <MenuItem
          key={`cost-${cost.cost_center_id.toString()}`}
          value={cost.cost_center_id}
        >
          {cost.name}
        </MenuItem>
      )
      return cost
    })
    return row
  })
  return costCenterOptions
}
