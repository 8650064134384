import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { Icon } from '@fluentui/react'
import { type ListTimeSheet } from '../Services/TimesheetServices'

interface ClockedImageProps {
  row: ListTimeSheet
  type: 'clock_in_image' | 'clock_out_image'
  onClick: (urlImage?: string) => void
}

export default function ClockInOutPhoto ({ row, type, onClick }: ClockedImageProps): JSX.Element {
  if ((row.timesheet?.[type]) != null) {
    return (
      <Box>
        <IconButton
          onClick={() => {
            if ((row.timesheet?.[type]) != null) {
              onClick(row.timesheet[type])
            }
          }}
        >
          <Icon iconName='Photo2' />
        </IconButton>
      </Box>
    )
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  } else if (row.timesheet) {
    return <Typography>-</Typography>
  }
  return <Box></Box>
}
