import { Box, Typography } from '@mui/material'
import React from 'react'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import { useTranslation } from 'react-i18next'
import { TimesheetHelper } from '../Helpers/TimesheetHelper'
import { DateTime } from '../../../services/time'
import { type ListShiftForTimeSheet } from '../../../services/Swagger'
interface TotalTimesheetProps {
  data: ListTimeSheet[]
  handleApproveTotal: (data: any) => void
  shifts: ListShiftForTimeSheet[] | undefined
}

export default function TotalTimesheet ({ data, handleApproveTotal, shifts }: TotalTimesheetProps): JSX.Element {
  const { t } = useTranslation()
  const uniqueShifts: any[] = []
  const listShifts: ListShiftForTimeSheet[] = data.flatMap(e => e.shifts?.map(shift => shift))
    .filter(shift => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (shift && !uniqueShifts.includes(shift.shift_id)) {
        uniqueShifts.push(shift.shift_id)
        return true
      }
      return false
    })
  const totalShifts = new TimesheetHelper().calculateTotalShiftDuration(listShifts, 'shift')
  const totalShiftBreak = new TimesheetHelper().calculateTotalShiftDuration(shifts, 'break')
  const totalTimesheet = new TimesheetHelper().calculateTotalActualTotal(data, 'worked')
  const totalApproved = new TimesheetHelper().calculateTotalActualTotal(data, 'approved')
  const totalSheetBreak = new TimesheetHelper().calculateTotalActualTotal(data, 'break')
  const totalObject = {
    totalShifts,
    totalTimesheet,
    totalShiftBreak,
    totalSheetBreak
  }
  const handleClickApprovedDetails = (): void => {
    handleApproveTotal(totalObject)
  }
  return (
    <Box sx={{ px: 4 }}>
      <Typography sx={{ p: 4 }} borderTop={'1px solid #e5e5e5'}>{`${t('20_89_scheduled')} / ${t('00_06_worked')} / ${t('02_00_approved_total')}: ${' '}`} <span>{`${DateTime.FormatDuration(totalShifts ?? 0)}`}</span> / <span>{`${DateTime.FormatDuration(totalTimesheet)}`}</span> / <span className='link-cursor' onClick={handleClickApprovedDetails}>{`${DateTime.FormatDuration(totalApproved)}`}</span></Typography>
    </Box>
  )
}
