import React, { useEffect, useState } from 'react'
import { DatePicker, FormAlert, Modal } from '../../../components'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { useFormik } from 'formik'
import { type CustomTimeSheetDetailUpdate, totalSeconds } from './NewTimesheet'
import moment from 'moment'
import TimeAutocomplete from './TimeAutocomplete'
import { type RegistrationTimeToApprove } from '../../../services/Swagger'
import * as Yup from 'yup'
import { TimesheetHelper } from '../Helpers/TimesheetHelper'
import { toast } from 'react-toastify'
import storage from '../../../services/storage'
import { omit } from 'lodash'

export interface CustomRegistrationTimeToApprove
  extends RegistrationTimeToApprove {
  check_in_time_hour: number
  check_out_time_hour: number
}
interface BreakProps {
  onClose: (values?: RegistrationTimeToApprove) => void
  visible: boolean
  dataBreak: RegistrationTimeToApprove | undefined
  timesheet: CustomTimeSheetDetailUpdate
}

export default function BreakModal ({
  onClose,
  dataBreak,
  timesheet,
  visible
}: BreakProps): JSX.Element {
  const companySetting = storage.getItem('COMPANY_INFOMATION')
  const offset = moment().tz(companySetting?.timezone).utcOffset() * 60
  const dateFormat = companySetting?.date_format
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()
  const handleSubmit = async (values: CustomRegistrationTimeToApprove): Promise<void> => {
    const newValues: RegistrationTimeToApprove = omit(values, [
      'check_in_time_hour',
      'check_out_time_hour'
    ])
    let allBreaks: any[] = []
    if (timesheet.break_times != null) {
      if (dataBreak != null) {
        const filteredBreaks = timesheet.break_times?.filter(breakTime => breakTime.registration_time_approve_id !== dataBreak.registration_time_approve_id)
        allBreaks = new TimesheetHelper().ranges([...filteredBreaks, newValues]).sort()
      } else {
        allBreaks = new TimesheetHelper().ranges([...timesheet.break_times, newValues]).sort()
      }
    }

    if (!(new TimesheetHelper().checkRanges(
      timesheet.check_in_time,
      (timesheet.check_out_time !== formik.values.check_out_time)
        ? allBreaks[allBreaks.length - 1][1]
        : timesheet.check_out_time,
      allBreaks
    ))) {
      setError('00_02_overlapsed_break')
      return
    }

    try {
      setIsLoading(true)
      onClose(newValues)
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }

  const formSchema = Yup.object().shape({
    check_in_time_hour: Yup.number()
      .moreThan(-1, '00_02_start_break_require'),
    check_out_time_hour: Yup.number()
      .moreThan(-1, '00_02_end_break_require')
  })

  const [breakTime, setBreakTime] = useState<CustomRegistrationTimeToApprove>({
    registration_time_approve_id: 0,
    shift_id: timesheet.shift_id,
    check_in_time: moment((timesheet.check_in_time ?? 0) * 1000)
      .startOf('day')
      .unix(),
    check_in_time_hour: -1,
    check_out_time: moment((timesheet.check_out_time ?? 0) * 1000)
      .startOf('day')
      .unix(),
    check_out_time_hour: -1,
    employee_id: timesheet.employee_id,
    group_id: timesheet.group_id,
    is_lock: false,
    approvedTime: 0,
    is_break: true,
    registration_time_id: 0,
    offset,
    is_client_out: 2,
    is_client_in: 2,
    stamp_value_out: '',
    stamp_value_in: '',
    parent_id: 0,
    note: '',
    is_paid: false,
    is_approved: false,
    type: 0,
    device_info: '',
    updated_by: 0,
    updated_type: 0,
    cost_center_id: timesheet.cost_center_id,
    shift_type_id: timesheet.shift_type_id,
    use_break: true
  })
  const formik = useFormik({
    initialValues: breakTime,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    if (dataBreak != null) {
      const newBreak: CustomRegistrationTimeToApprove = {
        ...dataBreak,
        check_in_time_hour:
          (dataBreak?.check_in_time ?? 0) -
          moment((dataBreak?.check_in_time ?? 0) * 1000)
            .startOf('day')
            .unix(),
        check_out_time_hour:
          (dataBreak?.check_out_time ?? 0) -
          moment((dataBreak?.check_out_time ?? 0) * 1000)
            .startOf('day')
            .unix()
      }
      setBreakTime(newBreak)
    }
  }, [dataBreak])

  return (
    <Modal
      open={visible}
      onClose={() => { onClose() }}
      size="small"
      title={t((dataBreak != null) ? '01_01_edit_break' : '22_00_break')}
    >
      <Box className="NewTimesheet" sx={{ px: 4, py: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <FormAlert formik={formik} error={error} />
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography>{t('17_01_clocked_in')}</Typography>
            </Grid>
            <Grid item xs>
              <DatePicker
                format={dateFormat}
                value={moment.unix(formik.values?.check_in_time ?? 0)}
                slotProps={{ textField: { variant: 'standard' } }}
                minDate={moment.unix(timesheet.check_in_time ?? 0)}
                onChange={(date: any) => {
                  void formik.setFieldValue(
                    'check_in_time',
                    moment(date).unix()
                  )
                }}
                slots={{
                  openPickerIcon: ArrowDropDownOutlinedIcon
                }}
              />
            </Grid>
            <Grid item xs>
              <TimeAutocomplete
                value={formik.values.check_in_time_hour}
                onChange={(seconds) => {
                  if (seconds === -1) {
                    void formik.setFieldValue(
                      'check_in_time_hour',
                      seconds <= totalSeconds ? Number(seconds) : 0
                    )
                    void formik.setFieldValue('check_in_time', timesheet.check_in_time)
                  }
                  if (seconds > -1) {
                    void formik.setFieldValue(
                      'check_in_time_hour',
                      seconds <= totalSeconds ? Number(seconds) : 0
                    )
                    const checkInTime =
                      moment
                        .unix(formik.values.check_in_time ?? 0)
                        .startOf('day')
                        .unix() +
                      (seconds <= totalSeconds ? Number(seconds) : 0)
                    void formik.setFieldValue('check_in_time', checkInTime)
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography>{t('20_84_clocked_out')}</Typography>
            </Grid>
            <Grid item xs>
              <DatePicker
                format={dateFormat}
                value={moment.unix(formik.values.check_out_time ?? 0)}
                slotProps={{ textField: { variant: 'standard' } }}
                onChange={(date: any) => {
                  void formik.setFieldValue(
                    'check_out_time',
                    moment(date).unix()
                  )
                }}
                slots={{
                  openPickerIcon: ArrowDropDownOutlinedIcon
                }}
              />
            </Grid>
            <Grid item xs>
              <TimeAutocomplete
                value={formik.values.check_out_time_hour}
                onChange={(seconds) => {
                  if (seconds === -1) {
                    void formik.setFieldValue(
                      'check_out_time_hour',
                      seconds <= totalSeconds ? Number(seconds) : 0
                    )
                    void formik.setFieldValue('check_out_time', timesheet.check_out_time)
                  }
                  if (seconds > -1) {
                    void formik.setFieldValue(
                      'check_out_time_hour',
                      seconds <= totalSeconds ? Number(seconds) : 0
                    )
                    const checkOutTime =
                      moment
                        .unix(formik.values.check_out_time ?? 0)
                        .startOf('day')
                        .unix() +
                      (seconds <= totalSeconds ? Number(seconds) : 0)
                    void formik.setFieldValue('check_out_time', checkOutTime)
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ py: 2, justifyContent: 'flex-end' }}
          >
            <Grid item>
              <Button onClick={() => { onClose() }} variant="outlined">
                {t('20_13_cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  formik.handleSubmit()
                }}
                disabled={isLoading}
                variant="contained"
              >
                {t('20_13_save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  )
}
