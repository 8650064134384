import React, { useState } from 'react'
import {
  type GridRenderCellParams,
  type GridTreeNodeWithRender
} from '@mui/x-data-grid-premium'
import { type ListTimeSheet, checkTimesheetGreaterThan } from './TimesheetServices'
import { Box, Typography, Chip, Tooltip, Menu, MenuItem, Divider, Avatar } from '@mui/material'
import { DateTime } from '../../../services/time'
import {
  LinearProgress,
  ShiftAbbreviation,
  TimeSheetAbbreviation
} from '../Components'
import { type ListShiftForTimeSheet, type CompanySetting, type IndicatorSetting } from '../../../services/Swagger'
import { type UseQueryResult } from '@tanstack/react-query'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import { t } from 'i18next'
import { Colors } from '../../../theme'
import { TimesheetHelper } from '../Helpers/TimesheetHelper'
import moment from 'moment'
import { MODULEID, getGroupRoleByModule } from '../../../services/modules'
interface CallbackTimesheet {
  timesheet: ListTimeSheet
  type: string
}
interface CallbackShift {
  shift: ListShiftForTimeSheet
  type: string
}
interface CallbackEmployee {
  id: number
  type: string
}
interface CallbackViewTimesheet {
  timesheet: ListTimeSheet
  type: string
}
interface FullnameCellProps {
  params: GridRenderCellParams<ListTimeSheet, any, any, GridTreeNodeWithRender>
  employeeQuery: any
  callback: (params: { id?: number, type: string, timesheet?: ListTimeSheet }) => void
}
interface ScheduledShiftCellProps {
  createRole: any
  params: GridRenderCellParams<ListTimeSheet, any, any, GridTreeNodeWithRender>
  callback: (params: { shift: ListShiftForTimeSheet, type: string }) => void
}
interface WorkedTimeCellProps {
  params: GridRenderCellParams<ListTimeSheet, any, any, GridTreeNodeWithRender>
  settings?: IndicatorSetting[] | undefined
  callback: (params: { timesheet: ListTimeSheet, type: string }) => void
}

interface ActualTotalCellProps {
  params: GridRenderCellParams<ListTimeSheet, any, any, GridTreeNodeWithRender>
  timesheetSetting: UseQueryResult<CompanySetting, unknown>
  t: any
}

export const ScheduledShiftCell = ({ params, callback, createRole }: ScheduledShiftCellProps): JSX.Element => {
  const { row } = params
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpenMenu = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = (): void => {
    setAnchorEl(null)
  }
  const handleCreateTimesheet = (): void => {
    const params: CallbackShift = { shift: row.shifts[0], type: 'create' }
    callback(params)
    setAnchorEl(null)
  }
  const handleSelectCreateTimesheet = (shift: ListShiftForTimeSheet): void => {
    const params: CallbackShift = { shift, type: 'create' }
    callback(params)
    setAnchorEl(null)
  }
  const handleCreateAndApprove = (): void => {
    const params: CallbackShift = { shift: row.shifts[0], type: 'approve' }
    callback(params)
    setAnchorEl(null)
  }
  const handleSelectCreateAndApprove = (shift: ListShiftForTimeSheet): void => {
    const params: CallbackShift = { shift, type: 'approve' }
    callback(params)
    setAnchorEl(null)
  }
  const handleCreateAndEdit = (): void => {
    const params: CallbackShift = { shift: row.shifts[0], type: 'edit' }
    callback(params)
    setAnchorEl(null)
  }
  const handleSelectCreateAndEdit = (shift: ListShiftForTimeSheet): void => {
    const params: CallbackShift = { shift, type: 'edit' }
    callback(params)
    setAnchorEl(null)
  }
  const handleClockIn = (): void => {
    const params: CallbackShift = { shift: row.shifts[0], type: 'clockin' }
    callback(params)
    setAnchorEl(null)
  }
  const handleSelectClockIn = (shift: ListShiftForTimeSheet): void => {
    const params: CallbackShift = { shift, type: 'clockin' }
    callback(params)
    setAnchorEl(null)
  }
  if (row.shifts?.length > 0) {
    return <Box className='ScheduledShift'>
      {row.shifts.map((shift, index) => {
        let string = ` ${DateTime.FormatDate(
          shift.start_time,
          'time'
        )} - ${DateTime.FormatDate(shift.end_time, 'time')} `
        if (shift.duration_break !== 0) {
          string += ` (${DateTime.ConvertHour(shift.duration_break ?? 0)}) `
        }
        return (
          <Box key={index} sx={{ cursor: 'pointer', display: 'flex', mr: 1 }} onClick={handleOpenMenu}>
            {string}
            <ShiftAbbreviation shift={shift} />
          </Box>
        )
      })}
      {createRole.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          sx={{ px: 2 }}
        >
          <MenuItem className='mw-200' onClick={handleCreateTimesheet}>{t('02_00_create_timesheet')}</MenuItem>
          {row.shifts.length > 1 &&
            row.shifts.map((shift, index) => (
              <MenuItem sx={{ pl: 8 }} key={index} onClick={() => { handleSelectCreateTimesheet(shift) }}>{`${DateTime.FormatDate(shift.start_time, 'time')} - ${DateTime.FormatDate(shift.end_time, 'time')}`}</MenuItem>
            ))
          }
          {(getGroupRoleByModule(MODULEID.timesheet, row.shifts[0].group_id ?? 0).Approve === true) && (
            [<Divider key="divider" />, <MenuItem key="createAndApprove" onClick={handleCreateAndApprove}>{t('02_00_create_and_approve_timesheet')}</MenuItem>]
          )}
          {row.shifts.length > 1 &&
            row.shifts.map((shift, index) => (
              <MenuItem sx={{ pl: 8 }} key={index} onClick={() => { handleSelectCreateAndApprove(shift) }}>{` ${DateTime.FormatDate(shift.start_time, 'time')} - ${DateTime.FormatDate(shift.end_time, 'time')}`}</MenuItem>
            ))
          }
          <Divider />
          <MenuItem className='mw-200' onClick={handleCreateAndEdit}>{t('02_00_create_and_edit')}</MenuItem>
          {row.shifts.length > 1 &&
            row.shifts.map((shift, index) => (
              <MenuItem sx={{ pl: 8 }} key={index} onClick={() => { handleSelectCreateAndEdit(shift) }}>{` ${DateTime.FormatDate(shift.start_time, 'time')} - ${DateTime.FormatDate(shift.end_time, 'time')}`}</MenuItem>
            ))
          }
          <Divider />
          <MenuItem className='mw-200' onClick={handleClockIn}>{t('13_02_clock_in')}</MenuItem>
          {row.shifts.length > 1 &&
            row.shifts.map((shift, index) => (
              <MenuItem sx={{ pl: 8 }} key={index} onClick={() => { handleSelectClockIn(shift) }}>{` ${DateTime.FormatDate(shift.start_time, 'time')} - ${DateTime.FormatDate(shift.end_time, 'time')}`}</MenuItem>
            ))
          }
        </Menu>
      )}
    </Box>
  }
  // eslint-disable-next-line react/jsx-key
  return <Typography>{(row.timesheet != null ? '-' : '')}</Typography>
}

export const FullnameCell = ({ params, employeeQuery, callback }: FullnameCellProps): JSX.Element => {
  const { row } = params
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpenMenu = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = (): void => {
    setAnchorEl(null)
  }
  if (
    params.rowNode.type === 'group' &&
    params.field === params.rowNode.groupingField
  ) {
    return <></>
  }
  let employee = params.row.employee
  if (params.value != null) {
    employee = employeeQuery.data?.find(
      (e: any) => e.fullname === params.value
    )
  }
  const handleViewProfile = (): void => {
    if (employee.employee_id != null) {
      const params: CallbackEmployee = { id: employee?.employee_id ?? 0, type: 'profile' }
      callback(params)
      setAnchorEl(null)
    }
  }
  const handleViewTimesheet = (): void => {
    const params: CallbackViewTimesheet = { timesheet: row, type: 'view' }
    callback(params)
    setAnchorEl(null)
  }
  if (employee?.employee_id != null) {
    return (
      <Box>
        <Box display="flex" gap={3} alignItems="center" onClick={handleOpenMenu} sx={{ cursor: 'pointer' }}>
          <Avatar
            sx={{
              width: 24,
              height: 24,
              bgcolor: employee.color ?? Colors.primary,
              fontSize: 10
            }}
            alt={employee.fullname}
            title={employee.fullname}
            src={employee.avatar}
          >
            {new TimesheetHelper().getInitials(employee.fullname ?? '')}
          </Avatar>
          <Typography fontWeight="500"> {employee.fullname}</Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          sx={{ px: 2 }}
        >
          <MenuItem onClick={handleViewTimesheet}>{t('02_01_view_timesheets')}</MenuItem>
          <MenuItem onClick={handleViewProfile}>{t('20_87_profile')}</MenuItem>
        </Menu>
      </Box>
    )
  }
  return <></>
}

export const WorkedTimeCell = ({ params, settings, callback }: WorkedTimeCellProps): JSX.Element => {
  const { row } = params
  const handleClickEdit = (): void => {
    const params: CallbackTimesheet = { timesheet: row, type: 'edit' }
    callback(params)
    setAnchorEl(null)
  }
  const handleClickClockOut = (): void => {
    const params: CallbackTimesheet = { timesheet: row, type: 'clockOut' }
    callback(params)
    setAnchorEl(null)
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpenMenu = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = (): void => {
    setAnchorEl(null)
  }

  if (row.timesheet?.check_in_time != null) {
    const isLocked = row.timesheet.is_lock
    const isApproved = row.timesheet.status

    const getCheckInTimeStyle = (): { color?: string } => {
      if (row.workStartTime === 0 || row.workStartTime === undefined) return {}
      const timeDiff = Number(row.timesheet?.check_in_time) - row.workStartTime
      const matchingSetting = settings?.find(
        (setting: any) => timeDiff > Number(setting.value) && setting.is_show
      )
      if (matchingSetting != null) {
        return {
          color: matchingSetting.color
        }
      }
      return {}
    }

    const getCheckOutTimeStyle = (): { color?: string } | undefined => {
      if (row.workEndTime == null) return {}
      if (row.workEndTime === 0 || row.workEndTime === undefined) return {}
      const timeDiff = row.workEndTime - Number(row.timesheet?.check_out_time)
      const matchingSetting = settings?.find(
        (setting: any) => timeDiff > Number(setting.value) && setting.is_show
      )
      if (matchingSetting != null) {
        return {
          color: matchingSetting.color
        }
      }
    }
    const getTooltipContent = (): string | undefined => {
      const haveCheckOutTime = (row.timesheet.check_out_time != null) ? DateTime.FormatDate(row.timesheet.registration_time_raw?.check_out_time, 'time') : '?'
      return `${DateTime.FormatDate(row.timesheet.registration_time_raw?.check_in_time, 'time')} - ${haveCheckOutTime}`
    }

    return (
      <Box sx={{ cursor: 'pointer' }}>
        <Tooltip title={getTooltipContent()} arrow placement="top" className="custom-tooltip" PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              fontSize: '12px'
            }
          }
        }}>
          <Box display="flex" gap={1} alignItems="center" onClick={handleOpenMenu}>
            <Typography style={getCheckInTimeStyle()}>
              {' '}
              {DateTime.FormatDate(row.timesheet.check_in_time, 'time')}
            </Typography>
            <Typography>-</Typography>
            <Typography style={row.timesheet.check_out_time != null ? getCheckOutTimeStyle() : undefined}>
              {row.timesheet.check_out_time != null ? DateTime.FormatDate(row.timesheet.check_out_time, 'time') : '?'}
            </Typography>
            {row.timesheet.duration_break !== undefined && row.timesheet.duration_break > 0 && (
              <Typography>
                {' '}
                ({DateTime.ConvertHour(row.timesheet.duration_break)})
              </Typography>
            )}
            {row.timesheet.abbreviation != null && <TimeSheetAbbreviation timeSheet={row.timesheet} />}
            {(row.timesheet?.updated_by ?? 0) > 0 && (
              <ManageAccountsOutlinedIcon color="error" className="icons-16" />
            )}
          </Box>
        </Tooltip>
        {!(isLocked ?? false) && isApproved !== 1 && (
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} sx={{ px: 2 }}>
            {(getGroupRoleByModule(MODULEID.timesheet, row.timesheet?.group_id ?? 0).Edit === true) && (
              <MenuItem className="mw-200" onClick={handleClickEdit}>
                {t('00_03_edit')}
              </MenuItem>
            )}
            {row.timesheet.check_in_time !== null && row.timesheet.check_out_time == null && ((getGroupRoleByModule(MODULEID.timesheet, row.timesheet?.group_id ?? 0).Edit === true) || (getGroupRoleByModule(MODULEID.timesheet, row.timesheet?.group_id ?? 0).Create === true)) && (
              <MenuItem className="mw-200" onClick={handleClickClockOut}>
                {t('20_89_clock_out')}
              </MenuItem>
            )}
          </Menu>
        )}
      </Box>
    )
  } else {
    return <Typography>{(row.timesheet != null ? '-' : '')}</Typography>
  }
}

export const StatusCell = ({ params, callback }: WorkedTimeCellProps): JSX.Element => {
  const { row } = params
  const handleClickApproveTimesheet = (): void => {
    const params: CallbackTimesheet = { timesheet: row, type: 'approved' }
    callback(params)
  }
  const handleClickUnApproveTimesheet = (): void => {
    const params: CallbackTimesheet = { timesheet: row, type: 'unapproved' }
    callback(params)
  }
  if (row.timesheet != null && Number(row.timesheet.status) > -1) {
    const isLocked = row.timesheet.is_lock
    const [groupRole] = useState(
      getGroupRoleByModule(MODULEID.timesheet, row.timesheet.group_id ?? 0)
    )
    if (row.timesheet.status === 1) {
      return (
        <Box onClick={!(isLocked ?? false) && (groupRole.Approve ?? false) ? handleClickUnApproveTimesheet : undefined}>
          <Chip
            style={{ padding: '0 5px', cursor: !(isLocked ?? false) && (groupRole.Approve ?? false) ? 'pointer' : 'default' }}
            icon={row.timesheet.is_lock === true ? <LockOutlinedIcon fontSize="small" color="success" /> : <DoneOutlinedIcon fontSize="small" color="success" />}
            sx={{ color: '#417505', border: '1px solid #417505' }}
            label={t('20_98_approved')}
            variant="outlined"
          />
        </Box>
      )
    } else {
      return (
        <Box onClick={!(isLocked ?? false) && (groupRole.Approve ?? false) && (row.timesheet.check_out_time != null) ? handleClickApproveTimesheet : undefined}>
          <Chip
            style={{ padding: '0 5px', cursor: !(isLocked ?? false) && (groupRole.Approve ?? false) && (row.timesheet.check_out_time != null) ? 'pointer' : 'default' }}
            icon={row.timesheet.is_lock === true ? <LockOutlinedIcon fontSize="small" color="warning" /> : <HourglassEmptyOutlinedIcon fontSize="small" color="warning" />}
            sx={{ color: '#f5a623', border: '1px solid #f5a623' }}
            label={t('20_19_pending')}
            variant="outlined"
          />
        </Box>
      )
    }
  }
  return <></>
}

export const ActualTotalCell = ({ params, timesheetSetting, t }: ActualTotalCellProps): JSX.Element => {
  const { row } = params
  if ((row.timesheet?.actual_total) != null) {
    const percent = (row.scheduleTotal !== 0)
      ? (row.timesheet.actual_total / row.timesheet.actual_total) * 100
      : 100
    const showWarnings = checkTimesheetGreaterThan(
      row,
      timesheetSetting.data,
      t
    )
    const currentTime = moment().unix()
    return (
      <Box width="100%" position="relative" height={22}>
        {(row.timesheet.check_out_time != null) && (
          <LinearProgress showWarnings={showWarnings} value={percent} />
        )}
        <Tooltip title={showWarnings?.message} placement="top" PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              fontSize: '12px'
            }
          }
        }}>
          <Typography
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            position="absolute"
            top={0}
            right={0}
            textAlign="center"
            width="100%"
            border={'1px solid'}
          >
            {
              (row.timesheet.check_out_time ?? 0) > 0 ? DateTime.FormatDuration(row.timesheet.actual_total) : ((currentTime - (row.timesheet.check_in_time ?? 0)) > 0) ? DateTime.FormatDuration(currentTime - (row.timesheet.check_in_time ?? 0)) : '0:00'}
          </Typography>
        </Tooltip>
      </Box>
    )
  } else return <Box></Box>
}
