import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CreateGroupNameProps {
  onCreateGroupChat: () => void
  onGroupNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  length: number
}

export default function CreateGroupName ({
  onCreateGroupChat,
  onGroupNameChange,
  length
}: CreateGroupNameProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <Box sx={{ p: 4 }}>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={3}>
          <Typography>{t('20_09_group_name')}</Typography>
        </Grid>
        <Grid item xs>
          <TextField
            required
            fullWidth
            placeholder={t('20_09_group_name') ?? ''}
            name="email"
            variant="standard"
            onChange={onGroupNameChange}
          />
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" sx={{ pt: 0 }}>
        <Button
          disabled={length === 0}
          onClick={onCreateGroupChat}
          color="primary"
          variant="contained"
        >
          {t('20_00_next')}
        </Button>
      </Stack>
    </Box>
  )
}
