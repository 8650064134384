import * as Yup from 'yup'

export const validationTimesheet = Yup.object().shape({
  check_in_time_hour: Yup.number().moreThan(-1, '02_00_clocked_in_require'),
  group_id: Yup.number().moreThan(0, '02_01_working_group_is_required'),
  employee_id: Yup.number().moreThan(0, '20_31_employee_is_required')
})
export const validationGroups = Yup.object().shape({
  groups: Yup.array()
    .test('notEmpty', '00_02_role_required', (value) => {
      return (value?.length ?? 0) > 0
    })
})
