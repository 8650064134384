
import React, { useState } from 'react'
import { Box, Divider, IconButton, Menu, MenuItem } from '@mui/material'
import { Icon } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import { DateTime } from '../../../services/time'
import { type ReponseListTimeSheet, type ListShiftForTimeSheet } from '../../../services/Swagger'
import { MODULEID, getGroupRoleByModule } from '../../../services/modules'
interface TimesheetActionProps {
  timesheet: ListTimeSheet
  handleNewTimesheet: (timesheet: ListTimeSheet) => void
  handleEditTimesheet: (timesheet: ReponseListTimeSheet) => void
  handleViewTimesheet: (timesheet: ListTimeSheet) => void
  handleLockTimesheet: (timesheet: ListTimeSheet) => void
  handleUnLockTimesheet: (timesheet: ListTimeSheet) => void
  handleApproveTimesheet: (timesheet: ListTimeSheet) => void
  handleUnApproveTimesheet: (timesheet: ListTimeSheet) => void
  handleScheuledShift: (timesheet: ListTimeSheet) => void
  handleSelectedScheuledShift: (shift: ListShiftForTimeSheet) => void
  handleSelectApproveByShift: (shift: ListShiftForTimeSheet, timesheet: ListTimeSheet) => void
  handleCreateClockIn: (timesheet: ListTimeSheet) => void
  handleCreateClockOut: (timesheet: ListTimeSheet) => void
  handleSelectedClockIn: (shift: ListShiftForTimeSheet) => void
  handleDeleteTimesheet: (timesheet: ListTimeSheet) => void
  handleTimesheetDetail: (timesheet: ListTimeSheet) => void
  handleApprovedDetails: (timesheet: ListTimeSheet) => void
  handleApproveByShift: (timesheet: ListTimeSheet) => void
  createRole: any
}

export default function Action ({
  timesheet,
  handleNewTimesheet,
  handleEditTimesheet,
  handleViewTimesheet,
  handleLockTimesheet,
  handleUnLockTimesheet,
  handleApproveTimesheet,
  handleUnApproveTimesheet,
  handleSelectedScheuledShift,
  handleScheuledShift,
  handleCreateClockIn,
  handleCreateClockOut,
  handleSelectedClockIn,
  handleDeleteTimesheet,
  handleTimesheetDetail,
  handleApprovedDetails,
  handleApproveByShift,
  handleSelectApproveByShift,
  createRole
}: TimesheetActionProps): JSX.Element {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpenMenu = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = (): void => {
    setAnchorEl(null)
  }
  const handleOpenNewTimesheet = (): void => {
    handleNewTimesheet(timesheet)
    setAnchorEl(null)
  }
  const handleOpenEditTimesheet = (): void => {
    handleEditTimesheet(timesheet.timesheet)
    setAnchorEl(null)
  }
  const handleOpenViewTimesheet = (): void => {
    handleViewTimesheet(timesheet)
    setAnchorEl(null)
  }
  const handleClickLockTimesheet = (): void => {
    handleLockTimesheet(timesheet)
    setAnchorEl(null)
  }
  const handleClickUnLockTimesheet = (): void => {
    handleUnLockTimesheet(timesheet)
    setAnchorEl(null)
  }
  const handleClickApproveTimesheet = (): void => {
    handleApproveTimesheet(timesheet)
    setAnchorEl(null)
  }
  const handleClickUnApproveTimesheet = (): void => {
    handleUnApproveTimesheet(timesheet)
    setAnchorEl(null)
  }
  const handleCreateScheuledShift = (): void => {
    handleScheuledShift(timesheet)
    setAnchorEl(null)
  }
  const handleSelectScheuledShift = (shift: ListShiftForTimeSheet): void => {
    handleSelectedScheuledShift(shift)
    setAnchorEl(null)
  }
  const handleCLickClockIn = (): void => {
    handleCreateClockIn(timesheet)
    setAnchorEl(null)
  }
  const handleCLickClockOut = (): void => {
    handleCreateClockOut(timesheet)
    setAnchorEl(null)
  }
  const handleSelectClockInShift = (shift: ListShiftForTimeSheet): void => {
    handleSelectedClockIn(shift)
    setAnchorEl(null)
  }
  const handleClickDelete = (): void => {
    handleDeleteTimesheet(timesheet)
    setAnchorEl(null)
  }
  const handleClickDetail = (): void => {
    handleTimesheetDetail(timesheet)
    setAnchorEl(null)
  }
  const handleClickApprovedDetails = (): void => {
    handleApprovedDetails(timesheet)
    setAnchorEl(null)
  }
  const handleClickApproveByShift = (): void => {
    handleApproveByShift(timesheet)
    setAnchorEl(null)
  }
  const handleSelectedApproveByShift = (shift: ListShiftForTimeSheet, timesheet: ListTimeSheet): void => {
    handleSelectApproveByShift(shift, timesheet)
    setAnchorEl(null)
  }
  const isLocked = timesheet.timesheet.is_lock
  const isApprove = timesheet.timesheet.status
  const [groupRole] = useState(
    getGroupRoleByModule(MODULEID.timesheet, timesheet.timesheet.group_id ?? 0)
  )
  return (
    <Box display='flex' alignItems='center'>
      {((timesheet.shifts?.length > 0 && (createRole.length > 0)) || groupRole.Create === true || groupRole.Edit === true || groupRole.Approve === true || groupRole.Lock === true || groupRole.Delete === true || groupRole.View) && <IconButton sx={{ fontSize: 13 }} onClick={handleOpenMenu}>
        <Icon iconName='MoreVertical' />
      </IconButton>}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {createRole.length > 0 && (
          <MenuItem className='mw-200' onClick={handleOpenNewTimesheet}>{t('00_03_new')}</MenuItem>
        )}
        {timesheet.shifts?.length > 0 && (getGroupRoleByModule(MODULEID.timesheet, timesheet.shifts[0].group_id ?? 0).Create === true) && (
          <MenuItem onClick={handleCreateScheuledShift}>{t('02_00_create_by_shift')}</MenuItem>
        )}
        {timesheet.shifts?.length > 1 &&
          timesheet.shifts?.map((shift, index) => (
            (getGroupRoleByModule(MODULEID.timesheet, shift.group_id ?? 0).Create === true) &&
            <MenuItem sx={{ pl: 8 }} key={index} onClick={() => { handleSelectScheuledShift(shift) }}>{` ${DateTime.FormatDate(shift.start_time, 'time')} - ${DateTime.FormatDate(shift.end_time, 'time')}`}</MenuItem>
          ))
        }
        <Divider />
        {timesheet.timesheet?.check_in_time !== null && timesheet.shifts?.length > 0 && (getGroupRoleByModule(MODULEID.timesheet, timesheet.shifts[0].group_id ?? 0).Create === true) && (
          <MenuItem onClick={handleCLickClockIn}>{t('13_02_clock_in')}</MenuItem>
        )}
        {timesheet.shifts?.length > 1 &&
          timesheet.shifts?.map((shift, index) => (
            (getGroupRoleByModule(MODULEID.timesheet, shift.group_id ?? 0).Create === true) &&
             <MenuItem sx={{ pl: 8 }} key={index} onClick={() => { handleSelectClockInShift(shift) }}>{`${DateTime.FormatDate(shift.start_time, 'time')} - ${DateTime.FormatDate(shift.end_time, 'time')}`}</MenuItem>
          ))
        }
        {(timesheet.timesheet.check_out_time === null) && (groupRole.Edit === true || groupRole.Create === true) && (
          <MenuItem onClick={handleCLickClockOut}>{t('13_02_clock_out')}</MenuItem>
        )
        }
        {timesheet.timesheet.status === 0 && timesheet.timesheet.is_lock === false && timesheet.timesheet.check_out_time !== null && groupRole.Approve === true && (
          <MenuItem onClick={handleClickApproveTimesheet}>{t('20_00_approve')}</MenuItem>
        )}
        {timesheet.timesheet.status !== 0 && timesheet.timesheet.is_lock === false && timesheet.timesheet.check_out_time !== null && groupRole.Approve === true && (
          <MenuItem onClick={handleClickUnApproveTimesheet}>{t('02_01_unapprove')}</MenuItem>
        )}
        {timesheet.shifts?.length > 0 && (timesheet.timesheet.check_in_time != null) && (timesheet.timesheet.check_out_time != null) && !(isLocked ?? false) && isApprove !== 1 && (getGroupRoleByModule(MODULEID.timesheet, timesheet.shifts[0].group_id ?? 0).Approve === true) && (
          <MenuItem onClick={handleClickApproveByShift}>{t('17_01_approve_by_shift')}</MenuItem>
        )}
        {timesheet.shifts?.length > 1 && (timesheet.timesheet.check_in_time != null) && (timesheet.timesheet.check_out_time != null) && !(isLocked ?? false) && isApprove !== 1 &&
          timesheet.shifts.map((shift, index) => (
            (getGroupRoleByModule(MODULEID.timesheet, shift.group_id ?? 0).Approve === true) &&
            <MenuItem sx={{ pl: 8 }} key={index} onClick={() => { handleSelectedApproveByShift(shift, timesheet) }}>{`${DateTime.FormatDate(shift.start_time, 'time')} - ${DateTime.FormatDate(shift.end_time, 'time')}`}</MenuItem>
          ))
        }
        {isApprove === 1 && (groupRole.Approve ?? false) && (
          <MenuItem onClick={handleClickApprovedDetails}>{t('00_06_approved_details')}</MenuItem>
        )}
        {Object.keys(timesheet.timesheet).length !== 0 && groupRole.View && (
          <div><Divider /><MenuItem onClick={handleOpenViewTimesheet}>{t('01_01_view')}</MenuItem></div>
        )}
        {Object.keys(timesheet.timesheet).length !== 0 && !(isLocked ?? false) && isApprove !== 1 && groupRole.Edit === true && (
          <MenuItem onClick={handleOpenEditTimesheet}>{t('00_03_edit')}</MenuItem>
        )}
        {Object.keys(timesheet.timesheet).length !== 0 && timesheet.timesheet.is_lock !== true && groupRole.Lock === true && (
          <MenuItem onClick={handleClickLockTimesheet}>{t('20_12_lock')}</MenuItem>
        )}
        {Object.keys(timesheet.timesheet).length !== 0 && timesheet.timesheet.is_lock === true && groupRole.Lock === true && (
          <MenuItem onClick={handleClickUnLockTimesheet}>{t('20_00_unlock')}</MenuItem>
        )}
        {Object.keys(timesheet.timesheet).length !== 0 && !(isLocked ?? false) && isApprove !== 1 && groupRole.Delete === true && (
          <MenuItem onClick={handleClickDelete}>{t('20_00_delete')}</MenuItem>
        )}
        {Object.keys(timesheet.timesheet).length !== 0 && (
          <div>
            <Divider />
            {groupRole.View && <MenuItem onClick={handleClickDetail}>{t('20_23_timesheet_info')}</MenuItem>}
            <MenuItem >{t('02_01_logs')}</MenuItem>
          </div>
        )}
      </Menu>
    </Box>
  )
}
