
import moment from 'moment'
export const StorageKey = {
  USER_PROFILE: 'USER_PROFILE'
}
export const GENDERS = [
  {
    label: '00_06_male',
    value: 1
  },
  {
    label: '00_06_female',
    value: 2
  },
  {
    label: '00_06_other',
    value: 3
  }
]
export const PAGE_SIZE = 10
export const STATUS_DELETE = 2
export const STATUS_DEACTIVE = 0
export const STATUS_ACTIVE = 1
export const STATUS_UNVERIFIED = 4

export const STATUSES = [
  {
    status_id: STATUS_ACTIVE,
    status_name: '10_01_status_actived'
  },
  {
    status_id: STATUS_DEACTIVE,
    status_name: '10_01_status_un_veried'
  },
  {
    status_id: STATUS_UNVERIFIED,
    status_name: '10_01_status_unactivated'
  },
  {
    status_id: STATUS_DELETE,
    status_name: '10_01_status_deleted'
  }
]

export const TIMEBANK_UNIT_DAYS = 1
export const TIMEBANK_UNIT_HOURS = 2

export const COUNTRIES = [
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF'
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL'
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ'
  },
  {
    name: 'AmericanSamoa',
    dial_code: '+1 684',
    code: 'AS'
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD'
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO'
  },
  {
    name: 'Anguilla',
    dial_code: '+1 264',
    code: 'AI'
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG'
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR'
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM'
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW'
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU'
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT'
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ'
  },
  {
    name: 'Bahamas',
    dial_code: '+1 242',
    code: 'BS'
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH'
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD'
  },
  {
    name: 'Barbados',
    dial_code: '+1 246',
    code: 'BB'
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY'
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE'
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ'
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ'
  },
  {
    name: 'Bermuda',
    dial_code: '+1 441',
    code: 'BM'
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT'
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA'
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW'
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR'
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO'
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG'
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF'
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI'
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH'
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM'
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA'
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'CV'
  },
  {
    name: 'Cayman Islands',
    dial_code: '+ 345',
    code: 'KY'
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF'
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD'
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL'
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN'
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX'
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO'
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM'
  },
  {
    name: 'Congo',
    dial_code: '+242',
    code: 'CG'
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK'
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR'
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR'
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU'
  },
  {
    name: 'Cyprus',
    dial_code: '+537',
    code: 'CY'
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ'
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK'
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ'
  },
  {
    name: 'Dominica',
    dial_code: '+1 767',
    code: 'DM'
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1 849',
    code: 'DO'
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC'
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG'
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV'
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ'
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER'
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE'
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET'
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO'
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ'
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI'
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR'
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF'
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF'
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA'
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM'
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE'
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE'
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH'
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI'
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR'
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL'
  },
  {
    name: 'Grenada',
    dial_code: '+1 473',
    code: 'GD'
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP'
  },
  {
    name: 'Guam',
    dial_code: '+1 671',
    code: 'GU'
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT'
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN'
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW'
  },
  {
    name: 'Guyana',
    dial_code: '+595',
    code: 'GY'
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT'
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN'
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU'
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS'
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN'
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID'
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ'
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE'
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL'
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT'
  },
  {
    name: 'Jamaica',
    dial_code: '+1 876',
    code: 'JM'
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP'
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO'
  },
  {
    name: 'Kazakhstan',
    dial_code: '+7 7',
    code: 'KZ'
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE'
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI'
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW'
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG'
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV'
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB'
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS'
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR'
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI'
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT'
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU'
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG'
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW'
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY'
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV'
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ML'
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT'
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH'
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ'
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR'
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU'
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT'
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX'
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC'
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN'
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME'
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS'
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA'
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    code: 'MM'
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA'
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR'
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP'
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL'
  },
  {
    name: 'Netherlands Antilles',
    dial_code: '+599',
    code: 'AN'
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC'
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ'
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI'
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'NE'
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG'
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU'
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF'
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1 670',
    code: 'MP'
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO'
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'OM'
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK'
  },
  {
    name: 'Palau',
    dial_code: '+680',
    code: 'PW'
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'PA'
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG'
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY'
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'PE'
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH'
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL'
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT'
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1 939',
    code: 'PR'
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA'
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO'
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW'
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS'
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM'
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA'
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN'
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS'
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC'
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL'
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG'
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK'
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI'
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB'
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS'
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES'
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK'
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD'
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR'
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    code: 'SZ'
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE'
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH'
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ'
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH'
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG'
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK'
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO'
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1 868',
    code: 'TT'
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN'
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR'
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM'
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1 649',
    code: 'TC'
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV'
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG'
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA'
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE'
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB'
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'US'
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY'
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ'
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU'
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF'
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE'
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM'
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW'
  },
  {
    name: 'land Islands',
    dial_code: '',
    code: 'AX'
  },
  {
    name: 'Antarctica',
    dial_code: '',
    code: 'AQ'
  },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    code: 'BO'
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'BN'
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC'
  },
  {
    name: 'Congo, The Democratic Republic of the',
    dial_code: '+243',
    code: 'CD'
  },
  {
    name: 'Cote d\'Ivoire',
    dial_code: '+225',
    code: 'CI'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'FK'
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG'
  },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    code: 'VA'
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK'
  },
  {
    name: 'Iran, Islamic Republic of',
    dial_code: '+98',
    code: 'IR'
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM'
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE'
  },
  {
    name: 'Korea, Democratic People\'s Republic of',
    dial_code: '+850',
    code: 'KP'
  },
  {
    name: 'Korea, Republic of',
    dial_code: '+82',
    code: 'KR'
  },
  {
    name: 'Lao People\'s Democratic Republic',
    dial_code: '+856',
    code: 'LA'
  },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'LY'
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO'
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    dial_code: '+389',
    code: 'MK'
  },
  {
    name: 'Micronesia, Federated States of',
    dial_code: '+691',
    code: 'FM'
  },
  {
    name: 'Moldova, Republic of',
    dial_code: '+373',
    code: 'MD'
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ'
  },
  {
    name: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    code: 'PS'
  },
  {
    name: 'Pitcairn',
    dial_code: '+872',
    code: 'PN'
  },
  {
    name: 'Réunion',
    dial_code: '+262',
    code: 'RE'
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU'
  },
  {
    name: 'Saint Barthélemy',
    dial_code: '+590',
    code: 'BL'
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'SH'
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1 869',
    code: 'KN'
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1 758',
    code: 'LC'
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'MF'
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1 784',
    code: 'VC'
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'ST'
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO'
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    code: 'SJ'
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    code: 'SY'
  },
  {
    name: 'Taiwan, Province of China',
    dial_code: '+886',
    code: 'TW'
  },
  {
    name: 'Tanzania, United Republic of',
    dial_code: '+255',
    code: 'TZ'
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    code: 'TL'
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    dial_code: '+58',
    code: 'VE'
  },
  {
    name: 'Viet Nam',
    dial_code: '+84',
    code: 'VN'
  },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1 284',
    code: 'VG'
  },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1 340',
    code: 'VI'
  }
]

export const OFFSET_TIMEZONES = {
  'Etc/GMT+11': '(GMT-11:00) Midway Island, Samoa',
  'Etc/GMT+10': '(GMT-10:00) Hawaii-Aleutian',
  'Etc/GMT+9': '(GMT-09:00) Alaska',
  'Etc/GMT+8': '(GMT-08:00) Pacific Time (US & Canada)',
  'Etc/GMT+7': '(GMT-07:00) Mountain Time (US & Canada)',
  'Etc/GMT+6': '(GMT-06:00) Central Time (US & Canada)',
  'Etc/GMT+5': '(GMT-05:00) Eastern Time (US & Canada)',
  'Etc/GMT+4': '(GMT-04:00) Atlantic Time (Canada)',
  'Etc/GMT+3': '(GMT-03:00) Greenland',
  'Etc/GMT+2': '(GMT-02:00) Mid-Atlantic',
  'Etc/GMT+1': '(GMT-01:00) Cape Verde Is.',
  'Etc/GMT': '(GMT) Greenwich Mean Time : London',
  'Etc/GMT-1': '(GMT+01:00) Western Europe',
  'Etc/GMT-2': '(GMT+02:00) Eastern Europe',
  'Etc/GMT-3': '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
  'Etc/GMT-4': '(GMT+04:00) Abu Dhabi, Muscat',
  'Etc/GMT-5': '(GMT+05:00) Ekaterinburg',
  'Etc/GMT-6': '(GMT+06:00) Astana, Dhaka',
  'Etc/GMT-7': '(GMT+07:00) Bangkok, Hanoi, Jakarta',
  'Etc/GMT-8': '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  'Etc/GMT-9': '(GMT+09:00) Osaka, Sapporo, Tokyo, Seoul',
  'Etc/GMT-10': '(GMT+10:00) Vladivostok',
  'Etc/GMT-11': '(GMT+11:00) Solomon Is., New Caledonia',
  'Etc/GMT-12': '(GMT+12:00) Auckland, Wellington',
  'Etc/GMT-13': '(GMT+13:00) Nuku\'alofa',
  'Etc/GMT-14': '(GMT+14:00) Kiritimati'
}

export const WEEK_DAYS = [{
  short_text: '00_04_sun',
  label: '00_04_sunday',
  value: 7
}, {
  short_text: '00_04_mon',
  label: '00_04_monday',
  value: 1
}, {
  short_text: '00_04_tue',
  label: '00_04_tuesday',
  value: 2
}, {
  short_text: '00_04_wed',
  label: '00_04_wednesday',
  value: 3
}, {
  short_text: '00_04_thu',
  label: '00_04_thusday',
  value: 4
}, {
  short_text: '00_04_fri',
  label: '00_04_friday',
  value: 5
}, {
  short_text: '00_04_sat',
  label: '00_04_saturday',
  value: 6
}]

export function getListMonths (): Array<{
  value: number
  label: string
}> {
  const months = []
  const m = moment()
  for (let i = 0; i < 12; i++) {
    months.push({
      value: i + 1,
      label: m.month(i).format('MMMM')
    })
  }
  return months
}

export const LIST_INDUSTRIES = [{
  label: '11_03_industry_1',
  value: 1
}, {
  label: '11_03_industry_2',
  value: 2
}, {
  label: '11_03_industry_3',
  value: 3
}, {
  label: '11_03_industry_4',
  value: 4
}, {
  label: '11_03_industry_5',
  value: 5
}, {
  label: '11_03_industry_6',
  value: 6
}, {
  label: '11_03_industry_7',
  value: 7
}, {
  label: '11_03_industry_8',
  value: 8
}
]

export function getListTimezone (): Array<{
  value: string
  label: string
}> {
  const timeZones = moment.tz.names()
  const offsetTmz = []
  // eslint-disable-next-line @typescript-eslint/no-for-in-array
  for (const i in timeZones) {
    if (typeof timeZones[i] === 'string') {
      const obj = {
        value: timeZones[i],
        label: timeZones[i]
      }
      offsetTmz.push(obj)
    }
  }
  return offsetTmz
}

export function getListTime (is12: boolean): Array<{
  value: number
  label: string
}> {
  const listInterval: Array<{
    value: number
    label: string
  }> = []
  for (let i = 0; i < 1440; i += 15) {
    listInterval.push({
      value: i,
      label: convertTimeFormat(i, is12)
    })
  }
  return listInterval
}

function convertTimeFormat (minute: number, is12: boolean): string {
  const hour = addZero(Math.floor(minute / 60)) + ''
  const min = addZero(minute % 60) + ''
  if (is12) { return moment('2015/04/03 ' + hour + ':' + min + ':00', 'YYYY/MM/DD HH:mm:ss').format('hh:mm a') } else { return (hour + ':' + min) }
}

export function addZero (number: number): string {
  return (number < 10) ? `0${number}` : number.toString()
}

export const TIME_STEPS = [
  { value: 60, label: '00_06_minute' },
  { value: 5 * 60, label: '00_06_minutes' },
  { value: 15 * 60, label: '00_06_minutes' },
  { value: 30 * 60, label: '00_06_minutes' }
]

export const LANGUAGES =
  [{
    code: 'aa',
    name: 'Afar'
  }, {
    code: 'ab',
    name: 'Abkhazian'
  }, {
    code: 'ae',
    name: 'Avestan'
  }, {
    code: 'af',
    name: 'Afrikaans'
  }, {
    code: 'ak',
    name: 'Akan'
  }, {
    code: 'am',
    name: 'Amharic'
  }, {
    code: 'an',
    name: 'Aragonese'
  }, {
    code: 'ar',
    name: 'Arabic'
  }, {
    code: 'as',
    name: 'Assamese'
  }, {
    code: 'av',
    name: 'Avaric'
  }, {
    code: 'ay',
    name: 'Aymara'
  }, {
    code: 'az',
    name: 'Azerbaijani'
  }, {
    code: 'ba',
    name: 'Bashkir'
  }, {
    code: 'be',
    name: 'Belarusian'
  }, {
    code: 'bg',
    name: 'Bulgarian'
  }, {
    code: 'bh',
    name: 'Bihari languages'
  }, {
    code: 'bi',
    name: 'Bislama'
  }, {
    code: 'bm',
    name: 'Bambara'
  }, {
    code: 'bn',
    name: 'Bengali'
  }, {
    code: 'bo',
    name: 'Tibetan'
  }, {
    code: 'br',
    name: 'Breton'
  }, {
    code: 'bs',
    name: 'Bosnian'
  }, {
    code: 'ca',
    name: 'Catalan; Valencian'
  }, {
    code: 'ce',
    name: 'Chechen'
  }, {
    code: 'ch',
    name: 'Chamorro'
  }, {
    code: 'co',
    name: 'Corsican'
  }, {
    code: 'cr',
    name: 'Cree'
  }, {
    code: 'cs',
    name: 'Czech'
  }, {
    code: 'cu',
    name: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic'
  }, {
    code: 'cv',
    name: 'Chuvash'
  }, {
    code: 'cy',
    name: 'Welsh'
  }, {
    code: 'da',
    name: 'Danish'
  }, {
    code: 'de',
    name: 'German'
  }, {
    code: 'dv',
    name: 'Divehi; Dhivehi; Maldivian'
  }, {
    code: 'dz',
    name: 'Dzongkha'
  }, {
    code: 'ee',
    name: 'Ewe'
  }, {
    code: 'el',
    name: 'Greek, Modern (1453-)'
  }, {
    code: 'en',
    name: 'English'
  }, {
    code: 'eo',
    name: 'Esperanto'
  }, {
    code: 'es',
    name: 'Spanish; Castilian'
  }, {
    code: 'et',
    name: 'Estonian'
  }, {
    code: 'eu',
    name: 'Basque'
  }, {
    code: 'fa',
    name: 'Persian'
  }, {
    code: 'ff',
    name: 'Fulah'
  }, {
    code: 'fi',
    name: 'Finnish'
  }, {
    code: 'fj',
    name: 'Fijian'
  }, {
    code: 'fo',
    name: 'Faroese'
  }, {
    code: 'fr',
    name: 'French'
  }, {
    code: 'fy',
    name: 'Western Frisian'
  }, {
    code: 'ga',
    name: 'Irish'
  }, {
    code: 'gd',
    name: 'Gaelic; Scottish Gaelic'
  }, {
    code: 'gl',
    name: 'Galician'
  }, {
    code: 'gn',
    name: 'Guarani'
  }, {
    code: 'gu',
    name: 'Gujarati'
  }, {
    code: 'gv',
    name: 'Manx'
  }, {
    code: 'ha',
    name: 'Hausa'
  }, {
    code: 'he',
    name: 'Hebrew'
  }, {
    code: 'hi',
    name: 'Hindi'
  }, {
    code: 'ho',
    name: 'Hiri Motu'
  }, {
    code: 'hr',
    name: 'Croatian'
  }, {
    code: 'ht',
    name: 'Haitian; Haitian Creole'
  }, {
    code: 'hu',
    name: 'Hungarian'
  }, {
    code: 'hy',
    name: 'Armenian'
  }, {
    code: 'hz',
    name: 'Herero'
  }, {
    code: 'ia',
    name: 'Interlingua (International Auxiliary Language Association)'
  }, {
    code: 'id',
    name: 'Indonesian'
  }, {
    code: 'ie',
    name: 'Interlingue; Occidental'
  }, {
    code: 'ig',
    name: 'Igbo'
  }, {
    code: 'ii',
    name: 'Sichuan Yi; Nuosu'
  }, {
    code: 'ik',
    name: 'Inupiaq'
  }, {
    code: 'io',
    name: 'Ido'
  }, {
    code: 'is',
    name: 'Icelandic'
  }, {
    code: 'it',
    name: 'Italian'
  }, {
    code: 'iu',
    name: 'Inuktitut'
  }, {
    code: 'ja',
    name: 'Japanese'
  }, {
    code: 'jv',
    name: 'Javanese'
  }, {
    code: 'ka',
    name: 'Georgian'
  }, {
    code: 'kg',
    name: 'Kongo'
  }, {
    code: 'ki',
    name: 'Kikuyu; Gikuyu'
  }, {
    code: 'kj',
    name: 'Kuanyama; Kwanyama'
  }, {
    code: 'kk',
    name: 'Kazakh'
  }, {
    code: 'kl',
    name: 'Kalaallisut; Greenlandic'
  }, {
    code: 'km',
    name: 'Central Khmer'
  }, {
    code: 'kn',
    name: 'Kannada'
  }, {
    code: 'ko',
    name: 'Korean'
  }, {
    code: 'kr',
    name: 'Kanuri'
  }, {
    code: 'ks',
    name: 'Kashmiri'
  }, {
    code: 'ku',
    name: 'Kurdish'
  }, {
    code: 'kv',
    name: 'Komi'
  }, {
    code: 'kw',
    name: 'Cornish'
  }, {
    code: 'ky',
    name: 'Kirghiz; Kyrgyz'
  }, {
    code: 'la',
    name: 'Latin'
  }, {
    code: 'lb',
    name: 'Luxembourgish; Letzeburgesch'
  }, {
    code: 'lg',
    name: 'Ganda'
  }, {
    code: 'li',
    name: 'Limburgan; Limburger; Limburgish'
  }, {
    code: 'ln',
    name: 'Lingala'
  }, {
    code: 'lo',
    name: 'Lao'
  }, {
    code: 'lt',
    name: 'Lithuanian'
  }, {
    code: 'lu',
    name: 'Luba-Katanga'
  }, {
    code: 'lv',
    name: 'Latvian'
  }, {
    code: 'mg',
    name: 'Malagasy'
  }, {
    code: 'mh',
    name: 'Marshallese'
  }, {
    code: 'mi',
    name: 'Maori'
  }, {
    code: 'mk',
    name: 'Macedonian'
  }, {
    code: 'ml',
    name: 'Malayalam'
  }, {
    code: 'mn',
    name: 'Mongolian'
  }, {
    code: 'mr',
    name: 'Marathi'
  }, {
    code: 'ms',
    name: 'Malay'
  }, {
    code: 'mt',
    name: 'Maltese'
  }, {
    code: 'my',
    name: 'Burmese'
  }, {
    code: 'na',
    name: 'Nauru'
  }, {
    code: 'nb',
    name: 'Bokmål, Norwegian; Norwegian Bokmål'
  }, {
    code: 'nd',
    name: 'Ndebele, North; North Ndebele'
  }, {
    code: 'ne',
    name: 'Nepali'
  }, {
    code: 'ng',
    name: 'Ndonga'
  }, {
    code: 'nl',
    name: 'Dutch; Flemish'
  }, {
    code: 'nn',
    name: 'Norwegian Nynorsk; Nynorsk, Norwegian'
  }, {
    code: 'no',
    name: 'Norwegian'
  }, {
    code: 'nr',
    name: 'Ndebele, South; South Ndebele'
  }, {
    code: 'nv',
    name: 'Navajo; Navaho'
  }, {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja'
  }, {
    code: 'oc',
    name: 'Occitan (post 1500); Provençal'
  }, {
    code: 'oj',
    name: 'Ojibwa'
  }, {
    code: 'om',
    name: 'Oromo'
  }, {
    code: 'or',
    name: 'Oriya'
  }, {
    code: 'os',
    name: 'Ossetian; Ossetic'
  }, {
    code: 'pa',
    name: 'Panjabi; Punjabi'
  }, {
    code: 'pi',
    name: 'Pali'
  }, {
    code: 'pl',
    name: 'Polish'
  }, {
    code: 'ps',
    name: 'Pushto; Pashto'
  }, {
    code: 'pt',
    name: 'Portuguese'
  }, {
    code: 'qu',
    name: 'Quechua'
  }, {
    code: 'rm',
    name: 'Romansh'
  }, {
    code: 'rn',
    name: 'Rundi'
  }, {
    code: 'ro',
    name: 'Romanian; Moldavian; Moldovan'
  }, {
    code: 'ru',
    name: 'Russian'
  }, {
    code: 'rw',
    name: 'Kinyarwanda'
  }, {
    code: 'sa',
    name: 'Sanskrit'
  }, {
    code: 'sc',
    name: 'Sardinian'
  }, {
    code: 'sd',
    name: 'Sindhi'
  }, {
    code: 'se',
    name: 'Northern Sami'
  }, {
    code: 'sg',
    name: 'Sango'
  }, {
    code: 'si',
    name: 'Sinhala; Sinhalese'
  }, {
    code: 'sk',
    name: 'Slovak'
  }, {
    code: 'sl',
    name: 'Slovenian'
  }, {
    code: 'sm',
    name: 'Samoan'
  }, {
    code: 'sn',
    name: 'Shona'
  }, {
    code: 'so',
    name: 'Somali'
  }, {
    code: 'sq',
    name: 'Albanian'
  }, {
    code: 'sr',
    name: 'Serbian'
  }, {
    code: 'ss',
    name: 'Swati'
  }, {
    code: 'st',
    name: 'Sotho, Southern'
  }, {
    code: 'su',
    name: 'Sundanese'
  }, {
    code: 'sv',
    name: 'Swedish'
  }, {
    code: 'sw',
    name: 'Swahili'
  }, {
    code: 'ta',
    name: 'Tamil'
  }, {
    code: 'te',
    name: 'Telugu'
  }, {
    code: 'tg',
    name: 'Tajik'
  }, {
    code: 'th',
    name: 'Thai'
  }, {
    code: 'ti',
    name: 'Tigrinya'
  }, {
    code: 'tk',
    name: 'Turkmen'
  }, {
    code: 'tl',
    name: 'Tagalog'
  }, {
    code: 'tn',
    name: 'Tswana'
  }, {
    code: 'to',
    name: 'Tonga (Tonga Islands)'
  }, {
    code: 'tr',
    name: 'Turkish'
  }, {
    code: 'ts',
    name: 'Tsonga'
  }, {
    code: 'tt',
    name: 'Tatar'
  }, {
    code: 'tw',
    name: 'Twi'
  }, {
    code: 'ty',
    name: 'Tahitian'
  }, {
    code: 'ug',
    name: 'Uighur; Uyghur'
  }, {
    code: 'uk',
    name: 'Ukrainian'
  }, {
    code: 'ur',
    name: 'Urdu'
  }, {
    code: 'uz',
    name: 'Uzbek'
  }, {
    code: 've',
    name: 'Venda'
  }, {
    code: 'vi',
    name: 'Vietnamese'
  }, {
    code: 'vo',
    name: 'Volapük'
  }, {
    code: 'wa',
    name: 'Walloon'
  }, {
    code: 'wo',
    name: 'Wolof'
  }, {
    code: 'xh',
    name: 'Xhosa'
  }, {
    code: 'yi',
    name: 'Yiddish'
  }, {
    code: 'yo',
    name: 'Yoruba'
  }, {
    code: 'za',
    name: 'Zhuang; Chuang'
  }, {
    code: 'zh',
    name: 'Chinese'
  }, {
    code: 'zu',
    name: 'Zulu'
  }]

export const LANGS = [{
  code: 'ab',
  name: 'Abkhaz',
  nativeName: 'аҧсуа'
},
{
  code: 'aa',
  name: 'Afar',
  nativeName: 'Afaraf'
},
{
  code: 'af',
  name: 'Afrikaans',
  nativeName: 'Afrikaans'
},
{
  code: 'ak',
  name: 'Akan',
  nativeName: 'Akan'
},
{
  code: 'sq',
  name: 'Albanian',
  nativeName: 'Shqip'
},
{
  code: 'am',
  name: 'Amharic',
  nativeName: 'አማርኛ'
},
{
  code: 'ar',
  name: 'Arabic',
  nativeName: 'العربية'
},
{
  code: 'an',
  name: 'Aragonese',
  nativeName: 'Aragonés'
},
{
  code: 'hy',
  name: 'Armenian',
  nativeName: 'Հայերեն'
},
{
  code: 'as',
  name: 'Assamese',
  nativeName: 'অসমীয়া'
},
{
  code: 'av',
  name: 'Avaric',
  nativeName: 'авар мацӀ, магӀарул мацӀ'
},
{
  code: 'ae',
  name: 'Avestan',
  nativeName: 'avesta'
},
{
  code: 'ay',
  name: 'Aymara',
  nativeName: 'aymar aru'
},
{
  code: 'az',
  name: 'Azerbaijani',
  nativeName: 'azərbaycan dili'
},
{
  code: 'bm',
  name: 'Bambara',
  nativeName: 'bamanankan'
},
{
  code: 'ba',
  name: 'Bashkir',
  nativeName: 'башҡорт теле'
},
{
  code: 'eu',
  name: 'Basque',
  nativeName: 'euskara, euskera'
},
{
  code: 'be',
  name: 'Belarusian',
  nativeName: 'Беларуская'
},
{
  code: 'bn',
  name: 'Bengali',
  nativeName: 'বাংলা'
},
{
  code: 'bh',
  name: 'Bihari',
  nativeName: 'भोजपुरी'
},
{
  code: 'bi',
  name: 'Bislama',
  nativeName: 'Bislama'
},
{
  code: 'bs',
  name: 'Bosnian',
  nativeName: 'bosanski jezik'
},
{
  code: 'br',
  name: 'Breton',
  nativeName: 'brezhoneg'
},
{
  code: 'bg',
  name: 'Bulgarian',
  nativeName: 'български език'
},
{
  code: 'my',
  name: 'Burmese',
  nativeName: 'ဗမာစာ'
},
{
  code: 'ca',
  name: 'Catalan; Valencian',
  nativeName: 'Català'
},
{
  code: 'ch',
  name: 'Chamorro',
  nativeName: 'Chamoru'
},
{
  code: 'ce',
  name: 'Chechen',
  nativeName: 'нохчийн мотт'
},
{
  code: 'ny',
  name: 'Chichewa; Chewa; Nyanja',
  nativeName: 'chiCheŵa, chinyanja'
},
{
  code: 'zh',
  name: 'Chinese',
  nativeName: '中文 (Zhōngwén), 汉语, 漢語'
},
{
  code: 'cv',
  name: 'Chuvash',
  nativeName: 'чӑваш чӗлхи'
},
{
  code: 'kw',
  name: 'Cornish',
  nativeName: 'Kernewek'
},
{
  code: 'co',
  name: 'Corsican',
  nativeName: 'corsu, lingua corsa'
},
{
  code: 'cr',
  name: 'Cree',
  nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ'
},
{
  code: 'hr',
  name: 'Croatian',
  nativeName: 'hrvatski'
},
{
  code: 'cs',
  name: 'Czech',
  nativeName: 'česky, čeština'
},
{
  code: 'da',
  name: 'Danish',
  nativeName: 'dansk'
},
{
  code: 'dv',
  name: 'Divehi; Dhivehi; Maldivian;',
  nativeName: 'ދިވެހި'
},
{
  code: 'nl',
  name: 'Dutch',
  nativeName: 'Nederlands, Vlaams'
},
{
  code: 'en',
  name: 'English',
  nativeName: 'English'
},
{
  code: 'eo',
  name: 'Esperanto',
  nativeName: 'Esperanto'
},
{
  code: 'et',
  name: 'Estonian',
  nativeName: 'eesti, eesti keel'
},
{
  code: 'ee',
  name: 'Ewe',
  nativeName: 'Eʋegbe'
},
{
  code: 'fo',
  name: 'Faroese',
  nativeName: 'føroyskt'
},
{
  code: 'fj',
  name: 'Fijian',
  nativeName: 'vosa Vakaviti'
},
{
  code: 'fi',
  name: 'Finnish',
  nativeName: 'suomi, suomen kieli'
},
{
  code: 'fr',
  name: 'French',
  nativeName: 'français, langue française'
},
{
  code: 'ff',
  name: 'Fula; Fulah; Pulaar; Pular',
  nativeName: 'Fulfulde, Pulaar, Pular'
},
{
  code: 'gl',
  name: 'Galician',
  nativeName: 'Galego'
},
{
  code: 'ka',
  name: 'Georgian',
  nativeName: 'ქართული'
},
{
  code: 'de',
  name: 'German',
  nativeName: 'Deutsch'
},
{
  code: 'el',
  name: 'Greek, Modern',
  nativeName: 'Ελληνικά'
},
{
  code: 'gn',
  name: 'Guaraní',
  nativeName: 'Avañeẽ'
},
{
  code: 'gu',
  name: 'Gujarati',
  nativeName: 'ગુજરાતી'
},
{
  code: 'ht',
  name: 'Haitian; Haitian Creole',
  nativeName: 'Kreyòl ayisyen'
},
{
  code: 'ha',
  name: 'Hausa',
  nativeName: 'Hausa, هَوُسَ'
},
{
  code: 'he',
  name: 'Hebrew (modern)',
  nativeName: 'עברית'
},
{
  code: 'hz',
  name: 'Herero',
  nativeName: 'Otjiherero'
},
{
  code: 'hi',
  name: 'Hindi',
  nativeName: 'हिन्दी, हिंदी'
},
{
  code: 'ho',
  name: 'Hiri Motu',
  nativeName: 'Hiri Motu'
},
{
  code: 'hu',
  name: 'Hungarian',
  nativeName: 'Magyar'
},
{
  code: 'ia',
  name: 'Interlingua',
  nativeName: 'Interlingua'
},
{
  code: 'id',
  name: 'Indonesian',
  nativeName: 'Bahasa Indonesia'
},
{
  code: 'ie',
  name: 'Interlingue',
  nativeName: 'Originally called Occidental; then Interlingue after WWII'
},
{
  code: 'ga',
  name: 'Irish',
  nativeName: 'Gaeilge'
},
{
  code: 'ig',
  name: 'Igbo',
  nativeName: 'Asụsụ Igbo'
},
{
  code: 'ik',
  name: 'Inupiaq',
  nativeName: 'Iñupiaq, Iñupiatun'
},
{
  code: 'io',
  name: 'Ido',
  nativeName: 'Ido'
},
{
  code: 'is',
  name: 'Icelandic',
  nativeName: 'Íslenska'
},
{
  code: 'it',
  name: 'Italian',
  nativeName: 'Italiano'
},
{
  code: 'iu',
  name: 'Inuktitut',
  nativeName: 'ᐃᓄᒃᑎᑐᑦ'
},
{
  code: 'ja',
  name: 'Japanese',
  nativeName: '日本語 (にほんご／にっぽんご)'
},
{
  code: 'jv',
  name: 'Javanese',
  nativeName: 'basa Jawa'
},
{
  code: 'kl',
  name: 'Kalaallisut, Greenlandic',
  nativeName: 'kalaallisut, kalaallit oqaasii'
},
{
  code: 'kn',
  name: 'Kannada',
  nativeName: 'ಕನ್ನಡ'
},
{
  code: 'kr',
  name: 'Kanuri',
  nativeName: 'Kanuri'
},
{
  code: 'ks',
  name: 'Kashmiri',
  nativeName: 'कश्मीरी, كشميري'
},
{
  code: 'kk',
  name: 'Kazakh',
  nativeName: 'Қазақ тілі'
},
{
  code: 'km',
  name: 'Khmer',
  nativeName: 'ភាសាខ្មែរ'
},
{
  code: 'ki',
  name: 'Kikuyu, Gikuyu',
  nativeName: 'Gĩkũyũ'
},
{
  code: 'rw',
  name: 'Kinyarwanda',
  nativeName: 'Ikinyarwanda'
},
{
  code: 'ky',
  name: 'Kirghiz, Kyrgyz',
  nativeName: 'кыргыз тили'
},
{
  code: 'kv',
  name: 'Komi',
  nativeName: 'коми кыв'
},
{
  code: 'kg',
  name: 'Kongo',
  nativeName: 'KiKongo'
},
{
  code: 'ko',
  name: 'Korean',
  nativeName: '한국어 (韓國語), 조선말 (朝鮮語)'
},
{
  code: 'ku',
  name: 'Kurdish',
  nativeName: 'Kurdî, كوردی'
},
{
  code: 'kj',
  name: 'Kwanyama, Kuanyama',
  nativeName: 'Kuanyama'
},
{
  code: 'la',
  name: 'Latin',
  nativeName: 'latine, lingua latina'
},
{
  code: 'lb',
  name: 'Luxembourgish, Letzeburgesch',
  nativeName: 'Lëtzebuergesch'
},
{
  code: 'lg',
  name: 'Luganda',
  nativeName: 'Luganda'
},
{
  code: 'li',
  name: 'Limburgish, Limburgan, Limburger',
  nativeName: 'Limburgs'
},
{
  code: 'ln',
  name: 'Lingala',
  nativeName: 'Lingála'
},
{
  code: 'lo',
  name: 'Lao',
  nativeName: 'ພາສາລາວ'
},
{
  code: 'lt',
  name: 'Lithuanian',
  nativeName: 'lietuvių kalba'
},
{
  code: 'lu',
  name: 'Luba-Katanga',
  nativeName: 'Kiluba'
},
{
  code: 'lv',
  name: 'Latvian',
  nativeName: 'latviešu valoda'
},
{
  code: 'gv',
  name: 'Manx',
  nativeName: 'Gaelg, Gailck'
},
{
  code: 'mk',
  name: 'Macedonian',
  nativeName: 'македонски јазик'
},
{
  code: 'mg',
  name: 'Malagasy',
  nativeName: 'Malagasy fiteny'
},
{
  code: 'ms',
  name: 'Malay',
  nativeName: 'bahasa Melayu, بهاس ملايو'
},
{
  code: 'ml',
  name: 'Malayalam',
  nativeName: 'മലയാളം'
},
{
  code: 'mt',
  name: 'Maltese',
  nativeName: 'Malti'
},
{
  code: 'mi',
  name: 'Māori',
  nativeName: 'te reo Māori'
},
{
  code: 'mr',
  name: 'Marathi (Marāṭhī)',
  nativeName: 'मराठी'
},
{
  code: 'mh',
  name: 'Marshallese',
  nativeName: 'Kajin M̧ajeļ'
},
{
  code: 'mn',
  name: 'Mongolian',
  nativeName: 'монгол'
},
{
  code: 'na',
  name: 'Nauru',
  nativeName: 'Ekakairũ Naoero'
},
{
  code: 'nv',
  name: 'Navajo, Navaho',
  nativeName: 'Diné bizaad, Dinékʼehǰí'
},
{
  code: 'nb',
  name: 'Norwegian Bokmål',
  nativeName: 'Norsk bokmål'
},
{
  code: 'nd',
  name: 'North Ndebele',
  nativeName: 'isiNdebele'
},
{
  code: 'ne',
  name: 'Nepali',
  nativeName: 'नेपाली'
},
{
  code: 'ng',
  name: 'Ndonga',
  nativeName: 'Owambo'
},
{
  code: 'nn',
  name: 'Norwegian Nynorsk',
  nativeName: 'Norsk nynorsk'
},
{
  code: 'no',
  name: 'Norwegian',
  nativeName: 'Norsk'
},
{
  code: 'ii',
  name: 'Nuosu',
  nativeName: 'ꆈꌠ꒿ Nuosuhxop'
},
{
  code: 'nr',
  name: 'South Ndebele',
  nativeName: 'isiNdebele'
},
{
  code: 'oc',
  name: 'Occitan',
  nativeName: 'Occitan'
},
{
  code: 'oj',
  name: 'Ojibwe, Ojibwa',
  nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ'
},
{
  code: 'cu',
  name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
  nativeName: 'ѩзыкъ словѣньскъ'
},
{
  code: 'om',
  name: 'Oromo',
  nativeName: 'Afaan Oromoo'
},
{
  code: 'or',
  name: 'Oriya',
  nativeName: 'ଓଡ଼ିଆ'
},
{
  code: 'os',
  name: 'Ossetian, Ossetic',
  nativeName: 'ирон æвзаг'
},
{
  code: 'pa',
  name: 'Panjabi, Punjabi',
  nativeName: 'ਪੰਜਾਬੀ, پنجابی'
},
{
  code: 'pi',
  name: 'Pāli',
  nativeName: 'पाऴि'
},
{
  code: 'fa',
  name: 'Persian',
  nativeName: 'فارسی'
},
{
  code: 'pl',
  name: 'Polish',
  nativeName: 'polski'
},
{
  code: 'ps',
  name: 'Pashto, Pushto',
  nativeName: 'پښتو'
},
{
  code: 'pt',
  name: 'Portuguese',
  nativeName: 'Português'
},
{
  code: 'qu',
  name: 'Quechua',
  nativeName: 'Runa Simi, Kichwa'
},
{
  code: 'rm',
  name: 'Romansh',
  nativeName: 'rumantsch grischun'
},
{
  code: 'rn',
  name: 'Kirundi',
  nativeName: 'kiRundi'
},
{
  code: 'ro',
  name: 'Romanian, Moldavian, Moldovan',
  nativeName: 'română'
},
{
  code: 'ru',
  name: 'Russian',
  nativeName: 'русский язык'
},
{
  code: 'sa',
  name: 'Sanskrit (Saṁskṛta)',
  nativeName: 'संस्कृतम्'
},
{
  code: 'sc',
  name: 'Sardinian',
  nativeName: 'sardu'
},
{
  code: 'sd',
  name: 'Sindhi',
  nativeName: 'सिन्धी, سنڌي، سندھی'
},
{
  code: 'se',
  name: 'Northern Sami',
  nativeName: 'Davvisámegiella'
},
{
  code: 'sm',
  name: 'Samoan',
  nativeName: 'gagana faa Samoa'
},
{
  code: 'sg',
  name: 'Sango',
  nativeName: 'yângâ tî sängö'
},
{
  code: 'sr',
  name: 'Serbian',
  nativeName: 'српски језик'
},
{
  code: 'gd',
  name: 'Scottish Gaelic; Gaelic',
  nativeName: 'Gàidhlig'
},
{
  code: 'sn',
  name: 'Shona',
  nativeName: 'chiShona'
},
{
  code: 'si',
  name: 'Sinhala, Sinhalese',
  nativeName: 'සිංහල'
},
{
  code: 'sk',
  name: 'Slovak',
  nativeName: 'slovenčina'
},
{
  code: 'sl',
  name: 'Slovene',
  nativeName: 'slovenščina'
},
{
  code: 'so',
  name: 'Somali',
  nativeName: 'Soomaaliga, af Soomaali'
},
{
  code: 'st',
  name: 'Southern Sotho',
  nativeName: 'Sesotho'
},
{
  code: 'es',
  name: 'Spanish; Castilian',
  nativeName: 'español, castellano'
},
{
  code: 'su',
  name: 'Sundanese',
  nativeName: 'Basa Sunda'
},
{
  code: 'sw',
  name: 'Swahili',
  nativeName: 'Kiswahili'
},
{
  code: 'ss',
  name: 'Swati',
  nativeName: 'SiSwati'
},
{
  code: 'sv',
  name: 'Swedish',
  nativeName: 'svenska'
},
{
  code: 'ta',
  name: 'Tamil',
  nativeName: 'தமிழ்'
},
{
  code: 'te',
  name: 'Telugu',
  nativeName: 'తెలుగు'
},
{
  code: 'tg',
  name: 'Tajik',
  nativeName: 'тоҷикӣ, toğikī, تاجیکی'
},
{
  code: 'th',
  name: 'Thai',
  nativeName: 'ไทย'
},
{
  code: 'ti',
  name: 'Tigrinya',
  nativeName: 'ትግርኛ'
},
{
  code: 'bo',
  name: 'Tibetan Standard, Tibetan, Central',
  nativeName: 'བོད་ཡིག'
},
{
  code: 'tk',
  name: 'Turkmen',
  nativeName: 'Türkmen, Түркмен'
},
{
  code: 'tl',
  name: 'Tagalog',
  nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔'
},
{
  code: 'tn',
  name: 'Tswana',
  nativeName: 'Setswana'
},
{
  code: 'to',
  name: 'Tonga (Tonga Islands)',
  nativeName: 'faka Tonga'
},
{
  code: 'tr',
  name: 'Turkish',
  nativeName: 'Türkçe'
},
{
  code: 'ts',
  name: 'Tsonga',
  nativeName: 'Xitsonga'
},
{
  code: 'tt',
  name: 'Tatar',
  nativeName: 'татарча, tatarça, تاتارچا'
},
{
  code: 'tw',
  name: 'Twi',
  nativeName: 'Twi'
},
{
  code: 'ty',
  name: 'Tahitian',
  nativeName: 'Reo Tahiti'
},
{
  code: 'ug',
  name: 'Uighur, Uyghur',
  nativeName: 'Uyƣurqə, ئۇيغۇرچە'
},
{
  code: 'uk',
  name: 'Ukrainian',
  nativeName: 'українська'
},
{
  code: 'ur',
  name: 'Urdu',
  nativeName: 'اردو'
},
{
  code: 'uz',
  name: 'Uzbek',
  nativeName: 'zbek, Ўзбек, أۇزبېك'
},
{
  code: 've',
  name: 'Venda',
  nativeName: 'Tshivenḓa'
},
{
  code: 'vi',
  name: 'Vietnamese',
  nativeName: 'Tiếng Việt'
},
{
  code: 'vo',
  name: 'Volapük',
  nativeName: 'Volapük'
},
{
  code: 'wa',
  name: 'Walloon',
  nativeName: 'Walon'
},
{
  code: 'cy',
  name: 'Welsh',
  nativeName: 'Cymraeg'
},
{
  code: 'wo',
  name: 'Wolof',
  nativeName: 'Wollof'
},
{
  code: 'fy',
  name: 'Western Frisian',
  nativeName: 'Frysk'
},
{
  code: 'xh',
  name: 'Xhosa',
  nativeName: 'isiXhosa'
},
{
  code: 'yi',
  name: 'Yiddish',
  nativeName: 'ייִדיש'
},
{
  code: 'yo',
  name: 'Yoruba',
  nativeName: 'Yorùbá'
},
{
  code: 'za',
  name: 'Zhuang, Chuang',
  nativeName: 'Saɯ cueŋƅ, Saw cuengh'
}
]

export const CURRENCIES = [
  {
    countryCode: 'AD',
    countryName: 'Andorra',
    currencyCode: 'EUR',
    population: '84000',
    capital: 'Andorra la Vella',
    continentName: 'Europe'
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    currencyCode: 'AED',
    population: '4975593',
    capital: 'Abu Dhabi',
    continentName: 'Asia'
  },
  {
    countryCode: 'AF',
    countryName: 'Afghanistan',
    currencyCode: 'AFN',
    population: '29121286',
    capital: 'Kabul',
    continentName: 'Asia'
  },
  {
    countryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    currencyCode: 'XCD',
    population: '86754',
    capital: 'St. John\'s',
    continentName: 'North America'
  },
  {
    countryCode: 'AI',
    countryName: 'Anguilla',
    currencyCode: 'XCD',
    population: '13254',
    capital: 'The Valley',
    continentName: 'North America'
  },
  {
    countryCode: 'AL',
    countryName: 'Albania',
    currencyCode: 'ALL',
    population: '2986952',
    capital: 'Tirana',
    continentName: 'Europe'
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
    currencyCode: 'AMD',
    population: '2968000',
    capital: 'Yerevan',
    continentName: 'Asia'
  },
  {
    countryCode: 'AO',
    countryName: 'Angola',
    currencyCode: 'AOA',
    population: '13068161',
    capital: 'Luanda',
    continentName: 'Africa'
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    currencyCode: 'ARS',
    population: '41343201',
    capital: 'Buenos Aires',
    continentName: 'South America'
  },
  {
    countryCode: 'AS',
    countryName: 'American Samoa',
    currencyCode: 'USD',
    population: '57881',
    capital: 'Pago Pago',
    continentName: 'Oceania'
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    currencyCode: 'EUR',
    population: '8205000',
    capital: 'Vienna',
    continentName: 'Europe'
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    currencyCode: 'AUD',
    population: '21515754',
    capital: 'Canberra',
    continentName: 'Oceania'
  },
  {
    countryCode: 'AW',
    countryName: 'Aruba',
    currencyCode: 'AWG',
    population: '71566',
    capital: 'Oranjestad',
    continentName: 'North America'
  },
  {
    countryCode: 'AX',
    countryName: 'Åland',
    currencyCode: 'EUR',
    population: '26711',
    capital: 'Mariehamn',
    continentName: 'Europe'
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    currencyCode: 'AZN',
    population: '8303512',
    capital: 'Baku',
    continentName: 'Asia'
  },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    currencyCode: 'BAM',
    population: '4590000',
    capital: 'Sarajevo',
    continentName: 'Europe'
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados',
    currencyCode: 'BBD',
    population: '285653',
    capital: 'Bridgetown',
    continentName: 'North America'
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    currencyCode: 'BDT',
    population: '156118464',
    capital: 'Dhaka',
    continentName: 'Asia'
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    currencyCode: 'EUR',
    population: '10403000',
    capital: 'Brussels',
    continentName: 'Europe'
  },
  {
    countryCode: 'BF',
    countryName: 'Burkina Faso',
    currencyCode: 'XOF',
    population: '16241811',
    capital: 'Ouagadougou',
    continentName: 'Africa'
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    currencyCode: 'BGN',
    population: '7148785',
    capital: 'Sofia',
    continentName: 'Europe'
  },
  {
    countryCode: 'BH',
    countryName: 'Bahrain',
    currencyCode: 'BHD',
    population: '738004',
    capital: 'Manama',
    continentName: 'Asia'
  },
  {
    countryCode: 'BI',
    countryName: 'Burundi',
    currencyCode: 'BIF',
    population: '9863117',
    capital: 'Bujumbura',
    continentName: 'Africa'
  },
  {
    countryCode: 'BJ',
    countryName: 'Benin',
    currencyCode: 'XOF',
    population: '9056010',
    capital: 'Porto-Novo',
    continentName: 'Africa'
  },
  {
    countryCode: 'BL',
    countryName: 'Saint Barthélemy',
    currencyCode: 'EUR',
    population: '8450',
    capital: 'Gustavia',
    continentName: 'North America'
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda',
    currencyCode: 'BMD',
    population: '65365',
    capital: 'Hamilton',
    continentName: 'North America'
  },
  {
    countryCode: 'BN',
    countryName: 'Brunei',
    currencyCode: 'BND',
    population: '395027',
    capital: 'Bandar Seri Begawan',
    continentName: 'Asia'
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia',
    currencyCode: 'BOB',
    population: '9947418',
    capital: 'Sucre',
    continentName: 'South America'
  },
  {
    countryCode: 'BQ',
    countryName: 'Bonaire',
    currencyCode: 'USD',
    population: '18012',
    capital: 'Kralendijk',
    continentName: 'North America'
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    currencyCode: 'BRL',
    population: '201103330',
    capital: 'Brasília',
    continentName: 'South America'
  },
  {
    countryCode: 'BS',
    countryName: 'Bahamas',
    currencyCode: 'BSD',
    population: '301790',
    capital: 'Nassau',
    continentName: 'North America'
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan',
    currencyCode: 'BTN',
    population: '699847',
    capital: 'Thimphu',
    continentName: 'Asia'
  },
  {
    countryCode: 'BV',
    countryName: 'Bouvet Island',
    currencyCode: 'NOK',
    population: '0',
    capital: '',
    continentName: 'Antarctica'
  },
  {
    countryCode: 'BW',
    countryName: 'Botswana',
    currencyCode: 'BWP',
    population: '2029307',
    capital: 'Gaborone',
    continentName: 'Africa'
  },
  {
    countryCode: 'BY',
    countryName: 'Belarus',
    currencyCode: 'BYR',
    population: '9685000',
    capital: 'Minsk',
    continentName: 'Europe'
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize',
    currencyCode: 'BZD',
    population: '314522',
    capital: 'Belmopan',
    continentName: 'North America'
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    currencyCode: 'CAD',
    population: '33679000',
    capital: 'Ottawa',
    continentName: 'North America'
  },
  {
    countryCode: 'CC',
    countryName: 'Cocos [Keeling] Islands',
    currencyCode: 'AUD',
    population: '628',
    capital: 'West Island',
    continentName: 'Asia'
  },
  {
    countryCode: 'CD',
    countryName: 'Democratic Republic of the Congo',
    currencyCode: 'CDF',
    population: '70916439',
    capital: 'Kinshasa',
    continentName: 'Africa'
  },
  {
    countryCode: 'CF',
    countryName: 'Central African Republic',
    currencyCode: 'XAF',
    population: '4844927',
    capital: 'Bangui',
    continentName: 'Africa'
  },
  {
    countryCode: 'CG',
    countryName: 'Republic of the Congo',
    currencyCode: 'XAF',
    population: '3039126',
    capital: 'Brazzaville',
    continentName: 'Africa'
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    currencyCode: 'CHF',
    population: '7581000',
    capital: 'Bern',
    continentName: 'Europe'
  },
  {
    countryCode: 'CI',
    countryName: 'Ivory Coast',
    currencyCode: 'XOF',
    population: '21058798',
    capital: 'Yamoussoukro',
    continentName: 'Africa'
  },
  {
    countryCode: 'CK',
    countryName: 'Cook Islands',
    currencyCode: 'NZD',
    population: '21388',
    capital: 'Avarua',
    continentName: 'Oceania'
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    currencyCode: 'CLP',
    population: '16746491',
    capital: 'Santiago',
    continentName: 'South America'
  },
  {
    countryCode: 'CM',
    countryName: 'Cameroon',
    currencyCode: 'XAF',
    population: '19294149',
    capital: 'Yaoundé',
    continentName: 'Africa'
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    currencyCode: 'CNY',
    population: '1330044000',
    capital: 'Beijing',
    continentName: 'Asia'
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    currencyCode: 'COP',
    population: '47790000',
    capital: 'Bogotá',
    continentName: 'South America'
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    currencyCode: 'CRC',
    population: '4516220',
    capital: 'San José',
    continentName: 'North America'
  },
  {
    countryCode: 'CU',
    countryName: 'Cuba',
    currencyCode: 'CUP',
    population: '11423000',
    capital: 'Havana',
    continentName: 'North America'
  },
  {
    countryCode: 'CV',
    countryName: 'Cape Verde',
    currencyCode: 'CVE',
    population: '508659',
    capital: 'Praia',
    continentName: 'Africa'
  },
  {
    countryCode: 'CW',
    countryName: 'Curacao',
    currencyCode: 'ANG',
    population: '141766',
    capital: 'Willemstad',
    continentName: 'North America'
  },
  {
    countryCode: 'CX',
    countryName: 'Christmas Island',
    currencyCode: 'AUD',
    population: '1500',
    capital: 'Flying Fish Cove',
    continentName: 'Asia'
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    currencyCode: 'EUR',
    population: '1102677',
    capital: 'Nicosia',
    continentName: 'Europe'
  },
  {
    countryCode: 'CZ',
    countryName: 'Czechia',
    currencyCode: 'CZK',
    population: '10476000',
    capital: 'Prague',
    continentName: 'Europe'
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    currencyCode: 'EUR',
    population: '81802257',
    capital: 'Berlin',
    continentName: 'Europe'
  },
  {
    countryCode: 'DJ',
    countryName: 'Djibouti',
    currencyCode: 'DJF',
    population: '740528',
    capital: 'Djibouti',
    continentName: 'Africa'
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    currencyCode: 'DKK',
    population: '5484000',
    capital: 'Copenhagen',
    continentName: 'Europe'
  },
  {
    countryCode: 'DM',
    countryName: 'Dominica',
    currencyCode: 'XCD',
    population: '72813',
    capital: 'Roseau',
    continentName: 'North America'
  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    currencyCode: 'DOP',
    population: '9823821',
    capital: 'Santo Domingo',
    continentName: 'North America'
  },
  {
    countryCode: 'DZ',
    countryName: 'Algeria',
    currencyCode: 'DZD',
    population: '34586184',
    capital: 'Algiers',
    continentName: 'Africa'
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    currencyCode: 'USD',
    population: '14790608',
    capital: 'Quito',
    continentName: 'South America'
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    currencyCode: 'EUR',
    population: '1291170',
    capital: 'Tallinn',
    continentName: 'Europe'
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    currencyCode: 'EGP',
    population: '80471869',
    capital: 'Cairo',
    continentName: 'Africa'
  },
  {
    countryCode: 'EH',
    countryName: 'Western Sahara',
    currencyCode: 'MAD',
    population: '273008',
    capital: 'Laâyoune / El Aaiún',
    continentName: 'Africa'
  },
  {
    countryCode: 'ER',
    countryName: 'Eritrea',
    currencyCode: 'ERN',
    population: '5792984',
    capital: 'Asmara',
    continentName: 'Africa'
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    currencyCode: 'EUR',
    population: '46505963',
    capital: 'Madrid',
    continentName: 'Europe'
  },
  {
    countryCode: 'ET',
    countryName: 'Ethiopia',
    currencyCode: 'ETB',
    population: '88013491',
    capital: 'Addis Ababa',
    continentName: 'Africa'
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    currencyCode: 'EUR',
    population: '5244000',
    capital: 'Helsinki',
    continentName: 'Europe'
  },
  {
    countryCode: 'FJ',
    countryName: 'Fiji',
    currencyCode: 'FJD',
    population: '875983',
    capital: 'Suva',
    continentName: 'Oceania'
  },
  {
    countryCode: 'FK',
    countryName: 'Falkland Islands',
    currencyCode: 'FKP',
    population: '2638',
    capital: 'Stanley',
    continentName: 'South America'
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia',
    currencyCode: 'USD',
    population: '107708',
    capital: 'Palikir',
    continentName: 'Oceania'
  },
  {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    currencyCode: 'DKK',
    population: '48228',
    capital: 'Tórshavn',
    continentName: 'Europe'
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    currencyCode: 'EUR',
    population: '64768389',
    capital: 'Paris',
    continentName: 'Europe'
  },
  {
    countryCode: 'GA',
    countryName: 'Gabon',
    currencyCode: 'XAF',
    population: '1545255',
    capital: 'Libreville',
    continentName: 'Africa'
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    currencyCode: 'GBP',
    population: '62348447',
    capital: 'London',
    continentName: 'Europe'
  },
  {
    countryCode: 'GD',
    countryName: 'Grenada',
    currencyCode: 'XCD',
    population: '107818',
    capital: 'St. George\'s',
    continentName: 'North America'
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    currencyCode: 'GEL',
    population: '4630000',
    capital: 'Tbilisi',
    continentName: 'Asia'
  },
  {
    countryCode: 'GF',
    countryName: 'French Guiana',
    currencyCode: 'EUR',
    population: '195506',
    capital: 'Cayenne',
    continentName: 'South America'
  },
  {
    countryCode: 'GG',
    countryName: 'Guernsey',
    currencyCode: 'GBP',
    population: '65228',
    capital: 'St Peter Port',
    continentName: 'Europe'
  },
  {
    countryCode: 'GH',
    countryName: 'Ghana',
    currencyCode: 'GHS',
    population: '24339838',
    capital: 'Accra',
    continentName: 'Africa'
  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    currencyCode: 'GIP',
    population: '27884',
    capital: 'Gibraltar',
    continentName: 'Europe'
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    currencyCode: 'DKK',
    population: '56375',
    capital: 'Nuuk',
    continentName: 'North America'
  },
  {
    countryCode: 'GM',
    countryName: 'Gambia',
    currencyCode: 'GMD',
    population: '1593256',
    capital: 'Bathurst',
    continentName: 'Africa'
  },
  {
    countryCode: 'GN',
    countryName: 'Guinea',
    currencyCode: 'GNF',
    population: '10324025',
    capital: 'Conakry',
    continentName: 'Africa'
  },
  {
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    currencyCode: 'EUR',
    population: '443000',
    capital: 'Basse-Terre',
    continentName: 'North America'
  },
  {
    countryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    currencyCode: 'XAF',
    population: '1014999',
    capital: 'Malabo',
    continentName: 'Africa'
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    currencyCode: 'EUR',
    population: '11000000',
    capital: 'Athens',
    continentName: 'Europe'
  },
  {
    countryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Islands',
    currencyCode: 'GBP',
    population: '30',
    capital: 'Grytviken',
    continentName: 'Antarctica'
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
    currencyCode: 'GTQ',
    population: '13550440',
    capital: 'Guatemala City',
    continentName: 'North America'
  },
  {
    countryCode: 'GU',
    countryName: 'Guam',
    currencyCode: 'USD',
    population: '159358',
    capital: 'Hagåtña',
    continentName: 'Oceania'
  },
  {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    currencyCode: 'XOF',
    population: '1565126',
    capital: 'Bissau',
    continentName: 'Africa'
  },
  {
    countryCode: 'GY',
    countryName: 'Guyana',
    currencyCode: 'GYD',
    population: '748486',
    capital: 'Georgetown',
    continentName: 'South America'
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    currencyCode: 'HKD',
    population: '6898686',
    capital: 'Hong Kong',
    continentName: 'Asia'
  },
  {
    countryCode: 'HM',
    countryName: 'Heard Island and McDonald Islands',
    currencyCode: 'AUD',
    population: '0',
    capital: '',
    continentName: 'Antarctica'
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
    currencyCode: 'HNL',
    population: '7989415',
    capital: 'Tegucigalpa',
    continentName: 'North America'
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    currencyCode: 'HRK',
    population: '4284889',
    capital: 'Zagreb',
    continentName: 'Europe'
  },
  {
    countryCode: 'HT',
    countryName: 'Haiti',
    currencyCode: 'HTG',
    population: '9648924',
    capital: 'Port-au-Prince',
    continentName: 'North America'
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    currencyCode: 'HUF',
    population: '9982000',
    capital: 'Budapest',
    continentName: 'Europe'
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    currencyCode: 'IDR',
    population: '242968342',
    capital: 'Jakarta',
    continentName: 'Asia'
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    currencyCode: 'EUR',
    population: '4622917',
    capital: 'Dublin',
    continentName: 'Europe'
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    currencyCode: 'ILS',
    population: '7353985',
    capital: '',
    continentName: 'Asia'
  },
  {
    countryCode: 'IM',
    countryName: 'Isle of Man',
    currencyCode: 'GBP',
    population: '75049',
    capital: 'Douglas',
    continentName: 'Europe'
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    currencyCode: 'INR',
    population: '1173108018',
    capital: 'New Delhi',
    continentName: 'Asia'
  },
  {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    currencyCode: 'USD',
    population: '4000',
    capital: '',
    continentName: 'Asia'
  },
  {
    countryCode: 'IQ',
    countryName: 'Iraq',
    currencyCode: 'IQD',
    population: '29671605',
    capital: 'Baghdad',
    continentName: 'Asia'
  },
  {
    countryCode: 'IR',
    countryName: 'Iran',
    currencyCode: 'IRR',
    population: '76923300',
    capital: 'Tehran',
    continentName: 'Asia'
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    currencyCode: 'ISK',
    population: '308910',
    capital: 'Reykjavik',
    continentName: 'Europe'
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    currencyCode: 'EUR',
    population: '60340328',
    capital: 'Rome',
    continentName: 'Europe'
  },
  {
    countryCode: 'JE',
    countryName: 'Jersey',
    currencyCode: 'GBP',
    population: '90812',
    capital: 'Saint Helier',
    continentName: 'Europe'
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaica',
    currencyCode: 'JMD',
    population: '2847232',
    capital: 'Kingston',
    continentName: 'North America'
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
    currencyCode: 'JOD',
    population: '6407085',
    capital: 'Amman',
    continentName: 'Asia'
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    currencyCode: 'JPY',
    population: '127288000',
    capital: 'Tokyo',
    continentName: 'Asia'
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya',
    currencyCode: 'KES',
    population: '40046566',
    capital: 'Nairobi',
    continentName: 'Africa'
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    currencyCode: 'KGS',
    population: '5776500',
    capital: 'Bishkek',
    continentName: 'Asia'
  },
  {
    countryCode: 'KH',
    countryName: 'Cambodia',
    currencyCode: 'KHR',
    population: '14453680',
    capital: 'Phnom Penh',
    continentName: 'Asia'
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    currencyCode: 'AUD',
    population: '92533',
    capital: 'Tarawa',
    continentName: 'Oceania'
  },
  {
    countryCode: 'KM',
    countryName: 'Comoros',
    currencyCode: 'KMF',
    population: '773407',
    capital: 'Moroni',
    continentName: 'Africa'
  },
  {
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    currencyCode: 'XCD',
    population: '51134',
    capital: 'Basseterre',
    continentName: 'North America'
  },
  {
    countryCode: 'KP',
    countryName: 'North Korea',
    currencyCode: 'KPW',
    population: '22912177',
    capital: 'Pyongyang',
    continentName: 'Asia'
  },
  {
    countryCode: 'KR',
    countryName: 'South Korea',
    currencyCode: 'KRW',
    population: '48422644',
    capital: 'Seoul',
    continentName: 'Asia'
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    currencyCode: 'KWD',
    population: '2789132',
    capital: 'Kuwait City',
    continentName: 'Asia'
  },
  {
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    currencyCode: 'KYD',
    population: '44270',
    capital: 'George Town',
    continentName: 'North America'
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    currencyCode: 'KZT',
    population: '15340000',
    capital: 'Astana',
    continentName: 'Asia'
  },
  {
    countryCode: 'LA',
    countryName: 'Laos',
    currencyCode: 'LAK',
    population: '6368162',
    capital: 'Vientiane',
    continentName: 'Asia'
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
    currencyCode: 'LBP',
    population: '4125247',
    capital: 'Beirut',
    continentName: 'Asia'
  },
  {
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    currencyCode: 'XCD',
    population: '160922',
    capital: 'Castries',
    continentName: 'North America'
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    currencyCode: 'CHF',
    population: '35000',
    capital: 'Vaduz',
    continentName: 'Europe'
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    currencyCode: 'LKR',
    population: '21513990',
    capital: 'Colombo',
    continentName: 'Asia'
  },
  {
    countryCode: 'LR',
    countryName: 'Liberia',
    currencyCode: 'LRD',
    population: '3685076',
    capital: 'Monrovia',
    continentName: 'Africa'
  },
  {
    countryCode: 'LS',
    countryName: 'Lesotho',
    currencyCode: 'LSL',
    population: '1919552',
    capital: 'Maseru',
    continentName: 'Africa'
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    currencyCode: 'EUR',
    population: '2944459',
    capital: 'Vilnius',
    continentName: 'Europe'
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    currencyCode: 'EUR',
    population: '497538',
    capital: 'Luxembourg',
    continentName: 'Europe'
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    currencyCode: 'EUR',
    population: '2217969',
    capital: 'Riga',
    continentName: 'Europe'
  },
  {
    countryCode: 'LY',
    countryName: 'Libya',
    currencyCode: 'LYD',
    population: '6461454',
    capital: 'Tripoli',
    continentName: 'Africa'
  },
  {
    countryCode: 'MA',
    countryName: 'Morocco',
    currencyCode: 'MAD',
    population: '33848242',
    capital: 'Rabat',
    continentName: 'Africa'
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
    currencyCode: 'EUR',
    population: '32965',
    capital: 'Monaco',
    continentName: 'Europe'
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova',
    currencyCode: 'MDL',
    population: '4324000',
    capital: 'Chişinău',
    continentName: 'Europe'
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    currencyCode: 'EUR',
    population: '666730',
    capital: 'Podgorica',
    continentName: 'Europe'
  },
  {
    countryCode: 'MF',
    countryName: 'Saint Martin',
    currencyCode: 'EUR',
    population: '35925',
    capital: 'Marigot',
    continentName: 'North America'
  },
  {
    countryCode: 'MG',
    countryName: 'Madagascar',
    currencyCode: 'MGA',
    population: '21281844',
    capital: 'Antananarivo',
    continentName: 'Africa'
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    currencyCode: 'USD',
    population: '65859',
    capital: 'Majuro',
    continentName: 'Oceania'
  },
  {
    countryCode: 'MK',
    countryName: 'Macedonia',
    currencyCode: 'MKD',
    population: '2062294',
    capital: 'Skopje',
    continentName: 'Europe'
  },
  {
    countryCode: 'ML',
    countryName: 'Mali',
    currencyCode: 'XOF',
    population: '13796354',
    capital: 'Bamako',
    continentName: 'Africa'
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar [Burma]',
    currencyCode: 'MMK',
    population: '53414374',
    capital: 'Naypyitaw',
    continentName: 'Asia'
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    currencyCode: 'MNT',
    population: '3086918',
    capital: 'Ulan Bator',
    continentName: 'Asia'
  },
  {
    countryCode: 'MO',
    countryName: 'Macao',
    currencyCode: 'MOP',
    population: '449198',
    capital: 'Macao',
    continentName: 'Asia'
  },
  {
    countryCode: 'MP',
    countryName: 'Northern Mariana Islands',
    currencyCode: 'USD',
    population: '53883',
    capital: 'Saipan',
    continentName: 'Oceania'
  },
  {
    countryCode: 'MQ',
    countryName: 'Martinique',
    currencyCode: 'EUR',
    population: '432900',
    capital: 'Fort-de-France',
    continentName: 'North America'
  },
  {
    countryCode: 'MR',
    countryName: 'Mauritania',
    currencyCode: 'MRO',
    population: '3205060',
    capital: 'Nouakchott',
    continentName: 'Africa'
  },
  {
    countryCode: 'MS',
    countryName: 'Montserrat',
    currencyCode: 'XCD',
    population: '9341',
    capital: 'Plymouth',
    continentName: 'North America'
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    currencyCode: 'EUR',
    population: '403000',
    capital: 'Valletta',
    continentName: 'Europe'
  },
  {
    countryCode: 'MU',
    countryName: 'Mauritius',
    currencyCode: 'MUR',
    population: '1294104',
    capital: 'Port Louis',
    continentName: 'Africa'
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
    currencyCode: 'MVR',
    population: '395650',
    capital: 'Malé',
    continentName: 'Asia'
  },
  {
    countryCode: 'MW',
    countryName: 'Malawi',
    currencyCode: 'MWK',
    population: '15447500',
    capital: 'Lilongwe',
    continentName: 'Africa'
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    currencyCode: 'MXN',
    population: '112468855',
    capital: 'Mexico City',
    continentName: 'North America'
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    currencyCode: 'MYR',
    population: '28274729',
    capital: 'Kuala Lumpur',
    continentName: 'Asia'
  },
  {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    currencyCode: 'MZN',
    population: '22061451',
    capital: 'Maputo',
    continentName: 'Africa'
  },
  {
    countryCode: 'NA',
    countryName: 'Namibia',
    currencyCode: 'NAD',
    population: '2128471',
    capital: 'Windhoek',
    continentName: 'Africa'
  },
  {
    countryCode: 'NC',
    countryName: 'New Caledonia',
    currencyCode: 'XPF',
    population: '216494',
    capital: 'Noumea',
    continentName: 'Oceania'
  },
  {
    countryCode: 'NE',
    countryName: 'Niger',
    currencyCode: 'XOF',
    population: '15878271',
    capital: 'Niamey',
    continentName: 'Africa'
  },
  {
    countryCode: 'NF',
    countryName: 'Norfolk Island',
    currencyCode: 'AUD',
    population: '1828',
    capital: 'Kingston',
    continentName: 'Oceania'
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
    currencyCode: 'NGN',
    population: '154000000',
    capital: 'Abuja',
    continentName: 'Africa'
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    currencyCode: 'NIO',
    population: '5995928',
    capital: 'Managua',
    continentName: 'North America'
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    currencyCode: 'EUR',
    population: '16645000',
    capital: 'Amsterdam',
    continentName: 'Europe'
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    currencyCode: 'NOK',
    population: '5009150',
    capital: 'Oslo',
    continentName: 'Europe'
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
    currencyCode: 'NPR',
    population: '28951852',
    capital: 'Kathmandu',
    continentName: 'Asia'
  },
  {
    countryCode: 'NR',
    countryName: 'Nauru',
    currencyCode: 'AUD',
    population: '10065',
    capital: 'Yaren',
    continentName: 'Oceania'
  },
  {
    countryCode: 'NU',
    countryName: 'Niue',
    currencyCode: 'NZD',
    population: '2166',
    capital: 'Alofi',
    continentName: 'Oceania'
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    currencyCode: 'NZD',
    population: '4252277',
    capital: 'Wellington',
    continentName: 'Oceania'
  },
  {
    countryCode: 'OM',
    countryName: 'Oman',
    currencyCode: 'OMR',
    population: '2967717',
    capital: 'Muscat',
    continentName: 'Asia'
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
    currencyCode: 'PAB',
    population: '3410676',
    capital: 'Panama City',
    continentName: 'North America'
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    currencyCode: 'PEN',
    population: '29907003',
    capital: 'Lima',
    continentName: 'South America'
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    currencyCode: 'XPF',
    population: '270485',
    capital: 'Papeete',
    continentName: 'Oceania'
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    currencyCode: 'PGK',
    population: '6064515',
    capital: 'Port Moresby',
    continentName: 'Oceania'
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    currencyCode: 'PHP',
    population: '99900177',
    capital: 'Manila',
    continentName: 'Asia'
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
    currencyCode: 'PKR',
    population: '184404791',
    capital: 'Islamabad',
    continentName: 'Asia'
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    currencyCode: 'PLN',
    population: '38500000',
    capital: 'Warsaw',
    continentName: 'Europe'
  },
  {
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    currencyCode: 'EUR',
    population: '7012',
    capital: 'Saint-Pierre',
    continentName: 'North America'
  },
  {
    countryCode: 'PN',
    countryName: 'Pitcairn Islands',
    currencyCode: 'NZD',
    population: '46',
    capital: 'Adamstown',
    continentName: 'Oceania'
  },
  {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    currencyCode: 'USD',
    population: '3916632',
    capital: 'San Juan',
    continentName: 'North America'
  },
  {
    countryCode: 'PS',
    countryName: 'Palestine',
    currencyCode: 'ILS',
    population: '3800000',
    capital: '',
    continentName: 'Asia'
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    currencyCode: 'EUR',
    population: '10676000',
    capital: 'Lisbon',
    continentName: 'Europe'
  },
  {
    countryCode: 'PW',
    countryName: 'Palau',
    currencyCode: 'USD',
    population: '19907',
    capital: 'Melekeok',
    continentName: 'Oceania'
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
    currencyCode: 'PYG',
    population: '6375830',
    capital: 'Asunción',
    continentName: 'South America'
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    currencyCode: 'QAR',
    population: '840926',
    capital: 'Doha',
    continentName: 'Asia'
  },
  {
    countryCode: 'RE',
    countryName: 'Réunion',
    currencyCode: 'EUR',
    population: '776948',
    capital: 'Saint-Denis',
    continentName: 'Africa'
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    currencyCode: 'RON',
    population: '21959278',
    capital: 'Bucharest',
    continentName: 'Europe'
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    currencyCode: 'RSD',
    population: '7344847',
    capital: 'Belgrade',
    continentName: 'Europe'
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    currencyCode: 'RUB',
    population: '140702000',
    capital: 'Moscow',
    continentName: 'Europe'
  },
  {
    countryCode: 'RW',
    countryName: 'Rwanda',
    currencyCode: 'RWF',
    population: '11055976',
    capital: 'Kigali',
    continentName: 'Africa'
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    currencyCode: 'SAR',
    population: '25731776',
    capital: 'Riyadh',
    continentName: 'Asia'
  },
  {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    currencyCode: 'SBD',
    population: '559198',
    capital: 'Honiara',
    continentName: 'Oceania'
  },
  {
    countryCode: 'SC',
    countryName: 'Seychelles',
    currencyCode: 'SCR',
    population: '88340',
    capital: 'Victoria',
    continentName: 'Africa'
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan',
    currencyCode: 'SDG',
    population: '35000000',
    capital: 'Khartoum',
    continentName: 'Africa'
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    currencyCode: 'SEK',
    population: '9828655',
    capital: 'Stockholm',
    continentName: 'Europe'
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    currencyCode: 'SGD',
    population: '4701069',
    capital: 'Singapore',
    continentName: 'Asia'
  },
  {
    countryCode: 'SH',
    countryName: 'Saint Helena',
    currencyCode: 'SHP',
    population: '7460',
    capital: 'Jamestown',
    continentName: 'Africa'
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    currencyCode: 'EUR',
    population: '2007000',
    capital: 'Ljubljana',
    continentName: 'Europe'
  },
  {
    countryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen',
    currencyCode: 'NOK',
    population: '2550',
    capital: 'Longyearbyen',
    continentName: 'Europe'
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    currencyCode: 'EUR',
    population: '5455000',
    capital: 'Bratislava',
    continentName: 'Europe'
  },
  {
    countryCode: 'SL',
    countryName: 'Sierra Leone',
    currencyCode: 'SLL',
    population: '5245695',
    capital: 'Freetown',
    continentName: 'Africa'
  },
  {
    countryCode: 'SM',
    countryName: 'San Marino',
    currencyCode: 'EUR',
    population: '31477',
    capital: 'San Marino',
    continentName: 'Europe'
  },
  {
    countryCode: 'SN',
    countryName: 'Senegal',
    currencyCode: 'XOF',
    population: '12323252',
    capital: 'Dakar',
    continentName: 'Africa'
  },
  {
    countryCode: 'SO',
    countryName: 'Somalia',
    currencyCode: 'SOS',
    population: '10112453',
    capital: 'Mogadishu',
    continentName: 'Africa'
  },
  {
    countryCode: 'SR',
    countryName: 'Suriname',
    currencyCode: 'SRD',
    population: '492829',
    capital: 'Paramaribo',
    continentName: 'South America'
  },
  {
    countryCode: 'SS',
    countryName: 'South Sudan',
    currencyCode: 'SSP',
    population: '8260490',
    capital: 'Juba',
    continentName: 'Africa'
  },
  {
    countryCode: 'ST',
    countryName: 'São Tomé and Príncipe',
    currencyCode: 'STD',
    population: '175808',
    capital: 'São Tomé',
    continentName: 'Africa'
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
    currencyCode: 'USD',
    population: '6052064',
    capital: 'San Salvador',
    continentName: 'North America'
  },
  {
    countryCode: 'SX',
    countryName: 'Sint Maarten',
    currencyCode: 'ANG',
    population: '37429',
    capital: 'Philipsburg',
    continentName: 'North America'
  },
  {
    countryCode: 'SY',
    countryName: 'Syria',
    currencyCode: 'SYP',
    population: '22198110',
    capital: 'Damascus',
    continentName: 'Asia'
  },
  {
    countryCode: 'SZ',
    countryName: 'Swaziland',
    currencyCode: 'SZL',
    population: '1354051',
    capital: 'Mbabane',
    continentName: 'Africa'
  },
  {
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    currencyCode: 'USD',
    population: '20556',
    capital: 'Cockburn Town',
    continentName: 'North America'
  },
  {
    countryCode: 'TD',
    countryName: 'Chad',
    currencyCode: 'XAF',
    population: '10543464',
    capital: 'N\'Djamena',
    continentName: 'Africa'
  },
  {
    countryCode: 'TF',
    countryName: 'French Southern Territories',
    currencyCode: 'EUR',
    population: '140',
    capital: 'Port-aux-Français',
    continentName: 'Antarctica'
  },
  {
    countryCode: 'TG',
    countryName: 'Togo',
    currencyCode: 'XOF',
    population: '6587239',
    capital: 'Lomé',
    continentName: 'Africa'
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    currencyCode: 'THB',
    population: '67089500',
    capital: 'Bangkok',
    continentName: 'Asia'
  },
  {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    currencyCode: 'TJS',
    population: '7487489',
    capital: 'Dushanbe',
    continentName: 'Asia'
  },
  {
    countryCode: 'TK',
    countryName: 'Tokelau',
    currencyCode: 'NZD',
    population: '1466',
    capital: '',
    continentName: 'Oceania'
  },
  {
    countryCode: 'TL',
    countryName: 'East Timor',
    currencyCode: 'USD',
    population: '1154625',
    capital: 'Dili',
    continentName: 'Oceania'
  },
  {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    currencyCode: 'TMT',
    population: '4940916',
    capital: 'Ashgabat',
    continentName: 'Asia'
  },
  {
    countryCode: 'TN',
    countryName: 'Tunisia',
    currencyCode: 'TND',
    population: '10589025',
    capital: 'Tunis',
    continentName: 'Africa'
  },
  {
    countryCode: 'TO',
    countryName: 'Tonga',
    currencyCode: 'TOP',
    population: '122580',
    capital: 'Nuku\'alofa',
    continentName: 'Oceania'
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    currencyCode: 'TRY',
    population: '77804122',
    capital: 'Ankara',
    continentName: 'Asia'
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    currencyCode: 'TTD',
    population: '1228691',
    capital: 'Port of Spain',
    continentName: 'North America'
  },
  {
    countryCode: 'TV',
    countryName: 'Tuvalu',
    currencyCode: 'AUD',
    population: '10472',
    capital: 'Funafuti',
    continentName: 'Oceania'
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan',
    currencyCode: 'TWD',
    population: '22894384',
    capital: 'Taipei',
    continentName: 'Asia'
  },
  {
    countryCode: 'TZ',
    countryName: 'Tanzania',
    currencyCode: 'TZS',
    population: '41892895',
    capital: 'Dodoma',
    continentName: 'Africa'
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    currencyCode: 'UAH',
    population: '45415596',
    capital: 'Kiev',
    continentName: 'Europe'
  },
  {
    countryCode: 'UG',
    countryName: 'Uganda',
    currencyCode: 'UGX',
    population: '33398682',
    capital: 'Kampala',
    continentName: 'Africa'
  },
  {
    countryCode: 'UM',
    countryName: 'U.S. Minor Outlying Islands',
    currencyCode: 'USD',
    population: '0',
    capital: '',
    continentName: 'Oceania'
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    currencyCode: 'USD',
    population: '310232863',
    capital: 'Washington',
    continentName: 'North America'
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay',
    currencyCode: 'UYU',
    population: '3477000',
    capital: 'Montevideo',
    continentName: 'South America'
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    currencyCode: 'UZS',
    population: '27865738',
    capital: 'Tashkent',
    continentName: 'Asia'
  },
  {
    countryCode: 'VA',
    countryName: 'Vatican City',
    currencyCode: 'EUR',
    population: '921',
    capital: 'Vatican City',
    continentName: 'Europe'
  },
  {
    countryCode: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    currencyCode: 'XCD',
    population: '104217',
    capital: 'Kingstown',
    continentName: 'North America'
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela',
    currencyCode: 'VEF',
    population: '27223228',
    capital: 'Caracas',
    continentName: 'South America'
  },
  {
    countryCode: 'VG',
    countryName: 'British Virgin Islands',
    currencyCode: 'USD',
    population: '21730',
    capital: 'Road Town',
    continentName: 'North America'
  },
  {
    countryCode: 'VI',
    countryName: 'U.S. Virgin Islands',
    currencyCode: 'USD',
    population: '108708',
    capital: 'Charlotte Amalie',
    continentName: 'North America'
  },
  {
    countryCode: 'VN',
    countryName: 'Vietnam',
    currencyCode: 'VND',
    population: '89571130',
    capital: 'Hanoi',
    continentName: 'Asia'
  },
  {
    countryCode: 'VU',
    countryName: 'Vanuatu',
    currencyCode: 'VUV',
    population: '221552',
    capital: 'Port Vila',
    continentName: 'Oceania'
  },
  {
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
    currencyCode: 'XPF',
    population: '16025',
    capital: 'Mata-Utu',
    continentName: 'Oceania'
  },
  {
    countryCode: 'WS',
    countryName: 'Samoa',
    currencyCode: 'WST',
    population: '192001',
    capital: 'Apia',
    continentName: 'Oceania'
  },
  {
    countryCode: 'XK',
    countryName: 'Kosovo',
    currencyCode: 'EUR',
    population: '1800000',
    capital: 'Pristina',
    continentName: 'Europe'
  },
  {
    countryCode: 'YE',
    countryName: 'Yemen',
    currencyCode: 'YER',
    population: '23495361',
    capital: 'Sanaa',
    continentName: 'Asia'
  },
  {
    countryCode: 'YT',
    countryName: 'Mayotte',
    currencyCode: 'EUR',
    population: '159042',
    capital: 'Mamoudzou',
    continentName: 'Africa'
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    currencyCode: 'ZAR',
    population: '49000000',
    capital: 'Pretoria',
    continentName: 'Africa'
  },
  {
    countryCode: 'ZM',
    countryName: 'Zambia',
    currencyCode: 'ZMW',
    population: '13460305',
    capital: 'Lusaka',
    continentName: 'Africa'
  },
  {
    countryCode: 'ZW',
    countryName: 'Zimbabwe',
    currencyCode: 'ZWL',
    population: '13061000',
    capital: 'Harare',
    continentName: 'Africa'
  }
]

export const LIST_CURRENCIES = Object.values({
  USD: {
    symbol: '$',
    name: 'US Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'USD',
    name_plural: 'US dollars'
  },
  CAD: {
    symbol: 'CA$',
    name: 'Canadian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'CAD',
    name_plural: 'Canadian dollars'
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    code: 'EUR',
    name_plural: 'euros'
  },
  AED: {
    symbol: 'AED',
    name: 'United Arab Emirates Dirham',
    symbol_native: 'د.إ.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'AED',
    name_plural: 'UAE dirhams'
  },
  AFN: {
    symbol: 'Af',
    name: 'Afghan Afghani',
    symbol_native: '؋',
    decimal_digits: 0,
    rounding: 0,
    code: 'AFN',
    name_plural: 'Afghan Afghanis'
  },
  ALL: {
    symbol: 'ALL',
    name: 'Albanian Lek',
    symbol_native: 'Lek',
    decimal_digits: 0,
    rounding: 0,
    code: 'ALL',
    name_plural: 'Albanian lekë'
  },
  AMD: {
    symbol: 'AMD',
    name: 'Armenian Dram',
    symbol_native: 'դր.',
    decimal_digits: 0,
    rounding: 0,
    code: 'AMD',
    name_plural: 'Armenian drams'
  },
  ARS: {
    symbol: 'AR$',
    name: 'Argentine Peso',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'ARS',
    name_plural: 'Argentine pesos'
  },
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'AUD',
    name_plural: 'Australian dollars'
  },
  AZN: {
    symbol: 'man.',
    name: 'Azerbaijani Manat',
    symbol_native: 'ман.',
    decimal_digits: 2,
    rounding: 0,
    code: 'AZN',
    name_plural: 'Azerbaijani manats'
  },
  BAM: {
    symbol: 'KM',
    name: 'Bosnia-Herzegovina Convertible Mark',
    symbol_native: 'KM',
    decimal_digits: 2,
    rounding: 0,
    code: 'BAM',
    name_plural: 'Bosnia-Herzegovina convertible marks'
  },
  BDT: {
    symbol: 'Tk',
    name: 'Bangladeshi Taka',
    symbol_native: '৳',
    decimal_digits: 2,
    rounding: 0,
    code: 'BDT',
    name_plural: 'Bangladeshi takas'
  },
  BGN: {
    symbol: 'BGN',
    name: 'Bulgarian Lev',
    symbol_native: 'лв.',
    decimal_digits: 2,
    rounding: 0,
    code: 'BGN',
    name_plural: 'Bulgarian leva'
  },
  BHD: {
    symbol: 'BD',
    name: 'Bahraini Dinar',
    decimal_digits: 3,
    rounding: 0,
    code: 'BHD',
    name_plural: 'Bahraini dinars'
  },
  BIF: {
    symbol: 'FBu',
    name: 'Burundian Franc',
    symbol_native: 'FBu',
    decimal_digits: 0,
    rounding: 0,
    code: 'BIF',
    name_plural: 'Burundian francs'
  },
  BND: {
    symbol: 'BN$',
    name: 'Brunei Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'BND',
    name_plural: 'Brunei dollars'
  },
  BOB: {
    symbol: 'Bs',
    name: 'Bolivian Boliviano',
    symbol_native: 'Bs',
    decimal_digits: 2,
    rounding: 0,
    code: 'BOB',
    name_plural: 'Bolivian bolivianos'
  },
  BRL: {
    symbol: 'R$',
    name: 'Brazilian Real',
    symbol_native: 'R$',
    decimal_digits: 2,
    rounding: 0,
    code: 'BRL',
    name_plural: 'Brazilian reals'
  },
  BWP: {
    symbol: 'BWP',
    name: 'Botswanan Pula',
    symbol_native: 'P',
    decimal_digits: 2,
    rounding: 0,
    code: 'BWP',
    name_plural: 'Botswanan pulas'
  },
  BYN: {
    symbol: 'Br',
    name: 'Belarusian Ruble',
    symbol_native: 'руб.',
    decimal_digits: 2,
    rounding: 0,
    code: 'BYN',
    name_plural: 'Belarusian rubles'
  },
  BZD: {
    symbol: 'BZ$',
    name: 'Belize Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'BZD',
    name_plural: 'Belize dollars'
  },
  CDF: {
    symbol: 'CDF',
    name: 'Congolese Franc',
    symbol_native: 'FrCD',
    decimal_digits: 2,
    rounding: 0,
    code: 'CDF',
    name_plural: 'Congolese francs'
  },
  CHF: {
    symbol: 'CHF',
    name: 'Swiss Franc',
    symbol_native: 'CHF',
    decimal_digits: 2,
    rounding: 0.05,
    code: 'CHF',
    name_plural: 'Swiss francs'
  },
  CLP: {
    symbol: 'CL$',
    name: 'Chilean Peso',
    symbol_native: '$',
    decimal_digits: 0,
    rounding: 0,
    code: 'CLP',
    name_plural: 'Chilean pesos'
  },
  CNY: {
    symbol: 'CN¥',
    name: 'Chinese Yuan',
    symbol_native: 'CN¥',
    decimal_digits: 2,
    rounding: 0,
    code: 'CNY',
    name_plural: 'Chinese yuan'
  },
  COP: {
    symbol: 'CO$',
    name: 'Colombian Peso',
    symbol_native: '$',
    decimal_digits: 0,
    rounding: 0,
    code: 'COP',
    name_plural: 'Colombian pesos'
  },
  CRC: {
    symbol: '₡',
    name: 'Costa Rican Colón',
    symbol_native: '₡',
    decimal_digits: 0,
    rounding: 0,
    code: 'CRC',
    name_plural: 'Costa Rican colóns'
  },
  CVE: {
    symbol: 'CV$',
    name: 'Cape Verdean Escudo',
    symbol_native: 'CV$',
    decimal_digits: 2,
    rounding: 0,
    code: 'CVE',
    name_plural: 'Cape Verdean escudos'
  },
  CZK: {
    symbol: 'Kč',
    name: 'Czech Republic Koruna',
    symbol_native: 'Kč',
    decimal_digits: 2,
    rounding: 0,
    code: 'CZK',
    name_plural: 'Czech Republic korunas'
  },
  DJF: {
    symbol: 'Fdj',
    name: 'Djiboutian Franc',
    symbol_native: 'Fdj',
    decimal_digits: 0,
    rounding: 0,
    code: 'DJF',
    name_plural: 'Djiboutian francs'
  },
  DKK: {
    symbol: 'Dkr',
    name: 'Danish Krone',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'DKK',
    name_plural: 'Danish kroner'
  },
  DOP: {
    symbol: 'RD$',
    name: 'Dominican Peso',
    symbol_native: 'RD$',
    decimal_digits: 2,
    rounding: 0,
    code: 'DOP',
    name_plural: 'Dominican pesos'
  },
  DZD: {
    symbol: 'DA',
    name: 'Algerian Dinar',
    decimal_digits: 2,
    rounding: 0,
    code: 'DZD',
    name_plural: 'Algerian dinars'
  },
  EEK: {
    symbol: 'Ekr',
    name: 'Estonian Kroon',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'EEK',
    name_plural: 'Estonian kroons'
  },
  EGP: {
    symbol: 'EGP',
    name: 'Egyptian Pound',
    decimal_digits: 2,
    rounding: 0,
    code: 'EGP',
    name_plural: 'Egyptian pounds'
  },
  ERN: {
    symbol: 'Nfk',
    name: 'Eritrean Nakfa',
    symbol_native: 'Nfk',
    decimal_digits: 2,
    rounding: 0,
    code: 'ERN',
    name_plural: 'Eritrean nakfas'
  },
  ETB: {
    symbol: 'Br',
    name: 'Ethiopian Birr',
    symbol_native: 'Br',
    decimal_digits: 2,
    rounding: 0,
    code: 'ETB',
    name_plural: 'Ethiopian birrs'
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
    symbol_native: '£',
    decimal_digits: 2,
    rounding: 0,
    code: 'GBP',
    name_plural: 'British pounds sterling'
  },
  GEL: {
    symbol: 'GEL',
    name: 'Georgian Lari',
    symbol_native: 'GEL',
    decimal_digits: 2,
    rounding: 0,
    code: 'GEL',
    name_plural: 'Georgian laris'
  },
  GHS: {
    symbol: 'GH₵',
    name: 'Ghanaian Cedi',
    symbol_native: 'GH₵',
    decimal_digits: 2,
    rounding: 0,
    code: 'GHS',
    name_plural: 'Ghanaian cedis'
  },
  GNF: {
    symbol: 'FG',
    name: 'Guinean Franc',
    symbol_native: 'FG',
    decimal_digits: 0,
    rounding: 0,
    code: 'GNF',
    name_plural: 'Guinean francs'
  },
  GTQ: {
    symbol: 'GTQ',
    name: 'Guatemalan Quetzal',
    symbol_native: 'Q',
    decimal_digits: 2,
    rounding: 0,
    code: 'GTQ',
    name_plural: 'Guatemalan quetzals'
  },
  HKD: {
    symbol: 'HK$',
    name: 'Hong Kong Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'HKD',
    name_plural: 'Hong Kong dollars'
  },
  HNL: {
    symbol: 'HNL',
    name: 'Honduran Lempira',
    symbol_native: 'L',
    decimal_digits: 2,
    rounding: 0,
    code: 'HNL',
    name_plural: 'Honduran lempiras'
  },
  HRK: {
    symbol: 'kn',
    name: 'Croatian Kuna',
    symbol_native: 'kn',
    decimal_digits: 2,
    rounding: 0,
    code: 'HRK',
    name_plural: 'Croatian kunas'
  },
  HUF: {
    symbol: 'Ft',
    name: 'Hungarian Forint',
    symbol_native: 'Ft',
    decimal_digits: 0,
    rounding: 0,
    code: 'HUF',
    name_plural: 'Hungarian forints'
  },
  IDR: {
    symbol: 'Rp',
    name: 'Indonesian Rupiah',
    symbol_native: 'Rp',
    decimal_digits: 0,
    rounding: 0,
    code: 'IDR',
    name_plural: 'Indonesian rupiahs'
  },
  ILS: {
    symbol: '₪',
    name: 'Israeli New Sheqel',
    symbol_native: '₪',
    decimal_digits: 2,
    rounding: 0,
    code: 'ILS',
    name_plural: 'Israeli new sheqels'
  },
  INR: {
    symbol: 'Rs',
    name: 'Indian Rupee',
    symbol_native: 'টকা',
    decimal_digits: 2,
    rounding: 0,
    code: 'INR',
    name_plural: 'Indian rupees'
  },
  IQD: {
    symbol: 'IQD',
    name: 'Iraqi Dinar',
    decimal_digits: 0,
    rounding: 0,
    code: 'IQD',
    name_plural: 'Iraqi dinars'
  },
  IRR: {
    symbol: 'IRR',
    name: 'Iranian Rial',
    symbol_native: '﷼',
    decimal_digits: 0,
    rounding: 0,
    code: 'IRR',
    name_plural: 'Iranian rials'
  },
  ISK: {
    symbol: 'Ikr',
    name: 'Icelandic Króna',
    symbol_native: 'kr',
    decimal_digits: 0,
    rounding: 0,
    code: 'ISK',
    name_plural: 'Icelandic krónur'
  },
  JMD: {
    symbol: 'J$',
    name: 'Jamaican Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'JMD',
    name_plural: 'Jamaican dollars'
  },
  JOD: {
    symbol: 'JD',
    name: 'Jordanian Dinar',
    symbol_native: 'د.أ.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'JOD',
    name_plural: 'Jordanian dinars'
  },
  JPY: {
    symbol: '¥',
    name: 'Japanese Yen',
    symbol_native: '￥',
    decimal_digits: 0,
    rounding: 0,
    code: 'JPY',
    name_plural: 'Japanese yen'
  },
  KES: {
    symbol: 'Ksh',
    name: 'Kenyan Shilling',
    symbol_native: 'Ksh',
    decimal_digits: 2,
    rounding: 0,
    code: 'KES',
    name_plural: 'Kenyan shillings'
  },
  KHR: {
    symbol: 'KHR',
    name: 'Cambodian Riel',
    symbol_native: '៛',
    decimal_digits: 2,
    rounding: 0,
    code: 'KHR',
    name_plural: 'Cambodian riels'
  },
  KMF: {
    symbol: 'CF',
    name: 'Comorian Franc',
    symbol_native: 'FC',
    decimal_digits: 0,
    rounding: 0,
    code: 'KMF',
    name_plural: 'Comorian francs'
  },
  KRW: {
    symbol: '₩',
    name: 'South Korean Won',
    symbol_native: '₩',
    decimal_digits: 0,
    rounding: 0,
    code: 'KRW',
    name_plural: 'South Korean won'
  },
  KWD: {
    symbol: 'KD',
    name: 'Kuwaiti Dinar',
    symbol_native: 'د.ك.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'KWD',
    name_plural: 'Kuwaiti dinars'
  },
  KZT: {
    symbol: 'KZT',
    name: 'Kazakhstani Tenge',
    symbol_native: 'тңг.',
    decimal_digits: 2,
    rounding: 0,
    code: 'KZT',
    name_plural: 'Kazakhstani tenges'
  },
  LBP: {
    symbol: 'LB£',
    name: 'Lebanese Pound',
    symbol_native: 'ل.ل.‏',
    decimal_digits: 0,
    rounding: 0,
    code: 'LBP',
    name_plural: 'Lebanese pounds'
  },
  LKR: {
    symbol: 'SLRs',
    name: 'Sri Lankan Rupee',
    symbol_native: 'SL Re',
    decimal_digits: 2,
    rounding: 0,
    code: 'LKR',
    name_plural: 'Sri Lankan rupees'
  },
  LTL: {
    symbol: 'Lt',
    name: 'Lithuanian Litas',
    symbol_native: 'Lt',
    decimal_digits: 2,
    rounding: 0,
    code: 'LTL',
    name_plural: 'Lithuanian litai'
  },
  LVL: {
    symbol: 'Ls',
    name: 'Latvian Lats',
    symbol_native: 'Ls',
    decimal_digits: 2,
    rounding: 0,
    code: 'LVL',
    name_plural: 'Latvian lati'
  },
  LYD: {
    symbol: 'LD',
    name: 'Libyan Dinar',
    symbol_native: 'د.ل.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'LYD',
    name_plural: 'Libyan dinars'
  },
  MAD: {
    symbol: 'MAD',
    name: 'Moroccan Dirham',
    symbol_native: 'د.م.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'MAD',
    name_plural: 'Moroccan dirhams'
  },
  MDL: {
    symbol: 'MDL',
    name: 'Moldovan Leu',
    symbol_native: 'MDL',
    decimal_digits: 2,
    rounding: 0,
    code: 'MDL',
    name_plural: 'Moldovan lei'
  },
  MGA: {
    symbol: 'MGA',
    name: 'Malagasy Ariary',
    symbol_native: 'MGA',
    decimal_digits: 0,
    rounding: 0,
    code: 'MGA',
    name_plural: 'Malagasy Ariaries'
  },
  MKD: {
    symbol: 'MKD',
    name: 'Macedonian Denar',
    symbol_native: 'MKD',
    decimal_digits: 2,
    rounding: 0,
    code: 'MKD',
    name_plural: 'Macedonian denari'
  },
  MMK: {
    symbol: 'MMK',
    name: 'Myanma Kyat',
    symbol_native: 'K',
    decimal_digits: 0,
    rounding: 0,
    code: 'MMK',
    name_plural: 'Myanma kyats'
  },
  MOP: {
    symbol: 'MOP$',
    name: 'Macanese Pataca',
    symbol_native: 'MOP$',
    decimal_digits: 2,
    rounding: 0,
    code: 'MOP',
    name_plural: 'Macanese patacas'
  },
  MUR: {
    symbol: 'MURs',
    name: 'Mauritian Rupee',
    symbol_native: 'MURs',
    decimal_digits: 0,
    rounding: 0,
    code: 'MUR',
    name_plural: 'Mauritian rupees'
  },
  MXN: {
    symbol: 'MX$',
    name: 'Mexican Peso',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'MXN',
    name_plural: 'Mexican pesos'
  },
  MYR: {
    symbol: 'RM',
    name: 'Malaysian Ringgit',
    symbol_native: 'RM',
    decimal_digits: 2,
    rounding: 0,
    code: 'MYR',
    name_plural: 'Malaysian ringgits'
  },
  MZN: {
    symbol: 'MTn',
    name: 'Mozambican Metical',
    symbol_native: 'MTn',
    decimal_digits: 2,
    rounding: 0,
    code: 'MZN',
    name_plural: 'Mozambican meticals'
  },
  NAD: {
    symbol: 'N$',
    name: 'Namibian Dollar',
    symbol_native: 'N$',
    decimal_digits: 2,
    rounding: 0,
    code: 'NAD',
    name_plural: 'Namibian dollars'
  },
  NGN: {
    symbol: '₦',
    name: 'Nigerian Naira',
    symbol_native: '₦',
    decimal_digits: 2,
    rounding: 0,
    code: 'NGN',
    name_plural: 'Nigerian nairas'
  },
  NIO: {
    symbol: 'C$',
    name: 'Nicaraguan Córdoba',
    symbol_native: 'C$',
    decimal_digits: 2,
    rounding: 0,
    code: 'NIO',
    name_plural: 'Nicaraguan córdobas'
  },
  NOK: {
    symbol: 'Nkr',
    name: 'Norwegian Krone',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'NOK',
    name_plural: 'Norwegian kroner'
  },
  NPR: {
    symbol: 'NPRs',
    name: 'Nepalese Rupee',
    symbol_native: 'नेरू',
    decimal_digits: 2,
    rounding: 0,
    code: 'NPR',
    name_plural: 'Nepalese rupees'
  },
  NZD: {
    symbol: 'NZ$',
    name: 'New Zealand Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'NZD',
    name_plural: 'New Zealand dollars'
  },
  OMR: {
    symbol: 'OMR',
    name: 'Omani Rial',
    symbol_native: 'ر.ع.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'OMR',
    name_plural: 'Omani rials'
  },
  PAB: {
    symbol: 'B/.',
    name: 'Panamanian Balboa',
    symbol_native: 'B/.',
    decimal_digits: 2,
    rounding: 0,
    code: 'PAB',
    name_plural: 'Panamanian balboas'
  },
  PEN: {
    symbol: 'S/.',
    name: 'Peruvian Nuevo Sol',
    symbol_native: 'S/.',
    decimal_digits: 2,
    rounding: 0,
    code: 'PEN',
    name_plural: 'Peruvian nuevos soles'
  },
  PHP: {
    symbol: '₱',
    name: 'Philippine Peso',
    symbol_native: '₱',
    decimal_digits: 2,
    rounding: 0,
    code: 'PHP',
    name_plural: 'Philippine pesos'
  },
  PKR: {
    symbol: 'PKRs',
    name: 'Pakistani Rupee',
    symbol_native: '₨',
    decimal_digits: 0,
    rounding: 0,
    code: 'PKR',
    name_plural: 'Pakistani rupees'
  },
  PLN: {
    symbol: 'zł',
    name: 'Polish Zloty',
    symbol_native: 'zł',
    decimal_digits: 2,
    rounding: 0,
    code: 'PLN',
    name_plural: 'Polish zlotys'
  },
  PYG: {
    symbol: '₲',
    name: 'Paraguayan Guarani',
    symbol_native: '₲',
    decimal_digits: 0,
    rounding: 0,
    code: 'PYG',
    name_plural: 'Paraguayan guaranis'
  },
  QAR: {
    symbol: 'QR',
    name: 'Qatari Rial',
    symbol_native: 'ر.ق.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'QAR',
    name_plural: 'Qatari rials'
  },
  RON: {
    symbol: 'RON',
    name: 'Romanian Leu',
    symbol_native: 'RON',
    decimal_digits: 2,
    rounding: 0,
    code: 'RON',
    name_plural: 'Romanian lei'
  },
  RSD: {
    symbol: 'din.',
    name: 'Serbian Dinar',
    symbol_native: 'дин.',
    decimal_digits: 0,
    rounding: 0,
    code: 'RSD',
    name_plural: 'Serbian dinars'
  },
  RUB: {
    symbol: 'RUB',
    name: 'Russian Ruble',
    symbol_native: '₽.',
    decimal_digits: 2,
    rounding: 0,
    code: 'RUB',
    name_plural: 'Russian rubles'
  },
  RWF: {
    symbol: 'RWF',
    name: 'Rwandan Franc',
    symbol_native: 'FR',
    decimal_digits: 0,
    rounding: 0,
    code: 'RWF',
    name_plural: 'Rwandan francs'
  },
  SAR: {
    symbol: 'SR',
    name: 'Saudi Riyal',
    symbol_native: 'ر.س.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'SAR',
    name_plural: 'Saudi riyals'
  },
  SDG: {
    symbol: 'SDG',
    name: 'Sudanese Pound',
    symbol_native: 'SDG',
    decimal_digits: 2,
    rounding: 0,
    code: 'SDG',
    name_plural: 'Sudanese pounds'
  },
  SEK: {
    symbol: 'Skr',
    name: 'Swedish Krona',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'SEK',
    name_plural: 'Swedish kronor'
  },
  SGD: {
    symbol: 'S$',
    name: 'Singapore Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'SGD',
    name_plural: 'Singapore dollars'
  },
  SOS: {
    symbol: 'Ssh',
    name: 'Somali Shilling',
    symbol_native: 'Ssh',
    decimal_digits: 0,
    rounding: 0,
    code: 'SOS',
    name_plural: 'Somali shillings'
  },
  SYP: {
    symbol: 'SY£',
    name: 'Syrian Pound',
    symbol_native: 'ل.س.‏',
    decimal_digits: 0,
    rounding: 0,
    code: 'SYP',
    name_plural: 'Syrian pounds'
  },
  THB: {
    symbol: '฿',
    name: 'Thai Baht',
    symbol_native: '฿',
    decimal_digits: 2,
    rounding: 0,
    code: 'THB',
    name_plural: 'Thai baht'
  },
  TND: {
    symbol: 'DT',
    name: 'Tunisian Dinar',
    symbol_native: 'د.ت.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'TND',
    name_plural: 'Tunisian dinars'
  },
  TOP: {
    symbol: 'T$',
    name: 'Tongan Paʻanga',
    symbol_native: 'T$',
    decimal_digits: 2,
    rounding: 0,
    code: 'TOP',
    name_plural: 'Tongan paʻanga'
  },
  TRY: {
    symbol: 'TL',
    name: 'Turkish Lira',
    symbol_native: 'TL',
    decimal_digits: 2,
    rounding: 0,
    code: 'TRY',
    name_plural: 'Turkish Lira'
  },
  TTD: {
    symbol: 'TT$',
    name: 'Trinidad and Tobago Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'TTD',
    name_plural: 'Trinidad and Tobago dollars'
  },
  TWD: {
    symbol: 'NT$',
    name: 'New Taiwan Dollar',
    symbol_native: 'NT$',
    decimal_digits: 2,
    rounding: 0,
    code: 'TWD',
    name_plural: 'New Taiwan dollars'
  },
  TZS: {
    symbol: 'TSh',
    name: 'Tanzanian Shilling',
    symbol_native: 'TSh',
    decimal_digits: 0,
    rounding: 0,
    code: 'TZS',
    name_plural: 'Tanzanian shillings'
  },
  UAH: {
    symbol: '₴',
    name: 'Ukrainian Hryvnia',
    symbol_native: '₴',
    decimal_digits: 2,
    rounding: 0,
    code: 'UAH',
    name_plural: 'Ukrainian hryvnias'
  },
  UGX: {
    symbol: 'USh',
    name: 'Ugandan Shilling',
    symbol_native: 'USh',
    decimal_digits: 0,
    rounding: 0,
    code: 'UGX',
    name_plural: 'Ugandan shillings'
  },
  UYU: {
    symbol: '$U',
    name: 'Uruguayan Peso',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'UYU',
    name_plural: 'Uruguayan pesos'
  },
  UZS: {
    symbol: 'UZS',
    name: 'Uzbekistan Som',
    symbol_native: 'UZS',
    decimal_digits: 0,
    rounding: 0,
    code: 'UZS',
    name_plural: 'Uzbekistan som'
  },
  VEF: {
    symbol: 'Bs.F.',
    name: 'Venezuelan Bolívar',
    symbol_native: 'Bs.F.',
    decimal_digits: 2,
    rounding: 0,
    code: 'VEF',
    name_plural: 'Venezuelan bolívars'
  },
  VND: {
    symbol: '₫',
    name: 'Vietnamese Dong',
    symbol_native: '₫',
    decimal_digits: 0,
    rounding: 0,
    code: 'VND',
    name_plural: 'Vietnamese dong'
  },
  XAF: {
    symbol: 'FCFA',
    name: 'CFA Franc BEAC',
    symbol_native: 'FCFA',
    decimal_digits: 0,
    rounding: 0,
    code: 'XAF',
    name_plural: 'CFA francs BEAC'
  },
  XOF: {
    symbol: 'CFA',
    name: 'CFA Franc BCEAO',
    symbol_native: 'CFA',
    decimal_digits: 0,
    rounding: 0,
    code: 'XOF',
    name_plural: 'CFA francs BCEAO'
  },
  YER: {
    symbol: 'YR',
    name: 'Yemeni Rial',
    symbol_native: 'ر.ي.‏',
    decimal_digits: 0,
    rounding: 0,
    code: 'YER',
    name_plural: 'Yemeni rials'
  },
  ZAR: {
    symbol: 'R',
    name: 'South African Rand',
    symbol_native: 'R',
    decimal_digits: 2,
    rounding: 0,
    code: 'ZAR',
    name_plural: 'South African rand'
  },
  ZMK: {
    symbol: 'ZK',
    name: 'Zambian Kwacha',
    symbol_native: 'ZK',
    decimal_digits: 0,
    rounding: 0,
    code: 'ZMK',
    name_plural: 'Zambian kwachas'
  },
  ZWL: {
    symbol: 'ZWL$',
    name: 'Zimbabwean Dollar',
    symbol_native: 'ZWL$',
    decimal_digits: 0,
    rounding: 0,
    code: 'ZWL',
    name_plural: 'Zimbabwean Dollar'
  }
})

export const DATE_FORMATS = [{
  label: moment().format('MMMM DD, YYYY'),
  value: 'MMMM DD, YYYY'
},
{
  label: moment().format('MM/DD/YYYY'),
  value: 'MM/DD/YYYY'
},
{
  label: moment().format('DD/MM/YYYY'),
  value: 'DD/MM/YYYY'
},
{
  label: moment().format('DD-MM-YYYY'),
  value: 'DD-MM-YYYY'
},
{
  label: moment().format('DD.MM.YYYY'),
  value: 'DD.MM.YYYY'
},
{
  label: moment().format('YYYY-MM-DD'),
  value: 'YYYY-MM-DD'
},
{
  label: moment().format('MM/DD/YY'),
  value: 'MM/DD/YY'
},
{
  label: moment().format('DD/MM/YY'),
  value: 'DD/MM/YY'
},
{
  label: moment().format('MMM DD, YYYY'),
  value: 'MMM DD, YYYY'
}
]

export const TIME_FORMATS = [{
  label: moment().format('h:mma'),
  value: 'h:mma'
}, {
  label: moment().format('HH:mm'),
  value: 'HH:mm'
}]

export const DURANTION_FORMATS = [{
  label: '00_04_decimal',
  value: 0
}, {
  label: '00_04_without_second',
  value: 1
}, {
  label: '00_04_time_duration_NhNm',
  value: 2
}]

export const SUPPORT_LANGS = [{
  label: 'English',
  value: 'en'
}, {
  label: 'Tiếng Việt',
  value: 'vi'
}]

export const MOBILE_TYPES = [{
  label: '00_06_mobile',
  value: 0
}, {
  label: '00_06_phone',
  value: 1
}, {
  label: '00_06_fax',
  value: 2
}]

export const NAME_FORMATS = [{
  label: '00_06_firstname_lastname',
  value: 1
}, {
  label: '00_06_lastname_firstname',
  value: 2
}]
