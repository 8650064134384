import { type FMessage, type FRoom } from '@dansoft/titanplan-services'
import { Icon } from '@fluentui/react'
import moment from 'moment'
import Fslightbox from 'fslightbox-react'
import React, { useState } from 'react'
import { Avatar, Box, Typography } from '@mui/material'

interface PositionProps {
  message: FMessage
  user: any
  isMyMessage: boolean
  showDate: boolean
  currentDate?: string
  messageTime?: string
  onReaction: (message: FMessage) => void
  room: FRoom
  showNameAvatar: boolean
  onReply: (message: FMessage) => void
  onImageUploaded: (imageUrl: any) => void
}
const defaultIconName = 'heart'
const hoverIconName = 'heartFill'

export const showDate = (index: number, messages: FMessage[]): boolean => {
  const message = messages[index]
  const prevMessage = index > 0 ? messages[index - 1] : null
  if (prevMessage == null) return true
  const datePrev = moment.unix(prevMessage.createdAt).format('DD/MM/YYYY')
  const dateCurrent = moment.unix(message.createdAt).format('DD/MM/YYYY')
  if (datePrev !== dateCurrent) return true
  return false
}

export const showNameAvatar = (index: number, messages: FMessage[]): boolean => {
  const message = messages[index]
  const nextMessage = messages[index - 1] !== undefined ? messages[index - 1] : null
  if (nextMessage == null) return true
  if (nextMessage.user._id !== message.user._id) {
    return true
  }
  const dateNext = moment.unix(nextMessage.createdAt).format('DD/MM/YYYY')
  const dateCurrent = moment.unix(message.createdAt).format('DD/MM/YYYY')
  if (nextMessage.user._id === message.user._id && dateNext !== dateCurrent) {
    return true
  }
  return false
}

export default function MessengerItem ({
  message,
  user,
  onReaction,
  isMyMessage,
  showDate,
  showNameAvatar,
  onReply,
  onImageUploaded
}: PositionProps): JSX.Element {
  const isCurrentUser = message.user._id === user._id
  const boxPosition = isCurrentUser ? 'right' : 'left'
  const time = moment.unix(message.createdAt).format('hh:mm')
  const [iconName, setIconName] = useState(defaultIconName)

  const handleOnReply = (): void => {
    onReply(message)
  }
  const handleOnImageUploaded = (): void => {
    onImageUploaded(message)
  }

  const messageType = (message.image != null) || message.text
  const handleMenuItemClick = (): void => {
    if (messageType === message.image) {
      handleOnImageUploaded()
    } else if (messageType === message.text) {
      handleOnReply()
    }
  }

  const formatMessage = (message: any): JSX.Element => {
    if (typeof message === 'string' && message.includes('http')) {
      return (
        <Typography
          target="_blank"
          rel="noopener noreferrer"
          className="MessengerBox-text"
          component="a"
          href={message}
        >
          {message}
        </Typography>
      )
    } else {
      return <Typography className="MessengerBox-text">{message}</Typography>
    }
  }
  const [lightbox, setLightbox] = useState(false)
  return (
    <>
      {showDate && (
        <Box className="MessengerBox-timestamp">
          <Box className="MessengerBox-currentDate">
            {moment.unix(message.createdAt).format('DD/MM/YYYY')}
          </Box>
        </Box>
      )}
      <Box className={`MessengerBox-item MessengerBox-${boxPosition}`}>
        {!isMyMessage && showNameAvatar && (
          <Avatar
            sx={{ width: 36, height: 36 }}
            alt={message.user.avatar}
            src={message.user.avatar}
          />
        )}
        {!isMyMessage && showNameAvatar && (
          <Typography className="MessengerBox-name">
            {message.user.name}
          </Typography>
        )}
        <Box
          className={`MessengerBox-content ${
            boxPosition === 'left'
              ? 'MessengerBox--contentLeft'
              : 'MessengerBox--contentRight'
          }`}
        >
          {(message.image != null) && (
            <img
              src={message.image}
              alt="example"
              onClick={() => {
                setLightbox(!lightbox)
              }}
            />
          )}
          <Fslightbox
            toggler={lightbox}
            sources={[message.image ?? '']}
            type="image"
          />
          {formatMessage(message.text)}
          <Box className="MessengerBox-quantityHeart">
            {(message.likes != null) && message.likes?.length > 0 && (
              <>
                <Icon
                  className="MessengerBox-reactionHeart"
                  iconName={'heartFill'}
                />
                <Box className="MessengerBox-quantity">
                  {' '}
                  {message.likes?.length}
                </Box>
              </>
            )}
          </Box>
          <Typography sx={{ ml: 'auto', fontSize: '12px' }}>{time}</Typography>
          <Box
            className={`MessengerBox-reaction ${
              boxPosition === 'left'
                ? 'MessengerBox-reactionLeft'
                : 'MessengerBox-reactionRight'
            }`}
          >
            <Box
              className={`MessengerBox-heart ${
                boxPosition === 'left'
                  ? 'MessengerBox-heartLeft'
                  : 'MessengerBox-heartRight'
              }`}
              onClick={() => { onReaction(message) }}
            >
              <Icon
                className="MessengerBox-iconRed"
                iconName={iconName}
                onMouseEnter={() => { setIconName(hoverIconName) }}
                onMouseLeave={() => { setIconName(defaultIconName) }}
              />
            </Box>
            <Box className="MessengerBox-reply">
              <Icon
                className="MessengerBox-iconRed"
                onClick={() => { handleMenuItemClick() }}
                iconName="Reply"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
