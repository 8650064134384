import React from 'react'
import Modal from '../../../components/Modal/Modal'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { type ReponseListTimeSheet } from '../../../services/Swagger'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import moment from 'moment'
import { TimesheetHelper } from '../Helpers/TimesheetHelper'
import { DateTime } from '../../../services/time'
import storage from '../../../services/storage'

interface ViewTimesheetProps {
  onClose: () => void
  timesheet?: ReponseListTimeSheet
  data?: ListTimeSheet
  checkShowCost: boolean
}

export default function ViewTimesheet ({ onClose, timesheet, data, checkShowCost }: ViewTimesheetProps): JSX.Element {
  const companySetting = storage.getItem('COMPANY_INFOMATION')
  const dateFormat = companySetting?.date_format
  const timeFormat = companySetting?.time_format
  const { t } = useTranslation()
  return (
        <Modal open onClose={onClose} size="small" title={t('01_01_view')}>
            <Box sx={{ px: 4, py: 2 }}>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('17_01_clocked_in')}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{DateTime.FormatDate(timesheet?.check_in_time, 'full')}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('17_01_clocked_out')}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{timesheet?.check_out_time == null ? DateTime.FormatDate(timesheet?.check_in_time, 'date') : DateTime.FormatDate(timesheet?.check_out_time, 'full')}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('20_13_break')}</Typography>
                    </Grid>
                    <Grid item xs>
                        {timesheet?.breaks != null && timesheet.breaks.length > 0 && timesheet.breaks.map((item, index) => (
                            <Typography key={index}>{`${moment
                                .unix(item.check_in_time ?? 0)
                                .format(dateFormat)} ${moment
                                    .unix(item.check_in_time ?? 0)
                                    .format(timeFormat)} - ${moment
                                        .unix(item.check_out_time ?? 0)
                                        .format(timeFormat)} (${(item.check_out_time != null) && (item.check_in_time != null)
                                            ? moment
                                                .utc(
                                                    (item.check_out_time - item.check_in_time) *
                                                    1000
                                                )
                                                .format(timeFormat)
                                            : '-'
                                })`}</Typography>
                        ))}
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('20_24_worked_total')}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {
                                (timesheet?.check_in_time != null) &&
                                    (timesheet?.check_out_time != null)
                                  ? new TimesheetHelper().calculateTotalTimeWorked(
                                    timesheet.check_in_time,
                                    timesheet.check_out_time,
                                    timesheet.breaks
                                  )
                                  : '0:00'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('02_00_working_group')}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{timesheet?.group_name}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('02_01_employee')}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{data?.employee.fullname}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('20_26_shift_type')}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{timesheet?.shift_type_name}</Typography>
                    </Grid>
                </Grid>

                {checkShowCost && (
                    <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                        <Grid item xs={3}>
                            <Typography variant='body2'>{t('13_05_salary_cost_center')}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography>{timesheet?.cost_center}</Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid container alignItems="center" spacing={2} sx={{ py: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant='body2'>{t('20_98_note')}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{timesheet?.note}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}
