import React from 'react'
import { Modal, Box, IconButton } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'

interface Props {
  url: string | undefined
  onClose: () => void
  open: boolean
}

export default function ImageModal ({ onClose, url, open }: Props): JSX.Element {
  return (
    <Modal open={open} onClose={() => { onClose?.() }}>
      <Box
        width={'100%'}
        height={'100%'}
        position={'relative'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box position={'absolute'} top={16} right={16}>
          <IconButton
            aria-label="close"
            onClick={() => { onClose?.() }}
            sx={{ bgcolor: 'rgba(0,0,0,.5)', color: 'white' }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>
        <img src={url} alt="image-preview" />
      </Box>
    </Modal>
  )
}
