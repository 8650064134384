import moment from 'moment'
import { StorageKey } from './constants'
import Storage from './storage'

export const DateTime = {
  User: Storage.getItem(StorageKey.USER_PROFILE),
  Company: Storage.getItem('COMPANY_INFOMATION'),
  getListHourOfDay: (interval: number) => {
    const listInterval = []
    if (interval === 0) {
      interval = 15
    }
    for (let i = 0; i < 1440; i += interval) {
      listInterval.push({
        value: i,
        text: DateTime.TimeFormatSystem(i)
      })
    }
    return listInterval
  },
  TimeFormatSystem: (minutes: number) => {
    const time = moment().startOf('day').add(minutes, 'minutes')
    return time.format(DateTime.Company.time_format)
  },
  TimeStampToDate: (timestamp: number) => {
    return moment.unix(timestamp).toDate()
  },
  ToDateUserTZ: (date: Date, force2UTC?: boolean) => {
    const timezone = DateTime.User != null ? DateTime.User.settings.timezone : 'UTC'
    const strDate = moment(date).format('MM/DD/YYYY H:mm:ss')
    return moment.tz(
      strDate,
      'MM/DD/YYYY H:mm:ss',
      !(force2UTC ?? false) ? timezone : 'UTC'
    )
  },
  ConvertHour: (stamp: number) => {
    if (stamp <= 0 || stamp == null) return ''
    const hour = Math.floor(stamp / 60 / 60)
    const minute = Math.floor((stamp / 60) % 60)
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return hour + ':' + (minute < 10 ? '0' + minute : minute)
  },
  ConverMinute: (stamp: number) => {
    const minute = stamp / 60
    return minute
  },
  CleanStamp (timestamp: number) {
    return (timestamp !== 0)
      ? timestamp.toString().length > 10
        ? timestamp / 1000
        : timestamp
      : timestamp
  },
  GetHours: (timestamp: number) => {
    return moment(DateTime.CleanStamp(timestamp) * 1000).format(
      DateTime.User.settings.time_format
    )
  },

  FormatDate (timestamp?: number, type?: 'date' | 'time' | 'full' | 'days') {
    const formatType = type ?? 'date'
    const dateFormat = DateTime.Company != null ? DateTime.Company.date_format : 'DD/MM/YYYY'
    const timeFormat = DateTime.Company != null ? DateTime.Company.time_format : 'HH:mm'
    if (formatType === 'full') {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return moment.unix(timestamp ?? 0).format(dateFormat + ' ' + timeFormat)
    } else if (formatType === 'days') {
      return moment.unix(timestamp ?? 0).format('ddd, DD/MM/YYYY')
    } else {
      const selectedFormat = formatType === 'date' ? dateFormat : timeFormat
      return moment.unix(timestamp ?? 0).format(selectedFormat)
    }
  },

  EnumerateDaysBetweenDates (startTime: string | number, period?: number) {
    const startDate = moment(Number(startTime) * 1000)
    const startOfWeekDate = startDate
      .isoWeekday(Number(DateTime.Company.first_day_of_week))
      .startOf('day')
    const endDate = startOfWeekDate.clone().add((period ?? 7) - 1, 'days')
    const now = startOfWeekDate
    const dates = []
    while (now.isSameOrBefore(endDate)) {
      dates.push(now.clone().startOf('day').unix())
      now.add(1, 'days')
    }
    return dates
  },
  ChangeTimeOfTimestamp (timestamp: number, date: number) {
    const mmtime = moment.unix(timestamp)
    const mmdate = moment.unix(date)
    return moment({
      years: mmtime.year(),
      months: mmtime.month(),
      date: mmtime.date(),
      hours: Number(mmdate.format('HH')),
      minutes: Number(mmdate.format('mm')),
      seconds: 0,
      milliseconds: 0
    }).unix()
  },
  FormatDuration (time: number, formatOverwite?: string) {
    time = Number(time)
    let isNegativeNum = false
    let _timeFix
    if (time < 0) isNegativeNum = true
    time = Math.abs(time)
    if (moment.unix(time).format('s') === '59') _timeFix = time + 1

    let text = ''
    let format = (DateTime.Company !== null) ? DateTime.Company.time_duration_format : '0'

    if (formatOverwite != null) format = formatOverwite
    const _time = (_timeFix != null) ? _timeFix : time
    const hour = Math.floor(_time / 60 / 60)
    let min = Math.abs(Math.floor((_time % (60 * 60)) / 60))
    switch (Number(format)) {
      case 0:
        min = Math.abs(((_time / 60) % 60) / 60)
        text = (hour + min).toFixed(2)
        break
      case 1:
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        text = hour + ':' + (min < 10 ? '0' + min : min)
        break
      case 2:
        // const d1 = moment(), d2 = moment();
        // d2.add(hour, 'hours').add(min, 'minutes');
        // text = moment.preciseDiff(d1, d2, $filter('translate'), 'hour');
        // if (hour > 23)
        // text = hour + $filter('translate')('00_04_label_hours') + min + $filter('translate')('00_04_label_minutes');
        break
    }
    return (isNegativeNum ? '-' : '') + text
  }
}
