import { type FUser } from '@dansoft/titanplan-services'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/Modal/Modal'
import ContactsSelect from './ContactsSelect'
import CreateGroupName from './CreateGroupName'

interface OpenProps {
  isOpen: boolean
  onClose: () => void
  contacts: FUser[]
  onCreate: (group: FUser[]) => void
}

export default function DialogGroup ({
  isOpen,
  onClose,
  contacts,
  onCreate
}: OpenProps): JSX.Element {
  const { t } = useTranslation()
  const [showContactsSelect, setShowContactsSelect] = useState(false)
  const [showCreateGroupName, setShowCreateGroupName] = useState(true)
  const [groupName, setGroupName] = useState('')

  const handleCreateGroupChat = (): void => {
    setShowContactsSelect(true)
    setShowCreateGroupName(false)
  }
  const handleGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setGroupName(event.target.value)
  }

  useEffect(() => {
    if (isOpen) {
      setGroupName('')
      setShowContactsSelect(false)
      setShowCreateGroupName(true)
    }
  }, [isOpen])

  return (
    <div>
      <Modal
        open={isOpen}
        size="small"
        onClose={onClose}
        title={t('11_01_create_a_new_role')}
      >
        <>
          {showCreateGroupName && (
            <CreateGroupName
              length={groupName.length}
              onGroupNameChange={handleGroupNameChange}
              onCreateGroupChat={handleCreateGroupChat}
            />
          )}
          {showContactsSelect && (
            <ContactsSelect
              onCreate={onCreate}
              onClose={onClose}
              contacts={contacts}
            />
          )}
        </>
      </Modal>
    </div>
  )
}
