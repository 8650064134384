/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  useGridApiRef, useKeepGroupedColumnsHidden
} from '@mui/x-data-grid-premium'
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTimesheetService } from './Services/useTimesheetService'
import {
  type ListTimeSheet,
  checkTimesheetGreaterThan,
  processData
} from './Services/TimesheetServices'
import { EmployeePicker, GroupPicker, ImageModal, PeriodDatePicker, getAllGroupIds } from '../../components'
import { Tools, TimesheetTable, ViewTimesheet, DeleteModal, ClockOutModal, TimeSheetDetailModal, ApproveDetail, EmployeeInfomation, TotalTimesheet } from './Components'
import { Colors } from '../../theme'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import { TimeSheetApi, type CompanySetting, type ReponseListTimeSheet, type ListShiftForTimeSheet, type TimeSheetDetailUpdate, type TimeSheetDetail, type FormFilterTimeSheetApproveGroupView, type FormFilterDetailTimeSheetApproveGroupView, CompanysApi, type FormFullCompanyStructureByTimestamp } from '../../services/Swagger'
import NewTimesheet from './Components/NewTimesheet'
import storage from '../../services/storage'
import { TimesheetHelper } from './Helpers/TimesheetHelper'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import { MODULEID, isActiveModule } from '../../services/modules'
import { CheckAclTimesheet } from './Helpers/TimesheetAcl'
import { TimesheetForm } from './Helpers/TimesheetForm'
interface NewTimesheetModalProps {
  visible: boolean
  data?: ListTimeSheet
  timesheet?: ReponseListTimeSheet
  startTime: number
  view?: boolean
  edit?: ReponseListTimeSheet
  createAndEdit?: ListShiftForTimeSheet
}
interface ModalProps {
  visible: boolean
  data?: ListTimeSheet
  clockOut?: boolean
  detail?: boolean
  approvedDetails?: boolean
  edit?: boolean
  approveData?: any
  employeeTimesheet?: ReponseListTimeSheet[]
  approveTotal?: any
}
interface ProfileProps {
  visible: boolean
  id: number
  edit?: boolean
}

export default function Timesheet (): JSX.Element {
  const { t } = useTranslation()
  const companySetting = storage.getItem('COMPANY_INFOMATION')
  const departments = storage.getItem('ALL_DEPARTMENT')
  const createRole = new CheckAclTimesheet().GetGroupShowCreate(departments, MODULEID.timesheet)
  const isShowCostCenter = isActiveModule(MODULEID.salary)
  const checkShowCost = isShowCostCenter && companySetting.setting.use_cost_center.value
  const apiRef = useGridApiRef()
  const { filter, setFilter, timesheetQuery, employeeQuery, shiftQuery, indicatorSettingQuery, timesheetSetting, getEmployeeSettings } = useTimesheetService()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<ListTimeSheet[]>([])
  const [newTimesheetModal, setNewTimesheetModal] = useState<NewTimesheetModalProps>({
    visible: false,
    data: undefined,
    startTime: 0
  })
  const [showModal, setShowModal] = useState<ModalProps>({
    visible: false,
    data: undefined
  })
  const [profile, setProfile] = useState<ProfileProps>({
    visible: false,
    id: 0
  })
  const [rowGroupingModel, setRowGroupingModel] = useState(['', ''])
  const groupingModelChange = (model: any): void => {
    setRowGroupingModel(model)
  }
  const lastSelectedEmployees: any = getEmployeeSettings.data?.find((item) => item.key === 'timesheet_last_selected_employees')
  const [employeeView, setEmployeeView] = useState({
    on: false,
    employeeData: undefined
  })
  useEffect(() => {
    if ((timesheetQuery.data != null) && (shiftQuery.data != null) && (employeeQuery.data != null)) {
      const newData: ListTimeSheet[] = processData(
        employeeQuery.data,
        timesheetQuery.data,
        shiftQuery.data
      )
      const filterEmployeeGroup = new TimesheetHelper().filterEmpoyeeGroup(filter.groups, newData)
      isDataLoading()
      setData(filterEmployeeGroup)
    }
    if (rowGroupingModel.includes('scheduled_group') && (timesheetQuery.data != null) && (shiftQuery.data != null) && (employeeQuery.data != null)) {
      const newData: ListTimeSheet[] = processData(
        employeeQuery.data,
        timesheetQuery.data,
        shiftQuery.data
      )
      const filterEmployeeGroup = new TimesheetHelper().filterEmpoyeeGroup(filter.groups, newData)
      isDataLoading()
      setData(filterEmployeeGroup)
    }
    if (rowGroupingModel.includes('worked_group') && (timesheetQuery.data != null) && (shiftQuery.data != null) && (employeeQuery.data != null)) {
      const newData: ListTimeSheet[] = processData(
        employeeQuery.data,
        timesheetQuery.data,
        shiftQuery.data
      )
      const filteredData = new TimesheetHelper().filterWorkedGroups(filter.groups, newData)
      isDataLoading()
      setData(filteredData)
    }
    if (rowGroupingModel.includes('employee_name') && (timesheetQuery.data != null) && (shiftQuery.data != null) && (employeeQuery.data != null)) {
      const newData: ListTimeSheet[] = processData(
        [employeeView.employeeData] as any,
        timesheetQuery.data,
        shiftQuery.data
      )
      isDataLoading()
      setData(newData)
    }
  }, [rowGroupingModel, timesheetQuery.data, shiftQuery.data, employeeQuery.data, employeeView])
  const [timesheetWarnings, setTimesheetWarnings] = useState<ListTimeSheet[]>([])
  const [lightboxInfo, setLightboxInfo] = useState<{ urlImage: string | undefined, isShow: boolean }>({ urlImage: '', isShow: false })
  const listHaveTimesheet = data.filter((item) => { return Object.keys(item.timesheet).length !== 0 })
  const saveToLocalStorage = (): void => { storage.setItem('TIMESHEET_VIEW', { rowGroupingModel, filter }) }
  useEffect(() => {
    const timesheetView = storage.getItem('TIMESHEET_VIEW')
    if (timesheetView !== null) {
      setRowGroupingModel(timesheetView.rowGroupingModel)
      setFilter(timesheetView.filter)
    } else {
      const filteredDepartments = new CheckAclTimesheet().GetGroupView(departments, MODULEID.timesheet)
      setFilter({
        ...filter,
        start_time: moment().startOf('D').unix(),
        end_time: moment().endOf('D').unix(),
        groups: getAllGroupIds(filteredDepartments)
      })
    }
  }, [])
  const [fullCompany, setFullCompany] = useState<any[]>()
  const handleEmployeeViewMode = async (): Promise<void> => {
    const formFullCompany: FormFullCompanyStructureByTimestamp = {
      show_secondary: true,
      status: [1, 4],
      min_time: filter.end_time,
      groups: filter.groups
    }
    const res = await new CompanysApi().getFullCompanyStructureByTimestamp(formFullCompany)
    const options = res.data?.flatMap((option: any) =>
      option.employees.map((employee: any) => ({
        group_name: option.group_name,
        fullname: employee.fullname,
        employee_id: Number(employee.employee_id),
        avatar: employee.avatar,
        color: employee.color
      }))
    )
    setEmployeeView({
      on: true,
      employeeData: lastSelectedEmployees?.value ? lastSelectedEmployees?.value : options[0]
    })
    setFullCompany(options)
  }
  useEffect(() => {
    if ((rowGroupingModel[0] === 'timeSheet_date' && rowGroupingModel[1] === 'employee_name') || rowGroupingModel[0] === 'employee_name') {
      if (employeeView.employeeData === undefined) {
        void handleEmployeeViewMode()
      }
    } else {
      setEmployeeView({
        on: false,
        employeeData: undefined
      })
    }
  }, [rowGroupingModel])
  const onEmployeeChange = (value: any): void => {
    console.log(value)
    setEmployeeView({
      on: true,
      employeeData: value
    })
  }

  useEffect(() => { saveToLocalStorage() }, [rowGroupingModel, filter])
  useEffect(() => {
    if (Number(filter?.groups?.length) > 0 && Number(filter?.start_time) > 0) {
      getData()
    }
  }, [filter])

  useEffect(() => {
    if (data.length > 0 && (timesheetSetting.data != null)) {
      const filteredData = filterByWarningDuration(data, timesheetSetting.data)
      setTimesheetWarnings(filteredData)
    }
  }, [data, timesheetSetting.data])

  const listDontApproveTimesheet = new TimesheetHelper().dontApproveTimesheet(data)
  const handleExport = async (): Promise<string | undefined> => {
    const newFilter: FormFilterTimeSheetApproveGroupView = {
      ...filter,
      is_approved: -1,
      min_time: filter.start_time,
      max_time: filter.end_time
    }
    setIsLoading(true)
    try {
      const res = await new TimeSheetApi().exportRegistrationApproveGroupView(newFilter, undefined, { responseType: 'arraybuffer' })
      new TimesheetHelper().downloadFile(res)
      return res.data
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }
  const listCreateAndApprove = new TimesheetForm().listCreateAndApprove(listDontApproveTimesheet)
  const listShiftsRole = new CheckAclTimesheet().GetShiftRole(listCreateAndApprove, MODULEID.timesheet)
  const handleCreateAndApprove = async (): Promise<void> => {
    try {
      setIsLoading(true)
      await new TimeSheetApi().approveTimeRegistration(listShiftsRole)
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
      getData()
    }
  }

  const handleLockAll = async (data: ListTimeSheet[] | ListTimeSheet): Promise<void> => {
    try {
      setIsLoading(true)
      await new TimeSheetApi().lockTimeRegistration(true, new TimesheetHelper().listLockAll(data))
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
      getData()
    }
  }

  const handleUnLockTimesheet = (row: ListTimeSheet): void => {
    void handleUnLockAll(row)
  }

  const handleLockTimesheet = (row: ListTimeSheet): void => {
    void handleLockAll(row)
  }

  const handleUnLockAll = async (data: ListTimeSheet[] | ListTimeSheet): Promise<void> => {
    try {
      setIsLoading(true)
      await new TimeSheetApi().lockTimeRegistration(false, new TimesheetHelper().listLockAll(data))
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
      getData()
    }
  }

  const handleApproveTimesheet = async (row: ListTimeSheet): Promise<void> => {
    try {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      await new TimeSheetApi().approveTimesheetById(row.timesheet.timesheet_id ?? 0, row.timesheet.timesheet_id as any)
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
      getData()
    }
  }

  const handleCreateAndApproveShift = async (shift: ListShiftForTimeSheet): Promise<void> => {
    const listbreaks = new TimesheetForm().breaks(shift)
    const newData: TimeSheetDetail = {
      registration_time_approve_id: 0,
      check_in_time: shift.start_time,
      check_out_time: shift.end_time,
      break_times: listbreaks,
      group_id: new TimesheetForm().getGroupId(shift),
      employee_id: shift.employee_id,
      checkin_note: '',
      checkout_note: '',
      stamp_value_in: '',
      stamp_value_out: '',
      is_client_in: 2,
      is_client_out: 2,
      shift_id: shift.shift_id,
      is_approved: true,
      offset: moment().tz(companySetting?.timezone).utcOffset() * 60,
      is_lock: false,
      shift_type_id: shift.shift_type_id,
      is_break: false,
      parent_id: 0,
      is_paid: false
    }
    try {
      setIsLoading(true)
      await new TimeSheetApi().approveTimesheetById(0, newData)
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
      getData()
    }
  }

  const handleUnApproveTimesheet = async (row: ListTimeSheet): Promise<void> => {
    try {
      if (isLoading) {
        return
      }
      setIsLoading(true)
      await new TimeSheetApi().unApproveTimesheetById(row.timesheet.timesheet_id ?? 0, row.timesheet.timesheet_id as any)
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
      getData()
    }
  }

  const handleScheuledShift = async (row: ListTimeSheet): Promise<void> => {
    await handleShift(row.shifts[0])
  }

  const handleSelectedScheuledShift = async (shift: ListShiftForTimeSheet): Promise<void> => {
    await handleShift(shift)
  }

  const handleShift = async (shift: ListShiftForTimeSheet): Promise<void> => {
    const listbreaks = new TimesheetForm().breaks(shift)
    const newData: TimeSheetDetailUpdate = {
      registration_time_approve_id: 0,
      check_in_time: shift.start_time,
      check_out_time: shift.end_time,
      break_times: listbreaks,
      group_id: new TimesheetForm().getGroupId(shift),
      employee_id: shift.employee_id,
      checkin_note: '',
      checkout_note: '',
      stamp_value_in: '',
      stamp_value_out: '',
      is_client_in: 2,
      is_client_out: 2,
      shift_id: shift.shift_id,
      is_approved: false,
      offset: moment().tz(companySetting?.timezone).utcOffset() * 60,
      is_lock: false,
      shift_type_id: shift.shift_type_id,
      is_break: false,
      parent_id: 0,
      is_paid: false
    }
    try {
      await new TimeSheetApi().createTimeRegistrationApprove(newData)
      setIsLoading(true)
      getData()
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleClockIn = async (shift: ListShiftForTimeSheet): Promise<void> => {
    const newData: TimeSheetDetailUpdate = {
      registration_time_approve_id: 0,
      check_in_time: shift.start_time,
      check_out_time: undefined,
      break_times: [],
      group_id: new TimesheetForm().getGroupId(shift),
      employee_id: shift.employee_id,
      checkin_note: '',
      checkout_note: '',
      stamp_value_in: '',
      stamp_value_out: '',
      is_client_in: 2,
      is_client_out: 2,
      shift_id: shift.shift_id,
      is_approved: false,
      offset: moment().tz(companySetting?.timezone).utcOffset() * 60,
      is_lock: false,
      shift_type_id: shift.shift_type_id,
      is_break: false,
      parent_id: 0,
      is_paid: false
    }
    try {
      await new TimeSheetApi().createTimeRegistrationApprove(newData)
      setIsLoading(true)
      getData()
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreateClockIn = async (row: ListTimeSheet): Promise<void> => {
    await handleClockIn(row.shifts[0])
  }
  const handleCreateClockOut = async (row: ListTimeSheet): Promise<void> => {
    setShowModal({ visible: false, data: row, clockOut: true })
  }

  const handleSelectedClockIn = async (shift: ListShiftForTimeSheet): Promise<void> => {
    await handleClockIn(shift)
  }

  const handleDeleteTimesheet = async (row: ListTimeSheet): Promise<void> => {
    setShowModal({ visible: true, data: row })
  }
  const handleTimesheetDetail = async (row: ListTimeSheet): Promise<void> => {
    setShowModal({ visible: false, data: row, detail: true })
  }
  const handleApproveTotal = async (totalObject: any): Promise<void> => {
    const newFilter: FormFilterDetailTimeSheetApproveGroupView = {
      min_time: filter.start_time,
      max_time: filter.end_time,
      groups: filter.groups
    }
    try {
      setIsLoading(true)
      const res = await new TimeSheetApi().getListDetailRegistrationApproveGroupView(newFilter)
      setShowModal({
        visible: false,
        approvedDetails: true,
        approveData: res.data,
        approveTotal: totalObject
      })
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }
  const handleApprovedDetails = async (row: ListTimeSheet): Promise<void> => {
    const listTimeSheetByDays = new TimesheetHelper().dateTimesheet(timesheetQuery.data)
    const listByTimestamp = listTimeSheetByDays[row.timestamp]
    const employeeTimesheets = listByTimestamp.filter((e: ReponseListTimeSheet) => e.employee_id === row.employee.employee_id)

    try {
      const res = await new TimeSheetApi().getRegistrationApprovedDetailByTimesheetId(row.timesheet.timesheet_id ?? 0)
      setShowModal({
        visible: false,
        data: row,
        approvedDetails: true,
        approveData: res.data,
        employeeTimesheet: employeeTimesheets
      })
      setIsLoading(true)
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }
  const handleApproveByShift = async (row: ListTimeSheet): Promise<void> => {
    await handleApproveShift(row.shifts[0], row)
  }
  const handleSelectApproveByShift = async (shift: ListShiftForTimeSheet, row: ListTimeSheet): Promise<void> => {
    await handleApproveShift(shift, row)
  }
  const handleApproveShift = async (shift: ListShiftForTimeSheet, timesheet: ListTimeSheet): Promise<void> => {
    const listbreaks = new TimesheetForm().breaks(shift)
    const newData: TimeSheetDetailUpdate = {
      registration_time_approve_id: timesheet.timesheet.timesheet_id ?? 0,
      check_in_time: shift.start_time,
      check_out_time: shift.end_time,
      break_times: listbreaks,
      group_id: new TimesheetForm().getGroupId(shift),
      employee_id: shift.employee_id,
      checkin_note: '',
      checkout_note: '',
      stamp_value_in: '',
      stamp_value_out: '',
      is_client_in: 2,
      is_client_out: 2,
      shift_id: shift.shift_id,
      is_approved: false,
      offset: moment().tz(companySetting?.timezone).utcOffset() * 60,
      is_lock: false,
      shift_type_id: shift.shift_type_id,
      is_break: false,
      parent_id: 0,
      is_paid: false
    }
    try {
      await new TimeSheetApi().updateTimeRegistration([newData])
      await new TimeSheetApi().approveTimesheetById(newData.registration_time_approve_id, newData as any)
      setIsLoading(true)
      getData()
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }

  const rowGroupingModelStr = rowGroupingModel.join('')
  const initialState = useKeepGroupedColumnsHidden({ apiRef, rowGroupingModel })
  const handleRowGroupingView = async (groupType: [string, string]): Promise<void> => {
    setRowGroupingModel(groupType)
  }

  const handleEditTimesheetByWarning = (timesheet: ListTimeSheet): void => {
    handleEditTimesheet(timesheet.timesheet)
  }
  const handleTimesheetSelection = (row: ListTimeSheet): void => {
    setNewTimesheetModal({
      visible: true,
      data: row,
      startTime: 0
    })
  }
  const handleEditTimesheet = (timesheet: ReponseListTimeSheet): void => {
    setNewTimesheetModal({
      visible: true,
      data: undefined,
      startTime: 0,
      edit: timesheet
    })
  }
  const handleViewTimesheet = (row: ListTimeSheet): void => {
    setNewTimesheetModal({
      visible: false,
      data: row,
      timesheet: row.timesheet,
      startTime: 0,
      view: true
    })
  }

  const handleTimesheet = (time: number): void => {
    setNewTimesheetModal({
      visible: true,
      data: undefined,
      startTime: time
    })
  }

  const filterByWarningDuration = (data: ListTimeSheet[], companySetting: CompanySetting | undefined): ListTimeSheet[] => {
    if (data.length === 0) return []
    if (companySetting == null) return []
    const filteredData = []

    for (const item of data) {
      const checkIsValid = checkTimesheetGreaterThan(item, companySetting, t)
      if (checkIsValid?.isValid) filteredData.push(item)
    }
    return filteredData
  }

  const getData = (): void => {
    void timesheetQuery.refetch()
    void shiftQuery.refetch()
    void employeeQuery.refetch()
    void indicatorSettingQuery.refetch()
    void timesheetSetting.refetch()
    void getEmployeeSettings.refetch()
    saveToLocalStorage()
  }
  const isDataLoading = (): boolean => {
    return timesheetQuery.isLoading || timesheetQuery.isFetching ||
      shiftQuery.isLoading || shiftQuery.isFetching ||
      employeeQuery.isLoading || employeeQuery.isFetching ||
      indicatorSettingQuery.isLoading || indicatorSettingQuery.isFetching ||
      timesheetSetting.isLoading || timesheetSetting.isFetching
  }
  const settings = cloneDeep(indicatorSettingQuery.data)?.sort(
    (a, b) => Number(b.value) - Number(a.value)
  )
  const handleViewProfile = (id: number): void => {
    setProfile({ visible: true, id })
  }
  const handleCreateAndEdit = (shift: ListShiftForTimeSheet): void => {
    setNewTimesheetModal({
      visible: true,
      data: undefined,
      startTime: 0,
      createAndEdit: shift
    })
  }
  const handleShowImage = (urlImage: string | undefined): void => {
    setLightboxInfo({ urlImage, isShow: true })
  }
  const handleViewListTimesheet = (row: any): void => {

  }
  return (
    <>
      <ToastContainer />
      <Box className="TimeSheet">
        <Box className="TimeSheet-nav">
          <Box display="flex" gap={3}>
            {!employeeView.on && (
              <GroupPicker
                onClose={(departments: any) => {
                  setFilter({
                    ...filter,
                    groups: getAllGroupIds(departments)
                  })
                }}
                value={filter.groups}
                valueType="groupArr"
                initDefaultAll={true}
                SelectProps={{
                  style: { backgroundColor: Colors.white }
                }}
              />
            )}
            {employeeView.on && (
              <EmployeePicker
                options={fullCompany}
                onEmployeeChange={onEmployeeChange}
                employeeData={employeeView.employeeData}
                getEmployeeSettings={getEmployeeSettings.data}
              />
            )}
            <PeriodDatePicker
              onChange={(values) => {
                setFilter({
                  ...filter,
                  start_time: values[0].unix(),
                  end_time: values[1].unix()
                })
              }}
              startTime={filter.start_time}
              endTime={filter.end_time}
            />
          </Box>
          <Tools
            indicatorSettings={indicatorSettingQuery.data}
            onIndicatorSettingsUpdate={() => { void indicatorSettingQuery.refetch() }}
            employeeWarning={timesheetWarnings}
            filter={filter}
            handleNewTimesheet={handleTimesheet}
            rowGroupingModelStr={rowGroupingModelStr}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            handleRowGroupingView={handleRowGroupingView}
            dataUpdate={() => { getData() }}
            createAndApprove={handleCreateAndApprove}
            lockAll={() => { void handleLockAll(listHaveTimesheet) }}
            unLockAll={() => { void handleUnLockAll(listHaveTimesheet) }}
            listDontApproveTimesheet={listDontApproveTimesheet}
            isLock={new TimesheetHelper().checkIsLock(listHaveTimesheet)}
            handleExport={handleExport}
            rowGroupingModel={rowGroupingModel}
            handleEditTimesheetByWarning={handleEditTimesheetByWarning}
            createRole={createRole}
          />
        </Box>
        <TimesheetTable
          timesheetSetting={timesheetSetting}
          handleTimesheetSelection={(row) => { handleTimesheetSelection(row) }}
          handleEditTimesheet={(timesheet) => { handleEditTimesheet(timesheet) }}
          handleViewTimesheet={(row) => { handleViewTimesheet(row) }}
          handleLockTimesheet={(row) => { handleLockTimesheet(row) }}
          handleUnLockTimesheet={(row) => { handleUnLockTimesheet(row) }}
          handleApproveTimesheet={(row) => { void handleApproveTimesheet(row) }}
          handleScheuledShift={(row) => { void handleScheuledShift(row) }}
          handleUnApproveTimesheet={(row) => { void handleUnApproveTimesheet(row) }}
          handleSelectedScheuledShift={(shift) => { void handleSelectedScheuledShift(shift) }}
          handleCreateClockIn={(row) => { void handleCreateClockIn(row) }}
          handleCreateClockOut={(row) => { void handleCreateClockOut(row) }}
          handleSelectedClockIn={(shift) => { void handleSelectedClockIn(shift) }}
          handleDeleteTimesheet={(row) => { void handleDeleteTimesheet(row) }}
          handleTimesheetDetail={(row) => { void handleTimesheetDetail(row) }}
          handleApprovedDetails={(row) => { void handleApprovedDetails(row) }}
          handleApproveByShift={(row) => { void handleApproveByShift(row) }}
          handleSelectApproveByShift={(shift, data) => { void handleSelectApproveByShift(shift, data) }}
          employeeQuery={employeeQuery}
          handleViewProfile={(id) => { handleViewProfile(id) }}
          handleCreateAndApproveShift={(shift) => { void handleCreateAndApproveShift(shift) }}
          handleCreateAndEdit={(shift) => { handleCreateAndEdit(shift) }}
          handleShowImage={(urlImage) => { handleShowImage(urlImage) }}
          createRole={createRole}
          settings={settings}
          data={data}
          isLoading={isDataLoading()}
          handleLoading={isLoading}
          apiRef={apiRef}
          rowGroupingModel={rowGroupingModel}
          initialState={initialState}
          groupingModelChange={groupingModelChange}
          checkShowCost={checkShowCost}
          handleViewListTimesheet={(row) => { handleViewListTimesheet(row) }}
        />
        {!(rowGroupingModel.includes('worked_group')) && (
          <TotalTimesheet shifts={shiftQuery.data} handleApproveTotal={(totalObject) => { void handleApproveTotal(totalObject) }} data={data} />
        )}
        <ImageModal
          open={lightboxInfo.isShow}
          url={lightboxInfo.urlImage}
          onClose={() => { setLightboxInfo({ urlImage: '', isShow: !lightboxInfo.isShow }) }
          }
        />
        {newTimesheetModal.visible && (
          <NewTimesheet
            data={newTimesheetModal.data}
            timesheet={newTimesheetModal.timesheet}
            startTime={newTimesheetModal.startTime}
            edit={newTimesheetModal.edit}
            createAndEdit={newTimesheetModal.createAndEdit}
            dataUpdate={() => { getData() }}
            employees={employeeQuery.data}
            checkShowCost={checkShowCost}
            onClose={() => {
              setNewTimesheetModal({
                visible: false,
                data: undefined,
                startTime: 0
              })
            }}
          />
        )}
        {newTimesheetModal.view && (
          <ViewTimesheet
            timesheet={newTimesheetModal.timesheet}
            data={newTimesheetModal.data}
            checkShowCost={checkShowCost}
            onClose={() => {
              setNewTimesheetModal({
                visible: false,
                data: undefined,
                startTime: 0,
                view: false
              })
            }}
          />
        )}
        {showModal.visible && (
          <DeleteModal
            data={showModal.data}
            dataUpdate={() => { getData() }}
            onClose={() => {
              setShowModal({
                visible: false,
                data: undefined
              })
            }}
          />
        )}
        {showModal.clockOut && (
          <ClockOutModal
            dataUpdate={() => { getData() }}
            data={showModal.data}
            onClose={() => {
              setShowModal({
                visible: false,
                data: undefined
              })
            }} />
        )}
        {showModal.detail && (
          <TimeSheetDetailModal
            data={showModal.data}
            onClose={() => {
              setShowModal({
                visible: false,
                data: undefined
              })
            }}
          />
        )}
        {showModal.approvedDetails && (
          <ApproveDetail
            approveData={showModal.approveData}
            approveTotal={showModal.approveTotal}
            timesheet={showModal.data}
            employeeTimesheet={showModal.employeeTimesheet}
            onClose={() => {
              setShowModal({
                visible: false,
                data: undefined,
                approvedDetails: false,
                approveData: undefined,
                employeeTimesheet: undefined,
                approveTotal: undefined
              })
            }} />
        )}
        {profile.visible && (
          <EmployeeInfomation
            id={profile.id}
            onClose={() => {
              setProfile({
                visible: false,
                id: 0
              })
            }}
          />
        )}
      </Box>
    </>
  )
}
