/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GroupDetail } from '../models';
// @ts-ignore
import { ListCostCenterGroupByCategory } from '../models';
// @ts-ignore
import { SalaryRule } from '../models';
// @ts-ignore
import { SalaryRuleDetail } from '../models';
/**
 * SalaryApi - axios parameter creator
 * @export
 */
export const SalaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getListCostCenterGroupByCategory
         * @param {object} body getListShiftForTimeSheet Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListCostCenterGroupByCategory: async (body: object, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListCostCenterGroupByCategory', 'body', body)
            const localVarPath = `/api/salary/getListCostCenterGroupByCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListSalaryGroup
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSalaryGroup: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salary/getListSalaryGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListSalaryPeriod
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSalaryPeriod: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salary/getListSalaryPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSalaryGroupDetail
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryGroupDetail: async (id: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalaryGroupDetail', 'id', id)
            const localVarPath = `/api/salary/getSalaryGroupDetail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSalaryPeriodDetailBySalaryGroupId
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryPeriodDetailBySalaryGroupId: async (id: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalaryPeriodDetailBySalaryGroupId', 'id', id)
            const localVarPath = `/api/salary/getSalaryPeriodDetailBySalaryGroupId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSalaryPeriodDetailBySalaryRuleId
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryPeriodDetailBySalaryRuleId: async (id: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSalaryPeriodDetailBySalaryRuleId', 'id', id)
            const localVarPath = `/api/salary/getSalaryPeriodDetailBySalaryRuleId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalaryApi - functional programming interface
 * @export
 */
export const SalaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getListCostCenterGroupByCategory
         * @param {object} body getListShiftForTimeSheet Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListCostCenterGroupByCategory(body: object, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListCostCenterGroupByCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListCostCenterGroupByCategory(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListSalaryGroup
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListSalaryGroup(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListSalaryGroup(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListSalaryPeriod
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListSalaryPeriod(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalaryRule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListSalaryPeriod(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getSalaryGroupDetail
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalaryGroupDetail(id: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalaryRuleDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalaryGroupDetail(id, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getSalaryPeriodDetailBySalaryGroupId
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalaryPeriodDetailBySalaryGroupId(id: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalaryRule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalaryPeriodDetailBySalaryGroupId(id, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getSalaryPeriodDetailBySalaryRuleId
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalaryPeriodDetailBySalaryRuleId(id: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalaryRule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalaryPeriodDetailBySalaryRuleId(id, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SalaryApi - factory interface
 * @export
 */
export const SalaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalaryApiFp(configuration)
    return {
        /**
         * 
         * @summary getListCostCenterGroupByCategory
         * @param {object} body getListShiftForTimeSheet Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListCostCenterGroupByCategory(body: object, source?: string, options?: any): AxiosPromise<Array<ListCostCenterGroupByCategory>> {
            return localVarFp.getListCostCenterGroupByCategory(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListSalaryGroup
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSalaryGroup(source?: string, options?: any): AxiosPromise<Array<GroupDetail>> {
            return localVarFp.getListSalaryGroup(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListSalaryPeriod
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSalaryPeriod(source?: string, options?: any): AxiosPromise<Array<SalaryRule>> {
            return localVarFp.getListSalaryPeriod(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSalaryGroupDetail
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryGroupDetail(id: number, source?: string, options?: any): AxiosPromise<SalaryRuleDetail> {
            return localVarFp.getSalaryGroupDetail(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSalaryPeriodDetailBySalaryGroupId
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryPeriodDetailBySalaryGroupId(id: number, source?: string, options?: any): AxiosPromise<SalaryRule> {
            return localVarFp.getSalaryPeriodDetailBySalaryGroupId(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSalaryPeriodDetailBySalaryRuleId
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalaryPeriodDetailBySalaryRuleId(id: number, source?: string, options?: any): AxiosPromise<SalaryRule> {
            return localVarFp.getSalaryPeriodDetailBySalaryRuleId(id, source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalaryApi - object-oriented interface
 * @export
 * @class SalaryApi
 * @extends {BaseAPI}
 */
export class SalaryApi extends BaseAPI {
    /**
     * 
     * @summary getListCostCenterGroupByCategory
     * @param {object} body getListShiftForTimeSheet Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryApi
     */
    public getListCostCenterGroupByCategory(body: object, source?: string, options?: AxiosRequestConfig) {
        return SalaryApiFp(this.configuration).getListCostCenterGroupByCategory(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListSalaryGroup
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryApi
     */
    public getListSalaryGroup(source?: string, options?: AxiosRequestConfig) {
        return SalaryApiFp(this.configuration).getListSalaryGroup(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListSalaryPeriod
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryApi
     */
    public getListSalaryPeriod(source?: string, options?: AxiosRequestConfig) {
        return SalaryApiFp(this.configuration).getListSalaryPeriod(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSalaryGroupDetail
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryApi
     */
    public getSalaryGroupDetail(id: number, source?: string, options?: AxiosRequestConfig) {
        return SalaryApiFp(this.configuration).getSalaryGroupDetail(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSalaryPeriodDetailBySalaryGroupId
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryApi
     */
    public getSalaryPeriodDetailBySalaryGroupId(id: number, source?: string, options?: AxiosRequestConfig) {
        return SalaryApiFp(this.configuration).getSalaryPeriodDetailBySalaryGroupId(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSalaryPeriodDetailBySalaryRuleId
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryApi
     */
    public getSalaryPeriodDetailBySalaryRuleId(id: number, source?: string, options?: AxiosRequestConfig) {
        return SalaryApiFp(this.configuration).getSalaryPeriodDetailBySalaryRuleId(id, source, options).then((request) => request(this.axios, this.basePath));
    }
}
