import { type FRoom } from '@dansoft/titanplan-services'
import { Avatar, Box, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

interface RoomProps {
  room: FRoom
  currentRoom?: FRoom | undefined
}

export default function ChatItem ({ room, currentRoom }: RoomProps): JSX.Element {
  const time = moment.unix(room.lastMessage.createdAt).format('hh:mm')
  const isActive = currentRoom?._id === room._id
  return (
    <Box className={`chatItem${isActive ? ' active' : ''}`}>
      <Avatar
        className="chatItem-avatar"
        sx={{ width: 42, height: 42 }}
        alt={room.avatar}
        src={room.avatar}
      />
      <Box className="chatItem-Containerinfo">
        <Box className="chatItem-info">
          <Typography variant="h6" className="chatItem-name">
            {room.name}
          </Typography>
          <Typography variant="h5" className="chatItem-message">
            {room.lastMessage.text}
          </Typography>
        </Box>
        <Box className="chatItem-time">
          <Typography variant="caption">{time}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
