import { createSlice } from '@reduxjs/toolkit'

export interface SnackbarProps {
  visible: boolean
  message: string
  type?: 'error' | 'info' | 'success' | 'warning' | string
}

export const SnackbarSlice = createSlice({
  name: 'Alert',
  initialState: {
    visible: false,
    message: '',
    type: 'success'
  },
  reducers: {
    updateSnackbarState: (state: any, newState: any) => {
      return { ...newState.payload }
    }
  }
})
export const { updateSnackbarState } = SnackbarSlice.actions
export default SnackbarSlice.reducer
