import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import { Button, type ButtonProps, ListItemIcon, Stack } from '@mui/material'
import Menu from '../Menu/Menu'
import Icon from '../Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const pages = [
  'Dashboard',
  'Shift plan',
  'Messenger',
  'Calendar',
  'HR',
  'Timesheets',
  'Time banks',
  'Requests',
  'Reports',
  'Employee contracts',
  'Settings'
]

function NavButton ({ ...props }: ButtonProps): JSX.Element {
  return (
    <Button
      {...props}
      sx={{
        textTransform: 'none',
        color: 'primary.contrastText',
        height: '48px !important',
        px: 2
      }}
    />
  )
}
// eslint-disable-next-line @typescript-eslint/no-var-requires
const logoImg = require('../../assets/images/TITANPLAN_logo_bw.svg').default
export default function TAppBar (): JSX.Element {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
  }
  return (
    <AppBar
      position="fixed"
      color="secondary"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          sx={{
            '&': { minHeight: '48px' }
          }}
        >
          <img src={logoImg} alt="logo" height={40} />
          <Stack direction="row" spacing={2} sx={{ ml: 2 }}>
            {pages.map((page) => (
              <NavButton
                key={page}
                onClick={() => {
                  navigate(
                    page === 'Shift plan' ? '/planning' : '/settings/employees'
                  )
                }
                }
              >
                {page}
              </NavButton>
            ))}
          </Stack>
          <Box sx={{ ml: 'auto' }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/2.jpg"
                sx={{ width: 36, height: 36 }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              keepMounted
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <ListItemIcon>
                  <Icon iconName="Contact" />
                </ListItemIcon>
                {t('18_01_profile')}
              </MenuItem>
              <MenuItem onClick={() => { navigate('/auth/logout') }}>
                <ListItemIcon>
                  <Icon iconName="SignOut" />
                </ListItemIcon>
                {t('00_06_logout')}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
