import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import {
  Auth,
  Login,
  Logout,
  Messenger,
  Root,
  Timesheet
} from './pages'

import { Helmet } from 'react-helmet-async'
import { ContainerDialog } from './components'
import storage from './services/storage'

function App (): JSX.Element {
  const localToken: any = storage.getItem('APP_TOKEN')
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Titanplan</title>
      </Helmet>
      <Routes>
        <Route path="/">
          <Route path="auth" element={<Auth />}>
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
          </Route>
          {localToken !== null &&
            <Route path="" element={<Root />}>
              <Route path="messenger" element={<Messenger />} />
              <Route path="timesheet" element={<Timesheet />} />
            </Route>
          }
        </Route>
      </Routes>
      <Outlet />
      <ContainerDialog />
    </div>
  )
}

export default App
