/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Calendar } from '../models';
// @ts-ignore
import { CompanyDetail } from '../models';
// @ts-ignore
import { CompanySetting } from '../models';
// @ts-ignore
import { EmployeeForm } from '../models';
// @ts-ignore
import { FormFullCompanyStructureByTimestamp } from '../models';
// @ts-ignore
import { GroupDetail } from '../models';
// @ts-ignore
import { Modules } from '../models';
// @ts-ignore
import { RolesDetail } from '../models';
// @ts-ignore
import { SystemSetting } from '../models';
/**
 * CompanysApi - axios parameter creator
 * @export
 */
export const CompanysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllRole
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRole: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getAllRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCompanyInformation
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInformation: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getCompanyInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCompanySetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySetting: async (key: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getCompanySetting', 'key', key)
            const localVarPath = `/api/company/getCompanySetting/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEmployeeFormSetting
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeFormSetting: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getEmployeeFormSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFullCompanyStructureByTimestamp
         * @param {FormFullCompanyStructureByTimestamp} body getFullCompanyStructureByTimestamp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullCompanyStructureByTimestamp: async (body: FormFullCompanyStructureByTimestamp, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getFullCompanyStructureByTimestamp', 'body', body)
            const localVarPath = `/api/company/getFullCompanyStructureByTimestamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLastestTime
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastestTime: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getLastestTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListHoliday
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListHoliday: async (startTime: string, endTime: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getListHoliday', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getListHoliday', 'endTime', endTime)
            const localVarPath = `/api/company/getListHoliday/{start_time}/{end_time}`
                .replace(`{${"start_time"}}`, encodeURIComponent(String(startTime)))
                .replace(`{${"end_time"}}`, encodeURIComponent(String(endTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListModule
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListModule: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getListModule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSystemSetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemSetting: async (key: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getSystemSetting', 'key', key)
            const localVarPath = `/api/company/getSystemSetting/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCompanySetting
         * @param {string} key 
         * @param {CompanySetting} body updateCompanySetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySetting: async (key: string, body: CompanySetting, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateCompanySetting', 'key', key)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCompanySetting', 'body', body)
            const localVarPath = `/api/company/updateCompanySetting/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanysApi - functional programming interface
 * @export
 */
export const CompanysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getAllRole
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRole(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRole(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getCompanyInformation
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyInformation(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyInformation(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getCompanySetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySetting(key: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySetting(key, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getEmployeeFormSetting
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeFormSetting(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeForm>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeFormSetting(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getFullCompanyStructureByTimestamp
         * @param {FormFullCompanyStructureByTimestamp} body getFullCompanyStructureByTimestamp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullCompanyStructureByTimestamp(body: FormFullCompanyStructureByTimestamp, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullCompanyStructureByTimestamp(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getLastestTime
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastestTime(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastestTime(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListHoliday
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListHoliday(startTime: string, endTime: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Calendar>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListHoliday(startTime, endTime, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListModule
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListModule(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Modules>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListModule(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getSystemSetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemSetting(key: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemSetting(key, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateCompanySetting
         * @param {string} key 
         * @param {CompanySetting} body updateCompanySetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanySetting(key: string, body: CompanySetting, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanySetting(key, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanysApi - factory interface
 * @export
 */
export const CompanysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanysApiFp(configuration)
    return {
        /**
         * 
         * @summary getAllRole
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRole(source?: string, options?: any): AxiosPromise<Array<RolesDetail>> {
            return localVarFp.getAllRole(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getCompanyInformation
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInformation(source?: string, options?: any): AxiosPromise<CompanyDetail> {
            return localVarFp.getCompanyInformation(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getCompanySetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySetting(key: string, source?: string, options?: any): AxiosPromise<CompanySetting> {
            return localVarFp.getCompanySetting(key, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getEmployeeFormSetting
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeFormSetting(source?: string, options?: any): AxiosPromise<Array<EmployeeForm>> {
            return localVarFp.getEmployeeFormSetting(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getFullCompanyStructureByTimestamp
         * @param {FormFullCompanyStructureByTimestamp} body getFullCompanyStructureByTimestamp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullCompanyStructureByTimestamp(body: FormFullCompanyStructureByTimestamp, source?: string, options?: any): AxiosPromise<Array<GroupDetail>> {
            return localVarFp.getFullCompanyStructureByTimestamp(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getLastestTime
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastestTime(source?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getLastestTime(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListHoliday
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListHoliday(startTime: string, endTime: string, source?: string, options?: any): AxiosPromise<Array<Calendar>> {
            return localVarFp.getListHoliday(startTime, endTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListModule
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListModule(source?: string, options?: any): AxiosPromise<Array<Modules>> {
            return localVarFp.getListModule(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSystemSetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemSetting(key: string, source?: string, options?: any): AxiosPromise<SystemSetting> {
            return localVarFp.getSystemSetting(key, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateCompanySetting
         * @param {string} key 
         * @param {CompanySetting} body updateCompanySetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySetting(key: string, body: CompanySetting, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateCompanySetting(key, body, source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanysApi - object-oriented interface
 * @export
 * @class CompanysApi
 * @extends {BaseAPI}
 */
export class CompanysApi extends BaseAPI {
    /**
     * 
     * @summary getAllRole
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public getAllRole(source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).getAllRole(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getCompanyInformation
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public getCompanyInformation(source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).getCompanyInformation(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getCompanySetting
     * @param {string} key 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public getCompanySetting(key: string, source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).getCompanySetting(key, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getEmployeeFormSetting
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public getEmployeeFormSetting(source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).getEmployeeFormSetting(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getFullCompanyStructureByTimestamp
     * @param {FormFullCompanyStructureByTimestamp} body getFullCompanyStructureByTimestamp Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public getFullCompanyStructureByTimestamp(body: FormFullCompanyStructureByTimestamp, source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).getFullCompanyStructureByTimestamp(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getLastestTime
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public getLastestTime(source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).getLastestTime(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListHoliday
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public getListHoliday(startTime: string, endTime: string, source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).getListHoliday(startTime, endTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListModule
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public getListModule(source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).getListModule(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSystemSetting
     * @param {string} key 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public getSystemSetting(key: string, source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).getSystemSetting(key, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateCompanySetting
     * @param {string} key 
     * @param {CompanySetting} body updateCompanySetting Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysApi
     */
    public updateCompanySetting(key: string, body: CompanySetting, source?: string, options?: AxiosRequestConfig) {
        return CompanysApiFp(this.configuration).updateCompanySetting(key, body, source, options).then((request) => request(this.axios, this.basePath));
    }
}
