import { Autocomplete, Avatar, Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/Modal/Modal'
import { DatePicker } from '@mui/x-date-pickers'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import storage from '../../../services/storage'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { useQuery } from '@tanstack/react-query'
import {
  type EmployeeDetailNew,
  type RegistrationTimeToApprove,
  SalaryApi,
  ShifttypesApi,
  TimeSheetApi,
  type TimeSheetDetailUpdate,
  type ReponseListTimeSheet,
  type TimeSheetDetail,
  type ListShiftForTimeSheet
} from '../../../services/Swagger'
import { useFormik } from 'formik'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import React, { useEffect, useState } from 'react'
import { omit } from 'lodash'
import TimeAutocomplete from './TimeAutocomplete'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined'
import { BreakModal } from '.'
import { FormAlert } from '../../../components'
import { TIMESHEET_DEFAULT, TimesheetHelper } from '../Helpers/TimesheetHelper'
import { validationTimesheet } from '../Helpers/Schema'
import GetCostCenter from '../Helpers/GetCostCenter'
import { toast } from 'react-toastify'
import { DateTime } from '../../../services/time'
import { Colors } from '../../../theme'
import { TimesheetForm } from '../Helpers/TimesheetForm'
import { CheckAclTimesheet } from '../Helpers/TimesheetAcl'
import { MODULEID, getGroupRoleByModule } from '../../../services/modules'
interface NewTimesheetProps {
  onClose: () => void
  data?: ListTimeSheet
  employees: EmployeeDetailNew[] | undefined
  dataUpdate: () => void
  startTime: number
  timesheet?: ReponseListTimeSheet
  edit?: ReponseListTimeSheet
  createAndEdit?: ListShiftForTimeSheet
  checkShowCost: boolean
}
export const totalSeconds = 86400
export interface CustomTimeSheetDetailUpdate extends TimeSheetDetailUpdate {
  check_in_time_hour: number
  check_out_time_hour: number
}
const ITEM_HEIGHT = 50
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: 250
    }
  }
}

export default function NewTimesheet ({ onClose, timesheet, data, employees, edit, createAndEdit, dataUpdate, startTime, checkShowCost }: NewTimesheetProps): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [actions, setActions] = useState('')
  const [error, setError] = useState<string>()
  const shifttypesQuery = useQuery(
    ['api/shifttype/getAllPlanningType'],
    async () => {
      const res = await new ShifttypesApi().getAllPlanningType()
      return res.data
    }
  )
  const departments = storage.getItem('ALL_DEPARTMENT')
  const companySetting = storage.getItem('COMPANY_INFOMATION')
  // const groupStore = storage.getItem('GROUP_STORE')

  const dateFormat = companySetting?.date_format
  const timeFormat = companySetting?.time_format
  const CostCenters = useQuery(
    ['api/salary/getListCostCenterGroupByCategory'],
    async () => {
      const res = await new SalaryApi().getListCostCenterGroupByCategory({})
      return res.data
    },
    {
      enabled: checkShowCost
    }
  )
  // chỉ lấy Employees có status 1 và 4
  const filteredEmployees = employees?.filter(
    (item) => Number(item.status) === 1 || Number(item.status) === 4
  )
  const sortEmployees = filteredEmployees?.sort((a, b) => {
    const fullnameA = a.fullname?.toUpperCase() // Chuyển đổi tên thành chữ hoa để so sánh không phân biệt chữ hoa/chữ thường
    const fullnameB = b.fullname?.toUpperCase()

    if ((fullnameA ?? '') < (fullnameB ?? '')) {
      return -1 // a đứng trước b trong thứ tự
    }
    if ((fullnameA ?? '') > (fullnameB ?? '')) {
      return 1 // a đứng sau b trong thứ tự
    }
    return 0 // fullnameA và fullnameB bằng nhau
  })
  const costCenterOptions: JSX.Element[] = GetCostCenter(CostCenters.data)
  const mergedList = CostCenters.data?.reduce((acc: any, obj: any) => {
    return [...acc, ...obj.list]
  }, [])
  const handleSubmit = async (values: CustomTimeSheetDetailUpdate): Promise<void> => {
    if (values.check_out_time_hour === -1) {
      values.check_out_time = undefined
    }
    if (values.check_out_time !== undefined && values.check_in_time !== undefined && values.check_out_time < values.check_in_time) {
      setError('02_00_clocked_out_must_be_after_clocked_in')
      return
    }
    values.break_times?.forEach(item => {
      item.employee_id = values.employee_id
      item.group_id = values.group_id
    })
    const data: TimeSheetDetailUpdate = omit(values, [
      'check_in_time_hour',
      'check_out_time_hour'
    ])
    try {
      setIsLoading(true)
      if (actions === 'save') {
        await new TimeSheetApi().createTimeRegistrationApprove(data)
      } else if (actions === 'approve') {
        if (edit != null) {
          const shouldUpdate = data.break_times?.some((breakTimeData, index) => {
            const breakTimeEdit = (edit.breaks != null) ? edit.breaks[index] : undefined
            return breakTimeData.check_in_time !== breakTimeEdit?.check_in_time || breakTimeData.check_out_time !== breakTimeEdit?.check_out_time
          })
          // Nếu hành động là "Approve", thì gọi API approveTimesheetById với ID là 0
          if (data.check_in_time !== edit?.check_in_time || data.check_out_time !== edit?.check_out_time || data.group_id !== edit.group_id || data.shift_type_id !== edit.shift_type_id || data.cost_center_id !== edit.cost_center_id || data.note !== edit.note || (shouldUpdate ?? false)) {
            await new TimeSheetApi().updateTimeRegistration([data])
          }
        }
        await new TimeSheetApi().approveTimesheetById(
          data.registration_time_approve_id,
          data as TimeSheetDetail
        )
      } else if (actions === 'edit') {
        await new TimeSheetApi().updateTimeRegistration([data])
      } else if (actions === 'unapprove') {
        await new TimeSheetApi().unApproveTimesheetById(edit?.timesheet_id ?? 0, edit?.timesheet_id as any)
      }
      onClose()
      dataUpdate()
    } catch (ex: any) {
      toast.error(t(ex.response.statusText), {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } finally {
      setIsLoading(false)
    }
  }
  const [timeSheetDetailUpdate, setTimeSheetDetailUpdate] = useState<CustomTimeSheetDetailUpdate>(TIMESHEET_DEFAULT)
  useEffect(() => {
    const newTs = new TimesheetForm().getTimesheetDefault(startTime, data, timesheet, edit, createAndEdit)
    setTimeSheetDetailUpdate(newTs)
  }, [startTime, data, timesheet])
  const formik = useFormik({
    initialValues: timeSheetDetailUpdate,
    validationSchema: validationTimesheet,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const handleCloseModal = (value?: RegistrationTimeToApprove): void => {
    if (value != null) {
      if (breakModalObject.index > -1) {
        const breaks = [...(formik.values.break_times ?? [])]
        breaks[breakModalObject.index] = value
        void formik.setFieldValue('break_times', breaks)
      } else {
        const breaks = [...(formik.values.break_times ?? []), value]
        void formik.setFieldValue('break_times', breaks)
      }
    }
    setBreakModalObject({
      visible: false,
      break: undefined,
      index: -1
    })
  }

  const handleDeleteBreak = (index: number): void => {
    const newBreakTimes = [...(formik.values.break_times ?? [])]
    newBreakTimes.splice(index, 1)
    void formik.setFieldValue('break_times', newBreakTimes)
  }
  const [breakModalObject, setBreakModalObject] = useState<{
    visible: boolean
    break?: RegistrationTimeToApprove
    index: number
  }>({
    visible: false,
    break: undefined,
    index: -1
  })
  const filteredDepartments = new CheckAclTimesheet().GetGroupCreate(departments, MODULEID.timesheet)
  const options = filteredDepartments.flatMap((option: any) =>
    option.groups.map((group: any) => ({
      department_name: option.department_name,
      group_name: group.group_name,
      group_id: group.group_id
    }))
  )
  return (
    <Modal open onClose={onClose} size="small" title={(edit != null) ? t('02_01_edit_timesheet') : t('20_23_new_timesheet')}>
      <Box className="NewTimesheet" sx={{ px: 4, py: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <FormAlert formik={formik} error={error} />
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography variant='body2' className='required'>{t('17_01_clocked_in')}</Typography>
            </Grid>
            <Grid item xs>
              <DatePicker
                format={dateFormat}
                value={moment.unix(formik.values.check_in_time ?? 0)}
                slotProps={{ textField: { variant: 'standard' } }}
                disabled={edit?.status === 1}
                onChange={(date: any) => {
                  void formik.setFieldValue('check_in_time', moment(date).unix())
                  void formik.setFieldValue('check_out_time', moment(date).unix())
                }}
                slots={{ openPickerIcon: ArrowDropDownOutlinedIcon }}
              />
            </Grid>
            <Grid item xs>
              <TimeAutocomplete
                value={formik.values.check_in_time_hour}
                status={edit?.status}
                onChange={(seconds) => {
                  if (seconds === -1) {
                    void formik.setFieldValue(
                      'check_in_time_hour',
                      seconds <= totalSeconds ? Number(seconds) : 0)
                    void formik.setFieldValue('check_in_time', timeSheetDetailUpdate.check_in_time)
                  }
                  if (seconds > -1) {
                    void formik.setFieldValue(
                      'check_in_time_hour',
                      seconds <= totalSeconds ? Number(seconds) : 0)
                    const checkInTime =
                      moment
                        .unix(formik.values.check_in_time ?? 0)
                        .startOf('day')
                        .unix() +
                      (seconds <= totalSeconds ? Number(seconds) : 0)
                    void formik.setFieldValue('check_in_time', checkInTime)
                    if ((moment.unix(formik.values.check_in_time ?? 0).startOf('day').isSame(moment.unix(formik.values.check_out_time ?? 0), 'day'))) {
                      if (formik.values.check_out_time_hour !== -1 && seconds >= formik.values.check_out_time_hour) {
                        const nextDayTimestamp =
                          moment
                            .unix(formik.values.check_out_time ?? 0)
                            .add(1, 'day')
                            .startOf('day')
                            .unix() +
                          (formik.values.check_out_time_hour <= totalSeconds ? Number(seconds) : 0)
                        void formik.setFieldValue('check_out_time', nextDayTimestamp)
                      }
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography variant='body2'>{t('20_84_clocked_out')}</Typography>
            </Grid>
            <Grid item xs>
              <DatePicker
                format={dateFormat}
                value={moment.unix(formik.values.check_out_time ?? 0)}
                disabled={edit?.status === 1}
                slotProps={{ textField: { variant: 'standard' } }}
                onChange={(date: any) => { void formik.setFieldValue('check_out_time', moment(date).unix()) }}
                slots={{ openPickerIcon: ArrowDropDownOutlinedIcon }}
              />
            </Grid>
            <Grid item xs>
              <TimeAutocomplete
                value={formik.values.check_out_time_hour}
                status={edit?.status}
                onChange={(seconds) => {
                  if (seconds === -1) {
                    void formik.setFieldValue(
                      'check_out_time_hour',
                      seconds <= totalSeconds ? Number(seconds) : 0
                    )
                    void formik.setFieldValue('check_out_time', timeSheetDetailUpdate.check_out_time)
                  }
                  if (seconds > -1) {
                    void formik.setFieldValue(
                      'check_out_time_hour',
                      seconds <= totalSeconds ? Number(seconds) : 0
                    )
                    const checkOutTime =
                      moment
                        .unix(formik.values.check_out_time ?? 0)
                        .startOf('day')
                        .unix() +
                      (seconds <= totalSeconds ? Number(seconds) : 0)
                    void formik.setFieldValue('check_out_time', checkOutTime)
                    if ((moment.unix(formik.values.check_in_time ?? 0).startOf('day').isSame(moment.unix(formik.values.check_out_time ?? 0), 'day'))) {
                      if (seconds <= formik.values.check_in_time_hour) {
                        // Tăng giá trị của check_out_time lên 1 ngày
                        const nextDayTimestamp =
                          moment
                            .unix(formik.values.check_out_time ?? 0)
                            .add(1, 'day')
                            .startOf('day')
                            .unix() +
                          (seconds <= totalSeconds ? Number(seconds) : 0)
                        void formik.setFieldValue('check_out_time', nextDayTimestamp)
                      }
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography variant='body2'>{t('20_13_break')}</Typography>
            </Grid>
            <Grid item xs>
              {(formik.values.break_times != null) && formik.values.break_times.length > 0 && formik.values.break_times.map((item, index) => (
                <Grid key={index} container alignItems="center" spacing={2} sx={{ py: 2, mb: 2 }}>
                  <Grid item xs={10}>
                    <Typography>{`${moment
                      .unix(item.check_in_time ?? 0)
                      .format(dateFormat)} ${moment
                        .unix(item.check_in_time ?? 0)
                        .format(timeFormat)} - ${moment
                          .unix(item.check_out_time ?? 0)
                          .format(timeFormat)} (${(item.check_out_time != null) && (item.check_in_time != null)
                            ? DateTime.FormatDuration(item.check_out_time - item.check_in_time)
                            : '-'
                      })`}</Typography>
                  </Grid>
                  <Grid item xs display="flex" justifyContent="flex-end" flex="end" gap={2}>
                    <EditOutlinedIcon
                      className="icons-16"
                      onClick={() => {
                        setBreakModalObject({
                          visible: true,
                          break: item,
                          index
                        })
                      }}
                    ></EditOutlinedIcon>
                    <DoDisturbOnOutlinedIcon
                      className="icons-16"
                      color="error"
                      onClick={() => { handleDeleteBreak(index) }}
                    ></DoDisturbOnOutlinedIcon>
                  </Grid>
                </Grid>
              ))}
              <Button
                onClick={() => {
                  setBreakModalObject({
                    visible: true,
                    break: undefined,
                    index: -1
                  })
                }}
                disabled={formik.values.check_in_time_hour <= 0 || edit?.status === 1}
                color="secondary"
                variant="text"
                startIcon={<AddOutlinedIcon />}
              >
                {t('20_20_add_break')}
              </Button>
            </Grid>
          </Grid>
          {breakModalObject.visible && (
            <BreakModal
              dataBreak={breakModalObject.break}
              visible={breakModalObject.visible}
              timesheet={formik.values}
              onClose={handleCloseModal}
            />
          )}
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography variant='body2'>{t('20_24_worked_total')}</Typography>
            </Grid>
            <Grid item xs>
              <Typography>
                {formik.values.check_in_time_hour > -1 &&
                  (formik.values.check_in_time != null) &&
                  (formik.values.check_out_time != null) &&
                  formik.values.check_out_time_hour > 0
                  ? new TimesheetHelper().calculateTotalTimeWorked(
                    formik.values.check_in_time,
                    formik.values.check_out_time,
                    formik.values.break_times
                  )
                  : '0:00'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography variant='body2' className='required'>{t('02_00_working_group')}</Typography>
            </Grid>
            <Grid item xs>
              <Autocomplete
                value={options.find((option: any) => Number(option.group_id) === Number(formik.values.group_id)) ?? null}
                options={options}
                groupBy={(option: any) => option.department_name}
                getOptionLabel={(option: any) => option.group_name}
                onChange={(event, value: any) => {
                  if (value === null) {
                    void formik.setFieldValue('group_id', 0)
                  } else {
                    void formik.setFieldValue('group_id', value.group_id)
                  }
                }}
                renderInput={(params) => <TextField {...params} variant='standard' placeholder={t('02_01_select_a_group') ?? ''} />}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography variant='body2' className='required'>{t('02_01_employee')}</Typography>
            </Grid>
            <Grid item xs>
              {(sortEmployees != null) && (
                <Autocomplete
                  value={sortEmployees.find((option: any) => option.employee_id === formik.values.employee_id) ?? null}
                  options={sortEmployees}
                  getOptionLabel={(option: any) => option.fullname}
                  onChange={(event, value: any) => {
                    if (value === null) {
                      void formik.setFieldValue('employee_id', 0)
                    } else {
                      void formik.setFieldValue('employee_id', value.employee_id)
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" display="flex" gap={3} alignItems="center" {...props}>
                      <Avatar
                        sx={{
                          width: 24,
                          height: 24,
                          bgcolor: option.color ?? Colors.primary,
                          fontSize: 10
                        }}
                        alt={option.fullname}
                        title={option.fullname}
                        src={option.avatar}
                      >
                        {new TimesheetHelper().getInitials(option.fullname ?? '')}
                      </Avatar>
                      <Typography fontWeight="500"> {option.fullname}</Typography>
                    </Box>
                  )}
                  renderInput={(params) => <TextField {...params} variant='standard' placeholder={t('02_01_assign_employee') ?? ''} />}
                />
              )}
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography variant='body2'>{t('20_26_shift_type')}</Typography>
            </Grid>
            <Grid item xs>
              {(shifttypesQuery.data != null) && (
                <FormControl fullWidth variant="standard">
                  <Select
                    MenuProps={MenuProps}
                    value={formik.values.shift_type_id}
                    disabled={edit?.status === 1}
                    onChange={(event) => {
                      void formik.setFieldValue('shift_type_id', event.target.value)
                    }}
                    displayEmpty
                    renderValue={(selected: number) => {
                      if (selected === 0) {
                        return (
                          <Typography variant="body2">
                            {t('20_00_select')}
                          </Typography>
                        )
                      } else {
                        return shifttypesQuery.data.find(
                          (e) => e.shift_type_id === selected
                        )?.name
                      }
                    }}
                  >
                    {shifttypesQuery.data?.map((shifttype: any, index) => (
                      <MenuItem
                        key={index}
                        value={shifttype.shift_type_id}
                      >
                        {shifttype.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          {(mergedList != null) &&
            (
              <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
                <Grid item xs={3}>
                  <Typography variant='body2'>{t('13_05_salary_cost_center')}</Typography>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth variant="standard">
                    <Select
                      MenuProps={MenuProps}
                      value={formik.values.cost_center_id}
                      disabled={edit?.status === 1}
                      onChange={(event) => {
                        void formik.setFieldValue(
                          'cost_center_id',
                          event.target.value
                        )
                      }}
                      displayEmpty
                      renderValue={(selected: any) => {
                        if (selected === 0) {
                          return (
                            <Typography variant="body2">{t('20_00_select')} </Typography>
                          )
                        } else { return mergedList.find((e) => e.cost_center_id === selected)?.name }
                      }}
                    >
                      {costCenterOptions}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )
          }
          <Grid container alignItems="center" spacing={2} sx={{ py: 2 }}>
            <Grid item xs={3}>
              <Typography variant='body2'>{t('20_98_note')}</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                multiline
                maxRows={2}
                disabled={edit?.status === 1}
                value={formik.values.note}
                onChange={(event) => { void formik.setFieldValue('note', event.target.value) }}
                placeholder={t('20_98_note') ?? ''}
                id="standard-basic"
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ py: 2, justifyContent: 'flex-end' }}
          >
            {edit?.status !== 1 && (
              <><Grid item>
                {(getGroupRoleByModule(MODULEID.timesheet, createAndEdit?.group_id ?? 0).Approve === true) && (
                  <Button
                    onClick={() => {
                      setActions('approve')
                      formik.handleSubmit()
                    }}
                    disabled={isLoading || formik.values.check_out_time_hour === -1}
                    variant="outlined"
                  >
                    {t('20_26_accept')}
                  </Button>
                )}
              </Grid><Grid item>
                  <Button
                    onClick={() => {
                      if (edit != null) {
                        setActions('edit')
                      } else {
                        setActions('save')
                      }
                      formik.handleSubmit()
                    }}
                    disabled={isLoading}
                    variant="contained"
                  >
                    {t('20_13_save')}
                  </Button>
                </Grid></>
            )}
            {edit?.status === 1 && (
              <Grid item>
                <Button
                  onClick={() => {
                    setActions('unapprove')
                    formik.handleSubmit()
                  }}
                  disabled={isLoading || formik.values.check_out_time_hour === -1}
                  variant="outlined"
                >
                  {t('02_01_unapprove')}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </Modal>
  )
}
