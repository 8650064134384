import Storage from './storage'
import { type GroupRole, type DepartmentDetail, type Modules, type Profile } from './Swagger'
export const MODULEID = {
  shift: 1,
  timesheet: 2,
  salary: 3,
  timebank: 6,
  contract: 7,
  employee_and_access_role: 11,
  employee: 12,
  access_role: 13,
  newsfeed: 15,
  request: 16,
  messenger: 17,
  calendar: 18,
  report: 24,
  hr: 26,
  document: 28,
  timebudget: 29
}

export function isActiveModule (moduleId: number): boolean {
  const MODULES: Modules[] | null = Storage.getItem('LIST_MODULE')
  if (MODULES != null && MODULES.length > 0) {
    const moduleArr = MODULES.filter(
      (module) => moduleId === Number(module.module_id)
    )
    if (moduleArr.length > 0) {
      return Number(moduleArr[0].status) === 1
    }
  }
  return false
}

export function isHaveRole (moduleId: number, key: number): boolean {
  const MODULES: Modules[] = Storage.getItem('LIST_MODULE')
  const moduleArr = MODULES.filter(
    (module) => moduleId === Number(module.module_id)
  )
  if (moduleArr.length > 0) {
    return Number(moduleArr[0].action?.substring(key, key + 1)) === 1
  }
  return false
}

export function getRole (action: string | undefined, key: number): boolean {
  return Number(action?.substring(key, key + 1)) === 1
}

export function getRoleByGroupID (departments: DepartmentDetail[], groupId: number): Modules[] | undefined {
  let action: Modules[] | undefined
  if (departments.length > 0) {
    departments.map((department) => {
      department.groups?.map((group: GroupRole) => {
        if (+groupId === +group.group_id) {
          action = group.action
        }
        return group
      })
      return department
    })
  }
  return action
}

interface RoleProps {
  View: boolean
  Create?: boolean
  Edit?: boolean
  Delete?: boolean
  ViewAll?: boolean
  Approve?: boolean
  Lock?: boolean
  Take?: boolean
  Open?: boolean
  Swap?: boolean
  Shift?: boolean
  Timesheet?: boolean
  Off?: boolean
  Give?: boolean
  Info?: boolean
  Condition?: boolean
  Custom?: boolean
  Report?: boolean
}

const getRoleData = (
  moduleId: number,
  ROLES: Modules[] | undefined,
  MODULES: Modules[],
  userInfo: Profile
): RoleProps => {
  let RoleObject: RoleProps = {
    View: false,
    Create: false,
    Edit: false,
    Delete: false,
    Approve: false,
    Lock: false,
    Report: false
  }
  if ((userInfo?.is_admin)) {
    return {
      View: true,
      Create: true,
      Edit: true,
      Delete: true,
      ViewAll: true,
      Approve: true,
      Lock: true,
      Take: true,
      Open: true,
      Swap: true,
      Shift: true,
      Timesheet: true,
      Off: true,
      Give: true,
      Info: true,
      Condition: true,
      Custom: true
    }
  } else {
    ROLES?.forEach((role) => {
      if (+role.module_id === +moduleId) {
        RoleObject = {
          View: getRole(role.action, 0),
          Create: getRole(role.action, 1),
          Edit: getRole(role.action, 2),
          Delete: getRole(role.action, 3),
          Approve: getRole(role.action, 4),
          Lock: getRole(role.action, 5)
        }
        switch (+moduleId) {
          case MODULEID.request:
            RoleObject = {
              View: getRole(role.action, 0),
              Take: getRole(role.action, 1),
              Open: getRole(role.action, 2),
              Swap: getRole(role.action, 7),
              Shift: getRole(role.action, 3),
              Timesheet: getRole(role.action, 4),
              Off: getRole(role.action, 5),
              Approve: getRole(role.action, 6),
              Give: getRole(role.action, 8)
            }
            break
          case MODULEID.employee:
            RoleObject = {
              View: getRole(role.action, 0),
              Create: getRole(role.action, 1),
              Edit: getRole(role.action, 2),
              Info: getRole(role.action, 3),
              Condition: getRole(role.action, 4),
              Custom: getRole(role.action, 5),
              Delete: getRole(role.action, 6)
            }
            break
          case MODULEID.shift:
            RoleObject = {
              View: getRole(role.action, 0),
              Create: getRole(role.action, 1),
              Edit: getRole(role.action, 2),
              Delete: getRole(role.action, 3),
              Shift: getRole(role.action, 4),
              Lock: getRole(role.action, 5)
            }
            break
          case MODULEID.timesheet:
            RoleObject = {
              View: getRole(role.action, 0),
              ViewAll: getRole(role.action, 7),
              Create: getRole(role.action, 1),
              Edit: getRole(role.action, 2),
              Delete: getRole(role.action, 3),
              Approve: getRole(role.action, 4),
              Lock: getRole(role.action, 5),
              Report: getRole(role.action, 8)
            }
            break
          case MODULEID.timebank:
          case MODULEID.contract:
            RoleObject = {
              View: getRole(role.action, 0),
              ViewAll: getRole(role.action, 1),
              Create: getRole(role.action, 2),
              Edit: getRole(role.action, 3),
              Delete: getRole(role.action, 4)
            }
            break
          case MODULEID.report:
            RoleObject = {
              View: getRole(role.action, 0),
              Create: getRole(role.action, 1),
              ViewAll: getRole(role.action, 2)
            }
            break
          case MODULEID.document:
            RoleObject = {
              View: getRole(role.action, 0)
            }
            break
          default:
            break
        }
      }
    })
  }
  if (+moduleId === MODULEID.timesheet) {
    if (MODULES.find((item) => Number(item.module_id) === Number(MODULEID.salary)) == null) {
      RoleObject.Approve = false
    }
  }

  // / Map module action + acl for request
  if (+moduleId === MODULEID.request) {
    const MOD = MODULES.find((item) => item.module_id === +moduleId)
    const MOD_ACTION = MOD?.action
    const MOD_TAKE = getRole(MOD_ACTION, 1)
    const MOD_ACL = {
      View: RoleObject.View,
      Take: MOD_TAKE && RoleObject.Take,
      Open: MOD_TAKE && getRole(MOD_ACTION, 2) && RoleObject.Open,
      Swap: getRole(MOD_ACTION, 7) && RoleObject.Swap,
      Shift: getRole(MOD_ACTION, 3) && RoleObject.Shift,
      Timesheet: getRole(MOD_ACTION, 4) && RoleObject.Timesheet,
      Off: getRole(MOD_ACTION, 5) && RoleObject.Off,
      Approve: getRole(MOD_ACTION, 6) && RoleObject.Approve,
      Give: getRole(MOD_ACTION, 8) && RoleObject.Give
    }
    RoleObject = MOD_ACL
  }
  return RoleObject
}

export function getGroupRoleByModule (moduleId: number, groupId: number): RoleProps {
  const departments: DepartmentDetail[] = Storage.getItem('ALL_DEPARTMENT')
  const ROLES = getRoleByGroupID(departments, groupId)
  const MODULES: Modules[] = Storage.getItem('LIST_MODULE')
  const userInfo: Profile = Storage.getItem('USER_PROFILE')
  const roleObj = getRoleData(moduleId, ROLES, MODULES, userInfo)
  return roleObj
}
interface GroupACL {
  group_id: number
  group_name: string | undefined
}
interface DepartmentACL {
  department_id: number
  department_name: string | undefined
  groups: GroupACL[]
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function ACLGroupsByRole (moduleId: number, roleType: keyof RoleProps) {
  const dpts: DepartmentACL[] = []
  let grs: GroupACL[] = []
  const departments: DepartmentDetail[] = Storage.getItem('ALL_DEPARTMENT')
  const MODULES: Modules[] = Storage.getItem('LIST_MODULE')
  const userInfo: Profile = Storage.getItem('USER_PROFILE')
  departments.map((department) => {
    grs = []
    department.groups?.map((group) => {
      const ROLES = getRoleByGroupID(departments, Number(group.group_id))
      const aclObj = getRoleData(moduleId, ROLES, MODULES, userInfo)
      if (aclObj[roleType] ?? false) {
        grs.push({ group_id: group.group_id, group_name: group.group_name })
      }
      return group
    })
    if (grs.length > 0) {
      dpts.push({
        department_id: department.department_id,
        department_name: department.department_name,
        groups: grs
      })
    }
    return department
  })
  return dpts
}
