import { Icon, IconButton } from '@fluentui/react'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { type FMessage } from '@dansoft/titanplan-services'
import { Box } from '@mui/material'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-autosize-textarea'

interface sendMessengerProps {
  onSend: (text: any) => void
  onAttack: (file: { uri: string, fileName: string, type: string }) => void
  onReplyMessage?: FMessage
  handleCancelReply: () => void
  replyWithImage?: FMessage
  handleCancelReplyWithImage: () => void
  replyType: any
}

export default function InputMessenger ({
  onSend,
  onAttack,
  onReplyMessage,
  handleCancelReply,
  replyWithImage,
  handleCancelReplyWithImage,
  replyType
}: sendMessengerProps): JSX.Element {
  const [showEmoji, setShowEmoji] = useState(false)
  const [input, setInput] = useState<string>('')
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const handleSendMessenger = (): void => {
    const text = (onReplyMessage != null)
      ? `[Reply: ${onReplyMessage.text} ${input}`
      : input
    if (input.trim() !== '') {
      onSend(text)
      setInput('')
      setShowEmoji(false)
      handleCancelReply()
    }
  }
  const handleEmojiSelect = (emoji: any): void => {
    setInput((prevInput) => `${prevInput} ${emoji.native as string}`)
  }
  const handleClickOutSide = useCallback(
    (event: MouseEvent) => {
      if (
        showEmoji &&
        (ref.current != null) &&
        !ref.current.contains(event.target as Node)
      ) {
        setShowEmoji(false)
      }
    },
    [showEmoji, setShowEmoji, ref]
  )

  const handleKeyDown = (event: any): void => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSendMessenger()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide)
    }
  }, [showEmoji])

  return (
    <Box className="InputMessenger">
      <IconButton className="InputMessenger-file">
        <label htmlFor="upload-image">
          <Icon iconName="Attach" className="InputMessenger-icon" />
        </label>
        <input
          id="upload-image"
          type="file"
          accept="image/jpeg, image/png"
          style={{ display: 'none' }}
          onChange={(event: any) => {
            const file = event.target.files[0]
            if (file !== undefined) {
              const uri = URL.createObjectURL(file)
              const fileName = file.name
              const type = file.type
              onAttack({ uri, fileName, type })
            }
          }}
        />
      </IconButton>
      <Box className="InputMessenger-containerInput">
        {replyType === 'message' && (onReplyMessage != null) && (
          <Box className="InputMessenger-reply">
            <Icon iconName="Reply" />
            <Box className="InputMessenger-replyText">
              {`${onReplyMessage.user.name}: ${onReplyMessage.text}`}
            </Box>
            <Icon
              className="InputMessenger-replyClose"
              onClick={() => { handleCancelReply() }}
              iconName="CalculatorMultiply"
            />
          </Box>
        )}
        {replyType === 'image' && (replyWithImage != null) && (
          <Box className="InputMessenger-reply">
            <Icon iconName="Reply" />
            {replyWithImage.user.name}:
            <img
              className="InputMessenger-replyImage"
              src={replyWithImage.image}
              alt="Replying with image"
            />
            <Icon
              className="InputMessenger-replyClose"
              onClick={() => { handleCancelReplyWithImage() }}
              iconName="CalculatorMultiply"
            />
          </Box>
        )}
        <Box className="InputMessenger-box">
          <Icon
            iconName="Emoji2"
            onClick={() => { setShowEmoji(!showEmoji) }}
            className="InputMessenger-icon InputMessenger-start"
          />
          {showEmoji && (
            <Box className="InputMessenger-emoji" ref={ref}>
              <Picker
                data={data}
                onEmojiSelect={handleEmojiSelect}
                theme="light"
              />
            </Box>
          )}
          <TextareaAutosize
            style={{ maxHeight: 80, boxSizing: 'border-box' }}
            placeholder={t('14_01_messenger') ?? ''}
            onChange={(event: any) => { setInput(event.target.value) }}
            onKeyDown={handleKeyDown}
            className="InputMessenger-input"
            value={input}
          />
          <Icon
            iconName="Send"
            onClick={handleSendMessenger}
            className="InputMessenger-icon InputMessenger-end"
          ></Icon>
        </Box>
      </Box>
    </Box>
  )
}
