import React from 'react'
import { type FRoom } from '@dansoft/titanplan-services'
import { Avatar, Box, Typography } from '@mui/material'

interface UserMessengerProps {
  room?: FRoom
}

export default function UserMessenger ({ room }: UserMessengerProps): JSX.Element {
  return (
    <Box className="UserMessenger">
      <Avatar src={room?.avatar} />
      <Box>
        <Typography className="UserMessenger-overName" variant="h4">
          {room?.name}
        </Typography>
      </Box>
    </Box>
  )
}
