import moment from 'moment'
import { type TimeSheetDetailUpdate, type ListShiftForTimeSheet, type RegistrationTimeToApprove, type ReponseListTimeSheet } from '../../../services/Swagger'
import storage from '../../../services/storage'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import { type CustomRegistrationTimeToApprove } from '../Components'
import { type CustomTimeSheetDetailUpdate } from '../Components/NewTimesheet'
import { TIMESHEET_DEFAULT } from './TimesheetHelper'

export class TimesheetForm {
  getGroupId (
    shifts: ListShiftForTimeSheet[] | ListShiftForTimeSheet
  ): number | undefined {
    const shiftArray = Array.isArray(shifts) ? shifts : [shifts]
    if (shiftArray.length > 0) {
      const firstShift = shiftArray[0]
      if (
        firstShift.parent_group_id != null &&
            firstShift.parent_group_id > 0
      ) {
        return firstShift.parent_group_id
      } else {
        return firstShift.group_id
      }
    }
    return undefined
  }

  getPrimaryGroupId (data: ListTimeSheet | undefined): number | undefined {
    const primaryGroup = data?.employee?.groups?.find(
      (group) => group.is_primary === 1
    )
    if (primaryGroup != null) {
      return primaryGroup.group_id
    }
    return undefined
  }

  breaks = (
    shift: ListShiftForTimeSheet
  ): RegistrationTimeToApprove[] | undefined => {
    const companySetting = storage.getItem('COMPANY_INFOMATION')
    const offset = moment().tz(companySetting?.timezone).utcOffset() * 60
    return shift.break_times?.map((breakTime): RegistrationTimeToApprove => {
      return {
        registration_time_approve_id: 0,
        shift_id: breakTime.shift_id,
        check_in_time: breakTime.start_time,
        check_out_time: breakTime.end_time,
        checkin_note: '',
        checkout_note: '',
        employee_id: shift.employee_id,
        group_id: this.getGroupId(shift),
        is_lock: false,
        approvedTime: 0,
        is_break: true,
        registration_time_id: 0,
        offset,
        is_client_out: 2,
        is_client_in: 2,
        stamp_value_out: '',
        stamp_value_in: '',
        parent_id: 0,
        note: '',
        is_paid: false,
        is_approved: false,
        type: 0,
        device_info: '',
        updated_by: 0,
        updated_type: 0,
        clock_in_image: '',
        clock_out_image: '',
        cost_center_id: undefined,
        shift_type_id: undefined,
        use_break: true
      }
    })
  }

  listCreateAndApprove = (data: ListTimeSheet[]): TimeSheetDetailUpdate[] => {
    const result: TimeSheetDetailUpdate[] = []
    const companySetting = storage.getItem('COMPANY_INFOMATION')
    const offset = moment().tz(companySetting?.timezone).utcOffset() * 60
    data.forEach((item: ListTimeSheet) => {
      if (item.shifts?.length === 0) {
        const registrationTimesheet: TimeSheetDetailUpdate = {
          registration_time_approve_id: item.timesheet.timesheet_id ?? 0,
          note: '',
          is_break: false,
          is_lock: false,
          is_client_in: 2,
          parent_id: 0,
          is_paid: false,
          is_approved: true
        }
        result.push(registrationTimesheet)
      }
      if (item.shifts?.length > 0 && Object.keys(item.timesheet).length !== 0) {
        const registrationTimesheet: TimeSheetDetailUpdate = {
          registration_time_approve_id: item.timesheet.timesheet_id ?? 0,
          note: '',
          is_break: false,
          is_lock: false,
          is_client_in: 2,
          parent_id: 0,
          is_paid: false,
          is_approved: true
        }
        result.push(registrationTimesheet)
      }
      if (item.shifts?.length > 0 && Object.keys(item.timesheet).length === 0) {
        item.shifts.forEach((shift) => {
          const listbreaks = this.breaks(shift)
          const registrationTime: TimeSheetDetailUpdate = {
            break_times: listbreaks,
            registration_time_approve_id: 0,
            check_in_time: shift.start_time,
            check_out_time: shift.end_time,
            employee_id: shift.employee_id,
            group_id: this.getGroupId(shift),
            is_approved: true,
            is_client_in: 2,
            is_client_out: 2,
            checkin_note: undefined,
            checkout_note: undefined,
            offset,
            shift_id: shift.shift_id,
            shift_type_id: shift.shift_type_id,
            stamp_value_in: '',
            stamp_value_out: '',
            is_lock: false,
            approvedTime: 0,
            is_break: false,
            registration_time_id: 0,
            parent_id: 0,
            note: '',
            is_paid: false,
            type: 0,
            device_info: '',
            updated_by: 0,
            updated_type: 0,
            clock_in_image: undefined,
            clock_out_image: undefined,
            cost_center_id: 0,
            use_break: false
          }
          result.push(registrationTime)
        })
      }
    })
    return result
  }

  getTimesheetDefault (
    timestamp: number,
    data?: ListTimeSheet,
    timesheet?: ReponseListTimeSheet,
    edit?: ReponseListTimeSheet,
    createAndEdit?: ListShiftForTimeSheet
  ): CustomTimeSheetDetailUpdate {
    const tsDetault = {
      ...TIMESHEET_DEFAULT,
      check_in_time: timestamp,
      check_out_time: timestamp
    }
    if (data != null) {
      tsDetault.check_in_time =
        data.shifts?.length > 0
          ? moment
            .unix(data.shifts[0].start_time ?? 0)
            .startOf('day')
            .unix()
          : moment
            .unix(data.timesheet.check_in_time ?? 0)
            .startOf('day')
            .unix()
      tsDetault.check_out_time =
        data.shifts?.length > 0
          ? moment
            .unix(data.shifts[0].end_time ?? 0)
            .startOf('day')
            .unix()
          : data.timesheet.check_out_time !== null
            ? moment
              .unix(data.timesheet.check_out_time ?? 0)
              .startOf('day')
              .unix()
            : moment
              .unix(data.timesheet.check_in_time ?? 0)
              .startOf('day')
              .unix()
      // tsDetault.shift_id = data.shifts?.length > 0 ? data.shifts[0].shift_id : 0
      tsDetault.employee_id = data.employee.employee_id
      tsDetault.group_id =
        this.getPrimaryGroupId(data)
      tsDetault.shift_type_id =
        data.shifts?.length > 0
          ? data.shifts[0].shift_type_id ?? timestamp
          : data.timesheet.shift_type_id
    }
    if (timesheet != null) {
      tsDetault.check_in_time = timesheet.check_in_time ?? timestamp
      tsDetault.check_out_time = (timesheet.check_out_time == null)
        ? timesheet.check_in_time ?? timestamp
        : timesheet.check_out_time ?? timesheet
      tsDetault.check_in_time_hour =
        (timesheet.check_in_time ?? 0) -
        moment((timesheet?.check_in_time ?? 0) * 1000)
          .startOf('day')
          .unix()
      tsDetault.check_out_time_hour = (timesheet.check_out_time == null)
        ? -1
        : (timesheet?.check_out_time ?? 0) -
          moment((timesheet?.check_out_time ?? 0) * 1000)
            .startOf('day')
            .unix()
      tsDetault.break_times =
        timesheet.breaks as CustomRegistrationTimeToApprove[]
    }
    if (edit != null) {
      tsDetault.registration_time_approve_id = edit.timesheet_id ?? 0
      tsDetault.check_in_time = edit.check_in_time ?? timestamp
      tsDetault.check_out_time = (edit.check_out_time == null)
        ? edit.check_in_time ?? timestamp
        : edit.check_out_time ?? edit
      tsDetault.check_in_time_hour =
        (edit.check_in_time ?? 0) ===
        moment((edit?.check_in_time ?? 0) * 1000)
          .startOf('day')
          .unix()
          ? moment(0).hour()
          : (edit.check_in_time ?? 0) -
            moment((edit?.check_in_time ?? 0) * 1000)
              .startOf('day')
              .unix()
      tsDetault.check_out_time_hour = (edit.check_out_time == null)
        ? -1
        : (edit.check_out_time ?? 0) ===
          moment((edit?.check_out_time ?? 0) * 1000)
            .startOf('day')
            .unix()
            ? moment(0).hour()
            : (edit.check_out_time ?? 0) -
          moment((edit?.check_out_time ?? 0) * 1000)
            .startOf('day')
            .unix()
      tsDetault.employee_id = edit.employee_id
      tsDetault.shift_type_id = edit.shift_type_id
      tsDetault.break_times = edit.breaks as CustomRegistrationTimeToApprove[]
      tsDetault.cost_center_id = edit.cost_center_id
      tsDetault.group_id = edit.group_id
      tsDetault.note = edit.note
    }
    if (createAndEdit != null) {
      tsDetault.registration_time_approve_id = 0
      tsDetault.check_in_time = createAndEdit.start_time ?? 0
      tsDetault.check_out_time = createAndEdit.end_time ?? 0
      tsDetault.check_in_time_hour =
        (createAndEdit.start_time ?? 0) -
        moment((createAndEdit?.start_time ?? 0) * 1000)
          .startOf('day')
          .unix()
      tsDetault.check_out_time_hour =
        (createAndEdit.end_time ?? 0) -
        moment((createAndEdit?.end_time ?? 0) * 1000)
          .startOf('day')
          .unix()
      tsDetault.break_times = this.breaks(createAndEdit)
      tsDetault.employee_id = createAndEdit.employee_id
      tsDetault.group_id = createAndEdit.group_id
      tsDetault.shift_type_id = createAndEdit.shift_type_id
    }
    const companySetting = storage.getItem('COMPANY_INFOMATION')
    tsDetault.offset = moment().tz(companySetting?.timezone).utcOffset() * 60
    return tsDetault
  }
}
