import globalAxios from 'axios'
import Storage from './storage'

globalAxios.defaults.baseURL = process.env.REACT_APP_API_URL
const localToken: any = Storage.getItem('APP_TOKEN')
if (localToken !== null) {
  globalAxios.defaults.headers.common.token = localToken.token
}

globalAxios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log(error.response.statusText)
    return await Promise.reject(error)
  }
)

export async function callDeleteApi (url: string, data: any): Promise<any> {
  return await globalAxios.delete(url, {
    headers: {
      Authorization: localToken.token
    },
    data
  })
}
