import {
  Modal,
  Box,
  type ModalProps,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  DialogTitle
} from '@mui/material'
import React from 'react'
import Icon from '../Icon/Icon'
const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  bgcolor: 'background.paper'
}
type Props = {
  title: string
  loading?: boolean
  size?: 'large' | 'medium' | 'small'
} & ModalProps
export default function TModal ({
  onClose,
  title,
  loading,
  children,
  size,
  ...props
}: Props): JSX.Element {
  const widths = {
    large: 960,
    medium: 720,
    small: 600
  }
  return (
    <Modal {...props} onClose={() => { onClose?.({}, 'escapeKeyDown') }}>
      <Box
        sx={{ ...style, width: widths[size ?? 'medium'] }}
        className={loading === true ? 'positionRelative' : ''}
      >
        <Grid
          container
          alignItems="center"
          sx={{ px: 4, py: 1, backgroundColor: 'primary.main' }}
        >
          <Grid item xs>
            <Typography
              variant="h6"
              sx={{ textTransform: 'uppercase', color: 'primary.contrastText' }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <IconButton
              aria-label="Cancel"
              onClick={() => { onClose?.({}, 'backdropClick') }}
            >
              <Icon iconName="Cancel" color="white" />
            </IconButton>
          </Grid>
        </Grid>
        <Box>{children}</Box>
        {loading === true && (
          <div className="modalLoadingOverlay">
            <CircularProgress />
          </div>
        )}
      </Box>
    </Modal>
  )
}

export function ModalFull ({
  onClose,
  title,
  loading,
  children,
  ...props
}: Props): JSX.Element {
  const width = 'calc(100% - 100px)'
  return (
    <Modal
      {...props}
      onBackdropClick={() => { onClose?.({}, 'backdropClick') }}
    >
      <Box
        sx={{ ...style, width }}
        className={loading === true ? 'positionRelative' : ''}
      >
        <Grid container alignItems="center" sx={{ px: 4, py: 1 }}>
          <Grid item xs>
            <DialogTitle sx={{ px: 0, py: 2 }}>{title}</DialogTitle>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <IconButton
              aria-label="Cancel"
              onClick={() => { onClose?.({}, 'backdropClick') }}
            >
              <Icon iconName="Cancel" color="black" />
            </IconButton>
          </Grid>
        </Grid>
        <Box sx={{ border: '1px solid black', borderColor: 'divider' }}>
          {children}
        </Box>
        {loading === true && (
          <div className="modalLoadingOverlay">
            <CircularProgress />
          </div>
        )}
      </Box>
    </Modal>
  )
}
