/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Stack } from '@mui/material'
import { DataGridPremium, type GridGroupingColDefOverride } from '@mui/x-data-grid-premium'
import React, { useMemo } from 'react'
import { type ListTimeSheet } from '../Services/TimesheetServices'
import { type GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { type GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { t } from 'i18next'
import { DateTime } from '../../../services/time'
import Action from './Action'
import { type CompanySetting, type IndicatorSetting, type ListShiftForTimeSheet, type ReponseListTimeSheet } from '../../../services/Swagger'
import { ActualTotalCell, FullnameCell, ScheduledShiftCell, StatusCell, WorkedTimeCell } from '../Services/useColumn'
import ClockInOutPhoto from './ClockInOutPhoto'
import { type UseQueryResult } from '@tanstack/react-query'

interface TimesheetTableProps {
  createRole: any
  isLoading: boolean
  handleLoading: boolean
  data: ListTimeSheet[]
  apiRef: React.MutableRefObject<GridApiPremium>
  rowGroupingModel: string[]
  initialState: GridInitialStatePremium
  settings: IndicatorSetting[] | undefined
  employeeQuery: any
  checkShowCost: boolean
  timesheetSetting: UseQueryResult<CompanySetting, unknown>
  groupingModelChange: (model: any) => void
  handleTimesheetSelection: (row: ListTimeSheet) => void
  handleEditTimesheet: (timesheet: ReponseListTimeSheet) => void
  handleViewTimesheet: (row: ListTimeSheet) => void
  handleLockTimesheet: (row: ListTimeSheet) => void
  handleUnLockTimesheet: (row: ListTimeSheet) => void
  handleApproveTimesheet: (row: ListTimeSheet) => void
  handleScheuledShift: (row: ListTimeSheet) => void
  handleUnApproveTimesheet: (row: ListTimeSheet) => void
  handleSelectedScheuledShift: (shift: ListShiftForTimeSheet) => void
  handleCreateClockIn: (row: ListTimeSheet) => void
  handleCreateClockOut: (row: ListTimeSheet) => void
  handleSelectedClockIn: (shift: ListShiftForTimeSheet) => void
  handleDeleteTimesheet: (row: ListTimeSheet) => void
  handleTimesheetDetail: (row: ListTimeSheet) => void
  handleApprovedDetails: (row: ListTimeSheet) => void
  handleApproveByShift: (row: ListTimeSheet) => void
  handleSelectApproveByShift: (shift: ListShiftForTimeSheet, timesheet: ListTimeSheet) => void
  handleViewProfile: (id: number) => void
  handleCreateAndApproveShift: (shift: ListShiftForTimeSheet) => void
  handleCreateAndEdit: (shift: ListShiftForTimeSheet) => void
  handleShowImage: (urlImage: string | undefined) => void
  handleViewListTimesheet: (row: ListTimeSheet | undefined) => void
}

export default function TimesheetTable ({ handleLoading, data, apiRef, isLoading, settings, checkShowCost, timesheetSetting, createRole, handleViewListTimesheet, handleShowImage, handleTimesheetSelection, handleCreateAndEdit, handleCreateAndApproveShift, handleViewProfile, employeeQuery, handleSelectApproveByShift, handleApproveByShift, handleApprovedDetails, handleTimesheetDetail, handleDeleteTimesheet, handleSelectedClockIn, handleCreateClockOut, handleCreateClockIn, handleSelectedScheuledShift, handleUnApproveTimesheet, handleScheuledShift, handleApproveTimesheet, handleUnLockTimesheet, handleLockTimesheet, handleEditTimesheet, handleViewTimesheet, groupingModelChange, rowGroupingModel, initialState }: TimesheetTableProps): JSX.Element {
  const hasTotalTimesheet = !(rowGroupingModel.includes('worked_group'))
  const tableHeight = hasTotalTimesheet ? 'calc(100% - 140px)' : 'calc(100% - 88px)'

  const COLUMNS: any = useMemo(
    () => [
      {
        field: 'timeSheet_date',
        headerName: t('20_13_date'),
        width: 100,
        type: 'string',
        sortDirection: 'asc',
        sortingOrder: ['asc', 'desc', null],
        hideable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row?.employee?.employee_id !== undefined) {
            return DateTime.FormatDate(params.row.timestamp)
          }
        },
        groupingValueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row?.employee?.employee_id !== undefined) {
            const hasActions =
              params.row?.employee.employee_id
            return (hasActions != null)
              ? DateTime.FormatDate(params.row.timestamp)
              : null
          }
        }
      },
      {
        field: 'actions',
        headerName: '',
        width: 30,
        hideable: false,
        disableColumnMenu: true,
        renderCell: (params: { row: ListTimeSheet }) => {
          if (params.row?.employee?.employee_id !== undefined) {
            const hasActions =
              params.row?.employee.employee_id
            return (hasActions != null)
              ? <Action
                handleNewTimesheet={handleTimesheetSelection}
                handleEditTimesheet={handleEditTimesheet}
                handleViewTimesheet={handleViewTimesheet}
                handleLockTimesheet={handleLockTimesheet}
                handleUnLockTimesheet={handleUnLockTimesheet}
                handleApproveTimesheet={(row) => { handleApproveTimesheet(row) }}
                handleScheuledShift={(row) => { handleScheuledShift(row) }}
                handleUnApproveTimesheet={(row) => { handleUnApproveTimesheet(row) }}
                handleSelectedScheuledShift={(shift) => { handleSelectedScheuledShift(shift) }}
                handleCreateClockIn={(row) => { handleCreateClockIn(row) }}
                handleCreateClockOut={(data) => { handleCreateClockOut(data) }}
                handleSelectedClockIn={(shift) => { handleSelectedClockIn(shift) }}
                handleDeleteTimesheet={(row) => { handleDeleteTimesheet(row) }}
                handleTimesheetDetail={(row) => { handleTimesheetDetail(row) }}
                handleApprovedDetails={(row) => { handleApprovedDetails(row) }}
                handleApproveByShift={(row) => { handleApproveByShift(row) }}
                handleSelectApproveByShift={(shift, data) => { handleSelectApproveByShift(shift, data) }}
                createRole={createRole}
                timesheet={params.row} />
              : null
          }
        }
      },
      {
        field: 'employee_name',
        headerName: t('00_06_name'),
        width: 250,
        hideable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          return params.row.employee?.fullname
        },
        groupingValueGetter: (params: { row: ListTimeSheet }) => {
          return params.row.employee.fullname
        },
        renderCell: (params: any) => {
          return <FullnameCell params={params} employeeQuery={employeeQuery} callback={(data) => {
            if (data.type === 'profile') {
              handleViewProfile(data.id ?? 0)
            }
            if (data.type === 'view') {
              handleViewListTimesheet(data.timesheet)
            }
          }} />
        }
      },
      {
        field: 'scheduled_shift',
        headerName: t('20_26_scheduled_shift'),
        minWidth: 250,
        maxWidth: 400,
        groupable: false,
        hideable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row?.shifts?.length > 0) {
            return params.row.shifts[0].start_time
          }
        },
        renderCell: (props: any) => {
          return <ScheduledShiftCell createRole={createRole} params={props} callback={(data) => {
            if (data.type === 'create') {
              handleSelectedScheuledShift(data.shift)
            } else if (data.type === 'approve') {
              handleCreateAndApproveShift(data.shift)
            } else if (data.type === 'edit') {
              handleCreateAndEdit(data.shift)
            } else if (data.type === 'clockin') {
              handleSelectedClockIn(data.shift)
            }
          }} />
        }
      },
      {
        field: 'worked_time',
        headerName: t('21_00_worked_time'),
        width: 170,
        groupable: false,
        hideable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row?.timesheet) {
            return params.row.timesheet.check_in_time
          }
        },
        renderCell: (props: any) => {
          return <WorkedTimeCell params={props} settings={settings} callback={(data) => {
            if (data.type === 'edit') {
              handleEditTimesheet(data.timesheet.timesheet)
            }
            if (data.type === 'clockOut') {
              handleCreateClockOut(data.timesheet)
            }
          }} />
        }
      },
      {
        field: 'worked_group',
        headerName: t('02_00_actual_group'),
        width: 200,
        groupingValueGetter: (params: { row: ListTimeSheet }): any => {
          return params.row.timesheet?.group_name ? params.row.timesheet.group_name : t('20_03_not_working')
        },
        valueGetter: (params: { row: ListTimeSheet }) => params.row.timesheet?.group_name ?? (params.row.timesheet ? '-' : '')
      },
      {
        field: 'actual_total',
        headerName: t('02_00_actual_total'),
        width: 150,
        groupable: false,
        hideable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row?.timesheet) {
            return params.row.timesheet.actual_total
          }
        },
        renderCell: (props: any) => {
          return <ActualTotalCell params={props} timesheetSetting={timesheetSetting} t={t} />
        }
      },
      {
        field: 'status',
        headerName: t('08_01_status'),
        width: 120,
        groupable: false,
        hideable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row?.timesheet) {
            return params.row.timesheet.status
          }
        },
        renderCell: (props: any) => {
          return <StatusCell params={props} callback={(data) => {
            if (data.type === 'approved') {
              handleApproveTimesheet(data.timesheet)
            } else if (data.type === 'unapproved') {
              handleUnApproveTimesheet(data.timesheet)
            }
          }} />
        }
      },
      {
        field: 'scheduled_group',
        headerName: t('02_00_scheduled_group'),
        width: 200,
        sortingOrder: ['asc', 'desc', null],
        groupingValueGetter: (params: { row: ListTimeSheet }): any => {
          return params.row.shifts?.length > 0 ? params.row.shifts[0].parent_group_name : t('10_01_status_unscheduled')
        },
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row.shifts) {
            const groupNames = params.row.shifts.map((shift) => shift.parent_group_name)
            const nonEmptyGroupNames = groupNames.filter((name) => name)
            return nonEmptyGroupNames.length > 0 ? nonEmptyGroupNames.join('_') : '-'
          }
        }
      },
      {
        field: 'scheduled_note',
        headerName: t('02_00_scheduled_note'),
        width: 200,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row.shifts) {
            const notes = params.row.shifts.map((shift) => shift.note)
            return notes.some((note) => note) ? notes.join('_') : '-'
          }
        }
      },
      {
        field: 'scheduled_total',
        headerName: t('20_24_scheduled_total'),
        width: 150,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row.scheduleTotal !== undefined && params.row.scheduleTotal > 0) {
            return DateTime.FormatDuration(params.row.scheduleTotal)
          } else if (params.row.scheduleTotal === 0) {
            return '-'
          }
        }
      },
      {
        field: 'cost_centers',
        headerName: t('13_05_cost_center'),
        width: 200,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => params.row.timesheet?.cost_center ?? (params.row.timesheet ? '-' : '')
      },
      {
        field: 'note',
        headerName: t('20_98_note'),
        groupable: false,
        width: 200,
        valueGetter: (params: { row: ListTimeSheet }) => params.row.timesheet?.note === null ? '-' : params.row.timesheet?.note ?? (params.row.timesheet ? '-' : '')
      },
      {
        field: 'clocked_in_note',
        headerName: t('02_00_clocked_in_note'),
        width: 200,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => params.row.timesheet?.clock_in_note === '' ? '-' : params.row.timesheet?.clock_in_note ?? (params.row.timesheet ? '-' : '')
      },
      {
        field: 'clocked_in_device',
        headerName: t('02_00_clocked_in_device'),
        width: 200,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => params.row.timesheet?.clock_in_device === '' ? '-' : params.row.timesheet?.clock_in_device ?? (params.row.timesheet ? '-' : '')
      },
      {
        field: 'clock_in_location',
        headerName: t('02_00_clocked_in_location'),
        width: 200,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => params.row.timesheet?.clock_in_location === '' ? '-' : params.row.timesheet?.clock_in_location ?? (params.row.timesheet ? '-' : '')
      },
      {
        field: 'clocked_in_photo',
        headerName: t('02_00_clocked_in_image'),
        width: 100,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row?.timesheet) {
            return params.row.timesheet.clock_in_image
          }
        },
        renderCell: (params: { row: ListTimeSheet }) => {
          if (params.row.timesheet?.clock_in_image === '') {
            return '-'
          } else {
            return (
              <ClockInOutPhoto
                row={params.row}
                type='clock_in_image'
                onClick={(urlImage) => { handleShowImage(urlImage) }}
              />
            )
          }
        }
      },
      {
        field: 'clocked_out_photo',
        headerName: t('02_00_clocked_out_image'),
        width: 100,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row?.timesheet) {
            return params.row.timesheet.clock_out_image
          }
        },
        renderCell: (params: { row: ListTimeSheet }) => {
          if (params.row.timesheet?.clock_in_image === '') {
            return '-'
          } else {
            return (
              <ClockInOutPhoto
                row={params.row}
                type='clock_out_image'
                onClick={(urlImage) => { handleShowImage(urlImage) }}
              />
            )
          }
        }
      },
      {
        field: 'clocked_out_note',
        headerName: t('02_00_clocked_out_note'),
        width: 200,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => params.row.timesheet?.clock_out_note === '' ? '-' : params.row.timesheet?.clock_out_note ?? (params.row.timesheet ? '-' : '')
      },
      {
        field: 'clock_out_device',
        headerName: t('02_00_clocked_out_device'),
        width: 200,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => params.row.timesheet?.clock_out_device === '' ? '-' : params.row.timesheet?.clock_out_device ?? (params.row.timesheet ? '-' : '')
      },
      {
        field: 'clock_out_location',
        headerName: t('02_00_clocked_out_location'),
        width: 200,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => params.row.timesheet?.clock_out_location === '' ? '-' : params.row.timesheet?.clock_out_location ?? (params.row.timesheet ? '-' : '')
      },
      {
        field: 'approved_total',
        headerName: t('20_23_approved_total'),
        width: 120,
        groupable: false,
        valueGetter: (params: { row: ListTimeSheet }) => {
          if (params.row.timesheet?.approved_total) {
            return DateTime.FormatDuration(params.row.timesheet.approved_total)
          } else {
            return params.row.timesheet ? '-' : ''
          }
        }
      }
    ],
    [settings, timesheetSetting.data]
  )
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const noData = require('../../../assets/images/no_data_found.svg').default
  const NoResultsOverlay = (): JSX.Element => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <img src={noData} alt="no-data"></img>
        {t('01_01_no_templates_found')}
      </Stack>
    )
  }
  return (
    <Box height={tableHeight} paddingX={4} key={'TimesheetTable'}>
      <DataGridPremium
        rowHeight={44}
        sx={{ color: 'text.primary' }}
        columns={COLUMNS}
        rows={data}
        loading={isLoading || handleLoading}
        apiRef={apiRef}
        slots={{
          noRowsOverlay: NoResultsOverlay
        }}
        defaultGroupingExpansionDepth={-1}
        rowGroupingModel={rowGroupingModel}
        disableRowSelectionOnClick
        initialState={{
          ...initialState,
          sorting: {
            sortModel: [
              {
                field: 'employee_name',
                sort: 'asc'
              }
            ]
          },
          columns: {
            columnVisibilityModel: {
              cost_centers: checkShowCost,
              scheduled_group: false,
              scheduled_note: false,
              scheduled_total: false,
              note: false,
              clocked_in_note: false,
              clocked_in_device: false,
              clock_in_location: false,
              clocked_in_photo: false,
              clocked_out_photo: false,
              clocked_out_note: false,
              clock_out_device: false,
              clock_out_location: false,
              approved_total: false
            }
          }
        }}
        hideFooterPagination
        hideFooterSelectedRowCount
        onRowGroupingModelChange={(model) => { groupingModelChange(model) }}
        groupingColDef={(params) => {
          const override: GridGroupingColDefOverride = {}
          if (params.fields.includes('worked_group')) {
            return {
              headerName: t('02_00_actual_group') ?? '',
              sortDirection: 'asc',
              valueFormatter: (valueFormatterParams) => {
                const rowNode = valueFormatterParams.id !== null && valueFormatterParams.id !== undefined
                  ? apiRef.current.getRowNode(valueFormatterParams.id)
                  : null
                if (
                  rowNode?.type === 'group' &&
                  rowNode?.groupingField === 'worked_group'
                ) {
                  return `${String(rowNode.groupingKey) ?? ''}`
                }
                return undefined
              }
            }
          }
          if (params.fields.includes('scheduled_group')) {
            return {
              headerName: t('02_00_scheduled_group') ?? '',
              sortingOrder: ['asc', 'desc', null],
              valueFormatter: (valueFormatterParams) => {
                const rowNode = valueFormatterParams.id !== null && valueFormatterParams.id !== undefined
                  ? apiRef.current.getRowNode(valueFormatterParams.id)
                  : null
                if (
                  rowNode?.type === 'group' &&
                  rowNode?.groupingField === 'scheduled_group'
                ) {
                  return `${String(rowNode.groupingKey) ?? ''}`
                }
                return undefined
              }
            }
          }
          if (params.fields.includes('employee_name')) {
            return {
              headerName: t('00_06_name') ?? '',
              hideable: false,
              groupable: false,
              valueFormatter: (valueFormatterParams) => {
                const rowNode = valueFormatterParams.id !== null && valueFormatterParams.id !== undefined
                  ? apiRef.current.getRowNode(valueFormatterParams.id)
                  : null
                if (
                  rowNode?.type === 'group' &&
                  rowNode?.groupingField === 'employee_name'
                ) {
                  return `${String(rowNode.groupingKey) ?? ''}`
                }
                return undefined
              }
            }
          }
          return override
        }}
      />
    </Box>
  )
}
