import React from 'react'
import { Snackbar, Alert, Grow, type GrowProps } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { DialogAlertModal } from '..'
import { updateModalState, updateSnackbarState } from '../../redux'
export const ContainerDialog = (): JSX.Element => {
  const ConfirmDialog = useSelector((state: any) => state.ConfirmDialog)
  const SnackbarState = useSelector((state: any) => state.Snackbar)
  const dispatch = useDispatch()
  function GrowTransition (props: GrowProps): JSX.Element {
    return <Grow {...props} />
  }
  return (
    <>
      {(Boolean(ConfirmDialog.visible)) && (
        <DialogAlertModal
          title={ConfirmDialog.title}
          message={ConfirmDialog.message}
          form={ConfirmDialog.form}
          yesText={ConfirmDialog.yesText}
          yesBtnColor={ConfirmDialog.yesBtnColor }
          noText={ConfirmDialog.noText}
          open={ConfirmDialog.visible}
          onClose={(yes) => {
            const newState = {
              ...ConfirmDialog,
              visible: false
            }
            if (yes !== true && (Boolean(ConfirmDialog.actionNo))) {
              ConfirmDialog.actionNo(ConfirmDialog.paramNo)
            }
            if (yes === true && (Boolean(ConfirmDialog.actionYes))) {
              ConfirmDialog.actionYes(ConfirmDialog.paramYes)
            }
            dispatch(updateModalState(newState))
          }}
        />
      )}
      {(Boolean(SnackbarState.visible)) && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={SnackbarState.visible}
          autoHideDuration={3000}
          TransitionComponent={GrowTransition}
          onClose={() =>
            dispatch(updateSnackbarState({ ...SnackbarState, visible: false }))
          }
        >
          <Alert
            onClose={() =>
              dispatch(
                updateSnackbarState({ ...SnackbarState, visible: false })
              )
            }
            severity={SnackbarState.type}
            sx={{ width: '100%' }}
          >
            {SnackbarState.message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}
