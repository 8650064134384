import { TextField, type TextFieldProps } from '@mui/material'
import React, { useEffect, useState } from 'react'
interface InputHourProps {
  PropsText?: TextFieldProps
  onChange: (value: number | null) => void
  value: number | null
}
export const InputHour = (props: InputHourProps): JSX.Element => {
  const [valueViewer, setValueViewer] = useState<string>('')
  const BuildValueViewer = (value: string): number | null => {
    let time = ''
    let total = 0
    if (value.length > 0) {
      if (value.search('h') > 0 || value.search('H') > 0) {
        const arr = value.match(/[+-]?\d+(?:\.\d+)?/g)
        if ((arr != null) && arr?.length > 0) {
          total =
            Math.abs(parseInt(arr[0]) * 60) + ((arr[1].length > 0) ? parseInt(arr[1]) : 0)
          time = `${Math.abs(parseInt(arr[0]))} hrs ${((arr[1].length > 0) ? `${parseInt(arr[1])} min` : '')}`
        }
      } else {
        const valueNum = parseInt(value)
        if (Number.isInteger(valueNum)) {
          if (valueNum < 60) {
            total = Math.abs(valueNum)
            time = `${Math.abs(valueNum)} min`
          } else {
            total = Math.abs(valueNum)
            time = `${Math.abs(Math.floor(valueNum / 60))} hrs ${((valueNum % 60 > 0) ? `${valueNum % 60} min` : '')}`
          }
        } else {
          time = ''
        }
      }
    }
    if (time === '') {
      setValueViewer('')
      return null
    }
    setValueViewer(time)
    return total * 60
  }
  useEffect(() => {
    BuildValueViewer(
      (props.value != null) ? Math.round(props.value / 60).toString() : ''
    )
  }, [props.value])
  return (
    <TextField
      {...props.PropsText}
      value={valueViewer}
      onBlur={(e) => {
        props.onChange?.(BuildValueViewer(((e.target.value.length > 0) || '').toString()))
      }}
      onChange={(e) => {
        setValueViewer(e.target.value)
      }}
    ></TextField>
  )
}
