import * as React from 'react'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoginApi } from '../../../services/Swagger'
import Storage from '../../../services/storage'
import { useNavigate } from 'react-router-dom'
import globalAxios from 'axios'

export default function LogIn (): JSX.Element {
  const navigate = useNavigate()
  const [formObject, setFormObject] = React.useState({
    error: false,
    loading: false,
    message: ''
  })
  const { t } = useTranslation()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    try {
      setFormObject({
        error: false,
        loading: true,
        message: ''
      })
      const rs = await new LoginApi().login({
        email: data.get('email')?.toString() ?? '',
        password: data.get('password')?.toString() ?? ''
      })
      Storage.setItem('APP_TOKEN', rs.data)
      globalAxios.defaults.headers.common.token = rs.data.token
      setFormObject({
        error: false,
        loading: false,
        message: rs.data.email
      })
      navigate('/timesheet')
    } catch (ex: any) {
      setFormObject({
        error: true,
        loading: false,
        message: ex.response.statusText
      })
    }
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <Box component="form" width={'100%'} onSubmit={handleSubmit} noValidate>
      <Box sx={{ mb: 4 }}>
        <Typography component="h1" variant="h5" align="center">
          {t('99_02_welcome_message')}
        </Typography>
      </Box>
      {formObject.error && (
        <Alert sx={{ mb: 2 }} severity="error">
          {t(formObject.message)}
        </Alert>
      )}
      <Box sx={{ mb: 2 }}>
        <TextField
          required
          fullWidth
          id="email"
          placeholder={t('20_40_email') ?? ''}
          name="email"
          autoComplete="email"
          autoFocus
          variant="standard"
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          required
          fullWidth
          name="password"
          type="password"
          placeholder={t('15_01_password') ?? ''}
          id="password"
          autoComplete="current-password"
          variant="standard"
        />
      </Box>
      <Grid container alignItems="center">
        <Grid item xs>
          <FormControlLabel
            control={<Checkbox value="remember" checked color="primary" />}
            label={t('15_01_remember_me')}
          />
        </Grid>
        <Grid item>
          <Link href="#" variant="body2">
            {t('20_01_forgot_your_password')}
          </Link>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, mb: 2, justifyContent: 'center', display: 'flex' }}>
        {!formObject.loading && (
          <Button type="submit" fullWidth variant="contained">
            {t('20_25_login')}
          </Button>
        )}
        {formObject.loading && <CircularProgress size={20} sx={{ mt: 2 }} />}
      </Box>
    </Box>
  )
}
