export default {
  setItem: (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getItem: (key: string) => {
    const item = localStorage.getItem(key)
    if (item != null) {
      return JSON.parse(item)
    } else {
      return null
    }
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key)
  },
  updateItem: (key: string, value: any) => {
    const item = localStorage.getItem(key)
    let result = value
    if (item != null) {
      result = { ...JSON.parse(item), ...value }
    }
    localStorage.setItem(key, JSON.stringify(result))
  },
  clear: () => { localStorage.clear() }
}
