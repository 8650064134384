import React from 'react'
import { type FUser } from '@dansoft/titanplan-services'
import { Avatar, Box, Typography } from '@mui/material'

interface RoomProps {
  user: FUser
  onClick?: () => void
}

export default function ChatUser ({ user, onClick }: RoomProps): JSX.Element {
  const handleClick = (): void => {
    if (onClick != null) {
      onClick()
    }
  }
  return (
    <Box onClick={handleClick} className="chatItem">
      <Avatar className="chatItem-avatar" alt={user.avatar} src={user.avatar} />
      <Box className="chatItem-Containerinfo">
        <Box className="chatItem-info">
          <Typography variant="h6" className="chatItem-name">
            {user.name}
          </Typography>
          <Typography variant="h5" className="chatItem-message">
            {user.group_name}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
